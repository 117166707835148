/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import styled, { css } from 'styled-components/macro'
import { THEME } from '../../../core/theme'
import { Input } from 'components'

export const StyledPassportWrapper = styled.div`
  display: flex;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const StyledButtonBlock = styled.div`
  margin: 0;
  padding: 0 32px;
  transition: 0.3s;
`

export const StyledWrapper = styled.div`
  padding: 0 32px;

  @media (max-width: 480px) {
    padding: 0 15px;
  }
`

export const StyledValue = styled.div`
  display: flex;
  margin: 12px 0;
  position: relative;
  align-items: center;

  & p {
    position: relative;
    /* top: 0; */
  }
`

export const StyledTitle = styled.p`
  margin-left: 12px;
  position: relative;
  font-weight: 400;
  font-size: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  margin-right: 15px;
  top: -3px;

  & span {
    font-size: 11px;
    /* font-weight: bold; */
    background-color: gray;
    padding: 2px 3px;
    border-radius: 5px;
    color: white;
    opacity: 0.3;
  }
`

export const StyledSection = styled.div`
  margin-top: 50px;
  padding-bottom: 25px;
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};

  &:last-child {
    border-bottom: none;
  }
`
export const StyledCheckTitle = styled.p`
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 18px;
  color: #08003a;
  margin-right: 15px;
`

export const StyledWarningTitle = styled.p`
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #ff4646;
  margin-top: 25px;
`

export const StyledCheckBlock = styled.div`
  margin-bottom: 5px;
  margin-top: 20px;
`

export const StyledBadgeInfo = styled.div`
  display: flex;
  align-items: center;
`
export const StyledBadgeInfoDescription = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #08003a;
  margin-left: 10px;
`

export const StyledActionButton = styled.div<{ active: boolean }>`
  width: fit-content;
  padding: 2px 12px;
  /* background: ${THEME.COLORS.YELLOW}; */
  border-radius: 40px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);

  &:hover {
    background: ${THEME.COLORS.YELLOW_HOVER};
  }

  ${({ active }) =>
    active &&
    css`
      background: ${THEME.COLORS.YELLOW};
    `};
`

export const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledCaptchaBlock = styled.div`
  display: flex;
  align-items: center;
`
export const StyledCaptchaValue = styled.div`
  border: 1px solid ${THEME.COLORS.BORDER_LOAN};
  width: fit-content;
  border-radius: 16px;
  font-size: 25px;
  display: flex;
  padding: 8px 20px;
  margin-right: 10px;
  position: relative;
`
export const StyledCaptchaFilterDrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0.7;
`

export const StyledCaptchaSum = styled.p`
  font-size: 30px;
  display: flex;
  margin: 0 5px;
  font-weight: bold;
  filter: blur(2.5px);
`

export const StyledInput = styled(Input)`
  border: 1px solid ${THEME.COLORS.BORDER_LOAN};
  width: fit-content;
  border-radius: 16px;
`
export const StyledButtonsBlock = styled.div`
  display: flex;
  margin-top: 50px;
  border-top: 1px solid ${THEME.COLORS.BORDER_LOAN};
  padding-top: 25px;
  padding-left: 30px;
`
export const StyledSuccessBlock = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  & svg {
    margin-bottom: 20px;
  }
`

export const StyledSuccessTitle = styled.p`
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: #08003a;
`

export const StyledErrorSendBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`
export const StyledErrorSendTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  color: #08003a;
  margin-left: 10px;
  margin-right: 15px;
`

export const StyledLetterWindowWrapper = styled.div`
  position: fixed;
  width: 1000px;
  height: 670px;
  /* right: 100px; */
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 999;
  border: 1px solid #8080801a;
  max-height: 670px;
  /* position: absolute; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledLetterWindowHeader = styled.div`
  background: #3b414f;
  border-radius: 20px 20px 0px 0px;
  padding: 20px 25px;
`

export const StyledLetterWindowBody = styled.div`
  background: #ffffff;
  border-radius: 0px 0px 20px 20px;
  padding: 20px 25px;
  font-weight: bold;
  max-height: 670px;
  overflow: auto;
`

export const StyledLetterTitle = styled.p`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
`

export const StyleCloseModal = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: ${THEME.COLORS.WHITE};
  border-radius: 50%;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.35);
  padding: 10px;
  display: flex;
  cursor: pointer;
`

export const StyledLettersCount = styled.div`
  position: fixed;
  top: 84px;
  right: 350px;
  background: #2333cb;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.2);
  border-radius: 28px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
`

export const StyledLettersCountTitle = styled.p`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
`

export const StyledLettersCountNumber = styled.div`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: yellow;
  margin-left: 10px;
`

export const StyledLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1111111;
  pointer-events: none;
`

export const StyledSdlOptionalTitle = styled.p`
  font-weight: bold;
  font-size: 22px;
  line-height: 16px;
  color: #08003a;
  margin-top: 50px;
  margin-bottom: 25px;
`

export const StyledSdlItemValue = styled.div``

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px;
`
