/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ReactSelect, { components } from 'react-select'

import { THEME } from '../../core/theme'
import { ReactComponent as DropdownIndicatorSVG } from '../../assets/arrow-drop-down.svg'

import { ISelect } from './types'
import { StyledSelectPlaceholder, StyledSelectWrapper } from './Select.styles'

export const Select: React.FC<ISelect> = ({
  theme = 'filters',
  isMulti = false,
  isDisabled,
  placeholder,
  showPlaceholder = true,
  defaultValue,
  title,
  data,
  error = false,
  isClearable = false,
  value,
  ...rest
}) => {
  const DropdownIndicator = (props) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorSVG style={{ opacity: !value || value.length === 0 ? 1 : 0 }} />
      </components.DropdownIndicator>
    )

  const conmponents = {
    filters: {
      IndicatorSeparator: () => null,
      DropdownIndicator,
    },
    roleSelector: {
      IndicatorSeparator: () => null,
      DropdownIndicator,
    },
  }

  return (
    <StyledSelectWrapper>
      {title}
      <ReactSelect
        {...rest}
        isClearable={isClearable}
        isMulti={isMulti}
        styles={themes[error ? 'filtersErrored' : theme]}
        options={data}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        components={conmponents[theme]}
        placeholder={placeholder}
        value={value}
        menuPortalTarget={document?.querySelector('body')}
      />
      {showPlaceholder && value && placeholder && (
        <StyledSelectPlaceholder>{placeholder}</StyledSelectPlaceholder>
      )}
    </StyledSelectWrapper>
  )
}

const themes = {
  default: {
    menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
    control: (base) => ({
      ...base,
      height: 70,
      borderRadius: 0,
      background: THEME.COLORS.WHITE,
      border: 'none',
      width: '100%',
      fontSize: '18px',
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: 'all .2s ease',
      color: `${THEME.COLORS.DARK_BLUE}`,
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'none',
      marginBottom: 0,
      marginTop: 0,
      borderTop: '1px solid #dde8ef;',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      cursor: 'pointer',
    }),
    menuList: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: '0px 1px 3px rgba(0, 44 , 119, 0.04)',
      padding: 0,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      cursor: 'pointer',
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '16px',
      fontSize: '18px',
      height: '70px',
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? `${THEME.COLORS.WHITE}` : `${THEME.COLORS.WHITE}`,
      color: isSelected ? 'rgb(45 54 70 / 0.5)' : `${THEME.COLORS.DARK_BLUE}`,
      height: '62px',
      fontSize: '16px',
      paddingLeft: '16px',
      lineHeight: '2.5',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],
        backgroundColor: '#EAF1F5',
        cursor: 'pointer',
      },
      ':hover': {
        ...base[':active'],
        backgroundColor: '#EAF1F5',
        cursor: 'pointer',
      },
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '18px',
      color: `${THEME.COLORS.DARK_BLUE}`,
      fontWeight: 600,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '28px',
      color: 'red',
    }),
  },
  filters: {
    menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
    control: (base, { menuIsOpen }) => ({
      ...base,
      // height: 60,
      minHeight: '60px',
      borderRadius: 16,
      background: 'white',
      width: '100%',
      fontSize: '14px',
      lineHeight: '16px',
      boxShadow: 'none',
      cursor: 'pointer',
      border: menuIsOpen ? `2px solid ${THEME.COLORS.YELLOW} ` : `2px solid #dde8ef`, // TODO
      '&:hover': {
        border: `2px solid ${THEME.COLORS.YELLOW}`,
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: 'all .2s ease',
      color: `${THEME.COLORS.DARK_BLUE}`,
      marginRight: '15px',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'none',
      marginBottom: 0,
      marginTop: 0,
      borderTop: '1px solid #dde8ef;',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      cursor: 'pointer',
      zIndex: '1000',
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: '15px',
      height: 'auto',
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
      padding: 0,
      margin: 0,
      // marginTop: '20px',
      paddingBottom: 0,
      paddingTop: 0,
      cursor: 'pointer',
      lineHeight: '36px',
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '16px',
      fontSize: '14px',
      color: 'red',
      // height: '60px',
      minHeight: '60px',
      borderRadius: '20px',
      lineHeight: '36px',
    }),
    multiValue: (base) => ({
      ...base,
      background: '#00b8d91a',
      borderRadius: '12px',
      padding: '0',
      paddingRight: '0',
      lineHeight: '30px',
    }),
    multiValueLabel: (base, state) =>
      state.data.isFixed
        ? {
            ...base,
            backgroundColor: '#47ad82cf',
            fontWeight: 'bold',
            borderRadius: '12px',
            paddingRight: '8px',
            color: 'white',
          }
        : base,
    multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: 'none' } : base),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? `${THEME.COLORS.WHITE}` : `${THEME.COLORS.WHITE}`,
      color: `${THEME.COLORS.DARK_BLUE}`,
      // height: '42px',
      fontSize: '14px',
      lineHeight: '2.5',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],
        backgroundColor: '#EAF1F5',
        cursor: 'pointer',
      },
      ':hover': {
        ...base[':active'],
        backgroundColor: '#EAF1F5',
        cursor: 'pointer',
      },
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
      lineHeight: '19px',
      color: `${THEME.COLORS.DARK_BLUE}`,
      fontWeight: '600',
      bottom: '25px',
      overflow: 'visible',
    }),
  },
  filtersErrored: {
    menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
    control: (base, { menuIsOpen }) => ({
      ...base,
      height: 60,
      borderRadius: 16,
      background: 'white',
      width: '100%',
      fontSize: '14px',
      lineHeight: '16px',
      boxShadow: 'none',
      cursor: 'pointer',
      border: menuIsOpen ? `2px solid ${THEME.COLORS.YELLOW} ` : `2px solid red`, // TODO
      '&:hover': {
        border: `2px solid ${THEME.COLORS.YELLOW}`,
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: 'all .2s ease',
      color: `red`,
      marginRight: '15px',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'none',
      marginBottom: 0,
      marginTop: 0,
      borderTop: '1px solid #dde8ef;',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      cursor: 'pointer',
      zIndex: '999',
    }),
    menuList: (provided) => ({
      ...provided,

      borderRadius: '15px',
      height: 'auto',
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
      padding: 0,
      margin: 0,
      marginTop: '20px',
      paddingBottom: 0,
      paddingTop: 0,
      cursor: 'pointer',
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '16px',
      fontSize: '14px',
      height: '60px',
      borderRadius: '20px',
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? `${THEME.COLORS.WHITE}` : `${THEME.COLORS.WHITE}`,
      color: `${THEME.COLORS.DARK_BLUE}`,
      fontSize: '14px',
      lineHeight: '2.5',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],
        backgroundColor: '#EAF1F5',
        cursor: 'pointer',
      },
      ':hover': {
        ...base[':active'],
        backgroundColor: '#EAF1F5',
        cursor: 'pointer',
      },
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
      lineHeight: '19px',
      color: `${THEME.COLORS.DARK_BLUE}`,
      fontWeight: 'bold',
      // bottom: '35px',
      overflow: 'visible',
    }),
  },
  roleSelector: {
    menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
    control: (base, { menuIsOpen }) => ({
      ...base,
      height: 60,
      borderRadius: 3,
      background: '#24C665',
      width: '100%',
      fontSize: '14px',
      lineHeight: '16px',
      boxShadow: 'none',
      cursor: 'none',
      border: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: 'all .2s ease',
      color: `red`,
      marginRight: '15px',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'none',
      marginBottom: 0,
      marginTop: 0,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      cursor: 'pointer',
      zIndex: '999',
    }),
    menuList: (provided) => ({
      ...provided,
      height: 'auto',
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
      padding: 0,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      cursor: 'pointer',
      backgroundColor: 'white',
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '16px',
      fontSize: '16px',
      height: '60px',
      fontWeight: 'bold',
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? '#f8cc46' : '#fff',

      color: `${THEME.COLORS.DARK_BLUE}`,
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '2.5',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],
        backgroundColor: 'white',
        cursor: 'pointer',
      },
      ':hover': {
        ...base[':active'],
        backgroundColor: '#EAF1F5',
        cursor: 'pointer',
      },
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '18px',
      lineHeight: '19px',
      color: `white`,
      fontWeight: 'bold',
      overflow: 'visible',
    }),
  },
}
