/* eslint-disable indent */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { orderBy } from 'lodash'
import { GlobalState } from 'index'

import { ExpandedContainer, Module, Button } from 'components'

import { fetchFsspData } from 'features/loan/extra'
import { useGetLoanPassportData } from 'features/loan/loanSelectors'
import { StyledFsspContent, StyledRefresh } from './Modules.styles'

const moduleId = 'fssp'

export const Fssp: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const dispatch = useDispatch()
  const isOpen = openedModules.includes(moduleId)
  const {
    loadData: { loan_number: currentLoanNumber },
    userId,
    fssp: { isFetching, data },
  } = useSelector((state: GlobalState) => state.singleLoan)

  const [fsspData, setFsspData] = useState(undefined)

  useEffect(() => {
    if (data?.data) setFsspData(data?.data)
  }, [data?.data])

  const handleExpand = (): void => onToggleModule(moduleId)

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    middle_name: middleName = '',
    date_of_birth: birthDate,
  } = useGetLoanPassportData()

  const handleUpdateFssp = (): void => {
    dispatch(
      fetchFsspData({
        data: {
          last_name: lastName,
          first_name: firstName,
          mid_name: middleName,
          birth_date: moment(new Date(birthDate)).format('DD.MM.YYYY'),
        },
        userId,
        number: currentLoanNumber,
        force: true,
      })
    )
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            ФССП
            {fsspData && !fsspData?.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {isFetching && <Module.LoaderBlock />}
        <ExpandedContainer elements={fsspData?.length || 0} isFetching={isFetching}>
          {data?.detail === 'error' || !fsspData ? (
            <StyledRefresh>
              Возможно отчет не успел сформироваться:
              <Button type='smallSuccess' onClick={handleUpdateFssp}>
                Запросить повторно
              </Button>
            </StyledRefresh>
          ) : (
            <StyledFsspContent>
              <TableHead />
              {fsspData?.length
                ? orderBy(fsspData, ['status_date'], ['desc']).map((item, index) => (
                    <Table key={item?.loans_loan_id || index} {...item} />
                  ))
                : null}
            </StyledFsspContent>
          )}
        </ExpandedContainer>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Информация о должнике</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Даты</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Причина окончания или прекращения ИП </Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Предмет исполнения </Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Сумма непогашенной задолженности </Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Информация об отделе судебных приставов</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Реквизиты исполнительного производства (ИП) </Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props: any): JSX.Element => {
  const { debtor, date, reason, subject, sum, department, executiveDocument } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {debtor?.name} {debtor?.birthDate}г.р.
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{date || '-'}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {reason && Object.keys(reason).length ? (
            <>
              {reason.article && `Статья ${reason.article}`}
              {reason.part && `, часть ${reason.part}`}
              {reason.paragraph && `, пункт ${reason.paragraph}`}
            </>
          ) : (
            '-'
          )}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{subject}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{sum === '0.0' ? 0 : `${sum} руб.`}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{Object.values(department || {}).join(', ')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {Object.values(executiveDocument || {}).map((str) => (
            <>
              {str}
              <br />
              <br />
            </>
          ))}
        </Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
