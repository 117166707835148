/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { orderBy } from 'lodash'

import { GlobalState } from 'index'
import { Module } from 'components'
import { normalizeValues } from 'helpers'

import {
  StyledHeadTitle,
  StyledLoansDebtWrapper,
  StyledLoanTransactionContent,
} from './Modules.styles'

const moduleId = 'repaymentHistory'

interface IAccrueHistory {
  loan_id: string
  openedModules: string[]
  onToggleModule: (anchor: string) => void
  sameAddress?: boolean
}

export const RepaymentHistory: React.FC<IAccrueHistory> = ({
  openedModules,
  onToggleModule,
  loan_id: loanId,
}): JSX.Element => {
  const { dataTransactions, isFetchingTransactions } = useSelector(
    (state: GlobalState) => state.contracts
  )
  const isOpen = openedModules.includes(moduleId)
  const [loansData, setLoansData] = useState([])

  const handleExpand = (): void => onToggleModule(moduleId)

  useEffect(() => {
    if (dataTransactions && dataTransactions.length) setLoansData(dataTransactions)
  }, [dataTransactions])

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <StyledHeadTitle>
            История погашений
            {!loansData.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </StyledHeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {isFetchingTransactions && <Module.LoaderBlock />}
        <StyledLoanTransactionContent>
          <TableHead />
        </StyledLoanTransactionContent>
        {loansData && loansData.length
          ? orderBy(loansData, ['full_date'], ['desc']).map((item, index) => (
              <StyledLoansDebtWrapper key={item?.loans_loan_id || index}>
                <StyledLoanTransactionContent>
                  <Table {...item} key={item?.loans_loan_id || index} />
                </StyledLoanTransactionContent>
              </StyledLoansDebtWrapper>
            ))
          : null}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Сумма</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Тип платежа</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Вид платежа</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата и время</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const { trans_type: type, trans_date: date, amount, trans_method: method } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(amount)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(type)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(method)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(date, 'source_full_date')}</Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
