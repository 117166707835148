import styled from 'styled-components/macro'

export const StyledLoaderPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledWrapper = styled.div`
  display: flex;
`

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
  max-height: calc(100vh - 125px);
  overflow-y: scroll;
  padding-bottom: 70px;

  /* @media (max-width: 480px) {
    max-height: 100vh;
  } */
`

export const StyledBTN = styled.div``

export const StyledNavContent = styled.div`
  width: 100%;
`
