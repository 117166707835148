/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components/macro'

interface IStyledExpanderWrapper {
  expanded: boolean
}

export const StyledExpanderWrapper = styled.div<IStyledExpanderWrapper>`
  position: relative;
  height: 600px;
  overflow: hidden;
  transition: all 0.5s;
  overflow-x: auto;
  ${({ expanded }) =>
    expanded &&
    css`
      height: 100%;

      ${StyledGradient} {
        display: none;
      }
      ${StyledButtonWrapper} {
        display: none;
      }
    `};

  @media (max-width: 480px) {
    background: #f5f6fa;
  }
`

export const StyledButtonWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%);
  z-index: 9;
`

export const StyledGradient = styled.div`
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(255, 255, 255, 0.4150035014005602) 23%,
    rgba(255, 255, 255, 1) 100%
  );
`

export const StyledButton = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  border: 1px solid #80808014;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 44, 119, 0.35);
  background: white;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  opacity: 1;
  border: 1px solid #80808038;
  opacity: 0.8;
  transition: 0.2s;

  & svg {
    position: relative;
    left: 0px;
    top: -1px;
  }

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    width: 50px;
    height: 50px;

    svg {
      width: 9px;
    }
  }
`
