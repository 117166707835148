import moment from 'moment'

export function getDifferenceDateFromNow(date: string): string {
  const nowDate = new Date().toISOString()
  const currentUTCDate = moment.utc(nowDate).format('YYYY/MM/DD  HH:mm:ss')
  const loanCreatedDate = moment(new Date(date)).format('YYYY/MM/DD  HH:mm:ss')
  const difference = moment
    .utc(
      moment(currentUTCDate, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(loanCreatedDate, 'DD/MM/YYYY HH:mm:ss')
      )
    )
    .format('HH:mm:ss')

  return difference
}

export function getDifferenceDateFromTotalTime(totalTime: number, secondsCounter: number): string {
  const seconds = Math.round(totalTime) + secondsCounter
  const startTime = moment(new Date().toISOString()).format('YYYY/MM/DD HH:mm:ss')
  const addSeconds = moment(startTime, 'YYYY/MM/DD HH:mm:ss')
    .add(seconds, 'seconds')
    .format('YYYY/MM/DD HH:mm:ss')
  const difference = moment.duration(moment(addSeconds).diff(startTime))

  return moment.utc(difference.as('milliseconds')).format('HH:mm:ss')
}
