import styled from 'styled-components/macro'

export const StyledTimeLimitation = styled.div`
  display: none;
  position: relative;
  & svg {
    position: relative;
    top: 1px;
    margin-left: 5px;
  }
`
export const StyledTimeLimitationTitle = styled.p`
  display: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 12px;
  top: -18px;
  left: 31px;
  z-index: 9;
  width: 270px;
  font-size: 12px;
  font-weight: bold;
`
