/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik } from 'formik'
import { useContext, useEffect } from 'react'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { DatePickerField, InputField, setInputValue } from 'helpers/form'
import { agentFilterStatus, clearEmpties, getAdminFio, validationSchemaFilters } from 'helpers'
import { Select, Button } from 'components'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import { ReactComponent as ArrowUpSVG } from 'assets/arrow-up.svg'
import { ReactComponent as CloseDarkSVG } from 'assets/close-dark.svg'

import { useGetAgentUserNames, useGetAgentUserNamesWithIds } from 'features/dict/dictSelectors'
import { setAgentsFilters } from 'features/agentFilters/filtersSlice'

import { GlobalState } from 'index'

import {
  StyledFiltersWrapper,
  StyledResetFilter,
  StyledSelectBlock,
  StyledFiltersBlock,
  StyledActionsBlock,
  StyledTitle,
  StyledAction,
  StyledFiltersContent,
} from './Filters.styles'
import { CreditProductTranslate } from '../../../constants'
import { NavigationContext } from 'app/contexts'
import { AgentLoanStatusType } from 'features/agentLoans/types'

const formatDate = (date, format) => (date ? moment(new Date(date)).format(format) : date)
const formatValues = (values) => {
  let currentFilters = {
    ...values,
  }

  if (values.loan_creation_date?.start_date) {
    currentFilters = {
      ...currentFilters,
      loan_creation_date: {
        ...(currentFilters.loan_creation_date || {}),
        start_date: formatDate(values.loan_creation_date.start_date, 'YYYY-MM-DD'),
      },
    }
  }
  if (values.loan_creation_date?.stop_date) {
    currentFilters = {
      ...currentFilters,
      loan_creation_date: {
        ...(currentFilters.loan_creation_date || {}),
        stop_date: formatDate(values.loan_creation_date.stop_date, 'YYYY-MM-DD'),
      },
    }
  }
  if (values.loan_sum?.sum_from) {
    currentFilters = {
      ...currentFilters,
      loan_sum: {
        ...(currentFilters.loan_sum || {}),
        sum_from: +values.loan_sum.sum_from,
      },
    }
  }
  if (values.loan_sum?.sum_to) {
    currentFilters = {
      ...currentFilters,
      loan_sum: {
        ...(currentFilters.loan_sum || {}),
        sum_to: +values.loan_sum.sum_to,
      },
    }
  }
  return currentFilters
}

export const Filters: React.FC<any> = ({
  showFilters,
  toggleFilters,
  pending,
  type,
}): JSX.Element => {
  const dispatch = useDispatch()
  const dictData = useSelector((state: GlobalState) => state.dict)
  const agentUserNamesWithIds = useGetAgentUserNamesWithIds() || []

  const { agentManagerUsernames = [] } = useSelector((store: any) => store.dict.data)
  const { username = '' } = useSelector((store: GlobalState) => store.auth?.user || {})
  const { isAgent, isManager } = useContext(NavigationContext)

  const AgentUsernamesList = agentUserNamesWithIds.map((value) => ({
    label: getAdminFio(value.username, dictData),
    value: value.username,
  }))

  const AgentMangerUsernamesList = Object.keys(agentManagerUsernames).map((value) => ({
    label: getAdminFio(agentManagerUsernames[value], dictData),
    value: agentManagerUsernames[value],
  }))

  const submitForm = async (values: any): Promise<void> => {
    if (pending) {
      return
    }
    dispatch(setAgentsFilters(getOmitFilters(values)))
    toggleFilters()
  }
  const resetAllFilters = (resetForm) => (): void => {
    resetForm()
  }

  const { data: filters } = useSelector((store: GlobalState) => store.agentFilters)

  const getOmitFilters = (values) => {
    let agentUsername = values.agent_username || null
    if (!agentUsername) {
      if (!isManager && isAgent) {
        agentUsername = username
      }
    }
    const clearObj = formatValues(clearEmpties({ ...values }))
    return {
      ...clearObj,
      ...(agentUsername ? { agent_username: agentUsername } : {}),
      ...(values.agent_username && agentUsername === values.agent_manager_username
        ? { agent_manager_username: null }
        : {}),
    }
  }

  const agentFilterStatuses = Object.keys(agentFilterStatus).map((value) => ({
    label: agentFilterStatus[value],
    value,
  }))

  return (
    <Formik
      initialValues={{
        loan_creation_date: {
          start_date: filters?.loan_creation_date?.start_date || null,
          stop_date: filters?.loan_creation_date?.stop_date || null,
        },
        loan_sum: {
          sum_from: filters?.loan_sum?.sum_from || null,
          sum_to: filters?.loan_sum?.sum_to || null,
        },
        product: filters?.product || null, // data
        agent_username: filters?.agent_username || null,
        agent_manager_username: filters?.agent_manager_username || null,
        loan_status: filters?.loan_status || AgentLoanStatusType[type],
      }}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaFilters}
    >
      {(formikProps) => {
        const { values, dirty, resetForm, setFieldValue, handleSubmit }: any = formikProps

        const setValuesHandler = setInputValue(setFieldValue)

        const setDateHandler = (v, date) => setFieldValue(v, date ? +date : '')

        return (
          <StyledFiltersBlock showFilters={showFilters}>
            <StyledFiltersContent>
              <StyledFiltersWrapper>
                <DatePickerField
                  name='loan_creation_date.start_date'
                  onChange={setDateHandler}
                  placeholder='Дата создания заявки (от)'
                  value={values.loan_creation_date?.start_date || null}
                  format='dd.MM.yyyy'
                  selectsStart
                  startDate={values.loan_creation_date.start_date || null}
                  endDate={values.loan_creation_date.stop_date || null}
                  {...formikProps}
                />
                <DatePickerField
                  name='loan_creation_date.stop_date'
                  onChange={setDateHandler}
                  placeholder='Дата создания заявки (до)'
                  value={values.loan_creation_date.stop_date}
                  format='dd.MM.yyyy'
                  selectsEnd
                  startDate={values.loan_creation_date.start_date || null}
                  endDate={values.loan_creation_date.stop_date || null}
                  minDate={values.loan_creation_date.start_date || null}
                  {...formikProps}
                />
                {/* <InputField
                  name='loan_sum.sum_from'
                  onChange={setFieldValue}
                  placeholder='Сумма займа (от)'
                  value={values.loan_sum.sum_from}
                  {...formikProps}
                />
                <InputField
                  name='loan_sum.sum_to'
                  onChange={setFieldValue}
                  placeholder='Сумма займа (до)'
                  value={values.loan_sum.sum_to}
                  {...formikProps}
                /> */}

                <StyledSelectBlock>
                  <Select
                    data={AgentMangerUsernamesList}
                    placeholder='Менеджер'
                    value={
                      AgentMangerUsernamesList?.find(
                        (v) => v.value === values.agent_manager_username
                      ) || ''
                    }
                    onChange={setValuesHandler('agent_manager_username', 'value')}
                  />
                  {values.agent_manager_username && (
                    <StyledResetFilter
                      onClick={() => setFieldValue('agent_manager_username', null)}
                    >
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
                <StyledSelectBlock>
                  <Select
                    data={AgentUsernamesList}
                    placeholder='Агент'
                    value={AgentUsernamesList?.find((v) => v.value === values.agent_username) || ''}
                    onChange={setValuesHandler('agent_username', 'value')}
                  />
                  {values.agent_username && (
                    <StyledResetFilter onClick={() => setFieldValue('agent_username', null)}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
                <StyledSelectBlock>
                  <Select
                    data={CreditProductList}
                    isClearable={false}
                    isMulti
                    placeholder='Продукт'
                    showPlaceholder={false}
                    value={CreditProductList.filter((x) =>
                      values.product ? values.product.includes(x.value) : null
                    )}
                    onChange={(v) =>
                      setFieldValue(
                        'product',
                        Object.keys(v).map((x) => v[x].value)
                      )
                    }
                  />
                  {values.product && values.product.length > 0 && (
                    <StyledResetFilter onClick={() => setFieldValue('product', null)}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>

                {type === 'loan_approved' && (
                  <StyledSelectBlock>
                    <Select
                      data={agentFilterStatuses}
                      isClearable={false}
                      isMulti
                      placeholder='Статус заявки'
                      showPlaceholder={false}
                      value={agentFilterStatuses.filter((x) =>
                        values.loan_status ? values.loan_status.includes(x.value) : null
                      )}
                      onChange={(v) =>
                        setFieldValue(
                          'loan_status',
                          Object.keys(v).map((x) => v[x].value)
                        )
                      }
                    />
                    {values.loan_status && values.loan_status.length > 0 && (
                      <StyledResetFilter onClick={() => setFieldValue('loan_status', null)}>
                        <CloseSVG />
                      </StyledResetFilter>
                    )}
                  </StyledSelectBlock>
                )}
              </StyledFiltersWrapper>
              <StyledActionsBlock>
                <StyledAction onClick={toggleFilters}>
                  <ArrowUpSVG />
                  <StyledTitle>Свернуть</StyledTitle>
                </StyledAction>
                {dirty && (
                  <StyledAction onClick={resetAllFilters(resetForm)}>
                    <CloseDarkSVG />
                    <StyledTitle>Сбросить все</StyledTitle>
                  </StyledAction>
                )}
                {!isEqual(clearEmpties({ ...values }), filters) && (
                  <Button type='standard' onClick={handleSubmit} disabled={pending}>
                    Применить
                  </Button>
                )}
              </StyledActionsBlock>
            </StyledFiltersContent>
          </StyledFiltersBlock>
        )
      }}
    </Formik>
  )
}
const CreditProductList = Object.keys(CreditProductTranslate).map((value) => {
  const data = {
    label: CreditProductTranslate[value],
    value: CreditProductTranslate[value],
  }
  return data
})
