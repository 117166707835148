import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectLoansService } from '../../services/loanService'

export const fetchModalLoans = createAsyncThunk<any, { ids: string[]; type: string }>(
  'loans/modalModalLoans',
  async ({ ids, type }) => {
    let response

    if (type === 'userIds')
      response = await ProjectLoansService.getLoansByUserId(ids).then(({ data }) => data)
    if (type === 'loanIds')
      response = await ProjectLoansService.getLoansByLoanId(ids).then(({ data }) => data)

    return response?.data
  }
)
