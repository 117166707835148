import { LOCAL_STORAGE } from 'constants/localStorage'

import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { ServiceBase } from 'services/serviceBase'

import { fetchAuth, getUserInfo } from 'features/auth/extra'
import { onLogout, resetFetchingStatus } from 'features/auth/authSlice'

import { GlobalState } from 'index'
import {
  fetchAdminUsernames,
  fetchLoanStatus,
  fetchAgentUsernames,
  fetchAdminsRoles,
  fetchAgentManagerUsernames,
  fetchAdminsFio,
} from 'features/dict/extra'
import { NavMenuType } from 'App'
import { Auth } from './auth/Auth'
import { NavigationContext } from 'app/contexts'

enum AppPage {
  pending,
  auth,
  app,
}

export const PageScope = React.createContext(null)
export const App: React.FC<any> = (props) => {
  const { children } = props
  const [appState, setAppState] = useState<number>(AppPage.pending)
  const [mounted, setMounted] = useState(false)

  const { navMenuType } = useContext(NavigationContext)
  const [currentNavMenuType, setCurrentNavMenuType] = useState(null)
  const { user, pending, error, success } = useSelector((store: GlobalState) => store.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (navMenuType !== null) {
      setCurrentNavMenuType(navMenuType)
    }
  }, [navMenuType])

  useEffect(() => {
    dispatch(resetFetchingStatus())
  }, [error, success])

  useEffect(() => {
    if (appState === AppPage.app && !mounted && currentNavMenuType !== null) {
      batch(() => {
        dispatch(fetchAdminUsernames())
        dispatch(fetchLoanStatus())
        dispatch(fetchAdminsRoles())
        dispatch(fetchAdminsFio())

        if (currentNavMenuType === NavMenuType.agent) {
          dispatch(fetchAgentUsernames())
          dispatch(fetchAgentManagerUsernames())
        }
      })
      setMounted(true)
    }
    return () => {
      setMounted(false)
      setCurrentNavMenuType(null)
    }
  }, [appState, currentNavMenuType])

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE.AUTH_ITEMS)
    if (!user) {
      if (token) {
        const onSuccess = () => {
          ServiceBase.setAuthToken(token)
          setAppState(AppPage.app)
        }

        dispatch(getUserInfo({ onSuccess, handleLogout }))
      } else {
        dispatch(onLogout())
        setAppState(AppPage.auth)
        localStorage.removeItem(LOCAL_STORAGE.AUTH_ITEMS)
      }
    }
  }, [])

  const handleLogout = () => {
    dispatch(onLogout())
    setAppState(AppPage.auth)
    localStorage.removeItem(LOCAL_STORAGE.AUTH_ITEMS)
    window.location.reload()
  }

  useEffect(() => {
    if (appState === AppPage.app && !user) {
      handleLogout()
    }
  }, [user])

  const handleSignIn = ({ username, password }) => {
    const onSuccess = (token) => {
      localStorage.setItem(LOCAL_STORAGE.AUTH_ITEMS, token)
      ServiceBase.setAuthToken(token)
      setAppState(AppPage.app)
      dispatch(fetchAdminsRoles())
    }

    dispatch(fetchAuth({ username, password, onSuccess }))
  }

  switch (appState) {
    case AppPage.pending: {
      return <div>...pending</div>
    }
    case AppPage.auth: {
      return <Auth handleSignIn={handleSignIn} pending={pending} />
    }
    case AppPage.app: {
      return (
        <PageScope.Provider
          value={{
            handleLogout,
          }}
        >
          {children}
        </PageScope.Provider>
      )
    }
    default: {
      return null
    }
  }
}
