import { useEffect, useState } from 'react'
import { StyledButton, StyledDescription, StyledNotification, StyledTitle } from './Toast.styles'

export enum ToastPositions {
  'top-right' = 'top-right',
  'bottom-right' = 'bottom-right',
  'top-left' = 'top-left',
  'bottom-left' = 'bottom-left',
}

export enum ToastTypes {
  'success' = 'success',
  'danger' = 'danger',
  'warning' = 'warning',
  'info' = 'info',
}

interface IToast {
  id: number
  removeHandler: (id: number) => void
  position: ToastPositions
  autoDeleteTime: number
  isAutoDelete: boolean
  type: ToastTypes
  title: string
  description: string
}

export const Toast: any = ({
  id,
  removeHandler,
  position,
  autoDeleteTime,
  isAutoDelete,
  type,
  title,
  description,
}: IToast) => {
  const [isMouseOver, setIsMouseOver] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAutoDelete && !isMouseOver) {
        removeHandler(id)
      }
    }, autoDeleteTime)

    return () => {
      clearInterval(interval)
    }
  }, [isAutoDelete, autoDeleteTime, removeHandler, id, isMouseOver])

  return (
    <StyledNotification
      position={position}
      type={type}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseOut={() => setIsMouseOver(false)}
    >
      <StyledButton onClick={() => removeHandler(id)}>x</StyledButton>
      <div>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </div>
    </StyledNotification>
  )
}
