/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { fetchAssignTheLoan, fetchLoans, fetchLoansCount } from './extra'
import { ILoanSlice, ILoanRequest /* , ISelectedLoanData */ } from './types'

const initialState: ILoanSlice = {
  totalAmount: {
    pending: false,
    error: false,
    all: 0,
    unassigned: 0,
    processing: 0,
    pre_approved: 0,
  },
  all: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  unassigned: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  pre_approved: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  processing: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
}

export const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    resetLoans: (state, { payload }) =>
      payload
        ? {
            ...state,
            totalAmount: state.totalAmount,
            [payload]: initialState[payload],
          }
        : {
            ...initialState,
            totalAmount: state.totalAmount,
          },
    approveLoan: (state) => {
      state.totalAmount = {
        ...state.totalAmount,
        processing: state.totalAmount.processing - 1,
      }
    },
    getLoanInWork: (state) => {
      state.totalAmount = {
        ...state.totalAmount,
        processing: state.totalAmount.processing + 1,
        unassigned: state.totalAmount.unassigned - 1,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignTheLoan.pending, (state, payload: any) => {
        const {
          meta: {
            arg: { type, loanId },
          },
        } = payload
        state[type].data[loanId] = {
          ...state[type].data[loanId],
          pending: true,
        }
      })
      .addCase(fetchAssignTheLoan.rejected, (state, payload) => {
        const {
          meta: {
            arg: { type, loanId },
          },
        } = payload
        state[type].data[loanId] = {
          ...state[type].data[loanId],
          pending: false,
        }
      })
      .addCase(fetchAssignTheLoan.fulfilled, (state, { payload }) => {
        const { type, loanId, username } = payload
        state[type].data[loanId] = {
          ...state[type].data[loanId],
          admin_username: [...state[type].data[loanId].admin_username, username],
          loan_statuses_status_name: 'on_check', // todo ???
          pending: false,
        }
      })
      .addCase(fetchLoans.pending, (state, payload: any) => {
        const {
          meta: {
            arg: { type },
          },
        } = payload
        state[type].pending = true
      })
      .addCase(fetchLoans.rejected, (state, payload: any) => {
        const {
          meta: {
            arg: { type },
          },
        } = payload

        if (type) {
          state[type].error = true
          state[type].pending = false
          state[type].data = {}
        }
      })
      .addCase(fetchLoans.fulfilled, (state, { payload }: PayloadAction<ILoanRequest | any>) => {
        const { data, type, iteration, refresh } = payload
        state[type].pending = false
        const normalizeData = keyBy(data.data, 'loans_loan_id')
        state[type].data = !refresh
          ? {
              ...(state[type].data || {}),
              ...normalizeData,
            }
          : normalizeData
        state[type].totalCount = data.total
        state[type].finish = !data.count
        state[type].iteration = iteration || state[type].iteration + 1
      })

      .addCase(fetchLoansCount.pending, (state) => {
        state.totalAmount.pending = true
      })
      .addCase(fetchLoansCount.rejected, (state) => {
        state.totalAmount.error = true
        state.totalAmount.pending = false
      })
      .addCase(
        fetchLoansCount.fulfilled,
        (state, { payload }: PayloadAction<ILoanRequest | any>) => {
          const { all, processing, unassigned, pre_approved } = payload
          state.totalAmount = {
            all,
            processing,
            unassigned,
            pre_approved,
            pending: false,
          }
        }
      )
  },
})

export const { resetLoans, approveLoan, getLoanInWork } = loansSlice.actions

export default loansSlice.reducer
