/* eslint-disable arrow-body-style */
import { THEME } from 'core/theme'
import { Input } from './Input'
import styled, { css } from 'styled-components'

export const StyledInput = styled(Input)`
  border: 2px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;

  ${({ error }) =>
    error
      ? css`
          border: 2px solid ${THEME.COLORS.WARNING}!important;
        `
      : css`
          &:hover {
            border: 2px solid ${THEME.COLORS.YELLOW}!important;
          }
        `}
`

export const StyledInputField: any = (props) => {
  return <StyledInput {...props} />
}
