/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable guard-for-in */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'

import { ReactComponent as CloseModalSVG } from 'assets/close-modal.svg'

import { Loader } from 'components'
import { MODAL_STYLE_LOANS } from 'core/theme'
import 'core/theme/imageZoom.css'

import { STRUCTURE } from './TableStructure'

import {
  StyledModalContent,
  StyleCloseModal,
  StyledLoaderPage,
  StyledTableHeadWrapper,
  StyledTable,
  StyledHeadTitle,
  StyledTableWrapper,
  StyledTableModal,
  StyledModalTitle,
} from './Modal.styles'

import { Table } from './components/Table'

export const ModalIntersections = ({
  isOpen,
  onRequestClose,
  onClose,
  title,
  claimsResolve,
}): JSX.Element => {
  const { loans, isFetching } = useSelector((store: GlobalState) => store.loansModal)

  return (
    <Modal
      isOpen={isOpen}
      style={MODAL_STYLE_LOANS}
      onRequestClose={onRequestClose}
      contentLabel='onRequestClose Example'
    >
      <StyledModalContent>
        {isFetching && (
          <StyledLoaderPage>
            <Loader />
          </StyledLoaderPage>
        )}

        <StyleCloseModal onClick={onClose}>
          <CloseModalSVG />
        </StyleCloseModal>

        <StyledTableModal>
          <StyledModalTitle>{title}</StyledModalTitle>
          <StyledTableWrapper>
            <TableHead />
            {!isFetching &&
              loans &&
              loans.length &&
              loans?.map((loan: any) => (
                <React.Fragment key={loan.loans_loan_id}>
                  <Table
                    claimsResolve={claimsResolve}
                    status={loan.loan_statuses_status_name}
                    sum={loan.loans_loan_ask_sum}
                    createdAt={loan.loans_loan_date_create}
                    loanId={loan.loans_loan_id}
                    loanNumber={loan.loans_loan_number}
                    userId={loan.loans_user_id}
                    passportFirstName={loan.passport_first_name}
                    passportLastName={loan.passport_last_name}
                    passportMiddleName={loan.passport_middle_name}
                    passportNumber={loan.passport_number}
                    passportSerial={loan.passport_serial}
                    regAddress={loan.registration_address}
                    requestsCount={loan.requests_count}
                    userLogin={loan.users_login}
                    utmSource={loan.utm_source}
                  />
                </React.Fragment>
              ))}
          </StyledTableWrapper>
        </StyledTableModal>
      </StyledModalContent>
    </Modal>
  )
}

export const TableHead = (): JSX.Element => (
  <>
    <StyledTableHeadWrapper>
      {STRUCTURE.THEAD.map((column) => (
        <StyledTable key={column.id}>
          <StyledHeadTitle> {column.title}</StyledHeadTitle>
        </StyledTable>
      ))}
    </StyledTableHeadWrapper>
  </>
)
