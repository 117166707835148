/* eslint-disable indent */
import { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import {
  StyledTooltipBorder,
  StyledTooltipContent,
  StyledTooltipCorner,
  StyledTooltipWrapper,
} from './Tooltip.styles'

const cornerSize = 4
const cornerDiagonal = Math.sqrt(2) * cornerSize * 2
const defaultStyle = {
  tooltip: { top: 0, left: 0 },
  corner: { top: 0, left: 0 },
}

const getStyles: any = (tooltip, container) => {
  const containerRect = container?.current?.getBoundingClientRect()
  const tooltipRect = tooltip?.current?.getBoundingClientRect()
  if (!containerRect || !tooltipRect) {
    return defaultStyle
  }

  const scrollTop =
    (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop

  const scrollLeft =
    (document.documentElement && document.documentElement.scrollLeft) || document.body.scrollLeft

  const up =
    containerRect.top + containerRect.height + tooltipRect.height + cornerSize >
    document.body.clientHeight

  const tooltipLeft = containerRect.x - tooltipRect.width / 2 + containerRect.width / 2

  let left = tooltipLeft + scrollLeft
  if (left + tooltipRect.width > document.body.clientWidth) {
    left -= tooltipRect.width / 2 - containerRect.width / 2
  }
  if (left - tooltipRect.width < 0) {
    left += tooltipRect.width / 2 - containerRect.width / 2
  }

  return {
    tooltip: {
      left: `${left}px`,
      top: `${
        up
          ? containerRect.top + scrollTop - tooltipRect.height
          : containerRect.top + scrollTop + containerRect.height + cornerSize
      }px`,
    },
    corner: {
      left: `${containerRect.x + scrollLeft + containerRect.width / 2 - cornerSize}px`,
      top: `${
        up
          ? containerRect.top + scrollTop - cornerSize
          : containerRect.top + scrollTop + containerRect.height
      }px`,
      transform: up ? 'rotate(135deg)' : null,
    },
    border: {
      left: `${left}px`,
      top: `${
        up
          ? containerRect.top + scrollTop
          : containerRect.top + scrollTop + containerRect.height + cornerSize - cornerDiagonal
      }px`,
      height: `${cornerDiagonal}px`,
      width: `${tooltipRect.width}px`,
    },
  }
}

export const Tooltip: any = ({ children, content, color, coward = true }) => {
  const container = useRef()
  const tooltip = useRef()
  const [el] = useState(document.createElement('div'))

  const [visible, setVisible] = useState(false)
  const [position, setPosition]: any = useState(defaultStyle)

  const show = () => {
    const { current }: any = container

    document.body.appendChild(el)
    setPosition(getStyles(tooltip, container))
    current.parentNode.classList.add('visible')

    setTimeout(() => {
      setVisible(true)
    })

    current.onmouseleave = (e: any) => {
      if (coward) {
        remove(el)
      } else {
        const elem = e && (e.toElement || e.relatedTarget)
        if (!el.contains(elem)) {
          remove(el)
        }
      }
    }

    if (!coward) {
      el.onmouseleave = (e: any) => {
        const elem = e && (e.toElement || e.relatedTarget)
        if (!current.contains(elem)) {
          remove(el)
        }
      }
    }
  }

  const remove = (eL: any) => {
    const { current }: any = container
    setVisible(false)
    document.body.removeChild(eL)
    current.parentNode.classList.remove('visible')
  }

  useEffect(() => {
    const { current }: any = container
    current.onmouseenter = show

    return () => {
      current.onmouseenter = null
    }
  })

  return (
    <div ref={container} style={{ position: 'static', top: 0 }}>
      {content}
      {el
        ? ReactDOM.createPortal(
            <StyledTooltipWrapper show={visible}>
              {!coward && (
                <StyledTooltipBorder
                  style={{
                    ...position.border,
                  }}
                />
              )}
              <StyledTooltipCorner
                style={{
                  ...position.corner,
                  borderTopColor: color || null,
                  borderRightColor: color || null,
                }}
              />
              <StyledTooltipContent
                ref={tooltip}
                style={{ ...position.tooltip, backgroundColor: color || null }}
              >
                {children}
              </StyledTooltipContent>
            </StyledTooltipWrapper>,
            el
          )
        : null}
    </div>
  )
}

export default Tooltip
