/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */

import {
  StyledLettersCount,
  StyledLettersCountTitle,
  StyledLettersCountNumber,
} from './Modules.styles'

export const LettersCount = ({ documentsCount }): JSX.Element => {
  return (
    <StyledLettersCount>
      <StyledLettersCountTitle>Всего выбрано писем : </StyledLettersCountTitle>
      <StyledLettersCountNumber>{documentsCount}</StyledLettersCountNumber>
    </StyledLettersCount>
  )
}
