import moment from 'moment'
import Calendar from 'react-datepicker'
import ru from 'date-fns/locale/ru'

import { Portal } from 'react-overlays'
import { IDataPicker } from './types'
import MaskedTextInput from 'react-text-mask'

import { StyledDatePickerWrapper, StyledButtonClose, StyledPlaceholder } from './DatePicker.styles'
import 'react-datepicker/dist/react-datepicker.css'
import { useState } from 'react'

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return <Portal container={el}>{children}</Portal>
}

export const DatePicker: React.FC<IDataPicker | any> = (props) => {
  const {
    format,
    onChange,
    placeholder,
    value,
    onBlur,
    id,
    error,
    closeBtn = true,
    ...rest
  } = props

  const handleClearInput = (): void => {
    onChange(null)
  }
  const [editable, setEditable] = useState<boolean>(false)

  return (
    <StyledDatePickerWrapper showSelectIcon error={error} value={!!value}>
      <Calendar
        startDate={moment().toDate()}
        onChange={onChange}
        id={id}
        // onBlur={onBlur}
        customInput={
          <MaskedTextInput
            type='text'
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
          />
        }
        // placeholderText={placeholder}
        dateFormat={format}
        {...rest}
        showPopperArrow={false}
        locale={ru}
        shouldCloseOnSelect
        isClearable
        popperContainer={CalendarContainer}
        onFocus={() => setEditable(true)}
        onBlur={() => {
          setEditable(false)
          return onBlur
        }}
      />

      {closeBtn && value && <StyledButtonClose onClick={handleClearInput} />}
      {placeholder && (
        <StyledPlaceholder error={error} editable={editable}>
          {placeholder}
        </StyledPlaceholder>
      )}
    </StyledDatePickerWrapper>
  )
}
