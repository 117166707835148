import styled from 'styled-components/macro'

export const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledAuthWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 30px;
  justify-content: center;
`

export const StyledContent = styled.div`
  width: 200px;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 10px;
`

export const StyledTitle = styled.div`
  text-align: center;
  font-size: 18px;
`
export const StyledButtonBlock = styled.div`
  display: flex;
  justify-content: center;
`
