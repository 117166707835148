import { v4 as uuid } from 'uuid'

export const STRUCTURE = {
  all: [
    { title: 'Номер', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Паспорт', id: uuid() },
    { title: 'Телефон', id: uuid() },
    { title: 'Город подачи заявки', id: uuid() },
  ],
}
