import styled from 'styled-components/macro'
import { THEME } from 'core/theme/theme'
import { Link } from 'react-scroll'

export const StyledNav = styled.div`
  min-width: 240px;
  max-width: 300px;
  padding: 25px 22px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 80px);
  border-right: 1px solid ${THEME.COLORS.BORDER_LOAN};
  overflow-y: auto;
  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  color: ${THEME.COLORS.DARK_BLUE};

  &:hover {
    color: ${THEME.COLORS.BLUE};
  }
  &.active {
    color: ${THEME.COLORS.BLUE};
    font-weight: bold;
  }
`
