/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

import { fetchAssignTheLoan, fetchLoansCount } from 'features/loans/extra'
import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'
import { LoanStatus } from 'features/loan/types'
import { ITableProps, OVERTIME } from './types'
import { CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'
import { COLUMN_TEMPLATE } from './options'
import { GlobalState } from 'index'
import { ReactComponent as AlertSVG } from 'assets/alert_rectangle.svg'
import { HiOutlineClipboardCopy } from 'react-icons/hi'

import {
  StyledRowWrapper,
  StyledValue,
  StyledTitle,
  StyledStatusDate,
  StyledStatusDateToolTip,
  StyledInfo,
} from './Table.styles'
import { ReactComponent as SelectSVG } from '../../../assets/select-arrow.svg'
import { loanStatuses } from 'helpers/dictionary'
import { copyToClipboard, getAdminFio, modifyingText } from 'helpers'

const getOverTimeValue = (hours: number): string => {
  let overTimeVal = ''
  if (Math.floor(hours) <= 24) overTimeVal = OVERTIME['0_24']
  else if (Math.floor(hours) > 24 && Math.floor(hours) <= 48) overTimeVal = OVERTIME['24-48']
  else if (Math.floor(hours) > 48) overTimeVal = OVERTIME['48-more']
  return overTimeVal
}

export const Table = (props: ITableProps): JSX.Element => {
  const dictData = useSelector((state: GlobalState) => state.dict)

  const {
    claims,
    type,
    pending,
    createdAt,
    loanNumber,
    sum,
    passportFirstName,
    passportLastName,
    passportMiddleName,
    passportNumber,
    passportSerial,
    closedCount,
    userLogin,
    loanId,
    status,
    utmSource,
    adminUsername,
    username,
    regRegion,
    loanProduct,
    permittedSum,
    statusDate,
  } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const { path } = useRouteMatch()

  const handleOpenLoan = (e) => {
    e.preventDefault()
    history.push(`${path}/${loanId}?closedCount=${closedCount}`)
  }

  const operatorStructure = [
    {
      title: loanNumber,
      id: uuid(),
      copy: true,
      onClick: (e) => {
        e.stopPropagation()
        e.preventDefault()
        copy(loanNumber)
      },
    },
    { title: modifyingText(utmSource || '-----', 20), id: uuid() },
    {
      title: moment(new Date(createdAt)).add(3, 'hours').format('DD-MM-YYYY / HH:mm'),
      id: uuid(),
    },
    { title: CreditProductTranslate[CreditProductCodes[loanProduct]] },
    { title: closedCount, id: uuid() },
    {
      title: `${passportLastName} ${passportFirstName} ${passportMiddleName}`,
      id: uuid(),
    },
    { title: `${passportSerial} ${passportNumber} `, id: uuid() },
    {
      title: userLogin,
      id: uuid(),
      copy: true,
      onClick: (e) => {
        e.stopPropagation()
        e.preventDefault()
        copy(userLogin)
      },
    },
    { title: sum.toLocaleString(), id: uuid() },
    {
      title: permittedSum.toLocaleString() || '-----',
      id: uuid(),
    },
    { title: regRegion || '-----', id: uuid() },
    { title: status ? loanStatuses[status] : '-----', id: uuid() },
  ]

  const tableStructure = {
    underwriter: {
      all: [
        {
          title: loanNumber,
          id: uuid(),
          copy: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            copy(loanNumber)
          },
        },
        {
          title: modifyingText(`${utmSource && utmSource}`, 20),
          id: uuid(),
        },
        {
          title: moment(new Date(createdAt)).add(3, 'hours').format('DD-MM-YYYY / HH:mm'),
          id: uuid(),
        },
        { title: CreditProductTranslate[CreditProductCodes[loanProduct]], id: uuid() },
        { title: closedCount, id: uuid() },
        {
          title: `${passportLastName} ${passportFirstName} ${passportMiddleName} / ${passportSerial} ${passportNumber} `,
          id: uuid(),
        },
        {
          title: permittedSum.toLocaleString() || '-----',
          id: uuid(),
        },
        { title: regRegion || '-----', id: uuid() },
        {
          title: !adminUsername?.length ? (
            <b style={{ color: '#24C665' }}>Взять в работу</b>
          ) : Array.isArray(adminUsername) ? (
            getAdminFio(adminUsername[0], dictData)
          ) : (
            getAdminFio(adminUsername, dictData)
          ),
          id: uuid(),
          select: !adminUsername?.length,
          onClick:
            status === LoanStatus.setting_a_limit
              ? (e) => {
                  e.stopPropagation()
                  if (!pending && status === LoanStatus.setting_a_limit) {
                    dispatch(
                      fetchAssignTheLoan({
                        loanId,
                        type,
                        username,
                        onSuccess: () =>
                          dispatch(
                            addToast({
                              type: ToastTypes.success,
                              title: 'Поздравляем',
                              description: 'Заявка успешно взята в работу',
                            })
                          ),
                      })
                    )
                    dispatch(fetchLoansCount())
                  } else {
                    handleOpenLoan(e)
                  }
                }
              : null,
        },
        { title: status ? loanStatuses[status] : '-----', id: uuid() },
      ],
      unassigned: [
        {
          title: loanNumber,
          id: uuid(),
          copy: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            copy(loanNumber)
          },
        },
        {
          title: `${utmSource && utmSource} ${
            CreditProductTranslate[CreditProductCodes[loanProduct]]
          }`,
          id: uuid(),
        },
        {
          title: moment(new Date(createdAt)).add(3, 'hours').format('DD-MM-YYYY / HH:mm'),
          id: uuid(),
        },
        { title: closedCount, id: uuid() },
        {
          title: `${passportLastName} ${passportFirstName} ${passportMiddleName}`,
          id: uuid(),
        },
        {
          title: userLogin,
          id: uuid(),
          copy: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            copy(userLogin)
          },
        },
        { title: sum.toLocaleString(), id: uuid() },
        { title: regRegion || '-----', id: uuid() },
        {
          title: !adminUsername?.length ? (
            <b style={{ color: '#24C665' }}>Взять в работу</b>
          ) : Array.isArray(adminUsername) ? (
            getAdminFio(adminUsername[0], dictData)
          ) : (
            getAdminFio(adminUsername, dictData)
          ),
          id: uuid(),
          select: !adminUsername?.length,
          onClick:
            status === LoanStatus.setting_a_limit
              ? (e) => {
                  e.stopPropagation()
                  if (!pending && status === LoanStatus.setting_a_limit) {
                    dispatch(
                      fetchAssignTheLoan({
                        loanId,
                        type,
                        username,
                        onSuccess: () =>
                          dispatch(
                            addToast({
                              type: ToastTypes.success,
                              title: 'Поздравляем',
                              description: 'Заявка успешно взята в работу',
                            })
                          ),
                      })
                    )
                    dispatch(fetchLoansCount())
                  } else {
                    handleOpenLoan(e)
                  }
                }
              : null,
        },
      ],
      pre_approved: [
        {
          title: loanNumber,
          id: uuid(),
          copy: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            copy(loanNumber)
          },
        },
        {
          title: `${utmSource && utmSource} ${
            CreditProductTranslate[CreditProductCodes[loanProduct]]
          }`,
          id: uuid(),
        },
        {
          statusDate: true,
          id: uuid(),
        },
        {
          title: moment(new Date(createdAt)).add(3, 'hours').format('DD-MM-YYYY / HH:mm'),
          id: uuid(),
        },
        { title: closedCount, id: uuid() },
        {
          title: `${passportLastName} ${passportFirstName} ${passportMiddleName}`,
          id: uuid(),
        },
        {
          title: userLogin,
          id: uuid(),
          copy: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            copy(userLogin)
          },
        },
        { title: sum.toLocaleString(), id: uuid() },
        { title: regRegion || '-----', id: uuid() },
        {
          title: !adminUsername?.length ? (
            <b style={{ color: '#24C665' }}>Взять в работу</b>
          ) : Array.isArray(adminUsername) ? (
            getAdminFio(adminUsername[0], dictData)
          ) : (
            getAdminFio(adminUsername, dictData)
          ),
          id: uuid(),
          select: !adminUsername?.length,
          onClick:
            status === LoanStatus.setting_a_limit
              ? (e) => {
                  e.stopPropagation()
                  if (!pending && status === LoanStatus.setting_a_limit) {
                    dispatch(
                      fetchAssignTheLoan({
                        loanId,
                        type,
                        username,
                        onSuccess: () =>
                          dispatch(
                            addToast({
                              type: ToastTypes.success,
                              title: 'Поздравляем',
                              description: 'Заявка успешно взята в работу',
                            })
                          ),
                      })
                    )
                    dispatch(fetchLoansCount())
                  } else {
                    handleOpenLoan(e)
                  }
                }
              : null,
        },
      ],
      processing: [
        {
          title: loanNumber,
          id: uuid(),
          copy: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            copy(loanNumber)
          },
        },
        {
          title: `${utmSource && utmSource} ${
            CreditProductTranslate[CreditProductCodes[loanProduct]]
          }`,
          id: uuid(),
        },
        {
          title: moment(new Date(createdAt)).add(3, 'hours').format('DD-MM-YYYY / HH:mm'),
          id: uuid(),
        },
        { title: closedCount, id: uuid() },
        {
          title: `${passportLastName} ${passportFirstName} ${passportMiddleName}`,
          id: uuid(),
        },
        {
          title: userLogin,
          id: uuid(),
          copy: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            copy(userLogin)
          },
        },
        { title: sum.toLocaleString(), id: uuid() },
        { title: regRegion || '-----', id: uuid() },
        {
          title: !adminUsername?.length ? (
            <b style={{ color: '#24C665' }}>Взять в работу</b>
          ) : Array.isArray(adminUsername) ? (
            getAdminFio(adminUsername[0], dictData)
          ) : (
            getAdminFio(adminUsername, dictData)
          ),
          id: uuid(),
          select: !adminUsername?.length,
          onClick:
            status === LoanStatus.setting_a_limit
              ? (e) => {
                  e.stopPropagation()
                  if (!pending && status === LoanStatus.setting_a_limit) {
                    dispatch(
                      fetchAssignTheLoan({
                        loanId,
                        type,
                        username,
                        onSuccess: () =>
                          dispatch(
                            addToast({
                              type: ToastTypes.success,
                              title: 'Поздравляем',
                              description: 'Заявка успешно взята в работу',
                            })
                          ),
                      })
                    )
                    dispatch(fetchLoansCount())
                  } else {
                    handleOpenLoan(e)
                  }
                }
              : null,
        },
      ],
    },
    operator: {
      all: operatorStructure,
      processing: operatorStructure,
      unassigned: operatorStructure,
    },
  }

  const copy = (value) => {
    copyToClipboard(value)
      .then(() => {
        dispatch(
          addToast({
            type: ToastTypes.success,
            title: 'Успех',
            description: `Данные скопированны`,
          })
        )
      })
      .catch((err) => {
        console.log('Something went wrong', err)
      })
  }

  return (
    <>
      <StyledRowWrapper
        indicate={+closedCount > 1}
        onClick={handleOpenLoan}
        template={COLUMN_TEMPLATE[claims][type]}
        href={`${path}/${loanId}`}
      >
        {tableStructure[claims][type].map((column, index) => {
          const currentUTCUserTime = moment.utc(new Date())
          const duration = moment.duration(currentUTCUserTime.diff(statusDate))
          const hours = duration.asHours()
          const overTime = getOverTimeValue(hours)

          return (
            <StyledValue key={column.id || index} onClick={column.onClick}>
              <StyledTitle select={column.select} pending={pending}>
                {column.title}
                {column.copy && (
                  <StyledInfo>
                    <HiOutlineClipboardCopy />
                  </StyledInfo>
                )}
                {column.select && status === LoanStatus.setting_a_limit && <SelectSVG />}
                {column.statusDate && (
                  <>
                    <StyledStatusDate overTime={overTime}>
                      {overTime !== OVERTIME['0_24'] && (
                        <>
                          <StyledStatusDateToolTip>
                            Заявка находится на идентификации больше{' '}
                            {overTime === OVERTIME['24-48'] ? '24' : '48'} часов
                          </StyledStatusDateToolTip>
                          <AlertSVG />
                        </>
                      )}
                      {moment(new Date(statusDate)).add(3, 'hours').format('DD-MM-YYYY / HH:mm')}
                    </StyledStatusDate>
                  </>
                )}
              </StyledTitle>
            </StyledValue>
          )
        })}
      </StyledRowWrapper>
    </>
  )
}
