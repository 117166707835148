import styled, { css } from 'styled-components/macro'
import { StyledButtonWrapper } from 'components/Button/Button.styles'
import { THEME } from '../../../core/theme'

interface IStyledValue {
  styles?: string
  alarm?: string
}

export const StyledHeadWrapper = styled.div``

export const StyledStatus = styled.div`
  padding: 10px 16px 10px 35px;
  background-color: ${THEME.COLORS.SMOOTH};
  border-radius: 18.5px;
  position: relative;
  font-weight: bold;

  &:before {
    content: '';
    height: 12px;
    width: 12px;
    background: ${THEME.COLORS.SUCCESS};
    display: block;
    position: absolute;
    border-radius: 50%;
    left: 15px;
    top: 15px;
  }
`
export const StyledHeadActionBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  position: sticky;
  background-color: ${THEME.COLORS.WHITE};
  /* @media (max-width: 480px) {
    display: none;
  } */
`

export const StyledAction = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;

  & svg {
    margin-left: 12px;
  }

  @media (max-width: 480px) {
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    /* display: none; */
  }
`

export const StyledCloseExpandedElements = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;

  @media (max-width: 480px) {
    margin-right: 15px;
  }
`

export const StyledHeadLoanInfoWrapper = styled.div`
  padding: 32px;
  gap: 30px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    padding: 16px;
  }
`

export const StyledFioBlock = styled.div`
  margin-top: 30px;
`

export const StyledFioValue = styled.p`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #08003a;
`

export const StyledHeadLoanInfo = styled.div`
  display: grid;
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  grid-template-columns: repeat(4, 1fr);
  padding: 32px;
  gap: 30px 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    padding: 16px;
  }
`

export const StyledInfo = styled.div`
  background: ${THEME.COLORS.SUCCESS};
  color: ${THEME.COLORS.WHITE};
  border-radius: 15px;
  padding: 2px 8px;
  width: fit-content;
  opacity: 0.8;
  font-size: 14px;
  position: absolute;
  left: 40px;
  top: -8px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  & svg {
    color: ${THEME.COLORS.WHITE};
    margin-left: 5px;
  }
`

export const StyledInfoWrapper = styled.div`
  position: relative;

  &:hover {
    ${StyledInfo} {
      opacity: 1;
    }
  }
`

export const StyledHeadTitle = styled.p`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${THEME.COLORS.DARK_BLUE};
`

export const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  display: flex;
`

export const StyledValue = styled.p<IStyledValue>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.DARK_BLUE};
  word-break: break-word;

  ${({ alarm }) =>
    alarm === 'red' &&
    css`
      color: red;
    `}

  ${({ alarm }) =>
    alarm === 'green' &&
    css`
      color: #24c665;
    `}
`

export const StyledRollIcon = styled.p`
  font-weight: 900;
  padding-right: 6px;
`
export const StyledLikelihood = styled.p<any>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  ${({ status }) => {
    switch (status) {
      case true:
        return css`
          color: ${THEME.COLORS.WARNING};
        `
      case false:
        return css`
          color: ${THEME.COLORS.SUCCESS};
        `
      case '':
        return css`
          color: gray;
          opacity: 0.5;
        `

      default:
        return ''
    }
  }}
`

export const StyledTooltipMenuWrapper = styled.div`
  cursor: pointer;
  display: grid;

  ${StyledButtonWrapper} {
    color: ${THEME.COLORS.DARK_BLUE};
    border-radius: 0;
    padding: 10px 15px;
    font-size: 14px;

    &:hover {
      color: ${THEME.COLORS.BLUE};
      background-color: ${THEME.COLORS.SMOOTH};
    }
  }
`

export const StyledActionButton = styled.div`
  display: flex;
  align-items: center;
`

export const StyledCommentsBlock = styled.div`
  padding: 0 0 30px 30px;
`

export const StyledCommentTitle = styled.p`
  margin: 30px 0;
  font-weight: bold;
`
export const StyledTextArea = styled.textarea<any>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  width: 100%;
  max-width: 640px;
  height: 120px;
  resize: none;

  &:focus {
    outline: none;
  }
`

export const StyledButtons = styled.div`
  display: flex;
  margin-top: 15px;
`
