/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react'

import { PermissionContext } from 'app/contexts'
import { Permission, usePermission } from './hooks/usePermission'

interface PermissionWrapperProps {
  access: Permission[]
  children: React.ReactNode
  condition?: boolean
  requiredAll?: boolean
}

export const AccessWrapper: React.FC<PermissionWrapperProps> = ({
  access,
  children,
  condition = true,
  requiredAll = false,
}) => {
  const userContext = useContext(PermissionContext)

  if (!userContext) {
    throw new Error('AccessWrapper must be used within PermissionContext')
  }

  const { hasPermission } = usePermission(userContext.permissions)

  return condition && hasPermission(access, requiredAll) ? <>{children}</> : null
}
