/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, batch, useSelector } from 'react-redux'
import StickyBox from 'react-sticky-box'

import { GlobalState } from 'index'
import { NavMenuType } from 'App'
import { COLLECTOR_LOANS_ROUTE } from 'constants/pagesRoutes'

import { Loader, Navigation, ScrollToTop } from 'components'
import { INavigationItem } from 'components/Navigation/Navigation'
import { HeadInfo, Head } from './Head'

import {
  fetchCollectorLoanUserPassportData,
  fetchCollectorLoanUserPersonalData,
  fetchLoanPageById,
  fetchPhonesData,
  fetchUserLoans,
  fetchLoanTransactions,
  fetchLoanDebt,
  fetchCollectorTasks,
} from 'features/collector/extra'

import { AccrueHistory, Contacts, LoansHistory, RepaymentHistory, Tasks } from './Modules'

import {
  StyledLoaderPage,
  StyledWrapper,
  StyledContent,
  StyledNavContent,
} from './CollectorLoan.styles'
import { resetCollectorLoans } from 'features/collector/collectorSlice'
import { NavigationContext } from 'app/contexts'

const navigationList = (): INavigationItem[] => {
  const list = [
    { title: 'Контакты', anchor: 'contacts' },
    { title: 'История займов', anchor: 'loansHistory' },
    { title: 'История погашений', anchor: 'repaymentHistory' },
    { title: 'История начислений', anchor: 'accrueHistory' },
    { title: 'Задачи', anchor: 'tasks' },
  ]
  return list
}

export const CollectorLoan: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string | undefined }>()
  const [openedModules, setOpenedModules] = useState<string[]>([])

  const { userData, loanData, collectorUserName } = useSelector(
    (store: GlobalState) => store.collector
  )
  const collectorData = useSelector((store: GlobalState) => store.auth)
  const { setNavigation, isCollectorRole, setNavMenuType } = useContext(NavigationContext)

  useEffect(() => {
    setNavMenuType(NavMenuType.collector)
  }, [isCollectorRole])

  useEffect(() => {
    dispatch(fetchLoanPageById({ id }))
    return () => {
      dispatch(resetCollectorLoans(false))
    }
  }, [])

  useEffect(() => {
    if (userData?.user_id) {
      batch(() => {
        dispatch(fetchCollectorLoanUserPassportData({ userId: userData.user_id }))
        dispatch(fetchCollectorLoanUserPersonalData({ userId: userData.user_id }))
        dispatch(fetchPhonesData({ id: userData.user_id }))
        dispatch(fetchUserLoans({ ids: [`${userData.user_id}`] }))
      })
    }
    if (id) {
      batch(() => {
        dispatch(fetchLoanDebt({ loanId: id }))
        dispatch(fetchCollectorTasks({ loanId: id }))
      })
    }
    if (loanData?.loan_number) {
      dispatch(fetchLoanTransactions({ loan_number: loanData?.loan_number }))
    }
  }, [userData, loanData?.loan_number])

  const onToggleModule = (anchor: string): void => {
    const newOpenedModules = [...openedModules]
    const index = newOpenedModules.indexOf(anchor)
    index !== -1 ? newOpenedModules.splice(index, 1) : newOpenedModules.push(anchor)
    setOpenedModules(newOpenedModules)
  }

  const onNavigationClick = (anchor: string): void => {
    if (!openedModules.includes(anchor)) setOpenedModules([...openedModules, anchor])
  }

  const moduleProps = {
    openedModules,
    onToggleModule,
    loan_id: id,
  }

  const onRollUp = (): void => {
    setOpenedModules([])
    const el = document.getElementById('loan-content')
    if (el)
      el.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
  }
  const onRollDown = (): void => {
    const anchors = navigationList().map((v) => v.anchor)
    setOpenedModules(anchors)
  }

  const headInfoProps = {
    onRollUp,
    onRollDown,
    loanId: id,
    collectorUserName,
  }

  const isFetching = false

  useEffect(() => {
    const loanInWork = collectorData?.user?.username === collectorUserName

    if (loanData) {
      setNavigation({
        activeMenu: null,
        headContent: `Коллектор Заявка ${loanInWork ? 'в работе :' : ''} №${loanData?.loan_number}`,
        backTo: `${COLLECTOR_LOANS_ROUTE}?type=all`,
        slug: 'collector',
      })
    }
  }, [loanData, collectorData, collectorUserName])

  return (
    <>
      {isFetching ? (
        <StyledLoaderPage>
          <Loader />
        </StyledLoaderPage>
      ) : (
        <>
          <ScrollToTop />
          <StyledWrapper>
            <Navigation
              list={navigationList()}
              containerId='loan-content'
              onChange={onNavigationClick}
            />
            <StyledNavContent>
              <StickyBox>
                <HeadInfo {...headInfoProps} />
              </StickyBox>
              <StyledContent id='loan-content'>
                <Head />
                <Contacts {...moduleProps} />
                <LoansHistory {...moduleProps} />
                <RepaymentHistory {...moduleProps} />
                <AccrueHistory {...moduleProps} />
                <Tasks {...moduleProps} />
              </StyledContent>
            </StyledNavContent>
          </StyledWrapper>
        </>
      )}
    </>
  )
}
