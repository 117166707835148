import styled, { css } from 'styled-components/macro'
import { THEME } from '../../core/theme'

export const StyledControlsWrapper = styled.div`
  background: ${THEME.COLORS.SMOOTH};
  display: flex;
  justify-content: space-between;
  grid-template-columns: auto min-content;
  padding: 0 35px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    padding: 0 16px;
  }
`

export const StyledApplications = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 75px;
  align-self: end;
  padding-bottom: 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 480px) {
    overflow: auto;
    flex-wrap: nowrap;
    column-gap: 20px;
  }
`

export const StyledRequest = styled.div<any>`
  display: flex;
  align-items: center;
  margin-top: 20px;
  ${({ active }) =>
    active &&
    css`
      ${StyledTitle} {
        color: ${THEME.COLORS.BLACK};
        &:before {
          content: '';
          height: 2px;
          background: ${THEME.COLORS.DARK_BLUE};
          display: block;
          position: absolute;
          width: 100%;
          bottom: -15px;
        }
      }
    `}
`

export const StyledTitle = styled.p`
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
  color: ${THEME.COLORS.GRAY};
  white-space: nowrap;

  &:hover {
    color: ${THEME.COLORS.BLACK};

    &:before {
      content: '';
      height: 2px;
      background: ${THEME.COLORS.DARK_BLUE};
      display: block;
      position: absolute;
      width: 100%;
      bottom: -15px;
    }
  }
`

export const StyledValue = styled.div`
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${THEME.COLORS.DARK_BLUE};
  background: ${THEME.COLORS.YELLOW};
  padding: 5px 10px;
  border-radius: 28px;
`

export const StyledFilters = styled.div`
  display: grid;
  column-gap: 25px;
  grid-template-columns: 1fr 1fr;
  align-self: end;
  padding-bottom: 15px;
  margin-top: 20px;

  @media (max-width: 480px) {
    column-gap: 15px;
  }
`

export const StyledFiltersValue = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${THEME.COLORS.WHITE};
  background: ${THEME.COLORS.WARNING};
  margin-left: 8px;
  padding: 3px 10px;
  border-radius: 28px;
`

export const StyledContent = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.COLORS.DARK_BLUE};
`

export const StyledItemWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    margin-right: 8px;
  }

  &:hover {
    & svg path {
      fill: ${THEME.COLORS.BLUE};
    }
    ${StyledContent} {
      color: ${THEME.COLORS.BLUE};
    }
  }

  @media (max-width: 480px) {
    background-color: ${THEME.COLORS.WHITE};
    padding: 8px;
    border-radius: 12px;
  }
`
