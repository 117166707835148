export enum AgentLoanType {
  allApp = 'all_app',
  requiredDocs = 'on_check',
  atWork = 'agent_in_work',
  checking = 'setting_a_limit',
  checked = 'loan_approved',
  pre_approved = 'pre_approved',
  failureAutomatic = 'failure_automatic',
  agentRefusal = 'agent_refusal',
  underwriterRefusal = 'underwriter_refusal',
  clientRefusal = 'client_refusal',
}

export enum AgentLoanTypeValue {
  all_app = 'all_app',
  on_check = 'on_check',
  agent_in_work = 'agent_in_work',
  setting_a_limit = 'setting_a_limit',
  loan_approved = 'loan_approved',
  pre_approved = 'pre_approved',
}

export enum AgentLoanStatus {
  // 'waiting' = 'Ожидает анкеты',
  'agent_in_work' = 'В работе',
  'on_check' = 'Требуются документы',
  'setting_a_limit' = 'На проверке',
  'loan_approved' = 'Проверен',
  'failure_automatic' = 'Проверен',
  'agent_refusal' = 'Проверен',
  'underwriter_refusal' = 'Проверен',
  'client_refusal' = 'Проверен',
}

export const AgentLoanStatusType = {
  all_app: null,
  // waiting: '',
  on_check: ['on_check'],
  agent_in_work: ['agent_in_work'],
  setting_a_limit: ['setting_a_limit'],
  loan_approved: ['loan_approved', 'underwriter_refusal'],
  pre_approved: ['pre_approved'],
}

export interface IAgentLoanPageData {
  pending: boolean
  error: boolean
  data: any
  totalCount: number
  iteration: number
  activePage: string
}

type Nullable<T> = T | null
export interface IAgentLoanSlice {
  totalAmount: any
  all_app: IAgentLoanPageData
  waiting: IAgentLoanPageData
  on_check: IAgentLoanPageData
  agent_in_work: IAgentLoanPageData
  setting_a_limit: IAgentLoanPageData
  loan_approved: IAgentLoanPageData
  pre_approved: IAgentLoanPageData
  passportData: Nullable<IPassportData>
  personalData: Nullable<IPersonalData>
  loanData: Nullable<ILoanData>
  userData: Nullable<IUserData>
  imageData: Nullable<IImageLoanData>
  phonesData: Nullable<Array<IPhonesData>>
  assign_info: any
  collector_username: Nullable<string>
  userLoans: Nullable<Array<{ [str: string]: any }>>
  commentsData: IComments
  currentLoanIsBelongsToAgent: boolean
  actionPending: boolean
}

export interface ILoanRequest {
  data: IAgentLoanData[]
  total: number
}

export interface IAgentLoanData {
  loans_loan_ask_sum: string
  loans_loan_date_create: Date
  loans_loan_id: string
  loans_loan_number: string
  loans_user_id: string
  passport_first_name: string
  passport_last_name: string
  passport_middle_name: string
  passport_number: string
  passport_serial: string
  registration_address: string
  requests_count: string
  users_login: string
  utm_source: string
  total?: number
}

export interface ISelectedLoanData {
  loan_data: unknown
}
export interface IPhoto {
  image_id: string
  user_id: string
  created_at: string
  image_type_id: number
  bucket_name: string
  object_name: string
  photo_url: string
}

export interface IImageLoanData {
  personalDataPhoto: IPhoto[]
  registrationDataPhoto: IPhoto[]
  agentDataPhoto: IPhoto[]
}

export interface IPhonesData {
  number: string
  number_type: string
  is_default: boolean
  is_active: boolean
  comment: string
}

export interface IPassportData {
  first_name: string
  last_name: string
  middle_name: string
  gender: string
  date_of_birth: string
  place_of_birth: string
  citizenship: ''
  serial: string
  number: string
  date_of_issue: string
  place_of_issue: string
  issued_department_url: string
  code_division: string
}

export interface IPersonalData {
  login: string
  date_register: string
  time_zone: string
  check_word: string
  maiden_name: string
  marital_status: string
  children_amount: string
  email: string
  additional_services: string
  criminal_records: string
  user_id: string
}

export interface ILoanData {
  loan_id: string
  loan_number: string
  loan_source: unknown
  loan_date_create: string
  loan_issue_date: string
  loan_status: string
  loan_ask_sum: number
  loan_ask_period: number
  loan_permitted_sum: string
  loan_permitted_period: string
  loan_settings: unknown
  loan_repeated: boolean
  requests_count: number
  credit_product: string
  loan_sum: string
  product_percent_day: string
  usage_days: number
  is_third_transfer_approved: boolean
  is_transfer_bki_approved: boolean
}

export interface IUserData {
  user_id: string
  first_name: string
  last_name: string
  middle_name: string
  customer_id: number
}

export interface IComments {
  isFetching: boolean
  isUpdating: boolean
  pending: boolean
  data: ICommentsData[]
}

export interface ICommentsData {
  username: string
  comment: string
  added_at: string
}
