/* eslint-disable import/first */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Formik } from 'formik'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { DatePickerField, setInputValue } from 'helpers/form'
import { collectorStatus, validationSchemaFilters } from 'helpers'
import { Select, Button } from 'components'

import { setCollectorFilters } from 'features/collectorFilters/filtersSlice'
import { GlobalState } from 'index'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import { ReactComponent as ArrowUpSVG } from 'assets/arrow-up.svg'
import { ReactComponent as CloseDarkSVG } from 'assets/close-dark.svg'

import {
  StyledFiltersWrapper,
  StyledResetFilter,
  StyledSelectBlock,
  StyledFiltersBlock,
  StyledActionsBlock,
  StyledTitle,
  StyledAction,
  StyledFiltersContent,
} from './Filters.styles'

const formatDate = (date, format) => (date ? moment(new Date(date)).format(format) : date)

const overdueDays = [
  {
    label: '0 - 30',
    value: '0_30',
  },
  {
    label: '31 - 90',
    value: '31_90',
  },
  {
    label: '91+',
    value: '90',
  },
]

const overdueDaysNormalizedForApi = {
  '0_30': {
    overdue_period: {
      period_from: 1,
      period_to: 30,
    },
  },
  '31_90': {
    overdue_period: {
      period_from: 30,
      period_to: 90,
    },
  },
  '90': {
    overdue_period: {
      period_from: 90,
    },
  },
}

export const Filters: React.FC<any> = ({ showFilters, toggleFilters, pending }): JSX.Element => {
  const dispatch = useDispatch()
  const { data: filters } = useSelector((store: GlobalState) => store.collectorFilters)

  const collectorStatuses = Object.keys(collectorStatus).map((value) => ({
    label: collectorStatus[value],
    value,
  }))

  const submitForm = async (values: any): Promise<void> => {
    if (pending) return
    dispatch(setCollectorFilters(getOmitFilters(values)))
    toggleFilters()
  }

  const resetAllFilters = (resetForm, setFieldValue) => (): void => {
    resetForm()
    setFieldValue('overdue_days', '')
    setFieldValue('collector_status', null)
    setFieldValue('collector_added.start_date', null)
  }

  const getOmitFilters = (values) => {
    const currentFilters = {}
    if (values.collector_added.start_date)
      Object.assign(currentFilters, {
        collector_added: {
          start_date: formatDate(values.collector_added.start_date, 'YYYY-MM-DD'),
        },
      })
    if (values.collector_status)
      Object.assign(currentFilters, { collector_status: values.collector_status })
    if (values.overdue_days)
      Object.assign(currentFilters, overdueDaysNormalizedForApi[values.overdue_days])
    return currentFilters
  }

  const initialValues = {
    collector_added: { start_date: filters?.collector_added?.start_date || null },
    collector_status: filters?.collector_status || null,
    overdue_days:
      overdueDays.find(
        (item) =>
          item.value ===
          Object.keys(overdueDaysNormalizedForApi).find((key) => {
            if (isEqual(filters.overdue_period, overdueDaysNormalizedForApi[key].overdue_period))
              return key
            return false
          })
      )?.value || '',
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaFilters}
    >
      {(formikProps) => {
        const { values, resetForm, setFieldValue, handleSubmit }: any = formikProps
        const setValuesHandler = setInputValue(setFieldValue)

        return (
          <StyledFiltersBlock showFilters={showFilters}>
            <StyledFiltersContent>
              <StyledFiltersWrapper>
                <StyledSelectBlock>
                  <Select
                    data={collectorStatuses}
                    placeholder='Статус должника'
                    value={
                      collectorStatuses?.find((v) => v.value === values.collector_status) || ''
                    }
                    onChange={setValuesHandler('collector_status', 'value')}
                  />
                  {values.collector_status && (
                    <StyledResetFilter onClick={() => setFieldValue('collector_status', null)}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
                <DatePickerField
                  name='collector_added.start_date'
                  onChange={setFieldValue}
                  placeholder='Дата отработки'
                  value={values.collector_added?.start_date || null}
                  format='dd.MM.yyyy'
                  startDate={values.collector_added.start_date || null}
                  {...formikProps}
                />
                <StyledSelectBlock>
                  <Select
                    data={overdueDays}
                    placeholder='Количество дней просрочки'
                    value={overdueDays?.find((v) => v.value === values.overdue_days) || ''}
                    onChange={setValuesHandler('overdue_days', 'value')}
                  />
                  {values.overdue_days && (
                    <StyledResetFilter onClick={() => setFieldValue('overdue_days', null)}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
              </StyledFiltersWrapper>
              <StyledActionsBlock>
                <StyledAction onClick={toggleFilters}>
                  <ArrowUpSVG />
                  <StyledTitle>Свернуть</StyledTitle>
                </StyledAction>

                <StyledAction onClick={resetAllFilters(resetForm, setFieldValue)}>
                  <CloseDarkSVG />
                  <StyledTitle>Сбросить все</StyledTitle>
                </StyledAction>

                {!isEqual(values, filters) && (
                  <Button type='standard' onClick={handleSubmit} disabled={pending}>
                    Применить
                  </Button>
                )}
              </StyledActionsBlock>
            </StyledFiltersContent>
          </StyledFiltersBlock>
        )
      }}
    </Formik>
  )
}
