import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

export class ProjectDictService extends ServiceBase {
  protected static BASE_PATH = 'admins'

  public static adminUsernames(): AxiosPromise {
    return this.get(`${this.BASE_PATH}/admin_usernames`)
  }

  public static loanStatuses(): AxiosPromise {
    return this.get(`loans/loan_statuses`)
  }

  public static agentUsernames(): AxiosPromise {
    return this.get(`agent_api/${this.BASE_PATH}/agent-usernames`)
  }

  public static agentManagerUsernames(): AxiosPromise {
    return this.get(`agent_api/${this.BASE_PATH}/agent-manager-usernames`)
  }

  public static adminsRoles(): AxiosPromise {
    return this.get(`${this.BASE_PATH}/roles`)
  }

  public static adminsfio(): AxiosPromise {
    return this.get(`${this.BASE_PATH}/admin_fio`)
  }
}
