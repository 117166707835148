import { StyledButtonWrapper } from 'components/Button/Button.styles'
import styled, { css } from 'styled-components/macro'
import { THEME } from 'core/theme'

export const StyledTooltipWrapper = styled.div<any>`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear;

  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      opacity: 1;
      transition: opacity 0.2s linear;
    `}
`
export const StyledTooltipBorder = styled.div`
  position: absolute;
`
export const StyledTooltipCorner = styled.div`
  position: absolute;
  border: 4px solid transparent;
  border-top: 4px solid ${THEME.COLORS.WHITE};
  border-right: 4px solid ${THEME.COLORS.WHITE};
  transform: rotate(-45deg);
  z-index: 101;
`
export const StyledTooltipContent = styled.div`
  position: absolute;
  z-index: 99999;
  border-radius: 16px;
  padding: 5px 6px;
  text-align: center;
  word-break: break-all;
  background-color: ${THEME.COLORS.WHITE};
  color: black;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  width: max-content;

  @media (max-width: 480px) {
    top: 121px !important;
    left: 159px !important;
  }
`

export const StyledTooltipMenuWrapper = styled.div`
  cursor: pointer;
  display: grid;

  ${StyledButtonWrapper} {
    color: ${THEME.COLORS.DARK_BLUE};
    border-radius: 0;
    padding: 10px 15px;
    font-size: 14px;

    &:hover {
      color: ${THEME.COLORS.BLUE};
      background-color: ${THEME.COLORS.SMOOTH};
    }
  }
`
