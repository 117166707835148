import { shallowEqual, useSelector } from 'react-redux'
import { GlobalState } from '../../index'

export const useGetAgentUserNames = (): string[] =>
  useSelector(
    (store: GlobalState) => store.dict.data.agentList.map((x) => x.username),
    shallowEqual
  )

export const useGetAgentUserNamesWithIds = (): Array<{
  [key: string]: string
}> => useSelector((store: GlobalState) => store.dict.data.agentList, shallowEqual)
