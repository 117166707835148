/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { InputField } from 'helpers/form'

import { Button } from 'components'
import { ReactComponent as CloseSVG } from 'assets/close.svg'

import { useDispatch, batch } from 'react-redux'
import { setFilters } from 'features/filters/filtersSlice'
import { setCollectorFilters } from 'features/collectorFilters/filtersSlice'
import { resetCollectorLoans } from 'features/collector/collectorSlice'
// import { resetLoans } from 'features/loans/loanSlice'
// import { GlobalState } from 'index'
// import { isNil, omitBy } from 'lodash'
// import { useEffect } from 'react'

import { resetLoans } from 'features/loans/loanSlice'
import { setAgentsFilters } from 'features/agentFilters/filtersSlice'
import { resetAgentsLoans } from 'features/agentLoans/loanSlice'
import { resetContracts } from 'features/contracts/contractSlice'
import { setContractFilters } from 'features/contractsFilters/filtersSlice'
import { setBanListFilters } from 'features/banListFilters/filtersSlice'

import {
  StyledSearchWrapper,
  StyledSearchBlock,
  StyledActionsBlock,
  StyledSearchContent,
  StyledReset,
} from './Search.styles'

export const Search: React.FC<any> = ({
  actionType,
  showSearch,

  setShowFilters,
}): JSX.Element => {
  const dispatch = useDispatch()

  const actions = useMemo(
    () => (values) => ({
      underwriter: () => {
        if (values?.search)
          batch(() => {
            dispatch(setFilters(values))
            dispatch(resetLoans(null))
          })
        else dispatch(setFilters({}))
      },
      agent: () => {
        if (values?.search)
          batch(() => {
            dispatch(setAgentsFilters(values))
            dispatch(resetAgentsLoans(null))
          })
        else dispatch(setAgentsFilters({}))
      },
      contracts: () => {
        if (values?.search)
          batch(() => {
            dispatch(setContractFilters(values))
            dispatch(resetContracts(null))
          })
        else dispatch(setContractFilters({}))
      },
      banList: () => {
        if (values?.search)
          batch(() => {
            dispatch(setBanListFilters(values))
          })
        else dispatch(setBanListFilters({}))
      },
      sdl: () => {
        if (values?.search) dispatch(setFilters(values))
        else dispatch(setFilters({}))
      },
      collector: () => {
        if (values?.search)
          batch(() => {
            dispatch(setCollectorFilters(values))
            dispatch(resetCollectorLoans(null))
          })
        else dispatch(setFilters({}))
      },
    }),
    [actionType]
  )

  const submitForm = async (values: any): Promise<void> => {
    setShowFilters(false)
    actions(values)[actionType]()
  }

  const handleKeyDown = (values: any) => (e) => {
    if (e.key === 'Enter') {
      submitForm(values)
    }
  }

  return (
    <Formik
      initialValues={{
        search: '',
      }}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const { values, errors, setFieldValue, handleSubmit }: any = formikProps

        const disabled = !!Object.keys(errors).length
        return (
          <StyledSearchBlock showSearch={showSearch}>
            <StyledSearchContent>
              <StyledSearchWrapper>
                <InputField
                  name='search'
                  onChange={setFieldValue}
                  onKeyDown={handleKeyDown(values)}
                  placeholder={
                    values.search ? '' : 'Поиск (ФИО, серия, номер паспорта, телефон, почта)'
                  }
                  value={values.search}
                  {...formikProps}
                />
                {values.search && (
                  <StyledReset onClick={() => setFieldValue('search', '')}>
                    <CloseSVG />
                  </StyledReset>
                )}
              </StyledSearchWrapper>
              <StyledActionsBlock disabled={disabled}>
                <Button
                  type='standard'
                  onClick={!disabled ? handleSubmit : null}
                  disabled={disabled}
                >
                  Найти
                </Button>
              </StyledActionsBlock>
            </StyledSearchContent>
          </StyledSearchBlock>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  search: Yup.string(),
})
