/* eslint-disable react/no-array-index-key */
import { forwardRef } from 'react'
import { Events } from 'react-scroll'
import { StyledNav, StyledLink } from './Navigation.styles'

export interface INavigationProps {
  list: INavigationItem[]
  containerId: string
}
export interface INavigationItem {
  title: string
  anchor: string
}

export const Navigation: React.FC<any> = forwardRef((props: INavigationProps & any, ref: any) => {
  const { list, containerId, ...rest } = props
  Events.scrollEvent.register('begin', (to) => {
    rest.onChange(to)
  })
  return (
    <StyledNav>
      {list.map((item, i) => {
        const { anchor, title } = item

        return (
          <StyledLink
            key={i}
            activeClass='active'
            containerId={containerId}
            to={anchor.replace('#', '')}
            spy
            smooth
            duration={300}
            isDynamic
            ref={ref}
            {...(rest || {})}
          >
            {title}
          </StyledLink>
        )
      })}
    </StyledNav>
  )
})
