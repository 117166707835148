/* eslint-disable guard-for-in */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { AccessWrapper, getAddressState, InputField, normalizeValues, postMask } from 'helpers'
import { fetchPassportData, updateResidenceData } from 'features/loan/extra'
import { useGetResidenceData } from 'features/loan/loanSelectors'
import { IResidenceData, IResidenceDataUpdate } from 'features/loan/types'

import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { Button, Dadata, Module } from 'components'
import { StyledButtonBlock } from './Modules.styles'
import { IModuleProps } from './types'
import { rolesType } from 'constants/rtoles'
import { PermissionContext } from 'app/contexts'

const moduleId = 'residence'
export const Residence: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  sameAddress,
  claimsResolve,
  userId,
}): JSX.Element => {
  const dispatch = useDispatch()

  const [editable, setEditable] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<IResidenceData | unknown>({})
  const { permissions } = useContext(PermissionContext)

  const data = useGetResidenceData()
  const isOpen = openedModules.includes(moduleId)

  useEffect(() => {
    if (isOpen && userId && data.isFetching) {
      dispatch(fetchPassportData({ id: userId }))
    }
  }, [isOpen, userId])

  useEffect(() => {
    if (data && !editable) {
      setInitialValues(data)
    }
  }, [data, editable])

  const handleChangeEditable = (): void => {
    setEditable(!editable)
  }
  const handleCancel = (resetForm) => (): void => {
    resetForm()
    setEditable(!editable)
  }
  const handleExpand = (): void => {
    onToggleModule(moduleId)
  }
  const submitForm = async (values): Promise<void> => {
    const {
      postal_code,
      region,
      district,
      city,
      street,
      building,
      house_number,
      block,
      apartment,
      other,
    } = values

    // required
    const body: IResidenceDataUpdate = {
      postal_code,
      region,
      city,
      street,
      house_number,
    }
    // optional
    if (district) Object.assign(body, { district })
    if (building) Object.assign(body, { building })
    if (block) Object.assign(body, { block })
    if (apartment) Object.assign(body, { apartment })
    if (other) Object.assign(body, { other })

    dispatch(
      updateResidenceData({
        id: userId,
        body,
        values,
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Данные не сохранены`,
            })
          )
        },
        onSuccess: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: `Данные сохранены`,
            })
          )
          setEditable(false)
        },
      })
    )
  }

  function isValidForm(errors: unknown): boolean {
    return !!Object.keys(errors).length
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>
                  Адрес проживания{' '}
                  {sameAddress ? <b style={{ color: '#24C665' }}>/ совпадает </b> : ''}
                </Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <AccessWrapper
                condition={permissions.includes(rolesType.regionalManager) || claimsResolve}
                access={[rolesType.agent, rolesType.regionalManager]}
              >
                <Module.Button handleChangeEditable={handleChangeEditable} />
              </AccessWrapper>
              <Module.Content>
                {data.isFetching && <Module.LoaderBlock />}
                {editable ? <Edit {...formikProps} /> : <View {...formikProps} />}
              </Module.Content>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && (
                <div style={{ margin: '32px 0' }}>
                  <Button
                    disabled={isValidForm(errors) || data.isUpdating || !dirty}
                    type='standardBig'
                    onClick={() => dirty && handleSubmit()}
                    pending={data.isUpdating}
                  >
                    Сохранить
                  </Button>
                  <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue } = props
  const [address, setAddress] = useState({ value: '', data: {} }) as any

  useEffect(() => {
    if (address.value) {
      const addressBody = getAddressState(address)
      for (const key in addressBody) {
        setFieldValue(key, addressBody[key])
      }
    }
  }, [address, setFieldValue])

  return (
    <>
      <Module.Column column={4}>
        <Dadata
          name='actual-address'
          type='address'
          placeholder='Адрес (поиск)'
          value={address}
          onChange={(val) => setAddress(val)}
          required
        />
      </Module.Column>
      <Module.Column column={4}>
        <InputField
          name='postal_code'
          onChange={setFieldValue}
          placeholder='Почтовый индекс'
          value={values.postal_code}
          noMove
          mask={postMask}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='region'
          onChange={setFieldValue}
          placeholder='Регион'
          value={values.region}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='area'
          onChange={setFieldValue}
          placeholder='Область'
          value={values.area}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='district'
          onChange={setFieldValue}
          placeholder='Район'
          value={values.district}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='city'
          onChange={setFieldValue}
          placeholder='Город'
          value={values.city}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={4}>
        <InputField
          name='street'
          onChange={setFieldValue}
          placeholder='Улица'
          value={values.street}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='building'
          onChange={setFieldValue}
          placeholder='Строение'
          value={values.building}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='house_number'
          onChange={setFieldValue}
          placeholder='Дом'
          value={values.house_number}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <InputField
          name='block'
          onChange={setFieldValue}
          placeholder='Корпус'
          value={values.block}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='apartment'
          onChange={setFieldValue}
          placeholder='Квартира'
          value={values.apartment}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <InputField
          name='other'
          onChange={setFieldValue}
          placeholder='Дополнительные значения адреса'
          value={values.other}
          noMove
          {...props}
        />
      </Module.Column>
    </>
  )
}

const View = (props): JSX.Element => {
  const { values } = props
  return (
    <>
      <>
        <Module.Column column={4}>
          <Module.Title>Почтовый индекс</Module.Title>
          <Module.Value>{normalizeValues(values.postal_code)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Регион</Module.Title>
          <Module.Value>{normalizeValues(values.region)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Область</Module.Title>
          <Module.Value>{normalizeValues(values.area)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Район</Module.Title>
          <Module.Value>{normalizeValues(values.district)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Город</Module.Title>
          <Module.Value>{normalizeValues(values.city)}</Module.Value>
        </Module.Column>
        <Module.Column column={4}>
          <Module.Title>Улица</Module.Title>
          <Module.Value>{normalizeValues(values.street)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Строение</Module.Title>
          <Module.Value>{normalizeValues(values.building)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Дом</Module.Title>
          <Module.Value>{normalizeValues(values.house_number)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Корпус</Module.Title>
          <Module.Value>{normalizeValues(values.block)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Квартира</Module.Title>
          <Module.Value>{normalizeValues(values.apartment)}</Module.Value>
        </Module.Column>
        <Module.Column column={2}>
          <Module.Title>Дополнительные значения адреса</Module.Title>
          <Module.Value>{normalizeValues(values.other)}</Module.Value>
        </Module.Column>
      </>
    </>
  )
}

const validationSchema = Yup.object().shape({
  postal_code: Yup.string()
    .required()
    .test((val) => val && !val.includes('_')),
  region: Yup.string().required(),
  city: Yup.string().required(),
  street: Yup.string().required(),
  house_number: Yup.string().required(),
})
