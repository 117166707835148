/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { orderBy } from 'lodash'

import { getAdminFio, normalizeValues, useWindowSize } from 'helpers'
import { ExpandedContainer, Module } from 'components'

import {
  StyledContent,
  StyledMobileCommentInfo,
  StyledMobileDescription,
  StyledMobileHistory,
  StyledMobileHistoryItem,
  StyledMobileSection,
  StyledMobileValue,
  StyledSubValue,
} from './Modules.styles'

const moduleId = 'statusesHistory'

export const StatusesHistory: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const isOpen = openedModules.includes(moduleId)
  const {
    statusesHistory: { isFetching, data },
  } = useSelector((state: GlobalState) => state.singleLoan)
  const [statusesData, setStatusesData] = useState([])
  const { width } = useWindowSize()

  useEffect(() => {
    if (data.length > 0) setStatusesData(data)
  }, [data])

  const handleExpand = (): void => onToggleModule(moduleId)

  const DesktopView = () => (
    <StyledContent>
      <TableHead />
      {statusesData &&
        statusesData.length > 0 &&
        orderBy(statusesData, ['status_date'], ['desc']).map((item, index) => (
          <Table key={item?.loans_loan_id || index} {...item} />
        ))}
    </StyledContent>
  )

  const MobileView = () => (
    <StyledMobileHistory>
      {statusesData &&
        statusesData.length > 0 &&
        orderBy(statusesData, ['status_date'], ['desc']).map((item, index) => (
          <MobileTable key={item?.loans_loan_id || index} {...item} />
        ))}
    </StyledMobileHistory>
  )

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            История статусов
            {!statusesData.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {isFetching && <Module.LoaderBlock />}
        <ExpandedContainer elements={statusesData.length} isFetching={isFetching}>
          {width >= 480 ? <DesktopView /> : <MobileView />}
        </ExpandedContainer>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const MobileTable = (props): JSX.Element => {
  const dictData = useSelector((state: GlobalState) => state.dict)
  const { status_name: name, status_date: date, username, comment } = props

  return (
    <StyledMobileHistoryItem>
      <StyledMobileSection>
        <StyledMobileCommentInfo>
          <div>
            <StyledMobileDescription>Дата и время</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(date, 'full_date')}</StyledMobileValue>
          </div>

          <div>
            <StyledMobileDescription>Пользователь</StyledMobileDescription>
            <StyledMobileValue>{getAdminFio(username, dictData)}</StyledMobileValue>
          </div>
        </StyledMobileCommentInfo>
        <StyledMobileCommentInfo>
          <div>
            <StyledMobileDescription>Статус</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(name, 'loan_status')}</StyledMobileValue>
          </div>
        </StyledMobileCommentInfo>
      </StyledMobileSection>
      <div>
        <span style={{ fontSize: '12px' }}>{comment}</span>
      </div>
    </StyledMobileHistoryItem>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Статус</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Пользователь</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Комментарий</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const dictData = useSelector((state: GlobalState) => state.dict)
  const { status_name: name, status_date: date, username, comment } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {normalizeValues(name, 'loan_status')}
          <StyledSubValue>{normalizeValues(date, 'full_date')}</StyledSubValue>
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{getAdminFio(username, dictData)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue style={{ fontSize: '12px' }}>{comment}</Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
