/* eslint-disable indent */
/* eslint-disable prefer-const */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useSelector } from 'react-redux'
import { Fragment, useState } from 'react'
import { cloneDeep, isEmpty } from 'lodash'
import { Module, Badge, Button, ModalConfirm } from 'components'
import { GlobalState } from 'index'
import { Nullable } from 'commonTypes'

import {
  StyledWrapper,
  StyledSection,
  StyledCheckTitle,
  StyledCheckBlock,
  StyledBadgeInfo,
  StyledButtonsBlock,
  StyledActionButton,
  StyledActionsWrapper,
} from './Modules.styles'

import { STRUCTURE } from '../structure'

interface IShowModal {
  title: string
  description: string
  action: () => void
  style: string
  show: boolean
}

export const Step2 = ({ props }): JSX.Element => {
  const {
    setSteps,
    list,
    setShowWindow,
    documents,
    setDocuments,
    showWindow,
    prevDocumentsState,
    sePreviousDocumentsState,
    setCheckedDocuments,
    setAdditionalRequirements,
  } = props
  const { availabilityData } = useSelector((store: GlobalState) => store.sdl)
  const documentKeys = Object.keys(documents)
  const [showModal, setShowModal] = useState<IShowModal>({
    title: '',
    description: '',
    action: null,
    style: '',
    show: false,
  })

  let confirmedDocuments = {}
  let allowedNextStep = true
  let additionalRequirements = []

  const handleClickNext = (): void => {
    if (!allowedNextStep) {
      setShowModal({
        show: true,
        title: 'Есть не подтвержденные письма.',
        description: 'Не подтвержденные письма, не будут отправлены.',
        action: () => {
          setAdditionalRequirements(additionalRequirements.length ? additionalRequirements : null)
          setShowWindow({
            ...showWindow,
            show: false,
          })
          setCheckedDocuments({
            ...confirmedDocuments,
          })
          setSteps(additionalRequirements.length ? 3 : 4)
        },
        style: 'success',
      })
    } else {
      setAdditionalRequirements(additionalRequirements.length ? additionalRequirements : null)
      setCheckedDocuments({
        ...confirmedDocuments,
      })
      setShowWindow({
        ...showWindow,
        show: false,
      })
      setSteps(additionalRequirements.length ? 3 : 4)
    }
  }

  const handleClickBack = (): void => {
    if (!isEmpty(prevDocumentsState)) {
      setDocuments(cloneDeep(prevDocumentsState))
      sePreviousDocumentsState({})
    }
    setCheckedDocuments({})
    setSteps(1)
    setShowWindow({
      ...showWindow,
      show: false,
    })
  }

  const handleOpenLetterContent = (letterId: string): void => {
    setShowWindow({
      letterId,
      show: true,
    })
  }

  return (
    <StyledWrapper>
      <>
        {documentKeys.map((documentItemKey, index) => {
          const title = list.filter((listItem) => listItem.anchor === documentItemKey)[0]?.title
          const documentNestedKeys = Object.keys(documents[documentItemKey]).filter(
            (filteredKey) => documents[documentItemKey][filteredKey]
          )
          let convertedStructure = {}
          STRUCTURE.forEach((str) => {
            convertedStructure = { ...convertedStructure, ...str }
          })

          return (
            <Fragment key={index}>
              <ModalConfirm
                isOpen={showModal.show}
                onRequestClose={() => setShowModal({ ...showModal, show: false })}
                onClose={() => setShowModal({ ...showModal, show: false })}
                description={showModal.description}
                title={showModal.title}
                action={showModal.action}
                style={showModal.style}
              />
              {documentNestedKeys.length > 0 && (
                <StyledSection>
                  <Module.HeadTitle>{title}</Module.HeadTitle>
                  {documentNestedKeys.map((key, idx) => {
                    const currentItem = convertedStructure[documentItemKey].filter((x) => {
                      if (x.subSelection && x.subSelection.length) {
                        const hasValue = x.subSelection.some((sub) => sub.value === key)
                        return hasValue
                      }
                      return x.value === key
                    })[0]

                    if (currentItem.additionalRequiremnts) {
                      additionalRequirements = [
                        ...additionalRequirements,
                        { ...currentItem.additionalRequiremnts, value: currentItem.value },
                      ]
                    }

                    const success = availabilityData[documentItemKey][key]
                    if (!success) allowedNextStep = false

                    if (availabilityData[documentItemKey][key]) {
                      confirmedDocuments = {
                        ...confirmedDocuments,
                        [documentItemKey]: {
                          ...confirmedDocuments[documentItemKey],
                          [key]: true,
                        },
                      }
                    }

                    return (
                      <StyledCheckBlock key={idx}>
                        <StyledActionsWrapper>
                          <StyledCheckTitle>{currentItem.title} </StyledCheckTitle>
                          {success && (
                            <StyledActionButton
                              active
                              onClick={() =>
                                handleOpenLetterContent(
                                  currentItem.subSelection ? key : currentItem.value
                                )
                              }
                            >
                              Открыть письмо
                            </StyledActionButton>
                          )}
                        </StyledActionsWrapper>

                        <StyledBadgeInfo>
                          <Badge
                            type={success ? 'success' : 'danger'}
                            title={
                              availabilityData[documentItemKey][key]
                                ? 'Подтверждено'
                                : 'Не подтверждено'
                            }
                          />
                        </StyledBadgeInfo>
                      </StyledCheckBlock>
                    )
                  })}
                </StyledSection>
              )}
            </Fragment>
          )
        })}
        <StyledSection>
          <StyledButtonsBlock>
            <Button type='standardBig' onClick={handleClickNext}>
              Продолжить
            </Button>
            <Button type='emptyBig' onClick={handleClickBack}>
              Изменить
            </Button>
          </StyledButtonsBlock>
        </StyledSection>
      </>
    </StyledWrapper>
  )
}
