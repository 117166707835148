/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { BiDownload } from 'react-icons/bi'

import {
  InputField,
  normalizeValues,
  normalizeDateForApi,
  DatePickerField,
  numberPassportCodeMask,
  serialPassportCodeMask,
  divisionCodeMask,
  validationSchemaPassport,
  isValidForm,
  previousPassportCodeMask,
} from 'helpers'

import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { GlobalState } from 'index'

import {
  useGetLoanPassportData,
  useGetLoanOtherDocsData,
  useGetPhotoData,
} from 'features/loan/loanSelectors'
import {
  changeVerifyPassportData,
  checkVerifyPassportData,
  fetchDeleteImageById,
  fetchUpdatedINN,
  updatePassportData,
} from 'features/loan/extra'
import { genderType } from 'helpers/dictionary'

import { IPassportData, IPassportDataUpdate, LoanStatus } from 'features/loan/types'
import { addUndoAction, removeUndoIdAction } from 'features/undoAction/undoActionSlice'
import { Button, SmallLoader, UploadLoanImage, Select, Module, CheckBox } from 'components'
import { ImageModal } from 'containers'

import {
  StyledButtonBlock,
  StyledTitleWinActionButton,
  StyledActionButton,
  StyledImageDescription,
  StyledSelectBlock,
  StyledResetFilter,
} from './Modules.styles'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import { IModuleProps } from './types'

const moduleId = 'passport'
export const PassportData: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  claimsResolve,
  userId,
  loanStatus,
}): JSX.Element => {
  const passportData = useGetLoanPassportData()
  const otherDocsData = useGetLoanOtherDocsData()
  const images = useGetPhotoData()
  const dispatch = useDispatch()

  const { registrationDataPhotoFetching, personalDataPhotoFetching } = useSelector(
    (store: GlobalState) => store.singleLoan.loanImageDataFetch
  )

  const { verifyPassportData, loadData } = useSelector((store: GlobalState) => store.singleLoan)

  const [editable, setEditable] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<IPassportData | unknown>({})
  const isOpen = openedModules.includes(moduleId)
  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })
  const [, , deleteImages] = useSelector((state: GlobalState) => state.undoAction)

  useEffect(() => {
    if (passportData && !editable) {
      setInitialValues({
        ...passportData,
        ...otherDocsData,
      })
    }
  }, [passportData, otherDocsData, editable])

  const handleChangeEditable = (): void => setEditable(!editable)

  const handleCancel = (resetForm) => (): void => {
    resetForm()
    setEditable(!editable)
  }

  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = async (values: IPassportData): Promise<void> => {
    setInitialValues(values)

    const {
      first_name,
      last_name,
      middle_name,
      gender,
      date_of_birth,
      place_of_birth,
      serial,
      number,
      date_of_issue,
      place_of_issue,
      code_division,
      previous_passport_data,
    } = values

    const data: IPassportDataUpdate = {
      first_name,
      last_name,
      middle_name,
      gender,
      date_of_birth: normalizeDateForApi(date_of_birth),
      place_of_birth,
      serial,
      number,
      date_of_issue: normalizeDateForApi(date_of_issue),
      place_of_issue,
      code_division,
      previous_passport_data,
    }

    dispatch(
      updatePassportData({
        id: userId,
        body: data,
        values,
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Данные не сохранены`,
            })
          )
        },
        onSuccess: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: `Данные сохранены`,
            })
          )
          setEditable(false)
        },
      })
    )
  }

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const removeImage = (e, id) => {
    e.stopPropagation()

    dispatch(
      addUndoAction({
        title: 'Отменить удаление',
        action: () =>
          dispatch(
            fetchDeleteImageById({
              image_id: id,
              user_id: userId,
              onError: () => {
                dispatch(removeUndoIdAction(id))
              },
            })
          ),
        cancelAction: () => dispatch(removeUndoIdAction(id)),
        id,
      })
    )
  }

  const isEditable = true

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaPassport}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps
        const imageContent = { ...showModal.content }

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <ImageModal.ModalContent
              isEditable={isEditable}
              userId={userId}
              isOpen={showModal.show}
              claimsResolve={claimsResolve}
              onRequestClose={handleCloseModal}
              imageContent={imageContent}
              onClose={handleCloseModal}
              type='passport'
              removable
              loanStatus={loanStatus}
            />
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Паспортные данные</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              {claimsResolve && <Module.Button handleChangeEditable={handleChangeEditable} />}
              <Module.ImageBlock column>
                <StyledImageDescription>Основная страница</StyledImageDescription>
                <div>
                  {personalDataPhotoFetching && <Module.ImageLoader />}
                  {images &&
                    images?.personalDataPhoto.length > 0 &&
                    images.personalDataPhoto.map((image) => (
                      <Module.ImageWrapper
                        onClick={handleShowModal({ image })}
                        key={image.image_id}
                        hide={deleteImages.includes(image.image_id)}
                      >
                        <Module.SmallImage url={image.photo_url} />
                        {claimsResolve && (
                          <Module.ImageDelete onClick={(e) => removeImage(e, image.image_id)} />
                        )}
                      </Module.ImageWrapper>
                    ))}
                  {claimsResolve && (
                    <UploadLoanImage type='passport-main-page-photo' userId={userId} />
                  )}
                </div>
                <StyledImageDescription>Страница регистрации</StyledImageDescription>
                <div>
                  {registrationDataPhotoFetching && <Module.ImageLoader />}
                  {images &&
                    images?.registrationDataPhoto.length > 0 &&
                    images.registrationDataPhoto.map((image) => (
                      <Module.ImageWrapper
                        onClick={handleShowModal({ image })}
                        key={image.image_id}
                        hide={deleteImages.includes(image.image_id)}
                      >
                        <Module.SmallImage url={image.photo_url} />
                        {claimsResolve && loanStatus === LoanStatus.setting_a_limit && (
                          <Module.ImageDelete onClick={(e) => removeImage(e, image.image_id)} />
                        )}
                      </Module.ImageWrapper>
                    ))}
                  {claimsResolve && (
                    <UploadLoanImage type='passport-registration-page-photo' userId={userId} />
                  )}
                </div>
              </Module.ImageBlock>
              <Module.Content>
                {passportData.isFetching && <Module.LoaderBlock />}
                {editable ? (
                  <Edit
                    verifyPassportData={verifyPassportData}
                    loanId={loadData?.loan_id}
                    claimsResolve={claimsResolve}
                    {...formikProps}
                  />
                ) : (
                  <View
                    verifyPassportData={verifyPassportData}
                    {...formikProps}
                    claimsResolve={claimsResolve}
                  />
                )}
              </Module.Content>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && (
                <div style={{ margin: '32px 0' }}>
                  <Button
                    disabled={isValidForm(errors) || passportData.isUpdating || !dirty}
                    type='standardBig'
                    onClick={() => dirty && handleSubmit()}
                    pending={passportData.isUpdating}
                  >
                    Сохранить
                  </Button>
                  <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue, errors, verifyPassportData, loanId, claimsResolve } = props

  const dispatch = useDispatch()

  const suggestionList = Object.keys(genderType).map((value) => ({
    label: genderType[value],
    value,
  }))

  const handleChangePassportVerification = (value) => {
    dispatch(
      changeVerifyPassportData({
        loanId,
        status: !value,
        onSuccess: () => dispatch(checkVerifyPassportData({ loanId })),
      })
    )
  }

  return (
    <>
      <Module.Column column={1}>
        <InputField
          name='serial'
          onChange={setFieldValue}
          placeholder='Серия'
          value={values.serial}
          noMove
          mask={serialPassportCodeMask}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='number'
          onChange={setFieldValue}
          placeholder='Номер'
          value={values.number}
          noMove
          mask={numberPassportCodeMask}
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <DatePickerField
          name='date_of_issue'
          onChange={setFieldValue}
          placeholder='Дата выдачи'
          value={values.date_of_issue}
          format='dd.MM.yyyy'
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <InputField
          name='place_of_issue'
          onChange={setFieldValue}
          placeholder='Место выдачи'
          value={values.place_of_issue}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <InputField
          name='code_division'
          onChange={setFieldValue}
          placeholder='Код подразделения'
          value={values.code_division}
          noMove
          mask={divisionCodeMask}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='last_name'
          onChange={setFieldValue}
          placeholder='Фамилия'
          value={values.last_name}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='first_name'
          onChange={setFieldValue}
          placeholder='Имя'
          value={values.first_name}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='middle_name'
          onChange={setFieldValue}
          placeholder='Отчество'
          value={values.middle_name}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={suggestionList}
            placeholder='Семейный статус'
            value={suggestionList?.find((v) => v.value === values.gender) || ''}
            onChange={(v) => setFieldValue('gender', v.value)}
            error={!!errors?.gender}
          />
          {values.gender && (
            <StyledResetFilter onClick={() => setFieldValue('gender', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>
      <Module.Column column={1}>
        <DatePickerField
          name='date_of_birth'
          onChange={setFieldValue}
          placeholder='Дата рождения'
          value={values.date_of_birth}
          format='dd.MM.yyyy'
          {...props}
        />
      </Module.Column>
      <Module.Column column={2}>
        <InputField
          name='place_of_birth'
          onChange={setFieldValue}
          placeholder='Место рождения'
          value={values.place_of_birth}
          noMove
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='previous_passport_data'
          onChange={setFieldValue}
          placeholder='Предыдущий паспорт'
          value={values.previous_passport_data}
          noMove
          mask={previousPassportCodeMask}
          {...props}
        />
      </Module.Column>
      {claimsResolve && (
        <Module.Column column={1}>
          <Module.Title>Альтернативная проверка</Module.Title>
          <CheckBox
            active={verifyPassportData}
            onChange={(value) => handleChangePassportVerification(value)}
            value={verifyPassportData}
            label={verifyPassportData ? 'Да' : 'Нет'}
          />
        </Module.Column>
      )}
    </>
  )
}

const View = (props): JSX.Element => {
  const { values, verifyPassportData, claimsResolve } = props
  const {
    userId,
    updateINN: { isFetching },
  } = useSelector((store: GlobalState) => store.singleLoan)
  const dispatch = useDispatch()

  const handleUpdateINN = (): void => {
    dispatch(
      fetchUpdatedINN({
        id: userId,
        successAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: `ИНН успешно получен`,
            })
          )
        },
        errorAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `действие запрещено, ИНН не обновлен`,
            })
          )
        },
      })
    )
  }
  return (
    <>
      <>
        <Module.Column column={1}>
          <Module.Title>Серия</Module.Title>
          <Module.Value>{normalizeValues(values.serial)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Номер</Module.Title>
          <Module.Value>{normalizeValues(values.number)}</Module.Value>
        </Module.Column>
        <Module.Column column={2}>
          <Module.Title>Дата выдачи</Module.Title>
          <Module.Value>{normalizeValues(values.date_of_issue, 'date')}</Module.Value>
        </Module.Column>
        <Module.Column column={2}>
          <Module.Title>Место выдачи</Module.Title>
          <Module.Value>{normalizeValues(values.place_of_issue)}</Module.Value>
        </Module.Column>
        <Module.Column column={2}>
          <Module.Title>Код подразделения</Module.Title>
          <Module.Value>{normalizeValues(values.code_division)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Фамилия</Module.Title>
          <Module.Value>{normalizeValues(values.last_name)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Имя</Module.Title>
          <Module.Value>{normalizeValues(values.first_name)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Отчество</Module.Title>
          <Module.Value>{normalizeValues(values.middle_name)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Пол</Module.Title>
          <Module.Value>{normalizeValues(values.gender, 'gender')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Дата рождения</Module.Title>
          <Module.Value>{normalizeValues(values.date_of_birth, 'date')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Место рождения</Module.Title>
          <Module.Value>{normalizeValues(values.place_of_birth)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>СНИЛС</Module.Title>
          <Module.Value>{normalizeValues(values.snils_number)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <StyledTitleWinActionButton>
            <Module.Title>ИНН</Module.Title>
            <StyledActionButton fetching={isFetching} onClick={handleUpdateINN}>
              запросить ИНН
              <BiDownload />
              {isFetching && <SmallLoader button />}
            </StyledActionButton>
          </StyledTitleWinActionButton>
          <Module.Value>{normalizeValues(values.inn_number)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Предыдущий паспорт</Module.Title>
          <Module.Value>{normalizeValues(values.previous_passport_data)}</Module.Value>
        </Module.Column>
        {claimsResolve && (
          <Module.Column column={1}>
            <Module.Title>Альтернативная проверка</Module.Title>
            <CheckBox
              disabled
              active={verifyPassportData}
              onChange={null}
              value={verifyPassportData}
              label={verifyPassportData ? 'Да' : 'Нет'}
            />
          </Module.Column>
        )}
      </>
    </>
  )
}
