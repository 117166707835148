import styled, { css } from 'styled-components/macro'
import { THEME } from '../../core/theme'

export const PlaceholderTop = css`
  top: 4px;
  bottom: 10px;
  left: 18px;
  font-size: 12px;
  opacity: 1;
`

interface IStyledPlaceholder {
  error: boolean
  editable?: any
}
export const StyledPlaceholder = styled.span<IStyledPlaceholder>`
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 21px;
  transition: 0.2s ease all;
  z-index: 1;
  font-size: 14px;
  line-height: 20px;
  color: #808080;

  ${({ error }) =>
    error &&
    css`
      color: #ff601a;
    `};

  ${({ editable }) =>
    editable &&
    css`
      top: 4px;
      bottom: 10px;
      left: 18px;
      font-size: 12px;
      opacity: 1;
    `};
`

interface IShowSelectIcon {
  showSelectIcon: boolean
  error?: boolean
  value?: boolean
}

export const StyledButtonClose = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z' fill='%2373717E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289Z' fill='%2373717E'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  top: 17px;
  right: 10px;
  z-index: 1;
  position: absolute;
  cursor: pointer;
`

export const StyledDatePickerWrapper = styled.div<IShowSelectIcon>`
  position: relative;
  ${({ error }) =>
    error
      ? css`
          .react-datepicker__input-container input {
            border: 2px solid ${THEME.COLORS.WARNING}!important;

            ::placeholder {
              color: #ff601a;
            }
          }
        `
      : css`
          .react-datepicker__input-container input {
            ::placeholder {
              color: rgb(45 54 70 / 0.5);
            }
            &:hover {
              border: 2px solid ${THEME.COLORS.YELLOW}!important;
            }
          }
        `}

  &:focus ${StyledPlaceholder} {
    ${PlaceholderTop}
  }
  ${({ value }) =>
    value &&
    css`
      ${StyledPlaceholder} {
        ${PlaceholderTop}
      }
      &:invalid ${StyledPlaceholder} {
        color: #ff601a;
      }
    `}

  ${({ value }) =>
    value &&
    css`
      .react-datepicker__input-container input {
        padding-top: 10px;
      }
    `};
`
