/* eslint-disable arrow-body-style */
import { rolesType } from 'constants/rtoles'
import { useCallback } from 'react'

export type Permission = typeof rolesType[keyof typeof rolesType]

interface UsePermissionHook {
  hasPermission: (requiredPermissions: Permission[], requiredAll?: boolean) => boolean
}

export const usePermission = (userPermissions: Permission[]): UsePermissionHook => {
  const hasPermission = useCallback(
    (requiredPermissions: Permission[], requiredAll = false) =>
      requiredAll
        ? requiredPermissions.every((permission) => userPermissions.includes(permission))
        : requiredPermissions.some((permission) => userPermissions.includes(permission)),
    [userPermissions]
  )

  return { hasPermission }
}
