/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AGENT_LOANS_ROUTE,
  COLLECTOR_LOANS_ROUTE,
  OPERATOR_LOANS_ROUTE,
  SDL_ROUTE_ROUTE,
  UNDERWRITER_LOANS_ROUTE,
  SETTINGS_DOCUMENTS_ROUTE,
} from 'constants/pagesRoutes'

import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { MdExitToApp } from 'react-icons/md'

import { NavMenuType } from 'App'
import { Button, Select, Tooltip } from 'components'
import { StyledTooltipMenuWrapper } from 'components/Tooltip/Tooltip.styles'

import { ISidebarProps, TabsType } from './types'
import { NavMenu } from './NavMenu'
import { MobileSidebarMenu } from './MobileSidebarMenu'
import { Notification } from './Notification'
import { Profile } from './Profile'

import {
  StyledSidebarWrapper,
  StyledSidebarContent,
  StyledArrow,
  StyledLogo,
  StyledHead,
  StyledContainer,
  StyledMobileRoleSelector,
  StyledMobileLogo,
  StyledMobileLogout,
} from './Sidebar.styles'

import { ReactComponent as LogoSVG } from '../../assets/logo_horizontal.svg'
import { ReactComponent as ShortLogoSVG } from '../../assets/short-logo.svg'
import { ReactComponent as ArrowSVG } from '../../assets/chevron.svg'
import { ReactComponent as CloseSVG } from '../../assets/sidebar-close.svg'
import { PageScope } from 'pages/App/App'
import { NavigationContext } from 'app/contexts'

export const Sidebar = (props: any): JSX.Element => {
  const { opened, setOpened, activeTab, setActiveTab } = props
  const { ...otherProps } = props
  const history = useHistory()
  const { handleLogout } = useContext(PageScope)

  const {
    isDevelopment,
    isSuperUser,
    isAgentRole,
    isUnderwriterRole,
    isCollectorRole,
    isOperatorRole,
    setNavMenuType,
    navMenuType,
  } = useContext(NavigationContext)

  const toggleOpen = (): void => {
    setOpened(!opened)
  }

  const onSelectUnderwriter = (): void => {
    if (navMenuType !== NavMenuType.underwriter) {
      setNavMenuType(NavMenuType.underwriter)
      history.push(UNDERWRITER_LOANS_ROUTE)
    }
  }

  const onSelectAgent = (): void => {
    if (navMenuType !== NavMenuType.agent) {
      setNavMenuType(NavMenuType.agent)
      history.push(AGENT_LOANS_ROUTE)
    }
  }

  const onSelectOperator = (): void => {
    if (navMenuType !== NavMenuType.operator) {
      setNavMenuType(NavMenuType.operator)
      history.push(`${OPERATOR_LOANS_ROUTE}?type=all`)
    }
  }

  const onSelectCollector = (): void => {
    if (navMenuType !== NavMenuType.collector) {
      setNavMenuType(NavMenuType.collector)
      history.push(COLLECTOR_LOANS_ROUTE)
    }
  }

  const onSelectSdl = (): void => {
    if (navMenuType !== NavMenuType.sdl) {
      setNavMenuType(NavMenuType.sdl)
      history.push(SDL_ROUTE_ROUTE)
    }
  }

  const onSelectSettingRole = (): void => {
    if (navMenuType !== NavMenuType.settings) {
      setNavMenuType(NavMenuType.settings)
      history.push(SETTINGS_DOCUMENTS_ROUTE)
    }
  }

  const Logo = opened ? ShortLogoSVG : LogoSVG

  const getAllowedRoles = () => {
    const roles = []
    if (isUnderwriterRole) roles.push({ label: 'Андеррайтер', value: NavMenuType.underwriter })
    if (isAgentRole) roles.push({ label: 'Агент', value: NavMenuType.agent })
    if (isOperatorRole) roles.push({ label: 'Оператор', value: NavMenuType.operator })
    if (isCollectorRole) roles.push({ label: 'Коллектор', value: NavMenuType.collector })
    if (isSuperUser || isDevelopment) roles.push({ label: 'СДЛ', value: NavMenuType.sdl })
    if (isSuperUser || isDevelopment)
      roles.push({ label: 'Настройки', value: NavMenuType.settings })
    return roles
  }
  const roles = getAllowedRoles()

  const options = {
    [NavMenuType.agent]: AGENT_LOANS_ROUTE,
    [NavMenuType.underwriter]: UNDERWRITER_LOANS_ROUTE,
    [NavMenuType.operator]: OPERATOR_LOANS_ROUTE,
    [NavMenuType.collector]: COLLECTOR_LOANS_ROUTE,
    [NavMenuType.sdl]: SDL_ROUTE_ROUTE,
    [NavMenuType.settings]: SETTINGS_DOCUMENTS_ROUTE,
  }

  const handleChange = (v) => {
    const currentVal = options[v.value]
    setNavMenuType(v.value)
    history.push(currentVal)
  }

  return (
    <>
      <StyledSidebarWrapper {...otherProps} opened={opened}>
        <StyledHead>
          <StyledMobileLogo>
            <ShortLogoSVG />
          </StyledMobileLogo>
          <CloseSVG onClick={toggleOpen} />
        </StyledHead>
        <StyledMobileRoleSelector>
          <Select
            isSearchable={false}
            theme='roleSelector'
            data={roles}
            placeholder='Роль'
            value={roles?.find((v) => v.value === navMenuType) || ''}
            onChange={(v) => handleChange(v)}
          />
        </StyledMobileRoleSelector>

        <StyledLogo>
          <Tooltip content={<Logo />} coward={false}>
            <StyledTooltipMenuWrapper>
              {isUnderwriterRole && (
                <Button
                  type='emptyBig'
                  onClick={onSelectUnderwriter}
                  active={navMenuType === NavMenuType.underwriter}
                >
                  Андеррайтер
                </Button>
              )}
              {isAgentRole && (
                <Button
                  type='emptyBig'
                  onClick={onSelectAgent}
                  active={navMenuType === NavMenuType.agent}
                >
                  Агент
                </Button>
              )}
              {isOperatorRole && (
                <Button
                  type='emptyBig'
                  onClick={onSelectOperator}
                  active={navMenuType === NavMenuType.operator}
                >
                  Оператор
                </Button>
              )}
              {isCollectorRole && (
                <Button
                  type='emptyBig'
                  onClick={onSelectCollector}
                  active={navMenuType === NavMenuType.collector}
                >
                  Коллектор
                </Button>
              )}
              {(isSuperUser || isDevelopment) && (
                <Button
                  type='emptyBig'
                  onClick={onSelectSdl}
                  active={navMenuType === NavMenuType.sdl}
                >
                  СДЛ
                </Button>
              )}
              {(isSuperUser || isDevelopment) && (
                <Button
                  type='emptyBig'
                  onClick={onSelectSettingRole}
                  active={navMenuType === NavMenuType.settings}
                >
                  Настройки
                </Button>
              )}
            </StyledTooltipMenuWrapper>
          </Tooltip>
        </StyledLogo>

        <StyledContainer>
          <StyledSidebarContent>
            {activeTab === TabsType.menu && <NavMenu setOpened={setOpened} opened={opened} />}
            {activeTab === TabsType.notification && <Notification />}
            {activeTab === TabsType.profile && <Profile />}
            <StyledMobileLogout onClick={handleLogout}>
              <div>
                <MdExitToApp />
                Выход
              </div>
            </StyledMobileLogout>
          </StyledSidebarContent>
          <StyledArrow onClick={toggleOpen}>
            <ArrowSVG />
          </StyledArrow>
        </StyledContainer>
      </StyledSidebarWrapper>
    </>
  )
}
