import { v4 as uuid } from 'uuid'

export const LOAN_INFO = [
  {
    title: 'Номер заявки',
    name: 'loan_number',
    value: '',
    type: '',
    id: uuid(),
  },
  // { title: 'Тип траффика', name: 'loan_source', value: 'PDL', type: '', id: uuid() },
  // { title: 'Кол-во минут на выставлении', value: '3', type: '', id: uuid() },
  {
    title: 'Дата создания',
    name: 'loan_date_create',
    value: '28.04.2021',
    type: 'full_date',
    id: uuid(),
  },
  {
    title: 'Дата выдачи',
    name: 'loan_issue_date',
    value: '',
    type: 'full_date',
    id: uuid(),
  },
  {
    title: 'Статус заявки',
    name: 'loan_status',
    value: '',
    type: 'loan_status',
    id: uuid(),
  },
  {
    title: 'Запрашиваемая сумма',
    name: 'loan_ask_sum',
    value: '',
    type: 'sum',
    id: uuid(),
  },
  {
    title: 'Запрашиваемый срок',
    name: 'loan_ask_period',
    value: 1231230,
    type: '',
    id: uuid(),
  },
  {
    title: 'Разрешенная сумма',
    name: 'loan_permitted_sum',
    value: '',
    type: 'sum',
    id: uuid(),
  },
  {
    title: 'Разрешенный период',
    name: 'loan_permitted_period',
    value: '1987200',
    type: '',
    id: uuid(),
  },
  { title: 'Выбранная клиентом сумма', name: 'loan_sum', value: '5', id: uuid() },
  { title: 'Выбранный клиентом срок', name: 'loan_period', value: '5', id: uuid() },
  {
    title: 'Кол-во обращений',
    name: 'requests_count',
    value: '5',
    id: uuid(),
  },
  {
    title: 'Тип продукта',
    name: 'credit_product',
    value: '',
    id: uuid(),
    type: 'productsTranslate',
  },
]
