/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
import { ProjectLoansService } from 'services/loanService'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loanStatuses } from 'helpers'
import { ILoanPageData, IPhoto, INbkiHitory, INbkiScoring } from './types'
import { initialState } from './initialState'

import {
  fetchLoanPageById,
  fetchBankCardDataPhoto,
  fetchPersonalDataPhoto,
  fetchRegistrationDataPhoto,
  fetchNBKIHistoryData,
  fetchNBKIScoringData,
  fetchServicesData,
  fetchLoanRefuse,
  fetchLoanAccept,
  changeLoanStatus,
  fetchIsBanned,
  fetchOnUnban,
  fetchUnAssignLoan,
  fetchOnBan,
  fetchPersonalData,
  fetchPassportData,
  fetchBankCardData,
  fetchPhonesData,
  fetchAutoCheckData,
  fetchUserDetailsData,
  fetchUpdatedINN,
  fetchAgentDataPhoto,
  getLoanComments,
  createLoanComments,
  getStatusesHistory,
  updatePersonalData,
  updatePassportData,
  updateRegistrationData,
  updateResidenceData,
  updateUserDetailsData,
  updateLoanComments,
  getScoringResult,
  getPaymentsResult,
  fetchPtiData,
  fetchPtiBRSData,
  fetchLoanTransactionsByNumber,
  fetchLoanDebtByNumber,
  fetchAdditionalContacts,
  updateAdditionalContacts,
  createAdditionalContacts,
  deleteAdditionalContacts,
  fetchFsspData,
  fetchLoanRatingData,
  fetchLoanRatingResultData,
  fetchLoanAcceptWithoutLimit,
  fetchVideoVerification,
  fetchFemidaPhone,
  fetchFemidaPassport,
  fetchFemidaScoringLegal,
  fetchFemidaScoringOneTimePhone,
  fetchFemidaScoringFraudPhone,
  changeLoanStatusRevision,
  fetchBankruptData,
  updateBankruptData,
  createBankruptData,
  deleteBankruptData,
  fetchLoanGeneralInfoData,
  fetchBkiDocuments,
  fetchAnalyzeData,
  checkVerifyPassportData,
  fetchCheckIsBlacklisted,
  fetchNBKITransferData,
} from './extra'

export const singleLoanSlice = createSlice({
  name: 'singleLoan',
  initialState,
  reducers: {
    setExpandCounter: (state) => {
      state.expandCounterLoanItems += 1
    },
    setUserId: (state) => {
      state.expandCounterLoanItems += 1
    },
    setPause: (state, { payload }) => {
      state.statusesLoan.is_paused = payload
    },
    setAssignLoanToThisUser: (state) => {
      state.statusesLoan = {
        // same_address: state.statusesLoan.same_address,
        is_free: false,
        is_in_work: true,
        is_paused: state.statusesLoan.is_paused,
      }
      // state.assignInfo.total_time = 0
    },
    resetLoan: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanRefuse.pending, (state) => {
        state.pending = true
      })
      // accept loan
      .addCase(fetchLoanAccept.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchLoanRefuse.rejected, (state) => {
        state.fetchingLoanPageError = true
        state.pending = false
      })
      .addCase(fetchLoanAccept.rejected, (state) => {
        state.fetchingLoanPageError = true
        state.pending = false
      })

      // accept without limit
      .addCase(fetchLoanAcceptWithoutLimit.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchLoanAcceptWithoutLimit.rejected, (state) => {
        state.fetchingLoanPageError = true
        state.pending = false
      })
      .addCase(fetchLoanAcceptWithoutLimit.fulfilled, (state, { payload }) => {
        state.pending = false
        state.resolve = payload
        state.loadData = {
          ...state.loadData,
          loan_status: loanStatuses.loan_approved,
        }
        state.statusesLoan.is_in_work = false
        state.statusesLoan.is_paused = false
        state.statusesLoan.is_free = false
      })
      // change loan status
      .addCase(changeLoanStatus.pending, (state) => {
        state.pending = true
      })
      .addCase(changeLoanStatus.rejected, (state) => {
        state.fetchingLoanPageError = true
        state.pending = false
      })
      .addCase(changeLoanStatus.fulfilled, (state, { payload }) => {
        state.pending = false
        state.resolve = payload
        state.loadData = {
          ...state.loadData,
          loan_status: payload.status_name,
        }
        state.statusesLoan.is_in_work = false
        state.statusesLoan.is_paused = false
        state.statusesLoan.is_free = false
      })
      .addCase(changeLoanStatusRevision.pending, (state) => {
        state.pending = true
      })
      .addCase(changeLoanStatusRevision.rejected, (state) => {
        state.fetchingLoanPageError = true
        state.pending = false
      })
      .addCase(changeLoanStatusRevision.fulfilled, (state, { payload }) => {
        state.pending = false
        state.resolve = payload
        state.loadData = {
          ...state.loadData,
          loan_status: 'customer_revision',
        }
        state.statusesLoan.is_in_work = false
        state.statusesLoan.is_paused = false
        state.statusesLoan.is_free = false
      })
      // fetchAutoCheckData
      .addCase(fetchAutoCheckData.pending, (state) => {
        state.autoCheck.isFetching = true
      })
      .addCase(fetchAutoCheckData.fulfilled, (state, { payload }) => {
        state.autoCheck.isFetching = false
        state.autoCheck.autoCheckData = payload
      })
      .addCase(fetchAutoCheckData.rejected, (state) => {
        state.autoCheck.isFetching = false
      })
      .addCase(fetchFsspData.pending, (state) => {
        state.fssp.isFetching = true
      })
      .addCase(fetchFsspData.fulfilled, (state, { payload }) => {
        state.fssp.isFetching = false
        state.fssp.data = payload
      })
      .addCase(fetchFsspData.rejected, (state) => {
        state.fssp.data = { detail: 'error' }
        state.fssp.isFetching = false
      })
      .addCase(fetchLoanRatingData.pending, (state) => {
        state.loanRating.isFetching = true
      })
      .addCase(fetchLoanRatingData.fulfilled, (state, { payload }) => {
        state.loanRating.isFetching = false
        state.loanRating.token = payload?.operation_token
      })
      .addCase(fetchLoanRatingData.rejected, (state) => {
        state.loanRating.data = { detail: 'error' }
        state.loanRating.isFetching = false
      })
      .addCase(fetchLoanRatingResultData.pending, (state) => {
        state.loanRating.isFetching = true
      })
      .addCase(fetchLoanRatingResultData.fulfilled, (state, { payload }) => {
        state.loanRating.isFetching = false
        state.loanRating.data = payload
      })
      .addCase(fetchLoanRatingResultData.rejected, (state) => {
        state.loanRating.data = { detail: 'error' }
        state.loanRating.isFetching = false
      })
      .addCase(fetchPersonalData.pending, (state) => {
        state.personalData.isFetching = true
      })
      .addCase(fetchPersonalData.fulfilled, (state, { payload }) => {
        state.personalData = payload
        state.personalData.isFetching = false
      })
      //  update personal data
      .addCase(updatePersonalData.pending, (state) => {
        state.personalData.isUpdating = true
      })
      .addCase(updatePersonalData.fulfilled, (state, { payload }) => {
        state.personalData.isUpdating = false
        state.personalData = { ...state.personalData, ...payload }
      })
      .addCase(updatePersonalData.rejected, (state) => {
        state.personalData.isUpdating = false
      })
      //  fetch Phones Data
      .addCase(fetchPhonesData.fulfilled, (state, { payload }) => {
        state.phonesData.isFetching = false
        state.phonesData.dataPhones = payload
      })
      .addCase(fetchAnalyzeData.pending, (state) => {
        state.analyzeData.isFetching = true
      })
      .addCase(fetchAnalyzeData.fulfilled, (state, { payload }) => {
        state.analyzeData.isFetching = false
        state.analyzeData.intersection = payload.data_intersection
        state.analyzeData.ip = payload.ip_data
      })
      //  fetch dank cards data
      .addCase(fetchBankCardData.pending, (state) => {
        state.bankCardsFetch = true
      })
      .addCase(fetchBankCardData.fulfilled, (state, { payload }) => {
        state.bankCardsFetch = false
        state.bankCardsData = payload
      })
      .addCase(fetchBankCardData.rejected, (state) => {
        state.bankCardsFetch = false
        state.bankCardsData = []
      })
      //  fetch passport data
      .addCase(fetchPassportData.pending, (state) => {
        state.passportData.isFetching = true
        state.registrationData.isFetching = true
        state.residenceData.isFetching = true
      })
      .addCase(fetchPassportData.fulfilled, (state, { payload }) => {
        state.passportData.isFetching = false
        state.registrationData.isFetching = false
        state.residenceData.isFetching = false
        state.passportData = payload.passport
        state.otherDocs = payload.other_docs || {}
        state.registrationData = payload?.passport.registration_address
        state.residenceData = payload?.passport.residence_address
        state.same_address = payload?.same_address
        state.in_passport = payload.in_passport
      })
      //  update registration  data
      .addCase(updateRegistrationData.pending, (state) => {
        state.registrationData.isUpdating = true
      })
      .addCase(updateRegistrationData.fulfilled, (state, { payload }) => {
        state.registrationData.isUpdating = false
        state.registrationData = { ...state.registrationData, ...payload }
      })
      .addCase(updateRegistrationData.rejected, (state) => {
        state.registrationData.isUpdating = false
      })
      //  update residence  data //TODO
      .addCase(updateResidenceData.pending, (state) => {
        state.residenceData.isUpdating = true
      })
      .addCase(updateResidenceData.fulfilled, (state, { payload }) => {
        state.residenceData.isUpdating = false
        state.residenceData = { ...state.residenceData, ...payload }
      })
      .addCase(updateResidenceData.rejected, (state) => {
        state.residenceData.isUpdating = false
      })
      //  update passport data
      .addCase(updatePassportData.pending, (state) => {
        state.passportData.isUpdating = true
      })
      .addCase(updatePassportData.fulfilled, (state, { payload }) => {
        state.passportData.isUpdating = false
        state.passportData = { ...state.passportData, ...payload }
      })
      .addCase(updatePassportData.rejected, (state) => {
        state.passportData.isUpdating = false
      })
      //  fetch loan
      .addCase(fetchLoanRefuse.fulfilled, (state, { payload }) => {
        state.pending = false
        state.resolve = payload
        state.loadData = {
          ...state.loadData,
          loan_status: 'refusal_by_the_underwriter',
        }
        state.statusesLoan.is_in_work = false
        state.statusesLoan.is_paused = false
        state.statusesLoan.is_free = false
      })
      .addCase(fetchLoanAccept.fulfilled, (state, { payload }) => {
        state.pending = false
        state.resolve = payload
        state.loadData = {
          ...state.loadData,
          loan_status: loanStatuses.loan_approved,
        }
        state.statusesLoan.is_in_work = false
        state.statusesLoan.is_paused = false
        state.statusesLoan.is_free = false
      })
      .addCase(fetchLoanPageById.pending, (state) => {
        state.resolve = null
        state.isFetchingLoanPage = true
      })
      .addCase(fetchLoanPageById.fulfilled, (state, { payload }: PayloadAction<ILoanPageData>) => {
        state.isFetchingLoanPage = false
        state.selectedLoanData = payload
        state.userData = payload.user_data
        state.loadData = payload.loan_data
        state.userId = payload.user_data.user_id
        state.isOverdue = payload.is_overdue
        state.assignInfo =
          payload.assign_info === ''
            ? {
                ...state.assignInfo,
                total_time: 0,
              }
            : payload.assign_info
        state.statusesLoan = {
          is_free: payload.is_free,
          is_in_work: payload.is_in_work,
          is_paused: payload.is_paused,
        }
      })

      // fetchPersonalDataPhoto
      .addCase(fetchPersonalDataPhoto.pending, (state) => {
        state.loanImageDataFetch.personalDataPhotoFetching = true
      })
      .addCase(
        fetchPersonalDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          state.loanImageDataFetch.personalDataPhotoFetching = false
          state.loanImageData.personalDataPhoto = payload
        }
      )
      .addCase(fetchPersonalDataPhoto.rejected, (state) => {
        state.loanImageDataFetch.personalDataPhotoFetching = false
      })

      // fetchRegistrationDataPhoto
      .addCase(fetchRegistrationDataPhoto.pending, (state) => {
        state.loanImageDataFetch.registrationDataPhotoFetching = true
      })
      .addCase(
        fetchRegistrationDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          state.loanImageDataFetch.registrationDataPhotoFetching = false
          state.loanImageData.registrationDataPhoto = payload
        }
      )
      .addCase(fetchRegistrationDataPhoto.rejected, (state) => {
        state.loanImageDataFetch.registrationDataPhotoFetching = false
      })

      // fetchAgentDataPhoto
      .addCase(fetchAgentDataPhoto.pending, (state) => {
        state.loanImageDataFetch.agentDataPhotoFetching = true
      })
      .addCase(
        fetchAgentDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          state.loanImageDataFetch.agentDataPhotoFetching = false
          state.loanImageData.agentDataPhoto = payload
        }
      )
      .addCase(fetchAgentDataPhoto.rejected, (state) => {
        state.loanImageDataFetch.agentDataPhotoFetching = false
      })
      // user details data
      .addCase(fetchUserDetailsData.pending, (state) => {
        state.userDetailsData.isFetching = true
      })
      .addCase(fetchUserDetailsData.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.userDetailsData.isFetching = false
        state.userDetailsData.data = payload
      })

      // user details data // TODO !!!
      .addCase(updateUserDetailsData.pending, (state) => {
        state.userDetailsData.isUpdating = true
      })
      .addCase(updateUserDetailsData.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.userDetailsData.isUpdating = false
        state.userDetailsData.data = { ...state.userDetailsData.data, ...payload }
      })
      .addCase(updateUserDetailsData.rejected, (state) => {
        state.userDetailsData.isUpdating = false
      })

      // video verification
      .addCase(fetchVideoVerification.pending, (state) => {
        state.videoVerification.isFetching = true
      })
      .addCase(fetchVideoVerification.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.videoVerification.isFetching = false
        state.videoVerification.data = payload
      })
      .addCase(fetchVideoVerification.rejected, (state) => {
        state.videoVerification.isFetching = false
        state.videoVerification.data = []
      })

      // user bank cards data
      .addCase(fetchBankCardDataPhoto.pending, (state) => {
        state.loanImageDataFetch.bankCardDataPhotoFetching = true
      })
      .addCase(
        fetchBankCardDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          state.loanImageData.bankCardDataPhoto = payload
          state.loanImageDataFetch.bankCardDataPhotoFetching = false
        }
      )
      .addCase(
        fetchNBKIHistoryData.fulfilled,
        (state, { payload }: PayloadAction<Array<INbkiHitory>>) => {
          state.nbkiHistoryData = payload
        }
      )
      .addCase(
        fetchNBKIScoringData.fulfilled,
        (state, { payload }: PayloadAction<INbkiScoring>) => {
          state.nbkiScoringData = payload
        }
      )
      .addCase(fetchServicesData.pending, (state) => {
        state.servicesPending = true
      })
      .addCase(fetchServicesData.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.services = payload
        state.servicesPending = false
      })
      .addCase(fetchServicesData.rejected, (state) => {
        state.servicesPending = false
      })
      .addCase(fetchBkiDocuments.pending, (state) => {
        state.servicesPending = true
      })
      .addCase(fetchBkiDocuments.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.bkiDocuments = payload
        state.servicesPending = false
      })
      .addCase(fetchBkiDocuments.rejected, (state) => {
        state.servicesPending = false
      })
      .addCase(fetchIsBanned.fulfilled, (state, { payload }: any) => {
        state.isFetchingLoanPage = false
        state.isBanned = payload
      })
      .addCase(fetchFemidaPhone.fulfilled, (state, { payload }: any) => {
        state.femida.phone = payload
      })
      .addCase(fetchFemidaPassport.fulfilled, (state, { payload }: any) => {
        state.femida.passport = payload
      })
      .addCase(fetchFemidaScoringLegal.fulfilled, (state, { payload }: any) => {
        state.femida.scoringLegal = payload
      })
      .addCase(fetchFemidaScoringOneTimePhone.fulfilled, (state, { payload }: any) => {
        state.femida.scoringOneTimePhone = payload
      })
      .addCase(fetchFemidaScoringFraudPhone.fulfilled, (state, { payload }: any) => {
        state.femida.scoringFraudPhone = payload
      })
      .addCase(fetchOnBan.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchOnBan.rejected, (state) => {
        state.pending = false
      })
      .addCase(fetchOnBan.fulfilled, (state, { payload }: any) => {
        state.pending = false
        state.isBanned = payload
      })
      .addCase(fetchOnUnban.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchOnUnban.rejected, (state) => {
        state.pending = false
      })
      .addCase(fetchOnUnban.fulfilled, (state, { payload }: any) => {
        state.pending = false
        state.isBanned = payload
      })
      .addCase(fetchUnAssignLoan.fulfilled, (state) => {
        state.statusesLoan = {
          // same_address: state.statusesLoan.same_address,
          is_free: true,
          is_in_work: false,
          is_paused: false,
        }
      })
      .addCase(fetchUpdatedINN.pending, (state) => {
        state.updateINN.isFetching = true
      })
      .addCase(fetchUpdatedINN.fulfilled, (state, { payload }) => {
        state.updateINN.isFetching = false
        state.otherDocs.inn_number = payload.data.inn

        const { userId } = state
        const inn = state.otherDocs.inn_number
        ProjectLoansService.patchINN(userId, inn).catch((e) => payload.errorAction())
      })
      .addCase(fetchUpdatedINN.rejected, (state) => {
        state.updateINN.isFetching = false
      })
      // get comments
      .addCase(getLoanComments.pending, (state) => {
        state.commentsData.isFetching = true
      })
      .addCase(getLoanComments.fulfilled, (state, { payload }) => {
        state.commentsData.isFetching = false
        state.commentsData.data = payload
      })
      .addCase(getLoanComments.rejected, (state) => {
        state.commentsData.isFetching = false
      })
      // create comments
      .addCase(createLoanComments.pending, (state) => {
        state.commentsData.pending = true
      })
      .addCase(createLoanComments.fulfilled, (state) => {
        state.commentsData.pending = false
      })
      .addCase(createLoanComments.rejected, (state) => {
        state.commentsData.pending = false
      })
      // update comments // TODO
      .addCase(updateLoanComments.pending, (state) => {
        state.commentsData.isUpdating = true
      })
      .addCase(updateLoanComments.fulfilled, (state) => {
        state.commentsData.isUpdating = false
      })
      .addCase(updateLoanComments.rejected, (state) => {
        state.commentsData.isUpdating = false
      })
      // pti
      .addCase(fetchPtiData.pending, (state) => {
        state.ptiData.fetching = true
      })
      .addCase(fetchPtiData.fulfilled, (state, { payload }) => {
        state.ptiData.data = payload
        state.ptiData.fetching = false
      })
      .addCase(fetchPtiData.rejected, (state) => {
        state.ptiData.error = true
        state.ptiData.fetching = false
      })
      // ptiBRS
      .addCase(fetchPtiBRSData.pending, (state) => {
        state.ptiBRSData.fetching = true
      })
      .addCase(fetchPtiBRSData.fulfilled, (state, { payload }) => {
        state.ptiBRSData.data = payload
        state.ptiBRSData.fetching = false
      })
      .addCase(fetchPtiBRSData.rejected, (state) => {
        state.ptiBRSData.error = true
        state.ptiBRSData.fetching = false
      })
      // statuses history
      .addCase(getStatusesHistory.pending, (state) => {
        state.statusesHistory.isFetching = true
      })
      .addCase(getStatusesHistory.fulfilled, (state, { payload }) => {
        state.statusesHistory.isFetching = false
        state.statusesHistory.data = payload
      })
      .addCase(getStatusesHistory.rejected, (state) => {
        state.statusesHistory.isFetching = false
      })
      .addCase(getPaymentsResult.pending, (state) => {
        state.payments.isFetching = true
      })
      .addCase(getPaymentsResult.fulfilled, (state, { payload }) => {
        state.payments.isFetching = false
        state.payments.data = payload
      })
      .addCase(getPaymentsResult.rejected, (state) => {
        state.payments.isFetching = false
      })

      // getAdditionalContacts
      .addCase(fetchAdditionalContacts.pending, (state) => {
        state.additionalContacts.isFetching = true
      })
      .addCase(fetchAdditionalContacts.fulfilled, (state, { payload }) => {
        state.additionalContacts.isFetching = false
        state.additionalContacts.data = payload.data
      })
      .addCase(fetchAdditionalContacts.rejected, (state) => {
        state.additionalContacts.isFetching = false
      })

      // updateAdditionalContacts
      .addCase(updateAdditionalContacts.pending, (state) => {
        state.additionalContacts.isUpdating = true
      })
      .addCase(updateAdditionalContacts.fulfilled, (state) => {
        state.additionalContacts.isUpdating = false
      })
      .addCase(updateAdditionalContacts.rejected, (state) => {
        state.additionalContacts.isUpdating = false
      })

      // createAdditionalContact
      .addCase(createAdditionalContacts.pending, (state) => {
        state.additionalContacts.isUpdating = true
      })
      .addCase(createAdditionalContacts.fulfilled, (state) => {
        state.additionalContacts.isUpdating = false
      })
      .addCase(createAdditionalContacts.rejected, (state) => {
        state.additionalContacts.isUpdating = false
      })

      // deleteAdditionalContact
      .addCase(deleteAdditionalContacts.pending, (state) => {
        state.additionalContacts.isUpdating = true
      })
      .addCase(deleteAdditionalContacts.fulfilled, (state) => {
        state.additionalContacts.isUpdating = false
      })
      .addCase(deleteAdditionalContacts.rejected, (state) => {
        state.additionalContacts.isUpdating = false
      })

      // getExternalScoringResult
      .addCase(getScoringResult.rejected, (state, { payload }) => {})
      .addCase(getScoringResult.fulfilled, (state, { payload }) => {
        let errorMessage = null

        if (payload.response?.status) {
          switch (payload.response?.status) {
            case 404:
              errorMessage = 'Не был запрошен'
              break
            case 204:
              errorMessage = 'Не завершён'
              break
            default:
              errorMessage = 'Ошибка'
          }
        }

        if (!payload.response?.status) state.scoring = payload
        if (errorMessage) state.scoring.error = errorMessage
      })
      .addCase(fetchLoanTransactionsByNumber.fulfilled, (state, { payload }) => {
        state.dataTransactions = payload.data
      })
      .addCase(fetchLoanDebtByNumber.fulfilled, (state, { payload }) => {
        state.dataDept = payload.data
      })
      // fetchBankruptData
      .addCase(fetchBankruptData.pending, (state) => {
        state.bankruptData.isFetching = true
      })
      .addCase(fetchBankruptData.fulfilled, (state, { payload }) => {
        state.bankruptData.isFetching = false
        state.bankruptData.data = payload
      })
      .addCase(fetchBankruptData.rejected, (state) => {
        state.bankruptData.data = null
        state.bankruptData.isFetching = false
      })

      // updateBankruptData
      .addCase(updateBankruptData.pending, (state) => {
        state.bankruptData.isUpdating = true
      })
      .addCase(updateBankruptData.fulfilled, (state) => {
        state.bankruptData.isUpdating = false
      })
      .addCase(updateBankruptData.rejected, (state) => {
        state.bankruptData.isUpdating = false
      })

      // createBankruptData
      .addCase(createBankruptData.pending, (state) => {
        state.bankruptData.isUpdating = true
      })
      .addCase(createBankruptData.fulfilled, (state) => {
        state.bankruptData.isUpdating = false
      })
      .addCase(createBankruptData.rejected, (state) => {
        state.bankruptData.isUpdating = false
      })

      // deleteBankruptData
      .addCase(deleteBankruptData.pending, (state) => {
        state.bankruptData.isUpdating = true
      })
      .addCase(deleteBankruptData.fulfilled, (state) => {
        state.bankruptData.isUpdating = false
        state.bankruptData.data = null
      })
      .addCase(deleteBankruptData.rejected, (state) => {
        state.bankruptData.isUpdating = false
      })

      // fetchLoanGeneralInfoData
      .addCase(fetchLoanGeneralInfoData.pending, (state) => {
        state.loanGeneralInfoData.isFetching = true
      })
      .addCase(fetchLoanGeneralInfoData.fulfilled, (state, { payload }) => {
        state.loanGeneralInfoData.isFetching = false
        state.loanGeneralInfoData.data = payload
      })
      .addCase(fetchLoanGeneralInfoData.rejected, (state) => {
        state.loanGeneralInfoData.data = null
        state.loanGeneralInfoData.isFetching = false
      })
      // getVerifyPassportData
      .addCase(checkVerifyPassportData.fulfilled, (state, { payload }) => {
        state.verifyPassportData = payload?.result
      })
      .addCase(checkVerifyPassportData.rejected, (state, { payload }) => {
        state.verifyPassportData = false
      })
      // fetchCheckIsBlacklisted
      .addCase(fetchCheckIsBlacklisted.fulfilled, (state, { payload }) => {
        state.blacklisted = payload?.is_blacklisted
      })
      // fetchNBKITransferData
      .addCase(fetchNBKITransferData.pending, (state) => {
        state.nbkiTransferData.isFetching = true
      })
      .addCase(fetchNBKITransferData.fulfilled, (state, { payload }) => {
        state.nbkiTransferData.data = payload
      })
      .addCase(fetchNBKITransferData.rejected, (state) => {
        state.nbkiTransferData.isFetching = false
        state.nbkiTransferData.data = null
      })
  },
})

export const { setExpandCounter, setAssignLoanToThisUser, setPause, resetLoan } =
  singleLoanSlice.actions

export default singleLoanSlice.reducer
