import InputRange from 'react-input-range'
import styled from 'styled-components'

import { IRangeSliderProps } from './types'

const SliderContainer = styled.div`
  .input-range {
    top: 2px;
  }
  .input-range__slider {
    position: relative;
    top: -13px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16),
      0px 3px 1px rgba(0, 0, 0, 0.1);
    &:hover,
    &:active {
      cursor: pointer;
      background: white;
    }
  }
  .input-range__label {
    display: none;
  }
  .input-range__track--active {
    background: #f8cc46;
    height: 4px;
  }
  .input-range__track--background {
    background: rgb(255 96 26 / 0.15);
    height: 4px;
  }
`

export const RangeSlider: React.FC<IRangeSliderProps> = ({
  maxValue,
  minValue,
  value = 0,
  step = 1,
  onChange,
}) => (
  <SliderContainer>
    <InputRange
      value={value}
      maxValue={maxValue}
      minValue={minValue}
      onChange={(val: number) => onChange && onChange(val)}
      step={step}
    />
  </SliderContainer>
)
