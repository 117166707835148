/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { orderBy } from 'lodash'
import moment from 'moment'

import { getLoanComments, createLoanComments, updateLoanComments } from 'features/agentLoans/extra'
import { addToast } from 'features/toast/toastSlice'
import { GlobalState } from 'index'
import { Nullable } from 'commonTypes'
import { ICommentsData } from 'features/loan/types'

import { ToastTypes } from 'lib/toast/Toast'
import { Button, Module } from 'components'
import { getAdminFio, normalizeValues, useWindowSize } from 'helpers'

import { ReactComponent as EditButtonSVG } from 'assets/edit-button.svg'

import {
  StyledCommentsContent,
  StyledCommentsButtonBlock,
  StyledTextArea,
  StyledLimitButtons,
  StyledCommentsBlock,
  StyledCommentTitle,
  StyledEditCommentWrapper,
  StyledMobileComment,
  StyledMobileSection,
  StyledMobileCommentInfo,
  StyledMobileDescription,
  StyledMobileValue,
  StyledMobileCommentsWrapper,
} from './Modules.styles'

const moduleId = 'agentComments'
interface ICommentsProps {
  loan_id: string
  loanNumber: string
  openedModules: string[]
  onToggleModule: (anchor: string) => void
  sameAddress?: boolean
  title?: string
}

export const Comments: React.FC<ICommentsProps> = ({
  openedModules,
  onToggleModule,
  loan_id,
  loanNumber,
  title = 'Комментарии андеррайтера',
}): JSX.Element => {
  const dispatch = useDispatch()
  const {
    commentsData: { data, pending, isFetching },
  } = useSelector((state: GlobalState) => state.agentLoans)
  const user = useSelector((state: GlobalState) => state.auth.user)
  const isOpen = openedModules.includes(moduleId)
  const [comment, setComment] = useState<Nullable<string>>(null)
  const [showTextAria, setShowTextAria] = useState<boolean>(false)
  const [comments, setComments] = useState<ICommentsData[] | any>([])
  const { width } = useWindowSize()

  const sessionComments = window.sessionStorage.getItem('ppz_crm_comments')

  const handleExpand = (): void => {
    const sessionCommentsParse = sessionComments ? JSON.parse(sessionComments) : {}

    if (!isOpen) {
      sessionCommentsParse[loan_id] = data?.length || 0
      window.sessionStorage.setItem('ppz_crm_comments', JSON.stringify(sessionCommentsParse))
    }
    onToggleModule(moduleId)
  }

  useEffect(() => {
    const filtered = orderBy(data, ['create_date'], ['desc'])
    setComments(filtered)
  }, [data])

  const handleClick = (): void => {
    if (comment && !pending) {
      dispatch(
        createLoanComments({
          loanNumber,
          comment,
          successAction: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Комментарий добавлен`,
                })
              )
              dispatch(getLoanComments({ loanNumber }))
            })
          },
          errorAction: () => {
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: `Комментарий не добавлен`,
              })
            )
          },
        })
      )
      setShowTextAria(false)
      setComment(null)
    }
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            {title}
            <>
              {!isFetching && (
                <>
                  {comments.length === 0 ? (
                    <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
                  ) : null}
                  {comments.length &&
                  sessionComments &&
                  JSON.parse(sessionComments)[loan_id] !== undefined &&
                  +JSON.parse(sessionComments)[loan_id] < comments.length ? (
                    <span style={{ color: '#24C665', marginLeft: '10px' }}>
                      +{comments.length - +JSON.parse(sessionComments)[loan_id]}
                    </span>
                  ) : null}
                </>
              )}
            </>
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <>
          {isFetching ? (
            <Module.LoaderBlock />
          ) : (
            <>
              {width >= 480 ? (
                <StyledCommentsContent>
                  <TableHead />
                  {comments.length > 0 &&
                    orderBy(comments, ['added_at'], ['desc']).map((item) => (
                      <Table {...item} key={item?.added_at} user={user} loanNumber={loanNumber} />
                    ))}
                </StyledCommentsContent>
              ) : (
                <StyledMobileCommentsWrapper>
                  {comments.length > 0 &&
                    orderBy(comments, ['added_at'], ['desc']).map((item) => (
                      <MobileTable
                        {...item}
                        key={item?.added_at}
                        user={user}
                        loanNumber={loanNumber}
                      />
                    ))}
                </StyledMobileCommentsWrapper>
              )}
            </>
          )}
        </>

        {showTextAria ? (
          <StyledCommentsBlock>
            <StyledCommentTitle>Добавить комментарий</StyledCommentTitle>
            <StyledTextArea
              value={comment || ''}
              maxLength={1200}
              placeholder='Комментарий'
              onChange={(e) => setComment(e?.target?.value)}
            />
            <StyledLimitButtons>
              <Button type='bigSuccess' onClick={handleClick} disabled={pending || !comment}>
                Сохранить
              </Button>
              <Button
                type='emptyBig'
                disabled={pending}
                onClick={() => {
                  setShowTextAria(false)
                  setComment(null)
                }}
              >
                Отмена
              </Button>
            </StyledLimitButtons>
          </StyledCommentsBlock>
        ) : (
          <>
            <StyledCommentsButtonBlock>
              <Button
                disabled={false}
                type='bigSuccess'
                pending={false}
                onClick={() => setShowTextAria(true)}
              >
                Добавить новый
              </Button>
            </StyledCommentsButtonBlock>
          </>
        )}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата и время</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Пользователь</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Комментарий</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const MobileTable = (props): JSX.Element => {
  const {
    username,
    user_id: userId,
    text: comment,
    update_date: time,
    user,
    loanNumber,
    comment_id: commentId,
    admin_id: adminId,
  } = props

  const dispatch = useDispatch()
  const [editable, setEditable] = useState<boolean>(false)
  const [editComment, setEditComment] = useState<string>('')
  const dictData = useSelector((state: GlobalState) => state.dict)

  useEffect(() => {
    setEditComment(comment)
  }, [])

  const handleSave = (disabled: boolean): void => {
    if (!disabled) setEditable(!editable)
    dispatch(
      updateLoanComments({
        commentId,
        comment: editComment,
        successAction: () => {
          batch(() => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Комментарий обновлен`,
              })
            )
            dispatch(getLoanComments({ loanNumber }))
          })
        },
        errorAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Ошибка',
              description: `Комментарий не обновнен`,
            })
          )
        },
      })
    )
  }

  const handleChange = (): void => setEditable(!editable)

  const handleCancel = (): void => {
    setEditComment(comment)
    setEditable(false)
  }

  return (
    <StyledMobileComment>
      <StyledMobileSection>
        <StyledMobileCommentInfo>
          <div>
            <StyledMobileDescription>Дата и время</StyledMobileDescription>
            <StyledMobileValue>
              {moment(new Date(time)).format('DD-MM-YYYY  HH:mm')}
            </StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Пользователь</StyledMobileDescription>
            <StyledMobileValue>{getAdminFio(username, dictData)}</StyledMobileValue>
          </div>
        </StyledMobileCommentInfo>
      </StyledMobileSection>
      <div>
        {editable ? (
          <>
            <p style={{ marginBottom: '30px' }}>Изменить комментарий</p>
            <StyledTextArea
              value={editComment || ''}
              placeholder='Комментарий'
              onChange={(e) => setEditComment(e?.target?.value)}
            />

            <StyledLimitButtons>
              <Button
                type='standardBig'
                onClick={() => handleSave(editComment === comment)}
                disabled={editComment === comment}
              >
                Сохранить
              </Button>
              <Button type='emptyBig' disabled={false} onClick={handleCancel}>
                Отмена
              </Button>
            </StyledLimitButtons>
          </>
        ) : (
          <span>{normalizeValues(comment)}</span>
        )}
        {user?.sc_id === userId && (
          <StyledEditCommentWrapper onClick={handleChange} editable={editable}>
            <EditButtonSVG />
          </StyledEditCommentWrapper>
        )}
      </div>
    </StyledMobileComment>
  )
}

const Table = (props): JSX.Element => {
  const {
    text: comment,
    create_date: time,
    user,
    loanNumber,
    id: commentId,
    user_id: userId,
    username,
  } = props
  const dispatch = useDispatch()
  const dictData = useSelector((state: GlobalState) => state.dict)
  const [editable, setEditable] = useState<boolean>(false)
  const [editComment, setEditComment] = useState<string>('')

  useEffect(() => {
    setEditComment(comment)
  }, [])

  const handleSave = (disabled: boolean): void => {
    if (!disabled) setEditable(!editable)
    dispatch(
      updateLoanComments({
        commentId,
        comment: editComment,
        successAction: () => {
          batch(() => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Комментарий обновлен`,
              })
            )
            dispatch(getLoanComments({ loanNumber }))
          })
        },
        errorAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Ошибка',
              description: `Комментарий не обновнен`,
            })
          )
        },
      })
    )
  }

  const handleChange = (): void => setEditable(!editable)

  const handleCancel = (): void => {
    setEditComment(comment)
    setEditable(false)
  }

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{moment(new Date(time)).format('DD-MM-YYYY  HH:mm')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{getAdminFio(username, dictData)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {editable ? (
            <>
              <p style={{ marginBottom: '30px' }}>Изменить комментарий</p>
              <StyledTextArea
                value={editComment || ''}
                placeholder='Комментарий'
                onChange={(e) => setEditComment(e?.target?.value)}
                style={{ width: 'calc(100% - 80px)' }}
              />

              <StyledLimitButtons>
                <Button
                  type='standardBig'
                  onClick={() => handleSave(editComment === comment)}
                  disabled={editComment === comment}
                >
                  Сохранить
                </Button>
                <Button type='emptyBig' disabled={false} onClick={handleCancel}>
                  Отмена
                </Button>
              </StyledLimitButtons>
            </>
          ) : (
            <span style={{ marginRight: '60px' }}>{normalizeValues(comment)}</span>
          )}
        </Module.TableValue>
        {user?.sc_id === userId && (
          <StyledEditCommentWrapper onClick={handleChange} editable={editable}>
            <EditButtonSVG />
          </StyledEditCommentWrapper>
        )}
      </Module.TableColumn>
    </>
  )
}
