/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
import moment from 'moment'
import { EmptyValueReplacer } from 'components'

import {
  employees,
  employmentType,
  genderType,
  levelOfEducation,
  loanStatuses,
  workType,
  overdue,
  maritalStatus,
  collectorStatus,
  employeesNumbersDict,
  familiarTypes,
  LoanStatuses,
} from './dictionary'

import { CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'

export const formatDate = (value) => {
  const date = new Date(value)
  const monthMap = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ]
  return `${date.getDate()} ${monthMap[date.getMonth()]} ${date.getFullYear()}`
}

export function replaceEmptyValue<T>(value: T): T | string {
  if (!value || (typeof value === 'string' && value.trim() === '')) return '---'
  return value
}
export function normalizeDate(date: Date | string): string | Date {
  return moment(new Date(date)).format('DD-MM-YYYY')
}

export function normalizeDateForApi(date: Date | string): string {
  return moment(new Date(date)).format('YYYY-MM-DD')
}

export function booleanToString(value: boolean): string {
  return value ? 'Да' : 'Нет'
}

export const calcTime: any = (offset) => {
  const d = new Date()
  const utc = d.getTime() + d.getTimezoneOffset() * 60000
  const nd = new Date(utc + 3600000 * offset)
  return moment(nd).format('HH:mm')
}

export function normalizeValues<T>(value: T | any, type?: string): T | any {
  if (value === '' && type !== 'overdue') return <EmptyValueReplacer />

  switch (type) {
    case 'date':
      return moment(new Date(value)).add(3, 'hours').format('DD-MM-YYYY')
    case 'full_date':
      return moment(new Date(value)).add(3, 'hours').format('DD-MM-YYYY  HH:mm')
    case 'source_full_date':
      return moment(new Date(value)).format('DD-MM-YYYY  HH:mm')
    case 'date_seconds':
      return Math.floor(value / (3600 * 24))
    case 'date_to_time':
      return moment(new Date(value)).add(3, 'hours').format('HH:mm')
    case 'secondsToHms': {
      const d = Number(value)
      const h = Math.floor(d / 3600)
      const m = Math.floor((d % 3600) / 60)
      const s = Math.floor((d % 3600) % 60)

      const hDisplay = h > 0 ? `${h}:` : ''
      const mDisplay = m > 0 ? `${m}:` : ''
      const sDisplay = s > 0 ? `${s}` : '00'
      return hDisplay + mDisplay + sDisplay
    }
    case 'sum':
      return value && `${value.toLocaleString('ru-RU')}  ₽`
    case 'sum_without_symbol':
      return value && `${value.toLocaleString('ru-RU')}`
    case 'loan_status':
      return loanStatuses[value]
    case 'gender':
      return genderType[value]
    case 'education_level':
      return levelOfEducation[value]
    case 'activity_type':
      return employmentType[value]
    case 'employment_type':
      return workType[value]
    case 'company_employees_amount':
      return employees[value]
    case 'overdue':
      return overdue(value)
    case 'marital_status':
      return maritalStatus[value]
    case 'collector_status':
      return collectorStatus[value]
    case 'employment_numbers':
      return employeesNumbersDict[value]
    case 'familiar_types':
      return familiarTypes[value]
    case 'products':
      return CreditProductCodes[value]
    case 'productsTranslate':
      return CreditProductTranslate[value]
    case 'bool':
      return booleanToString(value)
  }

  return value
}

export const replaceToNumeric = (value: string): string => value.replaceAll('\\D+', '')

export const phoneMask = [
  `+`,
  `7`,
  ` `,
  `(`,
  /[1-69]/,
  /\d/,
  /\d/,
  `)`,
  ` `,
  /\d/,
  /\d/,
  /\d/,
  `-`,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const phoneMaskNumbers = [
  `+`,
  `7`,
  /[1-69]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const timeZoneMask = [`+`, /\d/, /\d/, `:`, 0, 0]

export const postMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const divisionCodeMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

// 0104 359230
export const previousPassportCodeMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const serialPassportCodeMask = [/\d/, /\d/, /\d/, /\d/]

export const numberPassportCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const declOfNum: any = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ]
}

export const getDurationMinutes: any = (date) => {
  const now = moment(new Date())
  const end = moment(date).add(3, 'hours')
  const duration = moment.duration(now.diff(end))
  return duration.asMinutes()
}

export const copyToClipboard: any = (textToCopy: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy)
  }
  const textArea = document.createElement('textarea')
  textArea.value = textToCopy
  textArea.style.position = 'fixed'
  textArea.style.left = '-999999px'
  textArea.style.top = '-999999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  return new Promise((res: any, rej) => {
    document.execCommand('copy') ? res() : rej()
    textArea.remove()
  })
}
export function isValidForm(errors: unknown): boolean {
  return !!Object.keys(errors).length
}

export const clearEmpties: any = (o) => {
  for (const k in o) {
    if (o[k] === null || o[k] === '') {
      delete o[k]
      continue
    }
    if (typeof o[k] !== 'object') {
      continue
    }

    clearEmpties(o[k])
    if (Object.keys(o[k]).length === 0) {
      delete o[k]
    }
  }
  return o
}

export function debounce(func, wait) {
  let timeout
  return function (...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}

export const getAddressState = (address) => {
  const postal_code = address?.data?.postal_code || ''
  const apartment = address?.data?.flat || ''
  const house_number = address?.data?.house || ''
  const street = address?.data?.street || ''
  const district = address?.data?.city_district || ''
  const region = address?.data?.region || ''
  const city =
    address?.data?.city ||
    (address?.data?.settlement
      ? address?.data?.settlement_with_type
        ? address?.data?.settlement_with_type
        : address?.data?.settlement
      : '') ||
    ''
  const block_type = address?.data?.block_type || ''
  const block = address?.data?.block || ''

  const splitBuilding = (block || '').split('стр')
  let corpus = ''
  let building = ''
  if (block_type === 'стр') {
    building = block
  }
  if (block_type === 'к') {
    corpus = block
  }

  if (block_type === 'к' && splitBuilding[1]) {
    corpus = splitBuilding[0].trim()
    building = splitBuilding[1].trim()
  }

  return {
    city,
    region,
    district,
    street,
    house_number,
    building,
    block: corpus,
    apartment,
    postal_code,
  }
}

export const getAdminFio = (username, dictData) => {
  let result = username
  if (dictData.data && dictData.data.adminsFioData.length) {
    const finedAdminData = dictData.data.adminsFioData.filter((x) => x.username === username)
    if (finedAdminData.length > 0)
      result = `${finedAdminData[0].last_name} ${finedAdminData[0].first_name} ${finedAdminData[0].middle_name}`
  }
  return result
}

/**
 * cut and modifying text
 * @param text
 * @returns {null}
 */
export function modifyingText(text, textLimit) {
  let renderText = text
  let tempText
  if (renderText.length > textLimit) {
    if (renderText.length > textLimit) {
      tempText = renderText.substr(0, textLimit)
      if (tempText.substr(-1).match(/\s/)) tempText = tempText.substr(0, tempText.length - 1)
      renderText = tempText + (tempText.substr(-1) === '.' ? '..' : '...')
    }
  }

  return renderText
}

export function calculateAge(birthDate) {
  const birthDateObj = new Date(birthDate)

  const currentDate = new Date()

  let age = currentDate.getFullYear() - birthDateObj.getFullYear()

  if (
    currentDate.getMonth() < birthDateObj.getMonth() ||
    (currentDate.getMonth() === birthDateObj.getMonth() &&
      currentDate.getDate() < birthDateObj.getDate())
  ) {
    age--
  }

  return age
}

export function checkLoanIssuedDate(loanIssuedDate): boolean {
  const thresholdDate = new Date('2024-07-01T00:00:00Z')
  const statusDate = new Date(loanIssuedDate)
  if (statusDate >= thresholdDate) return true
  return false
}

export function checkLoanIssuedStatus(statusesHistoryData): boolean {
  let loanIssuedFound = false

  for (let status of statusesHistoryData) {
    if (status.status_name === LoanStatuses.loan_issued) {
      loanIssuedFound = true
      break
    }
  }
  return loanIssuedFound
}
