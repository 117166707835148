/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { orderBy } from 'lodash'

import { GlobalState } from 'index'
import { Module } from 'components'
import { normalizeValues } from 'helpers'

import { StyledHeadTitle, StyledLoanHistoryContent, StyledLoansDebtWrapper } from './Modules.styles'
import { IModuleProps } from './types'

const moduleId = 'accrueHistory'

interface IAccrueHistory extends IModuleProps {
  loan_id: string
}

export const AccrueHistory: React.FC<IAccrueHistory> = ({
  openedModules,
  onToggleModule,
  loan_id: loanId,
}): JSX.Element => {
  const { dataDept, isFetchingDept } = useSelector((state: GlobalState) => state.collector)
  const isOpen = openedModules.includes(moduleId)
  const [loansData, setLoansData] = useState([])

  const handleExpand = (): void => onToggleModule(moduleId)

  useEffect(() => {
    if (dataDept && dataDept.length > 0) setLoansData([...dataDept])
  }, [dataDept])

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <StyledHeadTitle>
            История начислений
            {!loansData.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </StyledHeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {isFetchingDept && <Module.LoaderBlock />}
        <StyledLoanHistoryContent>
          <TableHead />
        </StyledLoanHistoryContent>
        {loansData && loansData.length
          ? orderBy(loansData, ['added_at'], ['desc']).map((item, index) => (
              <StyledLoansDebtWrapper key={item?.loans_loan_id || index}>
                <StyledLoanHistoryContent>
                  <Table {...item} key={item?.loans_loan_id || index} />
                </StyledLoanHistoryContent>
              </StyledLoansDebtWrapper>
            ))
          : null}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата и время</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Общий долг</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Основной долг</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Проценты</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Пеня</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>% на просроченный ОД</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const {
    total_debt: total,
    interest_debt: interest,
    penny_debt: penny,
    loans_loan_date_create: date,
    frozen_interest_debt: frozenInterest,
    principal_debt: principal,
    added_at: addedAt,
    fine_debt,
  } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(addedAt, 'full_date')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(total)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(principal)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(interest)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(penny)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{fine_debt}</Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
