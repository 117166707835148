/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
// import { CreditProductTranslate } from '../../constants'

interface IFilterState {
  data: {
    [key: string]: string | any
    search?: string
  }
}

// const productList = [
//   CreditProductTranslate.installment,
//   CreditProductTranslate.installment_2,
//   CreditProductTranslate.installment_3,
//   CreditProductTranslate.installment_20_30,
//   CreditProductTranslate.installment_9k,
//   CreditProductTranslate.installment_30_50k,
//   CreditProductTranslate.installment_30_100k,
//   CreditProductTranslate.installment_20_30k_distant,
//   CreditProductTranslate.installment_30_100k_distant,
//   CreditProductTranslate.installment_30_50k_distant,
// ]

export const initialFiltersState: IFilterState = {
  data: {},
}

export const agentFiltersSlice = createSlice({
  name: 'agentFilters',
  initialState: initialFiltersState,
  reducers: {
    setAgentsFilters: (state, { payload }) => {
      state.data = payload
    },
  },
})

export const { setAgentsFilters } = agentFiltersSlice.actions

export default agentFiltersSlice.reducer
