import styled, { css } from 'styled-components/macro'

import { THEME } from '../../../core/theme'
import { MIN_WIDTH } from './tableStructure'

export const StyledRowWrapper = styled.div<{
  template: string
  indicate?: boolean
  hover?: boolean
}>`
  padding: 0 35px;
  display: grid;
  grid-template-columns: ${(props) => props.template};
  column-gap: 6px;
  min-width: ${MIN_WIDTH};
  border-bottom: 1px solid ${THEME.COLORS.TABLE_BORDER};

  ${({ hover = true }) =>
    hover &&
    css`
      &:hover {
        cursor: pointer;
        background: ${THEME.COLORS.TABLE_HOVER}!important;
      }
    `}

  ${({ indicate }) =>
    indicate &&
    css`
      background: ${THEME.COLORS.TABLE_INDURATE}!important;
    `}

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    min-width: auto;
    padding: 24px;
    margin: 0 16px;
    margin-bottom: 25px;
    border-radius: 24px;
    background: ${THEME.COLORS.WHITE};
  }
`

export const StyledValue = styled.div`
  padding: 18px 5px;
  word-break: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;

  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledTitle = styled.p<{ select?: boolean; pending?: boolean }>`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ select }) =>
    select &&
    css`
      cursor: pointer;

      &:hover {
        color: ${THEME.COLORS.BLUE};
      }
    `}
  ${({ pending }) =>
    pending &&
    css`
      color: rgb(8 0 58 / 38%);
    `}

  & svg {
    margin-left: 5px;
  }
`

export const StyledActionTable = styled.div`
  cursor: pointer;
`

export const StyledCopyLink = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${THEME.COLORS.TABLE_BORDER};
  border-radius: 40px;
  padding: 5px 12px;
  background-color: ${THEME.COLORS.WHITE};

  & svg {
    margin-right: 8px;
  }

  @media (max-width: 480px) {
    background: ${THEME.COLORS.SMOOTH};
    border: 5px solid ${THEME.COLORS.SMOOTH};
    width: fit-content;
  }
`

export const StyledMobileSection = styled.div`
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  padding-bottom: 15px;
  margin-bottom: 15px;
`

export const StyledMobileItemWrapper = styled.div`
  width: 100%;
  display: none;

  ${StyledMobileSection} {
    &:last-child {
      border: none;
      margin-bottom: 0;
    }
  }

  @media (max-width: 480px) {
    display: block;
  }
`

export const StyledMobileDescription = styled.p`
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: ${THEME.COLORS.GRAY};
`

export const StyledMobileValue = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  margin-top: 5px;
`

export const StyledMobileLoanNumbers = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: ${THEME.COLORS.BLUE};
  margin-top: 7px;
`

export const StyledMobileStaff = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledMobileCopyLink = styled.div`
  margin-top: 12px;
`
export const StyledCreateDate = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  color: #4b506d;
`
export const StyledActionProvider = styled.span`
  display: flex;
  width: 75px;
  justify-content: space-between;
  justify-content: center;

  & svg {
    width: 24px;
    height: 24px;
  }
`
export const StyledActionChange = styled.span`
  display: block;
  & svg {
    opacity: 0.6;
  }
  &:hover {
    cursor: pointer;
    & svg {
      opacity: 1;
    }
  }
`

export const StyledActionDelete = styled.span`
  display: block;
  & svg {
    opacity: 0.6;
  }
  &:hover {
    cursor: pointer;
    & svg {
      opacity: 1;
    }
  }
`

export const StyledSelectAgent = styled.div`
  margin-bottom: 20px;
`
