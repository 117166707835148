/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectCollectorService } from 'services/collectorService'
import { CollectorType } from './types'
import { ProjectAgentLoansService } from '../../services/agentLoanService'
import { ProjectLoansService } from '../../services/loanService'
import { ProjectOperatorService } from '../../services/operatorService'

export const fetchCollectorLoans = createAsyncThunk<
  any,
  {
    username: string
    iteration: number
    type: keyof typeof CollectorType
    filters: any
    refresh?: boolean
  }
>('collector/fetchCollectorLoans', async (props) => {
  const { username, iteration, type, filters, refresh = false } = props

  const payload = {
    ...filters,
    loan_status: ['loan_overdue'],
  }
  if (type === CollectorType.inWork) payload['collector_username'] = username

  const response = await ProjectCollectorService.getCollectorLoans(iteration, type, {
    ...payload,
  }).then(({ data }) => ({
    data,
    type,
    refresh,
    iteration,
  }))
  return response
})

export const fetchCollectorLoansCount = createAsyncThunk<any>(
  'collectorLoans/loadCount',
  async () => {
    const { loans_count: totalCount } = await ProjectCollectorService.getLoansCount().then(
      ({ data }) => data
    )
    const { loans_count: inWorkCount } = await ProjectCollectorService.getLoansCountInWork().then(
      ({ data }) => data
    )
    const { loans_count: freeCount } = await ProjectCollectorService.getLoansCountFree().then(
      ({ data }) => data
    )
    const response = { totalCount, inWorkCount, freeCount }
    return response
  }
)

export const fetchCollectorLoanUserPassportData = createAsyncThunk<any, { userId: string }>(
  'collector/fetchUserPassport',
  async (props) => {
    const { userId } = props
    const response = await ProjectAgentLoansService.getPassportData(userId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchLoanPageById = createAsyncThunk<any, { id }>(
  'collector/loadPageById',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getLoanPageById(id).then(({ data }) => data)
    return response
  }
)

export const fetchPhonesData = createAsyncThunk<any, { id }>(
  'collector/loadPhonesData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPhonesData(id).then(({ data }) => data)
    return response
  }
)

export const fetchCollectorLoanUserPersonalData = createAsyncThunk<any, { userId: string }>(
  'collector/getPersonalData',
  async (props) => {
    const { userId } = props
    const response = await ProjectAgentLoansService.getPersonalData(userId).then(({ data }) => ({
      data,
    }))
    return response
  }
)
export const fetchUserLoans = createAsyncThunk<any, { ids: string[] }>(
  'collector/userLoans',
  async ({ ids }) => {
    const response = await ProjectLoansService.getDetailedLoansByUserId(ids).then(
      ({ data }) => data
    )
    return response?.data
  }
)

export const fetchAssignTheLoan = createAsyncThunk<
  any,
  { loanId: string; type?: string; username?: unknown; onSuccess?: any }
>('collector/AssignTheLoan', async (props) => {
  const { loanId, type, onSuccess, username } = props
  const response = await ProjectCollectorService.getAssignTheLoan(loanId).then(({ data }) => {
    onSuccess()
    return { data, type, loanId, username }
  })
  return response
})

export const fetchUnAssignTheLoan = createAsyncThunk<
  any,
  { loanId: string; type?: string; username?: unknown; onSuccess?: any }
>('collector/UnAssignTheLoan', async (props) => {
  const { loanId, type, onSuccess, username } = props
  const response = await ProjectCollectorService.getUnAssignTheLoan(loanId).then(({ data }) => {
    onSuccess()
    return { data, type, loanId, username }
  })
  return response
})

export const fetchAssignTheLoanFromDetail = createAsyncThunk<
  any,
  { loanId?: string; onSuccess?: () => void; onError?: () => void; collectorUserName: string }
>('collector/AssignTheLoanFromDetail', async (props) => {
  const { collectorUserName, onSuccess, onError, loanId } = props
  const response = await ProjectCollectorService.getAssignTheLoan(loanId)
    .then(({ data }) => {
      onSuccess()
      return { data, collectorUserName }
    })
    .catch((e) => onError())
  return response
})

export const fetchUnAssignTheLoanFromDetail = createAsyncThunk<
  any,
  { loanId?: string; onSuccess?: () => void; onError?: () => void; collectorUserName: string }
>('collector/UnAssignTheLoanFromDetail', async (props) => {
  const { loanId, onSuccess, onError, collectorUserName } = props
  const response = await ProjectCollectorService.getUnAssignTheLoan(loanId)
    .then(({ data }) => {
      onSuccess()
      return { data, collectorUserName }
    })
    .catch((e) => onError())
  return response
})

export const fetchLoanDebt = createAsyncThunk<any, { loanId: string }>(
  'collector/fetchLoanDebt',
  async (props) => {
    const { loanId } = props
    const response = await ProjectOperatorService.getLoanDebt(loanId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchLoanTransactions = createAsyncThunk<any, { loan_number: string }>(
  'collector/fetchLoanTransactions',
  async (props) => {
    const { loan_number } = props
    const response = await ProjectOperatorService.getLoanTransactions(loan_number).then(
      ({ data }) => ({
        data,
      })
    )
    return response
  }
)

export const fetchCollectorTasks = createAsyncThunk<any, { loanId: string }>(
  'collector/fetchCollectorTasks',
  async (props) => {
    const { loanId } = props
    const response = await ProjectCollectorService.getTasks(loanId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const addCollectorTasks = createAsyncThunk<
  any,
  { loanId: string; body: any; onError?: () => void; onSuccess?: () => void }
>('collector/addCollectorTasks', async (props) => {
  const { loanId, body, onError, onSuccess } = props
  const response = await ProjectCollectorService.addTasks(loanId, { data: body })
    .then(() => {
      onSuccess && onSuccess()
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})
