import { CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'
import { ContractType } from 'features/contracts/types'
import { v4 as uuid } from 'uuid'

export const BODY_STRUCTURE: any = {
  [ContractType.allContracts]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'balance', styles: 'boldGreen' },
    { id: uuid(), name: 'overdue_days', styles: '' },
    // { id: uuid(), name: 'interest_debt', styles: '' },
    { id: uuid(), value: () => null, transfer: true },
  ],

  [ContractType.active]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'balance', styles: 'boldGreen' },
    { id: uuid(), name: 'overdue_days', styles: '' },
    // { id: uuid(), name: 'principal_debt', styles: '' },
    // { id: uuid(), name: 'interest_debt', styles: '' },
    { id: uuid(), value: () => null, transfer: true },
  ],
  [ContractType.overdue]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'balance', styles: 'boldGreen' },
    { id: uuid(), name: 'overdue_days', styles: '' },
    // { id: uuid(), name: 'principal_debt', styles: '' },
    // { id: uuid(), name: 'interest_debt', styles: '' },
  ],
  [ContractType.repayment_date]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'balance', styles: 'boldGreen' },
    { id: uuid(), name: 'overdue_days', styles: '' },
    // { id: uuid(), name: 'principal_debt', styles: '' },
    // { id: uuid(), name: 'interest_debt', styles: '' },
  ],
}

export const HEAD_STRUCTURE = {
  [ContractType.allContracts]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'ФИО клиента', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Баланс', id: uuid() },
    { title: 'Дней просрочен', id: uuid() },
    // { title: 'Процентный долг', id: uuid() },
    { title: 'Действия', id: uuid() },
  ],
  [ContractType.active]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'ФИО клиента', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Баланс', id: uuid() },
    { title: 'Дней просрочен', id: uuid() },
    // { title: 'Основной долг', id: uuid() },
    // { title: 'Процентный долг', id: uuid() },
    { title: 'Действия', id: uuid() },
  ],
  [ContractType.overdue]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'ФИО клиента', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Баланс', id: uuid() },
    { title: 'Дней просрочен', id: uuid() },
    // { title: 'Основной долг', id: uuid() },
    // { title: 'Процентный долг', id: uuid() },
  ],
  [ContractType.repayment_date]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'ФИО клиента', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Баланс', id: uuid() },
    { title: 'Дней просрочен', id: uuid() },
  ],
}

export const MIN_WIDTH = '1500px'

export const COLUMN_TEMPLATE = {
  [ContractType.allContracts]: `
    minmax(100px, 250px) minmax(100px, 150px) minmax(100px, 350px) minmax(150px, 150px)
    minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px)
     minmax(100px, 200px)
    `,
  [ContractType.active]: ` 
  minmax(100px, 250px) minmax(100px, 150px) minmax(100px, 150px) minmax(150px, 150px)
  minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px)
  minmax(100px, 200px) minmax(100px, 150px)
    `,
  [ContractType.overdue]: `
  minmax(100px, 250px) minmax(100px, 150px) minmax(100px, 150px) minmax(150px, 150px)
  minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px)
    `,
  [ContractType.repayment_date]: `
  minmax(100px, 250px) minmax(100px, 150px) minmax(100px, 150px) minmax(150px, 150px)
  minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px)
    `,
}
