import { Nullable } from '../../commonTypes'

export enum CollectorType {
  all = 'all',
  inWork = 'inWork',
  free = 'free',
}

export interface ITotalAmount {
  free: number
  inWork: number
  all: number
  pending: boolean
  error?: boolean
}

export interface ICollectorSlice {
  totalAmount: ITotalAmount
  all: ICollectorPageData
  inWork: ICollectorPageData
  free: ICollectorPageData
  passportData: Nullable<IPassportData>
  personalData: Nullable<IPersonalData>
  loanData: Nullable<ILoanData>
  userData: Nullable<IUserData>
  phonesData: Nullable<Array<IPhonesData>>
  userLoans: Nullable<Array<{ [str: string]: any }>>
  isFetchingDept: boolean
  dataDept: ILoadDebtData[]
  isFetchingTransactions: boolean
  dataTransactions: ILoadTransactionsData[]
  isFetchingAssignInDetail: boolean
  collectorUserName: string
  tasks: ITasks[]
  isFetchAddTask: boolean
}

export interface ITasks {
  loan_number: string
  created_at: string
  task_status: string
  comment: string
  answer_type: string
  username: string
}

export interface ICollectorPageData {
  pending: boolean
  error: boolean
  data: any
  totalCount: number
  iteration: number
  activePage: string
}

export interface IPassportData {
  first_name: string
  last_name: string
  middle_name: string
  gender: string
  date_of_birth: string
  place_of_birth: string
  citizenship: ''
  serial: string
  number: string
  date_of_issue: string
  place_of_issue: string
  issued_department_url: string
  code_division: string
}

export interface ILoanData {
  loan_id: string
  loan_number: string
  loan_source: unknown
  loan_date_create: string
  loan_issue_date: string
  loan_status: string
  loan_ask_sum: number
  loan_ask_period: number
  loan_permitted_sum: string
  loan_permitted_period: string
  loan_settings: unknown
  loan_repeated: boolean
  requests_count: number
  credit_product: string
  loan_sum: string
}

export interface IPersonalData {
  login: string
  date_register: string
  time_zone: string
  check_word: string
  maiden_name: string
  marital_status: string
  children_amount: string
  email: string
  additional_services: string
  criminal_records: string
  user_id: string
}

export interface IUserData {
  user_id: string
  first_name: string
  last_name: string
  middle_name: string
}

export interface IPhonesData {
  number: string
  number_type: string
  is_default: boolean
  is_active: boolean
  comment: string
}

export interface ILoadDebtData {
  loan_id: string
  total_debt: number
  principal_debt: number
  interest_debt: number
  penny_debt: number
  frozen_interest_debt: number
  added_at: string
}
export interface ILoadTransactionsData {
  loan_id: string
  amount: number
  trans_type: string
  trans_date: string
}
