import styled, { css } from 'styled-components/macro'

export const StyledWrapper = styled.div<{ type: 'danger' | 'success' }>`
  width: fit-content;
  border-radius: 28px;
  padding: 2px 10px;

  & p {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
  ${({ type }) =>
    type &&
    css`
      background-color: ${type === 'danger' ? '#FF4646' : '#24C665'};
    `};
`
