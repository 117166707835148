export const MESSAGES = {
  FETCH: {
    ERROR: 'Во время выполнения запроса возникла ошибка!',
  },
  SYSTEM: {
    TEST: 'Тестовое сообщение, лимит выставлен',
  },
  LOANS: {
    NOT_EXIST: 'Такая заявка не найдена, либо во время выполнения запроса возникла ошибка ',
  },
  LOAN: {
    SAVE_SUCCESS: 'Данные успешно сохранены',
    SAVE_ERROR: 'Во время сохранения данных возникла ошибка, данные не сохранены!',
  },
  // AUTH: {
  //   ERROR: 'Пароль или логин не верны, введите корректные данные',
  //   SUCCESS: 'авторизация прошла успешно, вход в систему выполнен',
  // },
  VALIDATION: {
    ERROR: 'Необходимо заполнить все  обязательные поля',
  },
}
