/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchModalLoans } from './extra'
import { IInitialState, ILoanData } from './types'

const initialState: IInitialState = {
  loans: [],
  isFetching: false,
  error: false,
}

export const modalLoansSlice = createSlice({
  name: 'modalLoans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModalLoans.pending, (state) => {
      state.isFetching = true
    })
    builder.addCase(
      fetchModalLoans.fulfilled,
      (state, { payload }: PayloadAction<Array<ILoanData>>) => {
        state.isFetching = false
        state.error = false
        state.loans = payload
      }
    )
  },
})

export default modalLoansSlice.reducer
