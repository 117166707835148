import styled from 'styled-components/macro'

export const StyledPDLLimitWrapper = styled.div`
  grid-column-gap: 30px;
  padding: 25px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 480px) {
    padding: 10px;
  }
`

export const StyledModuleWrapper = styled.div`
  display: flex;

  @media (max-width: 1580px) {
    flex-direction: column;
  }
`

export const StyledButtonsBlockWrapper = styled.div`
  width: 350px;
  display: flex;
  justify-content: space-between;
`

export const StyledPDLLimitTitle = styled.div`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #08003a;
  padding: 15px 0;

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 5px;
  }
`
export const StyledSecondPDLLimitTitle = styled.div`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #08003a;
  padding: 15px 0;

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 5px;
  }
`

export const StyledTableBlockWrapper = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`

export const StyledFirstTableWrapper = styled.div`
  margin-right: 100px;

  @media (max-width: 480px) {
    margin-right: 0;
  }
`

export const StyledSecondTableWrapper = styled.div`
  margin-right: 55px;
  border-left: 1px solid #ececec;
  padding-left: 25px;

  @media (max-width: 1580px) {
    border: none;
    padding-left: 0px;
    margin-right: 0px;
  }
`

export const StyledTitleWrapper = styled.div`
  display: flex;
`

export const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const StyledLoader = styled.div`
  position: relative;
  top: -25px;
  margin-left: 30px;

  @media (max-width: 480px) {
    top: 0;
    margin-top: 50px;
  }
`

export const StyledFirstBlock = styled.div``

export const StyledSecondBlock = styled.div``

export const StyledTop = styled.div`
  height: 100px;
  @media (max-width: 1580px) {
    height: auto;
  }
`

export const StyledSecondTop = styled.div`
  height: 100px;
  padding-left: 25px;
  border-left: 1px solid #ececec;
  @media (max-width: 1580px) {
    height: auto;
    border: none;
    padding-left: 0;
  }
`
