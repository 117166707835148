/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'

import { declOfNum } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'
import { changeLoanStatusRevision, fetchLoanAccept, fetchLoanRefuse } from 'features/loan/extra'
import { approveLoan } from 'features/loans/loanSlice'
import { GlobalState } from 'index'
import { ProjectLoansService } from 'services/loanService'
import { clamp } from 'lodash'
import { RangeInput, Button, ModalConfirm, CheckBox } from 'components'

import {
  StyledLimitWrapper,
  StyledLimitTitle,
  StyledLimitContent,
  StyledRangeTitle,
  StyledLimitItem,
  StyledLimitButtons,
  StyledTextArea,
  StyledRefuseWrapper,
  StyledRefuseDescription,
  StyledRefuseTypesWrapper,
  StyledRefuseTypeTitle,
  StyledRefuseTypeTextWrapper,
  StyledCheckboxWrapper,
  StyledRefuseButtonWrapper,
} from './Modules.styles'

import { ReactComponent as CalendarSVG } from 'assets/calendar-limit.svg'
import { ReactComponent as WalletSVG } from 'assets/wallet-limit.svg'
import { CreditProduct } from '../../../constants'
import { revisionData } from './SetLimitInstallment/data'

const AMOUNT = {
  [CreditProduct.pdl]: {
    MIN: 1000,
    MAX: 15000,
    DEFAULT: 1500,
    STEP: 500,
    DISABLED: false,
  },
  [CreditProduct.pdl_08]: {
    MIN: 5000,
    MAX: 10000,
    DEFAULT: 5000,
    STEP: 1000,
    DISABLED: false,
  },
}

const TERM = {
  [CreditProduct.pdl]: {
    MIN: 1,
    MAX: 30,
    DEFAULT: 10,
  },
  [CreditProduct.pdl_08]: {
    MIN: 5,
    MAX: 30,
    DEFAULT: 10,
  },
}

const refuseTypes = (refuseByClient: boolean) => {
  if (refuseByClient)
    return [
      {
        title: 'Некорректно выбран продукт',
        comment: '#Неправильный продукт',
        allowComments: false,
        reject_code_id: 9,
      },
      {
        title: 'Нет страховки',
        comment: '#Без страховки',
        allowComments: false,
        reject_code_id: 5,
      },
      {
        title: 'Другая',
        allowComments: true,
        reject_code_id: 5,
      },
    ]
  return [
    {
      title: 'Карта',
      values: [
        'Не соответствует той, которая зарегистрирована',
        'Моментум (нет возможности проверить)',
        'Не принадлежит клиенту (после проверки СБП)',
        'Не читается',
        'Подозрение на мошенничество',
      ],
      allowComments: true,
      reject_code_id: 22,
    },
    {
      title: 'Документы',
      values: [
        'Плохое качество фото паспорта',
        'Паспорт на фото виден не полностью.',
        'Нет регистрации/временная',
        'Номер/серия паспорта не соответствует',
        'Нет ИНН',
        'Подозрение на мошенничество',
      ],
      allowComments: true,
      reject_code_id: 21,
    },
    {
      title: 'КИ',
      values: [
        'Закредитовка %',
        'Большая вероятность просрочки',
        'Мало информации для анализа КИ',
        'По КИ все по «0»',
      ],
      allowComments: true,
      reject_code_id: 16,
    },
    {
      title: 'ФСПП',
      comment: '#ФССП',
      allowComments: true,
      reject_code_id: 14,
    },
    {
      title: 'Звонок',
      values: ['Нет контакта', 'Мутный клиент (неадекватный)'],
      allowComments: true,
      reject_code_id: 11,
    },
    {
      title: 'Другая',
      allowComments: true,
      reject_code_id: 5,
    },
    {
      title: 'Скор балл',
      comment: '#Скорбалл',
      allowComments: true,
      reject_code_id: 12,
    },
  ]
}

export const SetLimitPLD: any = ({
  loan_id,
  creditProduct,
  loanStatus,
  claimsResolve,
}): JSX.Element => {
  const dispatch = useDispatch()
  const {
    loan_permitted_sum: loanPermittedSum,
    loan_permitted_period: loanPermittedPeriod,
    loan_ask_period: loanAskPeriod,
  } = useSelector((store: GlobalState) => store.singleLoan.loadData)
  const {
    pending,
    personalData: { user_id: userId },
  } = useSelector((store: GlobalState) => store.singleLoan)
  const { loans } = useSelector((state: GlobalState) => state.userLoans)

  const [refuseType, setRefuseType] = useState(null)
  const [comment, setComment] = useState(null)
  const [refuse, setRefuse] = useState(null)
  const [amount, setAmount] = useState(AMOUNT[creditProduct].DEFAULT) // TODO: Не устанавливается default value так как creditProduct определяется с задержкой ?
  const [term, setTerm] = useState(TERM[creditProduct].DEFAULT)
  const [lastLoanSum, setLastLoanSum] = useState(null)
  const [refuseByClient, setRefuseByClient] = useState(false)
  const [revision, setRevision] = useState(false)
  const [revisionComment, setRevisionComment] = useState(null)

  const [showModal, setShowModal] = useState({
    show: false,
    title: '',
    style: '',
  })

  useEffect(() => {
    if (!loans?.length) setAmount(0)
    else setTerm(loanAskPeriod)
  }, [loans, loanAskPeriod])

  useEffect(() => {
    if (userId && !lastLoanSum)
      ProjectLoansService.getLastLoan(userId).then(({ data }) => {
        setLastLoanSum(data.last_loan_sum)
      })
  }, [userId])

  useEffect(() => {
    if (lastLoanSum) setAmount(clamp(+lastLoanSum + 2000, 15000))
    else setAmount(+loanPermittedSum)
    if (loanPermittedPeriod) setTerm(+loanPermittedPeriod)
  }, [lastLoanSum, loanPermittedSum, loanPermittedPeriod])

  const onAccept = () => {
    dispatch(
      fetchLoanAccept({
        loan_id,
        timeLimit: term,
        sumLimit: Math.floor(amount / AMOUNT[creditProduct].STEP) * AMOUNT[creditProduct].STEP,
        comment: comment || '',
      })
    )
    dispatch(approveLoan())
  }
  const handleCloseModal = (): void => {
    setRevision(false)
    setRevisionComment(null)

    setShowModal({ show: false, title: '', style: '' })
  }

  const openModalRefuse = () => {
    setRefuseByClient(false)
    setRefuseType(comment ? refuseTypes(false).find((v) => !v.values) : null)
    setRefuse(comment || null)
    setShowModal({
      show: true,
      title: 'Отказать в займе',
      style: 'warning',
    })
  }

  const openModalRefuseByClient = () => {
    setRefuseByClient(true)
    setRefuseType(comment ? refuseTypes(false).find((v) => !v.values) : null)
    setRefuse(comment || null)
    setShowModal({
      show: true,
      title: 'Отказ клиента',
      style: 'warning',
    })
  }

  const openModalClientRevision = (): void => {
    setRevision(true)
    setShowModal({
      show: true,
      title: 'Доработка клиента',
      style: 'warning',
    })
  }

  const showButton = loanStatus === 'setting_a_limit' && claimsResolve

  const onRevision = async () => {
    if (!refuseType) return
    await dispatch(
      changeLoanStatusRevision({
        loan_id,
        timeLimit: term,
        sumLimit: amount,
        code: refuseType?.reject_code_id,
        comment: revisionComment || '',
      })
    )
    setShowModal({
      show: false,
      title: '',
      style: '',
    })
  }

  const onRefuse = async () => {
    await dispatch(
      fetchLoanRefuse({
        loan_id,
        reject_code_id: refuseType?.reject_code_id || 0,
        comment: refuse || '',
        timeLimit: term,
        sumLimit: amount,
        byClient: refuseByClient,
      })
    )
    setShowModal({
      show: false,
      title: '',
      style: '',
    })
  }

  const setRefuseTypeHandler = (type) => {
    setRefuseType(type)
    setRefuse(null)
    if (type?.comment) setRefuse(type?.comment)
  }

  return (
    <StyledLimitWrapper id='limit'>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        title={showModal.title}
        buttons={false}
        content={() => (
          <>
            <StyledRefuseWrapper>
              <StyledRefuseDescription>Выберите причину:</StyledRefuseDescription>
              <StyledRefuseTypesWrapper>
                {(revision ? revisionData : refuseTypes(refuseByClient)).map((type, i) => (
                  <StyledRefuseTypeTitle
                    key={i}
                    onClick={() => setRefuseTypeHandler(type)}
                    isActive={type.title === refuseType?.title}
                  >
                    {type.title}
                  </StyledRefuseTypeTitle>
                ))}
              </StyledRefuseTypesWrapper>
              {!refuseType?.comment && (
                <>
                  {refuseType && !revision && (
                    <StyledRefuseTypeTextWrapper>
                      {refuseType.values ? (
                        refuseType.values.map((val) => (
                          <StyledCheckboxWrapper key={val}>
                            <CheckBox
                              active={val === refuse}
                              onChange={setRefuse}
                              value={val}
                              label={val}
                            />
                          </StyledCheckboxWrapper>
                        ))
                      ) : (
                        <>
                          {refuseType.allowComments && (
                            <StyledTextArea
                              placeholder='Комментарий по заявке'
                              value={refuse || ''}
                              onChange={(e) => setRefuse(e?.target?.value)}
                            />
                          )}
                        </>
                      )}
                    </StyledRefuseTypeTextWrapper>
                  )}
                </>
              )}

              {revision ? (
                <>
                  <StyledRefuseTypeTextWrapper>
                    <StyledTextArea
                      placeholder='Комментарий по заявке'
                      value={revisionComment || ''}
                      onChange={(e) => setRevisionComment(e?.target?.value)}
                    />
                  </StyledRefuseTypeTextWrapper>

                  <StyledRefuseButtonWrapper>
                    <Button type='standardBig' onClick={onRevision} disabled={!refuseType}>
                      Отправить
                    </Button>
                  </StyledRefuseButtonWrapper>
                </>
              ) : (
                <StyledRefuseButtonWrapper>
                  <Button type='standardBig' onClick={onRefuse} disabled={!refuse}>
                    Отказать клиенту
                  </Button>
                </StyledRefuseButtonWrapper>
              )}
            </StyledRefuseWrapper>
          </>
        )}
      />
      <StyledLimitTitle>Выставление лимита</StyledLimitTitle>
      <StyledLimitContent>
        <StyledLimitItem>
          <WalletSVG />
          <StyledRangeTitle>Согласованная андеррайтером сумма займа</StyledRangeTitle>
          <RangeInput
            label
            value={amount}
            minValue={AMOUNT[creditProduct].MIN}
            maxValue={AMOUNT[creditProduct].MAX}
            onChange={setAmount}
            postfix='₽'
            step={AMOUNT[creditProduct].STEP}
            onBlur={() =>
              setAmount(
                Math.floor(amount / AMOUNT[creditProduct].STEP) * AMOUNT[creditProduct].STEP
              )
            }
          />
        </StyledLimitItem>
        <StyledLimitItem>
          <CalendarSVG />
          <StyledRangeTitle>Согласованный андеррайтером срок займа</StyledRangeTitle>
          <RangeInput
            label
            value={term}
            minValue={TERM[creditProduct].MIN}
            maxValue={TERM[creditProduct].MAX}
            onChange={setTerm}
            postfix={declOfNum(+term, ['день', 'дня', 'дней'])}
          />
        </StyledLimitItem>
      </StyledLimitContent>
      <StyledLimitTitle>Одобрение заявки</StyledLimitTitle>
      <StyledTextArea
        value={comment || ''}
        maxLength={1200}
        placeholder='Комментарий по заявке'
        onChange={(e) => setComment(e?.target?.value)}
      />
      <StyledLimitButtons>
        <Button
          type='bigSuccess'
          onClick={!pending && amount ? onAccept : null}
          disabled={pending || !amount}
        >
          Одобрить
        </Button>
        <Button type='emptyBig' onClick={!pending ? openModalRefuse : null} disabled={pending}>
          Отказать
        </Button>
        <Button
          type='emptyBig'
          onClick={!pending ? openModalRefuseByClient : null}
          disabled={pending}
        >
          Отказ клиента
        </Button>
        {showButton && (
          <Button
            type='standardBig'
            onClick={!pending ? openModalClientRevision : null}
            disabled={pending}
          >
            Доработка клиента
          </Button>
        )}
      </StyledLimitButtons>
    </StyledLimitWrapper>
  )
}
