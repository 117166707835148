import {
  Wrapper,
  Header,
  ContentWrapper,
  Content,
  Value,
  Button,
  Actions,
  Column,
  Title,
  LoaderBlock,
  Arrow,
  HeadTitle,
  TableColumn,
  TableValue,
  TableTitle,
  ImageLoader,
  SmallImage,
  ImageDelete,
  ImageBlock,
  ImageWrapper,
} from './Module'

export const Module = {
  Wrapper,
  Header,
  ContentWrapper,
  Button,
  Actions,
  Column,
  Title,
  Value,
  Content,
  LoaderBlock,
  Arrow,
  HeadTitle,
  TableColumn,
  TableValue,
  TableTitle,
  ImageLoader,
  SmallImage,
  ImageDelete,
  ImageBlock,
  ImageWrapper,
}
