/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchBanListBySearch, fetchOnBan, fetchOnUnban } from './extra'
import { IInitialState } from './types'

const initialState: IInitialState = {
  listing: null,
  isFetching: false,
  stepIsFetching: false,
  errorSend: false,
  bannedSuccess: false,
  unBannedSuccess: false,
}

export const banListSlice = createSlice({
  name: 'banList',
  initialState,
  reducers: {
    setStepIsFetching: (state, { payload }) => {
      state.stepIsFetching = payload
    },
    resetState: (state) => {
      state.listing = null
      state.isFetching = false
      state.stepIsFetching = false
      state.errorSend = false
      state.bannedSuccess = false
      state.unBannedSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanListBySearch.pending, (state) => {
        state.isFetching = true
      })
      .addCase(fetchBanListBySearch.rejected, (state) => {
        state.isFetching = false
      })
      .addCase(fetchBanListBySearch.fulfilled, (state, { payload }) => {
        state.listing = payload.data
        state.isFetching = false
      })
      .addCase(fetchOnBan.fulfilled, (state) => {
        state.bannedSuccess = true
      })
      .addCase(fetchOnUnban.fulfilled, (state) => {
        state.unBannedSuccess = true
      })
  },
})

export const { setStepIsFetching, resetState } = banListSlice.actions
export default banListSlice.reducer
