/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'

import { useGetNBKIData } from 'features/loan/loanSelectors'
import { ProjectLoansService } from 'services/loanService'
import { Button, Module } from 'components'
import { InputField, isValidForm, normalizeValues } from 'helpers'
import { INbkiHitory } from 'features/loan/types'

import { orderBy } from 'lodash'
import { StyledNBKIContent, StyledButtonBlock, StyledXLSDownload } from './Modules.styles'

interface IInitialState {
  items: INbkiHitory[] | []
}

const moduleId = 'history'
export const NBKIHistory: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const [editable, setEditable] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<IInitialState>({ items: [] })

  const data = useGetNBKIData()
  const isOpen = openedModules.includes(moduleId)

  useEffect(() => {
    if (data) {
      setInitialValues({ items: data })
    }
  }, [data])

  const handleCancel = (resetForm) => (): void => {
    resetForm()
    setEditable(!editable)
  }
  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = async (): Promise<void> => {
    setEditable(false)
  }
  const submitError = (handleSubmit) => (): void => handleSubmit()

  const handleDownload = async (id, dataId) => {
    const response = await ProjectLoansService.getNBKIFullData(id)

    const blob = response.data
    const downloadLink = window.document.createElement('a')
    downloadLink.href = window.URL.createObjectURL(
      new Blob([blob], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    )
    downloadLink.download = `NBKIHistory-${dataId}.xlsx`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>
                  История НБКИ{' '}
                  {!data.length ? (
                    <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
                  ) : null}
                </Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <StyledNBKIContent>
                {editable ? (
                  <Edit {...formikProps} />
                ) : (
                  <View {...formikProps} handleDownload={handleDownload} />
                )}
              </StyledNBKIContent>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && dirty && (
                <div style={{ margin: dirty ? '32px 0' : '0px' }}>
                  <Button
                    disabled={isValidForm(errors)}
                    type='standardBig'
                    onClick={isValidForm(errors) ? submitError(handleSubmit) : handleSubmit}
                    pending={false}
                  >
                    Сохранить
                  </Button>
                  <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue } = props

  return (
    <>
      <FieldArray
        name='items'
        render={() => (
          <>
            {values.items.map((item, index) => (
              <React.Fragment key={index}>
                <Module.Column column={1}>
                  <InputField
                    name={`items[${index}].loan_number`}
                    onChange={setFieldValue}
                    placeholder='Номер заявки'
                    value={item.loan_number}
                    noMove
                    {...props}
                  />
                </Module.Column>
                <Module.Column column={1}>
                  <InputField
                    name={`items[${index}].requested_at`}
                    onChange={setFieldValue}
                    placeholder='Дата запроса'
                    value={item.requested_at}
                    noMove
                    {...props}
                  />
                </Module.Column>
              </React.Fragment>
            ))}
          </>
        )}
      />
    </>
  )
}

const View = (props): JSX.Element => {
  const { values, handleDownload } = props

  return (
    <>
      {orderBy(values.items, ['requested_at'], ['desc']).map((item, index) => (
        <React.Fragment key={index}>
          <Module.Column column={1}>
            <Module.Title>Номер заявки</Module.Title>
            <Module.Value>{normalizeValues(item.loan_number)}</Module.Value>
          </Module.Column>
          <Module.Column column={1}>
            <Module.Title>Дата запроса</Module.Title>
            <Module.Value>{normalizeValues(item.requested_at, 'full_date')}</Module.Value>
          </Module.Column>
          <Module.Column column={1}>
            <Module.Title>Всего счетов</Module.Title>
            <Module.Value>{normalizeValues(item.total_accounts_count)}</Module.Value>
          </Module.Column>
          <Module.Column column={1}>
            <Module.Title>Активных счетов</Module.Title>
            <Module.Value>{normalizeValues(item.active_accounts_count)}</Module.Value>
          </Module.Column>
          <Module.Column column={1}>
            <Module.Title>Документ XLS</Module.Title>
            <StyledXLSDownload onClick={() => handleDownload(item.request_id, item.loan_number)}>
              Скачать
            </StyledXLSDownload>
          </Module.Column>
        </React.Fragment>
      ))}
    </>
  )
}

const validationSchema = Yup.object().shape({})
