/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchLoanDebt, fetchLoanTransactions } from './extra'
import { IInitialState } from './types'

const initialState: IInitialState = {
  isFetchingDept: false,
  dataDept: [],
  isFetchingTransactions: false,
  dataTransactions: [],
}

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    resetState: (state) => {
      state.isFetchingDept = false
      state.dataTransactions = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanDebt.pending, (state) => {
        state.isFetchingDept = true
      })
      .addCase(fetchLoanDebt.rejected, (state) => {
        state.isFetchingDept = false
      })
      .addCase(fetchLoanDebt.fulfilled, (state, { payload }) => {
        state.isFetchingDept = false
        state.dataDept = payload.data
      })
      .addCase(fetchLoanTransactions.pending, (state) => {
        state.isFetchingDept = true
      })
      .addCase(fetchLoanTransactions.rejected, (state) => {
        state.isFetchingDept = false
        state.dataTransactions = []
      })
      .addCase(fetchLoanTransactions.fulfilled, (state, { payload }) => {
        state.isFetchingDept = false
        state.dataTransactions = payload.data
      })
  },
})

export const { resetState } = operatorSlice.actions
export default operatorSlice.reducer
