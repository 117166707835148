/* eslint-disable react/destructuring-assignment */
import { CollectorType } from 'features/collector/types'
import { calcTime, collectorStatus, normalizeValues } from 'helpers'
import { v4 as uuid } from 'uuid'
import { EmptyValueReplacer } from 'components'

import { ReactComponent as GetSVG } from 'assets/folder_check.svg'
import { ReactComponent as BackSVG } from 'assets/back.svg'

export const BODY_STRUCTURE: any = {
  [CollectorType.all]: [
    {
      value: (data) => normalizeValues(data.loans_loan_date_create, 'full_date'),
      id: uuid(),
    },
    { id: uuid(), name: 'loans_loan_number', copy: true },
    { id: uuid(), name: 'overdue_days' },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    { id: uuid(), name: 'users_login', copy: true },
    { id: uuid(), name: 'residence_address' },
    {
      value: (data) => calcTime(data?.users_time_zone?.split(':')[0]),
      id: uuid(),
      name: 'users_time_zone',
    },
    { id: uuid(), name: 'loans_loan_ask_sum' },
    {
      value: (data) => normalizeValues(data.collector_added, 'full_date'),
      id: uuid(),
    },
    {
      id: uuid(),
      value: (data) =>
        // eslint-disable-next-line react/destructuring-assignment
        data?.collector_status ? collectorStatus[data.collector_status] : <EmptyValueReplacer />,
    },
    {
      id: uuid(),

      name: 'collector_username',
      value: (data, user) => {
        const userIsOwn = data.collector_username === user
        return userIsOwn ? (
          <span style={{ color: '#24c665' }}>{data.collector_username}</span>
        ) : (
          <>
            {data?.collector_username ? (
              data.collector_username
            ) : (
              <span style={{ opacity: '0.5', fontWeight: 'bold', color: 'gray' }}>не назначен</span>
            )}
          </>
        )
      },
    },
    {
      id: uuid(),
      action: true,
      value: (data: any) => {
        if (data.collector_username) return <BackSVG />
        return <GetSVG />
      },
    },
  ],

  [CollectorType.inWork]: [
    {
      value: (data) => normalizeValues(data.loans_loan_date_create, 'full_date'),
      id: uuid(),
    },
    { id: uuid(), name: 'loans_loan_number', copy: true },
    { id: uuid(), name: 'overdue_days' },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    { id: uuid(), name: 'users_login', copy: true },
    { id: uuid(), name: 'residence_address' },
    {
      value: (data) => calcTime(data?.users_time_zone?.split(':')[0]),
      id: uuid(),
      name: 'users_time_zone',
    },
    { id: uuid(), name: 'loans_loan_ask_sum' },
    {
      value: (data) => normalizeValues(data.collector_added, 'full_date'),
      id: uuid(),
    },
    {
      id: uuid(),
      value: (data) =>
        // eslint-disable-next-line react/destructuring-assignment
        data?.collector_status ? collectorStatus[data.collector_status] : <EmptyValueReplacer />,
    },
    {
      id: uuid(),

      name: 'collector_username',
      value: (data, user) => {
        const userIsOwn = data.collector_username === user
        return userIsOwn ? (
          <span style={{ color: '#24c665' }}>{data.collector_username}</span>
        ) : (
          <>
            {data?.collector_username ? (
              data.collector_username
            ) : (
              <span style={{ opacity: '0.5', fontWeight: 'bold', color: 'gray' }}>не назначен</span>
            )}
          </>
        )
      },
    },
    {
      id: uuid(),
      action: true,
      value: (data: any) => {
        if (data.collector_username) return <BackSVG />
        return <GetSVG />
      },
    },
  ],
  [CollectorType.free]: [
    {
      value: (data) => normalizeValues(data.loans_loan_date_create, 'full_date'),
      id: uuid(),
    },
    { id: uuid(), name: 'loans_loan_number', copy: true },
    { id: uuid(), name: 'overdue_days' },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    { id: uuid(), name: 'users_login', copy: true },
    { id: uuid(), name: 'residence_address' },
    {
      value: (data) => calcTime(data?.users_time_zone?.split(':')[0]),
      id: uuid(),
      name: 'users_time_zone',
    },
    { id: uuid(), name: 'loans_loan_ask_sum' },
    {
      value: (data) => normalizeValues(data.collector_added, 'full_date'),
      id: uuid(),
    },
    {
      id: uuid(),
      value: (data) =>
        // eslint-disable-next-line react/destructuring-assignment
        data?.collector_status ? collectorStatus[data.collector_status] : <EmptyValueReplacer />,
    },
    {
      id: uuid(),

      name: 'collector_username',
      value: (data, user) => {
        const userIsOwn = data.collector_username === user
        return userIsOwn ? (
          <span style={{ color: '#24c665' }}>{data.collector_username}</span>
        ) : (
          <>
            {data?.collector_username ? (
              data.collector_username
            ) : (
              <span style={{ opacity: '0.5', fontWeight: 'bold', color: 'gray' }}>не назначен</span>
            )}
          </>
        )
      },
    },
    {
      id: uuid(),
      action: true,
      value: (data: any) => {
        if (data.collector_username) return <BackSVG />
        return <GetSVG />
      },
    },
  ],
}

export const HEAD_STRUCTURE = {
  [CollectorType.all]: [
    { title: 'Дата и время ', id: uuid() },
    { title: 'Номер договора', id: uuid() },
    { title: 'Дни просрочки', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Телефон', id: uuid() },
    { title: 'Регион', id: uuid() },
    { title: 'Время клиента', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
    { title: 'Дата отработки', id: uuid() },
    { title: 'Статус должника', id: uuid() },
    { title: 'Коллектор', id: uuid() },
    { title: 'Действие', id: uuid() },
  ],
  [CollectorType.inWork]: [
    { title: 'Дата и время ', id: uuid() },
    { title: 'Номер договора', id: uuid() },
    { title: 'Дни просрочки', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Телефон', id: uuid() },
    { title: 'Регион', id: uuid() },
    { title: 'Время клиента', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
    { title: 'Дата отработки', id: uuid() },
    { title: 'Статус должника', id: uuid() },
    { title: 'Коллектор', id: uuid() },
    { title: 'Действие', id: uuid() },
  ],
  [CollectorType.free]: [
    { title: 'Дата и время ', id: uuid() },
    { title: 'Номер договора', id: uuid() },
    { title: 'Дни просрочки', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Телефон', id: uuid() },
    { title: 'Регион', id: uuid() },
    { title: 'Время клиента', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
    { title: 'Дата отработки', id: uuid() },
    { title: 'Статус должника', id: uuid() },
    { title: 'Коллектор', id: uuid() },
    { title: 'Действие', id: uuid() },
  ],
}

export const MIN_WIDTH = '1800px'

export const COLUMN_TEMPLATE = {
  [CollectorType.all]: `
    minmax(150px, 150px) minmax(150px, 150px) minmax(50px, 100px) minmax(300px, 350px)
    minmax(150px, 150px)  minmax(150px, 200px) minmax(80px, 120px) minmax(100px, 100px)
    minmax(150px, 150px) minmax(150px, 150px) minmax(150px, 150px) minmax(80px, 80px)
    `,
  [CollectorType.inWork]: `
    minmax(150px, 150px) minmax(150px, 150px) minmax(50px, 100px) minmax(300px, 350px)
    minmax(150px, 150px)  minmax(150px, 200px) minmax(80px, 120px) minmax(100px, 100px)
    minmax(150px, 150px) minmax(150px, 150px) minmax(150px, 150px) minmax(80px, 80px)
    `,
  [CollectorType.free]: `
    minmax(150px, 150px) minmax(150px, 150px) minmax(50px, 100px) minmax(300px, 350px)
    minmax(150px, 150px)  minmax(150px, 200px) minmax(80px, 120px) minmax(100px, 100px)
    minmax(150px, 150px) minmax(150px, 150px) minmax(150px, 150px) minmax(80px, 80px)
    `,
}
