/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { NavMenuType } from 'App'
import { OPERATOR_LOANS_ROUTE } from '../../constants'

import { NavigationContext } from 'app/contexts'

export const ArmPage = (): JSX.Element => {
  const history = useHistory()
  const { setNavMenuType } = useContext(NavigationContext)

  useEffect(() => {
    setNavMenuType(NavMenuType.operator)
    history.push(OPERATOR_LOANS_ROUTE)
  }, [])

  return null
}
