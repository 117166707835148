import { v4 as uuid } from 'uuid'

export const BODY_STRUCTURE: any = {
  waiting: [
    { id: uuid(), name: 'phone' },
    {
      value: (data) => `${data?.last_name} ${data?.first_name} ${data?.middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    { id: uuid(), action: true },
  ],
}

export const HEAD_STRUCTURE = {
  waiting: [
    { title: 'Номер телефона', id: uuid() },
    { title: 'ФИО клиента', id: uuid() },
    { title: 'Действия', id: uuid() },
  ],
}

export const MIN_WIDTH = '1500px'

export const COLUMN_TEMPLATE = {
  waiting: `
    minmax(100px, 250px) minmax(100px, 1fr) minmax(100px, 350px)
    `,
}
