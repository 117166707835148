/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useContext, useState } from 'react'

import { Button } from 'components'
import { useHistory } from 'react-router-dom'
import { ProjectAgentLoansService } from 'services/agentLoanService'
import { phoneMask } from 'helpers'
import {
  StyledPageWrapper,
  StyledButtonBlock,
  StyledInputWrapper,
  StyledInput,
  StyledErrorLabel,
} from './CreateLoan.styles'
import { NavigationContext } from 'app/contexts'

const formatPhone = (phone) => {
  let numbs = phone.replace('+7', '').replace(/[^+\d]/g, '')
  if (numbs.length > 10) {
    numbs = numbs.substr(numbs.length - 10)
  }
  return `+7${numbs}`
}
export const CreateLoan = (): JSX.Element => {
  const { setNavigation } = useContext(NavigationContext)
  const history = useHistory()
  const [phone, setPhone] = useState('')
  const [pending, setPending] = useState(false)
  const [error, setError] = useState<{ value: boolean; message: string }>({
    value: false,
    message: '',
  })

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Привязать клиента`,
      backTo: '/agent/loans?type=all_app',
    })
  }, [])

  useEffect(() => {
    if (error.value) {
      setError({
        value: false,
        message: '',
      })
    }
  }, [phone])

  const handleCancel = () => {
    history.push('/agent/loans?type=all_app')
  }
  const handleSubmit = async () => {
    if (!invalidPhone) {
      setPending(true)
      try {
        await ProjectAgentLoansService.addUser(phone)
        history.push('/agent/loans/waiting')
      } catch (e) {
        if (e.response?.status === 409) {
          setError({
            value: true,
            message: `Телефоный номер -  ${phone} уже был привязан, нельзя использовать один телефонный номер дважды!`,
          })
        } else {
          setError({ value: true, message: 'Возникла ошибка при создании заявки' })
        }
      } finally {
        setPending(false)
      }
    }
  }
  const setPhoneHandler = (val) => {
    setPhone(formatPhone(val?.target?.value))
  }

  const invalidPhone = formatPhone(phone).length < 12

  return (
    <StyledPageWrapper>
      <StyledInputWrapper>
        <StyledInput
          onChange={setPhoneHandler}
          placeholder='Введите мобильный номер заемщика'
          mask={phoneMask}
          value={phone}
        />
        {error.value && <StyledErrorLabel>{error.message}</StyledErrorLabel>}
      </StyledInputWrapper>
      <StyledButtonBlock>
        <Button disabled={invalidPhone} type='standardBig' onClick={handleSubmit} pending={pending}>
          Сохранить
        </Button>
        <Button type='emptyBig' onClick={handleCancel}>
          Отменить
        </Button>
      </StyledButtonBlock>
    </StyledPageWrapper>
  )
}
