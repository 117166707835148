import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectDictService } from '../../services/dictService'

export const fetchAdminUsernames = createAsyncThunk<any>('dict/adminUsernames', async () => {
  const response = await ProjectDictService.adminUsernames().then(({ data }) => data)
  return response
})

export const fetchAdminsFio = createAsyncThunk<any>('dict/adminsfio', async () => {
  const response = await ProjectDictService.adminsfio().then(({ data }) => data)
  return response
})

export const fetchLoanStatus = createAsyncThunk<any>('dict/loanStatus', async () => {
  const response = await ProjectDictService.loanStatuses().then(({ data }) => data)
  return response
})
export const fetchAgentUsernames = createAsyncThunk<any>('dict/agentUsernames', async () => {
  const response = await ProjectDictService.agentUsernames().then(({ data }) => data)
  return response
})
export const fetchAgentManagerUsernames = createAsyncThunk<any>(
  'dict/agentManagerUsernames',
  async () => {
    const response = await ProjectDictService.agentManagerUsernames().then(({ data }) => data)
    return response
  }
)

export const fetchAdminsRoles = createAsyncThunk<any>('dict/adminsRoles', async () => {
  const response = await ProjectDictService.adminsRoles().then(({ data }) => data)
  return response
})
