import { forwardRef } from 'react'
import MaskedInput from 'react-text-mask'

import { StyledInputWrapper, StyledInput, StyledPlaceholder } from './Input.styles'

export const Input: React.FC<any> = forwardRef((props, ref: any) => {
  const {
    label,
    placeholder,
    placeholderTop = true,
    icon,
    mask,
    noMove = false,
    // showMask = false,
    ...rest
  } = props

  return (
    <StyledInputWrapper>
      {label && <>{label}</>}
      {mask ? (
        <MaskedInput
          {...(rest || {})}
          ref={ref}
          mask={mask}
          noMove={noMove}
          placeholderChar='_'
          render={(r, p) => (
            <StyledInput
              ref={r}
              ph={placeholder}
              required={rest?.required}
              isValue={rest?.value}
              placeholderTop={placeholderTop}
              {...p}
            />
          )}
        />
      ) : (
        <StyledInput ref={ref} ph={placeholder} placeholderTop={placeholderTop} {...(rest || {})} />
      )}

      {placeholder && <StyledPlaceholder error={rest?.error}>{placeholder}</StyledPlaceholder>}
      {icon && <>{icon}</>}
    </StyledInputWrapper>
  )
})
