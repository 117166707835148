import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { UNDERWRITER_LOANS_ROUTE } from '../../constants'

export const OldLoanRedirect: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string | undefined }>()
  const history = useHistory()

  useEffect(() => {
    history.push(`${UNDERWRITER_LOANS_ROUTE}/${id}`)
  }, [])
  return <></>
}
