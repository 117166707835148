/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  fetchAdminUsernames,
  fetchAgentUsernames,
  fetchLoanStatus,
  fetchAgentManagerUsernames,
  fetchAdminsRoles,
  fetchAdminsFio,
} from './extra'

const initialState = {
  data: {
    adminsFioData: [],
    adminUsernames: [],
    agentManagerUsernames: [],
    loanStatuses: [],
    adminsRoles: [],
    agentList: [],
  },
  pending: true,
  error: false,
}

export const dictSlice = createSlice({
  name: 'dict',
  initialState,
  reducers: {
    setFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.pending = payload
    },
    resetFetchingStatus: (state) => {
      state.pending = false
      state.error = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminsFio.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchAdminsFio.rejected, (state) => {
        state.error = true
        state.pending = false
      })
      .addCase(fetchAdminsFio.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pending = false
        state.data = {
          ...state.data,
          adminsFioData: payload.data,
        }
      })
      .addCase(fetchAdminUsernames.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchAdminUsernames.rejected, (state) => {
        state.error = true
        state.pending = false
      })
      .addCase(fetchAdminUsernames.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pending = false
        state.data = {
          ...state.data,
          adminUsernames: payload.data,
        }
      })
      .addCase(fetchAgentManagerUsernames.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchAgentManagerUsernames.rejected, (state) => {
        state.error = true
        state.pending = false
      })
      .addCase(fetchAgentManagerUsernames.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pending = false
        state.data = {
          ...state.data,
          agentManagerUsernames: payload.data,
        }
      })
      .addCase(fetchAdminsRoles.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchAdminsRoles.rejected, (state) => {
        state.error = true
        state.pending = false
      })
      .addCase(fetchAdminsRoles.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pending = false
        state.data = {
          ...state.data,
          adminsRoles: payload.roles,
        }
      })
      .addCase(fetchLoanStatus.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchLoanStatus.rejected, (state) => {
        state.error = true
        state.pending = false
      })
      .addCase(fetchLoanStatus.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pending = false
        state.data = {
          ...state.data,
          loanStatuses: payload.data,
        }
      })
      .addCase(fetchAgentUsernames.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchAgentUsernames.rejected, (state) => {
        state.error = true
        state.pending = false
      })
      .addCase(fetchAgentUsernames.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pending = false
        state.data = {
          ...state.data,
          agentList: payload.data,
        }
      })
  },
})

export const { resetFetchingStatus } = dictSlice.actions

export default dictSlice.reducer
