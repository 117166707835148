/* eslint-disable prefer-const */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react'
import { find, forEach } from 'lodash'
import { useDispatch, useSelector, batch } from 'react-redux'

import { GlobalState } from 'index'
import {
  fetchLoanAccept,
  fetchLoanRefuse,
  changeLoanStatus,
  changeLoanStatusRevision,
} from 'features/loan/extra'
import { fetchLoansCount } from 'features/loans/extra'

import { ProjectLoansService } from 'services/loanService'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'

import { LoanStatuses, declOfNum } from 'helpers'

import { RangeInput, Button, ModalConfirm, StyledInputField } from 'components'
import { CreditProduct } from '../../../../constants'
import { IoMdWarning } from 'react-icons/io'

import {
  TERM,
  AMOUNT,
  CAN_BE_RETURNED_TO_REWORK,
  CAN_BE_REFUSED_BY_CLIENT,
  APPROVE_DATA,
  REFUSE_DATA,
  REVISION_DATA,
  SCORING_DATA,
  getAmountForScoring,
} from './data'

import {
  StyledLimitWrapper,
  StyledLimitTitle,
  StyledLimitContent,
  StyledRangeTitle,
  StyledLimitItem,
  StyledLimitButtons,
  StyledTextArea,
  StyledRefuseWrapper,
  StyledRefuseDescription,
  StyledRefuseTypesWrapper,
  StyledRefuseTypeTitle,
  StyledRefuseTypeTextWrapper,
  StyledRefuseButtonWrapper,
  StyledBlocker,
  StyledBlockerWrapper,
  StyledBanInfo,
} from '../Modules.styles'

import { ReactComponent as CalendarSVG } from 'assets/calendar-limit.svg'
import { ReactComponent as WalletSVG } from 'assets/wallet-limit.svg'

export const SetLimitInstallment: any = ({
  loan_id,
  creditProduct: creditProductType,
  loanStatus,
  claimsResolve,
}): JSX.Element => {
  const dispatch = useDispatch()
  const {
    loan_permitted_sum: loanPermittedSum,
    loan_permitted_period: loanPermittedPeriod,
    loan_ask_period: loanAskPeriod,
    loan_ask_sum: loanAskSum,
  } = useSelector((store: GlobalState) => store.singleLoan.loadData)

  const {
    pending,
    personalData: { user_id: userId },
    nbkiScoringData,
  } = useSelector((store: GlobalState) => store.singleLoan)

  const userLoans = useSelector((store: GlobalState) => store.userLoans)

  type DecisionType = 'approve' | 'refuse' | 'revision'

  const [creditProduct, setCreditProduct] = useState<string>(creditProductType)
  const [optionType, setOptionType] = useState(null)
  const [decision, setDecision] = useState<DecisionType>(null)
  const [comment, setComment] = useState<string | null>(null)
  const [otherIncome, setOtherIncome] = useState<string | null>(null)
  const [amount, setAmount] = useState(AMOUNT[creditProduct].DEFAULT)
  const [term, setTerm] = useState(TERM[creditProduct].DEFAULT)
  const [lastLoanSum, setLastLoanSum] = useState<number>(null)
  const [refuseByClient, setRefuseByClient] = useState<boolean>(false)
  const [returnToRevisionModal, setReturnToRevisionModal] = useState<boolean>(false)

  const [showModal, setShowModal] = useState({
    show: false,
    title: '',
  })

  useEffect(() => {
    if (creditProductType) setCreditProduct(creditProductType)
  }, [creditProductType])

  function checkIsFirstLoan() {
    let firstLoan = true

    forEach(userLoans?.loans, (loan) => {
      if (loan?.usage_days) firstLoan = false
    })
    return firstLoan
  }

  const firstLoan = checkIsFirstLoan()

  useEffect(() => {
    let amountSum = loanAskSum

    const shouldEvaluateScoring =
      (creditProduct === CreditProduct?.installment_16_30k_08_25w_agent ||
        creditProduct === CreditProduct?.installment_16_30k_08_25w) &&
      firstLoan

    if (loanAskSum && shouldEvaluateScoring) {
      const productData = SCORING_DATA[creditProduct]

      if (productData && nbkiScoringData?.score)
        amountSum = getAmountForScoring(nbkiScoringData?.score, productData)
    }

    setAmount(amountSum)

    if (loanAskPeriod) setTerm(loanAskPeriod)
  }, [loanAskPeriod, loanAskSum, creditProduct, nbkiScoringData?.score, firstLoan])

  useEffect(() => {
    if (userId && !lastLoanSum)
      ProjectLoansService.getLastLoan(userId).then(({ data }) => {
        setLastLoanSum(data.last_loan_sum)
      })
  }, [userId])

  const getTypes = () => {
    if (!decision || refuseByClient) return []

    const types = {
      approve: APPROVE_DATA,
      refuse: REFUSE_DATA,
      revision: REVISION_DATA,
    }
    const typesData = types[decision][creditProduct]

    if (!typesData) return []
    return typesData
  }

  const commentsTypes = getTypes()

  const handleSubmit = async (type: DecisionType) => {
    const foundItem: any = find(commentsTypes, {
      title: optionType?.title || '',
    })
    const commentWithPrefixType = `${foundItem?.value || ''} ${comment || ''}`

    if (type === 'approve') {
      await dispatch(
        fetchLoanAccept({
          loan_id,
          timeLimit: term,
          sumLimit: Math.floor(amount / AMOUNT[creditProduct].STEP) * AMOUNT[creditProduct].STEP,
          comment: commentWithPrefixType || '',
        })
      )
      dispatch(fetchLoansCount())
    } else if (type === 'refuse') {
      await dispatch(
        fetchLoanRefuse({
          loan_id,
          ...(!refuseByClient && { reject_code_id: foundItem?.reject_code_id || 0 }),
          ban_user: refuseByClient ? false : !!foundItem?.banUser,
          comment: commentWithPrefixType || '',
          timeLimit: term,
          sumLimit: amount,
          byClient: refuseByClient,
          notice: refuseByClient ? false : true,
        })
      )
    } else {
      await dispatch(
        changeLoanStatusRevision({
          loan_id,
          timeLimit: term,
          sumLimit: amount,
          code: foundItem?.reject_code_id,
          comment: commentWithPrefixType || '',
          ...(optionType?.proposed_other_income && {
            proposed_other_income: otherIncome,
          }),
        })
      )
    }
    setShowModal({
      show: false,
      title: '',
    })
  }

  const handleReturnToRevision = () => {
    dispatch(
      changeLoanStatus({
        loan_id,
        timeLimit: term,
        sumLimit: amount,
        comment,
        status_name: LoanStatuses.on_check,
        successAction: () => {
          batch(() => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Заявка вернута на доработку`,
              })
            )
          })
        },
        errorAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Ошибка',
              description: `Заявка не вернута на доработку`,
            })
          )
        },
      })
    )
    setShowModal({
      show: false,
      title: '',
    })
  }

  const openModalRefuse = (): void => {
    setRefuseByClient(false)
    setDecision('refuse')
    setShowModal({
      show: true,
      title: 'Отказать в займе',
    })
  }

  const openModalRefuseByClient = (): void => {
    setRefuseByClient(true)
    setDecision('refuse')
    setShowModal({
      show: true,
      title: 'Отказать в займе, отказ клиента',
    })
  }
  const openModalClientRevision = (): void => {
    setDecision('revision')
    setShowModal({
      show: true,
      title: 'Доработка клиента',
    })
  }

  const openModalReturnToRevision = (): void => {
    setReturnToRevisionModal(true)
    setShowModal({
      show: true,
      title: 'Доработка агента',
    })
  }

  const openModalApprove = (): void => {
    setDecision('approve')
    setShowModal({
      show: true,
      title: 'Одобрить займ',
    })
  }

  const handleCloseModal = (): void => {
    setShowModal({ show: false, title: '' })
    setRefuseByClient(false)
    setReturnToRevisionModal(false)
    setOptionType(null)
    setDecision(null)
  }

  const setTypeHandler = (type): void => setOptionType(type)

  // @ts-ignore
  const showRefuseByClientBlock: boolean = () => CAN_BE_REFUSED_BY_CLIENT.includes(creditProduct)

  // @ts-ignore
  const returnToRevision: boolean = () => CAN_BE_RETURNED_TO_REWORK.includes(creditProduct)

  const buttons = {
    approve: (
      <Button
        type='bigSuccess'
        onClick={() => (comment || optionType ? handleSubmit('approve') : null)}
        disabled={(!comment && !optionType) || pending || (getTypes().length > 0 && !optionType)}
        pending={pending}
      >
        Одобрить заявку!
      </Button>
    ),
    refuse: (
      <Button
        type='standardBig'
        onClick={() => (comment || optionType ? handleSubmit('refuse') : null)}
        disabled={(!comment && !optionType) || pending || (getTypes().length > 0 && !optionType)}
        pending={pending}
      >
        Отказать клиенту
      </Button>
    ),
    revision: (
      <Button
        type='standardBig'
        onClick={() => {
          if (optionType && (optionType.proposed_other_income ? otherIncome : true)) {
            handleSubmit('revision')
          }
        }}
        disabled={
          !optionType ||
          pending ||
          (getTypes().length > 0 && !optionType) ||
          (optionType?.proposed_other_income && !otherIncome)
        }
        pending={pending}
      >
        Отправить
      </Button>
    ),
  }

  const showButton = loanStatus === 'setting_a_limit' && claimsResolve

  return (
    <StyledLimitWrapper id='limit'>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        title={showModal.title}
        buttons={false}
        content={() => (
          <>
            {returnToRevisionModal ? (
              <StyledRefuseWrapper>
                <StyledRefuseButtonWrapper>
                  {returnToRevisionModal && (
                    <>
                      <StyledRefuseTypeTextWrapper>
                        <StyledTextArea
                          placeholder='Комментарий по заявке'
                          value={comment || ''}
                          onChange={(e) => setComment(e?.target?.value)}
                        />
                      </StyledRefuseTypeTextWrapper>
                      <br />
                      <Button
                        type='standardBig'
                        onClick={handleReturnToRevision}
                        disabled={pending}
                        pending={pending}
                      >
                        Вернуть
                      </Button>
                      <Button type='emptyBig' onClick={handleCloseModal}>
                        Отмена
                      </Button>
                    </>
                  )}
                </StyledRefuseButtonWrapper>
              </StyledRefuseWrapper>
            ) : (
              <StyledRefuseWrapper>
                <>
                  <StyledRefuseDescription>
                    {commentsTypes && 'Выберите тип:'}
                  </StyledRefuseDescription>
                  <StyledRefuseTypesWrapper>
                    {commentsTypes &&
                      commentsTypes.map((type, i) => (
                        <StyledRefuseTypeTitle
                          key={i}
                          onClick={() => setTypeHandler(type)}
                          isActive={type.title === optionType?.title}
                        >
                          {type.title}
                        </StyledRefuseTypeTitle>
                      ))}
                  </StyledRefuseTypesWrapper>
                </>
                <>
                  {decision === 'refuse' && optionType && !optionType?.banUser && (
                    <StyledBanInfo>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <IoMdWarning color='white' />
                        Клиент не будет забанен
                      </span>
                    </StyledBanInfo>
                  )}
                </>
                <StyledRefuseTypeTextWrapper>
                  <StyledTextArea
                    placeholder='Комментарий по заявке'
                    value={comment || ''}
                    onChange={(e) => setComment(e?.target?.value)}
                  />
                </StyledRefuseTypeTextWrapper>
                {optionType?.proposed_other_income && (
                  <>
                    <StyledInputField
                      onChange={(e) => setOtherIncome(e?.target?.value)}
                      placeholder='Сумма дополнительного дохода'
                      value={otherIncome}
                      type='number'
                    />
                  </>
                )}
                <StyledRefuseButtonWrapper>{buttons[decision]}</StyledRefuseButtonWrapper>
              </StyledRefuseWrapper>
            )}
          </>
        )}
      />
      <>
        <StyledLimitTitle>Выставление лимита</StyledLimitTitle>
        <StyledLimitContent>
          <StyledLimitItem>
            <WalletSVG />
            <StyledRangeTitle>Согласованная андеррайтером сумма займа</StyledRangeTitle>
            <RangeInput
              label
              // disabled={AMOUNT[creditProduct].DISABLED}
              disabled
              value={amount}
              minValue={AMOUNT[creditProduct].MIN}
              maxValue={AMOUNT[creditProduct].MAX}
              onChange={setAmount}
              postfix='₽'
              step={AMOUNT[creditProduct].STEP}
              onBlur={() =>
                setAmount(
                  Math.floor(amount / AMOUNT[creditProduct].STEP) * AMOUNT[creditProduct].STEP
                )
              }
            />
          </StyledLimitItem>
          <StyledLimitItem>
            <CalendarSVG />
            <StyledRangeTitle>Согласованный андеррайтером срок займа</StyledRangeTitle>
            <StyledBlockerWrapper>
              {creditProduct === CreditProduct.installment_2 && <StyledBlocker />}
              <RangeInput
                disabled
                label
                value={term}
                minValue={TERM[creditProduct].MIN}
                maxValue={TERM[creditProduct].MAX}
                onChange={setTerm}
                postfix={declOfNum(+term, ['неделю', 'недели', 'недель'])}
              />
            </StyledBlockerWrapper>
          </StyledLimitItem>
        </StyledLimitContent>
      </>

      <StyledLimitTitle>Одобрение заявки</StyledLimitTitle>
      <StyledTextArea
        value={comment || ''}
        maxLength={1200}
        placeholder='Комментарий по заявке'
        onChange={(e) => setComment(e?.target?.value)}
      />
      <StyledLimitButtons>
        <Button
          type='bigSuccess'
          pending={pending}
          onClick={!pending ? openModalApprove : null}
          disabled={pending || !amount}
        >
          Одобрить
        </Button>
        <Button
          type='emptyBig'
          onClick={!pending ? openModalRefuse : null}
          disabled={pending}
          pending={pending}
        >
          Отказать
        </Button>
        {/* @ts-ignore  */}
        {showRefuseByClientBlock() && (
          <Button
            type='emptyBig'
            pending={pending}
            onClick={!pending ? openModalRefuseByClient : null}
            disabled={pending}
          >
            Отказ клиента
          </Button>
        )}
        {/* @ts-ignore  */}
        {returnToRevision() && (
          <Button
            type='bigDanger'
            pending={pending}
            onClick={!pending ? openModalReturnToRevision : null}
            disabled={pending}
          >
            Доработка агента
          </Button>
        )}
        {showButton && (
          <Button
            type='standardBig'
            onClick={!pending ? openModalClientRevision : null}
            disabled={pending}
          >
            Доработка клиента
          </Button>
        )}
      </StyledLimitButtons>
    </StyledLimitWrapper>
  )
}
