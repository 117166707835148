/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import { CollectorType } from 'features/collector/types'

import { ServiceBase } from './serviceBase'

const fields = [
  'loans.loan_date_create',
  'requests_count',
  'loans.loan_id',
  'passport.first_name',
  'passport.last_name',
  'passport.middle_name',
  'collector_status',
  'users.login',
  'collector_added',
  'loans.loan_ask_sum',
  'passport.number',
  'passport.serial',
  'utm_source',
  'registration_address',
  'residence_address',
  'loan_statuses.status_name',
  'loans.loan_number',
  'overdue_days',
  'users.time_zone',
  'collector_username',
]

export class ProjectCollectorService extends ServiceBase {
  protected static LOAN_PATH = 'loans'

  protected static USERS_PATH = 'users'

  protected static COUNT_PAGE_REQUEST = 30

  public static getCollectorLoans(
    iteration: number,
    type: keyof typeof CollectorType,
    filters: any
  ): AxiosPromise {
    const data = JSON.stringify({
      fields: type === CollectorType[type] ? fields : fields.push('collector_username'),
      ...filters,
    })
    let response

    switch (type) {
      case CollectorType.all:
        response = this.post(
          `${this.LOAN_PATH}/get?page_size=${this.COUNT_PAGE_REQUEST}&p=${iteration}`,
          data
        )
        break

      case CollectorType.inWork:
        response = this.post(
          `${this.LOAN_PATH}/get?page_size=${this.COUNT_PAGE_REQUEST}&p=${iteration}`,
          data
        )
        break

      case CollectorType.free:
        response = this.post(
          `collector_api/${this.LOAN_PATH}/get_unassigned?p=${iteration}&page_size=${this.COUNT_PAGE_REQUEST}&date_sort=desc`,
          data
        )
        break

      default:
        break
    }

    return response
  }

  public static getLoansCount(): AxiosPromise {
    return this.get(`collector_api/${this.LOAN_PATH}/overdue_loans_count`)
  }

  public static getLoansCountInWork(): AxiosPromise {
    return this.get(`collector_api/${this.LOAN_PATH}/overdue_loans_in_work_count`)
  }

  public static getLoansCountFree(): AxiosPromise {
    return this.get(`collector_api/${this.LOAN_PATH}/overdue_loans_not_in_work_count`)
  }

  public static getAssignTheLoan(loanId: string): AxiosPromise {
    return this.post(`collector_api/${this.LOAN_PATH}/assign/${loanId}`)
  }

  public static getUnAssignTheLoan(loanId: string): AxiosPromise {
    return this.get(`collector_api/${this.LOAN_PATH}/unassign/${loanId}`)
  }

  public static getTasks(loanId: string): AxiosPromise {
    return this.get(`collector_api/${this.LOAN_PATH}/collector_tasks/${loanId}`)
  }

  public static addTasks(loanId: string, data: any): AxiosPromise {
    return this.post(`collector_api/tasks/add/${loanId}`, data)
  }
}
