/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { Module } from 'components'
import { orderBy } from 'lodash'

import { loanStatuses, normalizeValues } from 'helpers'

import {
  StyledHeadTitle,
  StyledLoanHistoryContent,
  StyledHistoryColumnWrapper,
  StyledHistoryMobileTableWrapper,
  StyledHistoryMobileItem,
  StyledItemTitle,
  StyledDescription,
} from './Modules.styles'
import { rolesType } from 'constants/rtoles'

const moduleId = 'loansHistory'

export const LoansHistory: any = ({ openedModules, onToggleModule, claims }): JSX.Element => {
  const { loans } = useSelector((state: GlobalState) => state.userLoans)
  const agentUserLoans = useSelector((store: GlobalState) => store.agentLoans.userLoans)
  const contractUserLoans = useSelector((store: GlobalState) => store.contracts.userLoans)
  const isOpen = openedModules.includes(moduleId)
  const [loansData, setLoansData] = useState([])

  const dataLoans = useMemo(
    () => ({
      [rolesType.underwriter]: loans,
      [rolesType.operator]: loans,
      [rolesType.agent]: agentUserLoans,
      [rolesType.agentContract]: contractUserLoans,
    }),
    [loans, loans, agentUserLoans, contractUserLoans]
  )

  const handleExpand = (): void => onToggleModule(moduleId)

  useEffect(() => {
    if (dataLoans[claims] && dataLoans[claims]?.length) {
      setLoansData(dataLoans[claims])
    }
  }, [dataLoans, claims])

  const handleOpenLoan = (loanId: string): void => {
    window.open(`${window.location.origin}/arm/loans/${loanId}`, '_blank')
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <StyledHeadTitle>
            История займов
            {!loansData.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </StyledHeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <StyledLoanHistoryContent>
          <TableHead />
        </StyledLoanHistoryContent>
        {dataLoans && loansData.length
          ? orderBy(loansData, ['loans_loan_date_create'], ['desc']).map((item, index) => {
              return (
                <Fragment key={item?.loans_loan_id || index}>
                  <>
                    <MobileView {...item} handleOpenLoan={handleOpenLoan} />
                  </>
                  <StyledHistoryColumnWrapper
                    key={item?.loans_loan_id || index}
                    onClick={() => handleOpenLoan(item.loans_loan_id)}
                  >
                    <StyledLoanHistoryContent>
                      <Table {...item} key={item?.loans_loan_id || index} />
                    </StyledLoanHistoryContent>
                  </StyledHistoryColumnWrapper>
                </Fragment>
              )
            })
          : null}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const MobileView = (props) => {
  const {
    loans_loan_date_create,
    loans_loan_issue_date,
    loans_loan_number,
    loans_loan_sum,
    loans_loan_id,
    handleOpenLoan,
    loans_loan_period,
    loans_product_id,
    overdue_days,
    usage_days,
    loan_statuses_status_name,
  } = props

  return (
    <StyledHistoryMobileTableWrapper>
      <StyledHistoryMobileItem onClick={() => handleOpenLoan(loans_loan_id)}>
        <StyledItemTitle>Номер заявки</StyledItemTitle>
        <StyledDescription>{normalizeValues(loans_loan_number)}</StyledDescription>
        <StyledItemTitle>Дата создания/Выдачи займа</StyledItemTitle>
        <StyledDescription>
          {normalizeValues(loans_loan_date_create, 'date')} /{' '}
          {normalizeValues(loans_loan_issue_date, 'date')}
        </StyledDescription>
        <StyledItemTitle>Продукт</StyledItemTitle>
        <StyledDescription>
          {normalizeValues(normalizeValues(loans_product_id, 'products'), 'productsTranslate')}
        </StyledDescription>
        <StyledItemTitle>Сумма займа</StyledItemTitle>
        <StyledDescription styles='boldGreen'>{normalizeValues(loans_loan_sum)}</StyledDescription>
        <StyledItemTitle>Дней использования</StyledItemTitle>
        <StyledDescription>{normalizeValues(usage_days)}</StyledDescription>
        {/* <StyledItemTitle>Срок займа</StyledItemTitle>
        <StyledDescription>{normalizeValues(loans_loan_period)}</StyledDescription> */}
        <StyledItemTitle>Дней просрочки (всего)</StyledItemTitle>
        <StyledDescription>
          {normalizeValues(overdue_days > 0 ? overdue_days : '', 'overdue')}
        </StyledDescription>
        <StyledItemTitle>Статус</StyledItemTitle>
        <StyledDescription>
          {normalizeValues(loanStatuses[loan_statuses_status_name])}
        </StyledDescription>
      </StyledHistoryMobileItem>
    </StyledHistoryMobileTableWrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Номер заявки</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата создания/Выдачи займа</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Продукт</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Сумма займа</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дней использования</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дней просрочки (всего)</Module.TableTitle>
    </Module.TableColumn>
    {/* <Module.TableColumn column={1}>
      <Module.TableTitle>Дней использования</Module.TableTitle>
    </Module.TableColumn> */}
    <Module.TableColumn column={1}>
      <Module.TableTitle>Статус</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const {
    loans_loan_date_create: createDate,
    loans_loan_issue_date: issueDate,
    loans_loan_number: number,
    loans_loan_sum: sum,
    loans_loan_period: period,
    overdue_days: overdue,
    usage_days: usage,
    loans_product_id: product,
    loan_statuses_status_name: status,
  } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(number)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {normalizeValues(createDate, 'date')} / {normalizeValues(issueDate, 'date')}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {normalizeValues(normalizeValues(product, 'products'), 'productsTranslate')}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(sum, 'sum')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(usage)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {normalizeValues(overdue > 0 ? overdue : '', 'overdue')}
        </Module.TableValue>
      </Module.TableColumn>
      {/* <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(usage)}</Module.TableValue>
      </Module.TableColumn> */}
      <Module.TableColumn column={1}>
        <Module.TableValue>{loanStatuses[status]}</Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
