import { createGlobalStyle } from 'styled-components'
import { THEME } from './theme'

// reset
export const GlobalStyle = createGlobalStyle`
 * {
  font-family: 'Nunito', sans-serif;
 }
  html, body {
    margin : 0;
    padding : 0;
    font-family: 'Nunito', sans-serif;   
    overflow: hidden;
  }
  
  /* set up default setting to body */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
  } 

  /* set up box sizing */
  *,
  *::before,
  *::after {
    box-sizing: border-box;   
  }
  /* remove inside padding */
  ul[class],
  ol[class] {
    padding: 0;
  }
  /* remove outside padding */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ol[class],
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  } 
  /* remove styling ul and il, in class*/
  ul[class],
  ol[class] {
    list-style: none;
  }
  /* tag a, without classes, set up to default */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }
  /* set up images */
  img {
    max-width: 100%;
    display: block;
  }
  #root {
    height: 100vh;
    width: 100%;
  }

  /* *::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #F5F5F5;
  height : 5px;
}

*::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #CFCED8;
} */

.ReactModal__Overlay {
  z-index: 9999999;
}

.react-datepicker-popper {
    z-index: 99999999;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    border: none;
    border-radius: 16px;
    width: 100%;
  }

  .react-datepicker__month {
    margin: 0.5rem;
  }

  .react-datepicker__month-container {
    box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.18);
    border-radius: 16px;
    font-weight: bold;
  }

  .react-datepicker__header {
    border-radius: 16px !important;
    background-color: white;
    border-bottom: none;
  }
  .react-datepicker__header__dropdown {
    height: 26px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .react-datepicker__input-container input {
    outline: 0;
    width: 100%;
    height: 60px;
    position: relative;
    z-index: 1;
    background: ${THEME.COLORS.WHITE};
    padding-left: 16px;
    box-shadow: 0px 1px 3px rgba(0, 44, 119, 0.04);
    border-radius: 16px;
    font-size: 16px;
    color: ${THEME.COLORS.DARK_BLUE};
    border: 2px solid ${THEME.COLORS.LIGHTLY_GRAY};
    font-weight: 600;
    min-height: 60px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::placeholder {
      font-family: 'Nunito', sans-serif;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .react-datepicker-ignore-onclickoutside {
    border: 2px solid ${THEME.COLORS.YELLOW}!important;
  }
  .react-datepicker__day {
    &:hover {
      background-color: ${THEME.COLORS.TABLE_HOVER};
      border-radius: 50% !important;
      color: ${THEME.COLORS.BLUE};
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${THEME.COLORS.BLUE};
    color: white;
    border-radius: 50%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${THEME.COLORS.BLUE};
    color: white;
    border-radius: 50%;
  }

  .react-datepicker__close-icon {
    cursor: pointer;
    border: 0;
    outline: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 14px;
    height: 30px;
    width: 30px;
    display: table-cell;
    vertical-align: middle;

    &:after {
      content: '';
      background-color: transparent;
    }
  }

  .ReactModal__Overlay{
    overflow: hidden;
  }
`
