import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAuthService } from '../../services/authService'

interface IAuthData {
  username: string
  password: string
  onSuccess: (token: string) => void
}
export const fetchAuth = createAsyncThunk<any, any>('auth/loadData', async (props: IAuthData) => {
  const { username, password, onSuccess } = props
  const formData = new FormData()

  formData.append('username', username)
  formData.append('password', password)

  const response = await ProjectAuthService.authorization(formData).then((data) => {
    const {
      data: { access_token: token },
    } = data

    onSuccess(token)
    return data
  })
  return response
})

export const getUserInfo = createAsyncThunk<any, any>(
  'auth/getUser',
  async ({ onSuccess, handleLogout }) => {
    const response = await ProjectAuthService.getUserInfo()
      .then((data) => {
        onSuccess()
        return data
      })
      .catch(() => {
        handleLogout()
      })
    return response
  }
)
