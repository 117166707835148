/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

interface IFilterState {
  data: {
    [key: string]: string | any
    search?: string
  }
}

export const initialFiltersState: IFilterState = {
  data: {},
}

export const collectorFiltersSlice = createSlice({
  name: 'collectorFilters',
  initialState: initialFiltersState,
  reducers: {
    setCollectorFilters: (state, { payload }) => {
      state.data = { ...payload }
    },
  },
})

export const { setCollectorFilters } = collectorFiltersSlice.actions

export default collectorFiltersSlice.reducer
