import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectLoansService } from '../../services/loanService'

export const fetchUserLoans = createAsyncThunk<any, { ids: string[] }>(
  'loans/userLoans',
  async ({ ids }) => {
    const response = await ProjectLoansService.getDetailedLoansByUserId(ids).then(
      ({ data }) => data
    )
    return response?.data
  }
)
