/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-names */

import { StyledScrollToTopWrapper, StyledButton } from './ScrollToTop.styles'

export const ScrollToTop: React.FC<any> = (props) => {
  const { scrollTo = '#loan-content' } = props
  const handleClick = () => {
    document.querySelector(scrollTo).scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <StyledScrollToTopWrapper show>
      <StyledButton onClick={handleClick}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
          <path d='M16.232 8.303a.953.953 0 011.395 0l.084.09c.385.404.385 1.06 0 1.464l-4.317 4.536c-.77.81-2.018.81-2.788 0L6.289 9.857a1.073 1.073 0 010-1.465l.084-.089a.953.953 0 011.395 0l3.535 3.715a.953.953 0 001.394 0l3.535-3.715z' />
        </svg>
      </StyledButton>
    </StyledScrollToTopWrapper>
  )
}
