import { normalizeValues } from 'helpers'
import { Fragment } from 'react'

import {
  StyledInfoTitle,
  StyledInfoContentWrapper,
  StyledInfoContent,
  StyledInfoDescTitle,
  StyledInfoDescContent,
  StyledCardCountTitle,
} from '../Modal.styles'

export const BankCardData = (props): JSX.Element => {
  const { bankCardData, isEditable, isOpenItem, handleOpen, claimsResolve } = props
  return (
    <>
      <StyledInfoContentWrapper
        isOpen={isOpenItem.id === 'bankCard'}
        onClick={handleOpen({ id: 'bankCard' })}
      >
        <StyledInfoTitle>Банковские карты</StyledInfoTitle>
        {bankCardData && bankCardData.length > 0 && (
          <>
            {bankCardData.map((item, index) => (
              <Fragment key={item?.card_number}>
                <StyledInfoContent edit={isEditable && claimsResolve}>
                  <StyledCardCountTitle>Карта {index + 1}</StyledCardCountTitle>
                  <StyledInfoDescTitle>Номер карты</StyledInfoDescTitle>
                  <StyledInfoDescContent>{item?.card_number}</StyledInfoDescContent>
                </StyledInfoContent>
                <StyledInfoContent edit={isEditable && claimsResolve}>
                  <StyledInfoDescTitle>Срок действия</StyledInfoDescTitle>
                  <StyledInfoDescContent>
                    {normalizeValues(item?.card_expiration_date, 'date')}
                  </StyledInfoDescContent>
                </StyledInfoContent>
                <StyledInfoContent edit={isEditable && claimsResolve}>
                  <StyledInfoDescTitle>Владелец</StyledInfoDescTitle>
                  <StyledInfoDescContent>{item?.card_holder}</StyledInfoDescContent>
                </StyledInfoContent>
                <StyledInfoContent edit={isEditable && claimsResolve}>
                  <StyledInfoDescTitle>Карта добавлена</StyledInfoDescTitle>
                  <StyledInfoDescContent>
                    {normalizeValues(item?.card_added_at, 'date')}
                  </StyledInfoDescContent>
                </StyledInfoContent>
              </Fragment>
            ))}
          </>
        )}
      </StyledInfoContentWrapper>
    </>
  )
}
