/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UNDERWRITER_LOANS_ROUTE } from 'constants/pagesRoutes'
import { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { NavMenuType } from 'App'
import { GlobalState } from 'index'
import { fetchAgentWaitingLoans } from 'features/agentLoans/extra'
import { resetAgentsLoans } from 'features/agentLoans/loanSlice'

import { Controls } from '../../components/Controls'

import { StyledPageWrapper } from './AgentWaitingPage.styles'

import { Table } from './Table'
import { NavigationContext } from 'app/contexts'

export const AgentWaitingList = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setNavigation, isAgentRole, setNavMenuType } = useContext(NavigationContext)

  useEffect(() => {
    if (isAgentRole === false) {
      history.push(UNDERWRITER_LOANS_ROUTE)
      setNavMenuType(NavMenuType.underwriter)
    } else {
      setNavMenuType(NavMenuType.agent)
    }
  }, [isAgentRole])

  const { waiting }: any = useSelector((store: GlobalState) => store.agentLoans)

  useEffect(() => {
    if (waiting?.data === null && !waiting?.pending) {
      dispatch(fetchAgentWaitingLoans())
    }
  }, [waiting])

  useEffect(() => {
    dispatch(resetAgentsLoans(null))
  }, [])

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Инстоллмент - Ожидают анкеты`,
      backTo: false,
    })
  }, [])

  const filterProps = {
    pageControlsStructure: 'waiting',
    filtersVisible: false,
  }

  return (
    <StyledPageWrapper>
      <Controls {...filterProps} />
      <Table loans={waiting} />
    </StyledPageWrapper>
  )
}
