import { useState, useEffect } from 'react'

function getWinSize() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window

    return {
      width,
      height,
    }
  }
  return { width: 0, height: 0 }
}

interface IWindowSize {
  width: number
  height: number
}

export function useWindowSize(): IWindowSize {
  const [windowDimensions, setWindowDimensions] = useState(getWinSize())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWinSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
