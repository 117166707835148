import styled from 'styled-components/macro'
import { THEME } from 'core/theme'

export const StyleCloseModal = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${THEME.COLORS.WHITE};
  border-radius: 50%;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.35);
  padding: 10px;
  display: flex;
  cursor: pointer;

  @media (max-width: 480px) {
    padding: 6px;
  }
`

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & svg {
    font-size: 50px;
    margin: 0 auto;
    color: ${THEME.COLORS.WARNING};
  }
`

export const StyledModalTitle = styled.p`
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: ${THEME.COLORS.DARK_BLUE};
  text-align: left;

  @media (max-width: 420px) {
    font-size: 18px;
  }
`

export const StyledButtonsBlock = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
`
export const StyledDescription = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.GRAY};
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;

  @media (max-width: 480px) {
    margin-bottom: 15px;
  }
`
