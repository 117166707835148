/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchDocumentsList } from './extra'

const initialState: any = {
  isFetching: false,
  data: [],
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocumentsList.pending, (state) => {
        state.isFetching = true
      })
      .addCase(fetchDocumentsList.rejected, (state) => {
        state.isFetching = false
      })
      .addCase(fetchDocumentsList.fulfilled, (state, { payload }) => {
        state.isFetching = false
        state.data = payload.data
      })
  },
})

export default settingsSlice.reducer
