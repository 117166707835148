/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectSdlService } from '../../services/sdlService'
import { ProjectLoansService } from '../../services/loanService'

export const fetchSdl = createAsyncThunk<any, { filters: any; refresh?: boolean }>(
  'sdl/fetchList',
  async (props) => {
    const { filters, refresh = false } = props
    const response = await ProjectSdlService.getSdlList(filters).then(({ data }) => ({
      data,
      refresh,
    }))
    return response
  }
)

export const fetchSdlUserPassportData = createAsyncThunk<any, { userId: string }>(
  'sdl/fetchUserPassport',
  async (props) => {
    const { userId } = props
    const response = await ProjectLoansService.getPassportData(userId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchSdlUserPersonalData = createAsyncThunk<any, { userId: string }>(
  'sdl/getPersonalData',
  async (props) => {
    const { userId } = props
    const response = await ProjectLoansService.getPersonalData(userId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchSdlAvailabilityData = createAsyncThunk<
  any,
  { userId: string; successAction: () => void }
>('sdl/getSdlAvailability', async (props) => {
  const { userId, successAction } = props
  const response = await ProjectSdlService.getSdlAvailability(userId).then((data) => {
    successAction()
    return data
  })
  return response
})

export const fetchSdlAtype = createAsyncThunk<any, { userId: string; body: string[] }>(
  'sdl/fetchSdlAtype',
  async (props) => {
    const { userId, body } = props
    const response = await ProjectSdlService.getAtype(userId, body).then((data) => data)
    return response
  }
)

export const fetchSdlRtype = createAsyncThunk<any, { userId: string; body: string[] }>(
  'sdl/fetchSdlRtype',
  async (props) => {
    const { userId, body } = props
    const response = await ProjectSdlService.getRtype(userId, body).then((data) => data)
    return response
  }
)

export const fetchSdlTtype = createAsyncThunk<any, { userId: string; body: string[] }>(
  'sdl/fetchSdlTtype',
  async (props) => {
    const { userId, body } = props
    const response = await ProjectSdlService.getTtype(userId, body).then((data) => data)
    return response
  }
)

export const fetchSdlBtype = createAsyncThunk<any, { userId: string; body: string[] }>(
  'sdl/fetchSdlBtype',
  async (props) => {
    const { userId, body } = props
    const response = await ProjectSdlService.getBtype(userId, body).then((data) => data)
    return response
  }
)
export const fetchSdlStype = createAsyncThunk<any, { userId: string }>(
  'sdl/fetchSdlStype',
  async (props) => {
    const { userId } = props
    const response = await ProjectSdlService.getStype(userId).then((data) => data)
    return response
  }
)

export const fetchLetterContent = createAsyncThunk<any, { userId: string; letterId: string }>(
  'sdl/fetchLetterContent',
  async (props) => {
    const { userId, letterId } = props
    const response = await ProjectSdlService.getLetterContent(userId, letterId).then((data) => data)
    return response
  }
)
