/* eslint-disable @typescript-eslint/no-unused-vars */
import { onLogout } from 'features/auth/authSlice'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { MESSAGES_404, EXCEPTIONS, MESSAGES_403, MESSAGES_204, MESSAGES_422 } from './dictionary'

const errorLogger: any = (store) => (next) => (action) => {
  const { error, type } = action
  if (action.type.includes('/rejected')) {
    console.warn('errorLogger %o', action)

    if (error.message.includes('204')) {
      const typeMessage = MESSAGES_204[type]
      next(
        addToast({
          type: ToastTypes.info,
          title: 'Запрос не может быть обработан',
          description: `${typeMessage || ''}`,
        })
      )
    }

    if (error.message.includes('400')) {
      store.dispatch(onLogout())
      next(
        addToast({
          type: ToastTypes.info,
          title: 'Администратор не активен',
          description: '',
        })
      )
    }
    if (error.message.includes('401')) {
      store.dispatch(onLogout())
      next(
        addToast({
          type: ToastTypes.info,
          title: 'Сессия истекла',
          description: 'Пожалуйста авторизуйтесь заново',
        })
      )
    }
    if (error.message.includes('403')) {
      const typeMessage = MESSAGES_403[type]

      next(
        addToast({
          type: ToastTypes.warning,
          title: 'Ошибка',
          description: `${typeMessage || 'Действие запрещено'}`,
        })
      )
    }
    // if (error.message.includes('404')) {
    //   const exception = EXCEPTIONS[type]
    //   if (!exception) {
    //     const typeMessage = MESSAGES_404[type]
    //     next(
    //       addToast({
    //         type: ToastTypes.info,
    //         title: 'Запрашиваемый ресурс не найден.',
    //         description: `${typeMessage || ''}`,
    //       })
    //     )
    //   }
    // }
    if (error.message.includes('500')) {
      next(
        addToast({
          type: ToastTypes.warning,
          title: 'Ошибка 500',
          description: `внутренняя ошибка сервера`,
        })
      )
    }
    if (error.message.includes('422')) {
      // store.dispatch(onLogout())
      const typeMessage = MESSAGES_422[type]

      next(
        addToast({
          type: ToastTypes.info,
          title: 'Ошибка валидации',
          description: `${typeMessage || 'введены неверные данные'}`,
        })
      )
    }
    // else {
    //   next(
    //     addToast({
    //       type: ToastTypes.warning,
    //       title: error.name || 'Error',
    //       description: error.message || 'An error has occurred',
    //     })
    //   )
    // }
  }
  return next(action)
}

export { errorLogger }
