import {
  getRoute,
  INDEX,
  ARM,
  SYSTEM,
  CREDIT,
  ACCOUNTANT,
  UPLOADING,
  SCORNING,
  PROTOCOLS,
  LOANS,
  CONTRACTS,
  CLIENTS,
  UNDERWRITER,
  AGENT,
  SDL,
  WAITING,
  LIST,
  LOAN_CREATE,
  BAN_LIST,
  OPERATOR,
  COLLECTOR,
  SETTINGS,
  DOCUMENTS,
} from './pagesNames'

/**
 * Authentication
 */
//   TODO: AUTH ROUTE
/**
 * Home
 */
export const HOME_ROUTE = getRoute(INDEX)

/**
 * underwriter
 */
export const UNDERWRITER_ROUTE = getRoute([UNDERWRITER])
export const UNDERWRITER_LOANS_ROUTE = getRoute([UNDERWRITER, LOANS])
export const UNDERWRITER_LOAN_ID_ROUTE = getRoute([UNDERWRITER, LOANS, ':id'])
export const UNDERWRITER_LOAN_BAN_LIST = getRoute([UNDERWRITER, BAN_LIST])

/**
 * operator loan\
 */
export const OPERATOR_LOAN_ID_ROUTE = getRoute([OPERATOR, LOANS, ':id'])
export const OPERATOR_LOANS_ROUTE = getRoute([OPERATOR, LOANS])

/**
 * collector loan\
 */
export const COLLECTOR_LOAN_ID_ROUTE = getRoute([COLLECTOR, LOANS, ':id'])
export const COLLECTOR_LOANS_ROUTE = getRoute([COLLECTOR, LOANS])

/**
 * agent\
 */
export const AGENT_ROUTE = getRoute([AGENT])
export const AGENT_LOANS_ROUTE = getRoute([AGENT, LOANS])
export const AGENT_LOAN_ID_ROUTE = getRoute([AGENT, LOANS, ':id'])

/**
 * create loan\
 */
export const AGENT_LOANS_CREATE = getRoute([AGENT, LOAN_CREATE])

/**
 * waiting\
 */
export const WAITING_ROUTE = getRoute([AGENT, LOANS, WAITING])
export const WAITING_LOANS_ROUTE = getRoute([AGENT, LOANS, WAITING])
// export const WAITING_ID_ROUTE = getRoute([WAITING, LOANS, ':id'])
/**
 * contracts\
 */
export const CONTRACTS_ROUTE = getRoute([CONTRACTS])
export const CONTRACTS_ITEMS_ROUTE = getRoute([CONTRACTS, LIST])
export const CONTRACT_ID_ROUTE = getRoute([CONTRACTS, LIST, ':id'])

export const CLIENTS_ROUTE = getRoute([AGENT, CLIENTS])

/**
 * sdl\
 */
export const SDL_ROUTE = getRoute([SDL])
export const SDL_ROUTE_ROUTE = getRoute([SDL, LOANS])
export const SDL_ROUTE_ID_ROUTE = getRoute([SDL, LOANS, ':id'])

/**
 * settings\
 */
export const SETTINGS_ROUTE = getRoute([SETTINGS])
export const SETTINGS_DOCUMENTS_ROUTE = getRoute([SETTINGS, DOCUMENTS])

/**
 * Arm
 */
export const ARM_ROUTE = getRoute([ARM])
export const ARM_LOANS_ROUTE = getRoute([ARM, LOANS])
export const ARM_LOAN_ID_ROUTE = getRoute([ARM, LOANS, ':id'])

/**
 * System
 */
export const SYSTEM_ROUTE = getRoute([SYSTEM])
/**
 * Credit
 */
export const CREDIT_ROUTE = getRoute([CREDIT])
/**
 * Accountant
 */
export const ACCOUNTANT_ROUTE = getRoute([ACCOUNTANT])
/**
 * Unloading
 */
export const UPLOADING_ROUTE = getRoute([UPLOADING])
/**
 * Scoring
 */
export const SCORNING_ROUTE = getRoute([SCORNING])
/**
 * protocols
 */
export const PROTOCOLS_ROUTE = getRoute([PROTOCOLS])
