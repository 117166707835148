import { useEffect, useState } from 'react'
import { StyledButton, StyledDescription, StyledNotification, StyledTitle } from './Undo.styles'
import { ReactComponent as CancelSVG } from '../../assets/cancel.svg'

export enum ToastPositions {
  'top-right' = 'top-right',
  'bottom-right' = 'bottom-right',
  'top-left' = 'top-left',
  'bottom-left' = 'bottom-left',
}

export enum ToastTypes {
  'success' = 'success',
  'danger' = 'danger',
  'warning' = 'warning',
  'info' = 'info',
}

interface IToast {
  id: number
  removeHandler: (id: number) => void
  position: ToastPositions
  title: string
  action: any
  cancelAction: any
}

export const Undo: any = ({ id, removeHandler, position, title, action, cancelAction }: IToast) => {
  const [time, setTime] = useState(10)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1)
      if (time <= 0) {
        action()
        clearInterval(interval)
        removeHandler(id)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [time, removeHandler, id])

  const cancel = () => {
    cancelAction(id)
    removeHandler(id)
  }

  return (
    <StyledNotification position={position} type={ToastTypes.info}>
      <StyledButton onClick={cancel}>
        <CancelSVG />
      </StyledButton>
      <div>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription init={time === 10}>{time}</StyledDescription>
      </div>
    </StyledNotification>
  )
}
