import { DatePicker, Input } from 'components'
import { THEME } from 'core/theme'
import { useField } from 'formik'
import { get } from 'lodash'
import styled, { css } from 'styled-components'

export const StyledInput = styled(Input)`
  border: 2px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;

  ${({ error }) =>
    error
      ? css`
          border: 2px solid ${THEME.COLORS.WARNING}!important;
        `
      : css`
          &:hover {
            border: 2px solid ${THEME.COLORS.YELLOW}!important;
          }
        `}
`

export const DatePickerField: any = (props) => {
  const { name, onChange, errors, touched, setFieldTouched, selected } = props
  const [field] = useField(name)

  const onChangeHandler = (v) => {
    setFieldTouched && setFieldTouched(name, true)
    onChange && onChange(name, v)
  }

  const isError = get(errors, name) && get(touched, name)

  return (
    <DatePicker
      {...field}
      {...props}
      selected={selected || (field.value && new Date(field.value)) || null}
      onChange={onChangeHandler}
      error={isError}
      errorText={isError ? get(errors, name) : null}
    />
  )
}

export const InputField: any = (props) => {
  const { name, onChange, errors, onError, touched, setFieldTouched } = props
  const [field] = useField(name)

  const onChangeHandler = (v) => {
    setFieldTouched && setFieldTouched(name, true)
    onChange && onChange(name, v.target.value)
  }

  const isError = get(errors, name) && get(touched, name)

  return (
    <StyledInput
      {...field}
      {...props}
      onChange={onChangeHandler}
      error={isError || onError}
      errorText={isError ? get(errors, name) : null}
    />
  )
}

export const setInputValue: any =
  (setFieldValue) => (type: string, property: string | any) => (opt) => {
    const value = typeof property === 'function' ? property(opt) : get(opt, property, opt)

    setFieldValue(type, value)
  }
