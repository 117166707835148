import { rolesType } from 'constants/rtoles'
import { LoanType } from 'features/loans/types'

export enum ITableStatus {
  // Free loan status
  setting_a_limit = 'setting_a_limit',
}

export interface ITableProps {
  type: LoanType
  pending: boolean
  createdAt: Date
  loanNumber: string
  sum: number
  loanId: string
  passportFirstName: string
  passportLastName: string
  passportMiddleName: string
  passportNumber: string
  passportSerial: string
  regAddress: IAddress[]
  closedCount: number
  permittedSum: number
  userLogin: string
  // userId: string
  utmSource: string | null
  status: string
  statusDate: string
  adminUsername?: any
  username?: any
  regRegion?: string
  claims?: keyof typeof rolesType
  loanProduct?: string
}

export interface IAddress {
  id: string
  postalCode: string
  region: string
  district: string
  city: string
  street: string
  houseNumber: string
  block: string
  building: string
  apartment: string
  locCityId: string
  other: any
}

export const OVERTIME = {
  '0_24': '0_24',
  '24-48': '24-48',
  '48-more': '48-more',
}
