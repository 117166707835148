import styled, { css } from 'styled-components/macro'
import { THEME } from 'core/theme'

export const transformAnimationRight = css`
  transition: transform 0.5s ease-in-out;
  animation: toast-in-right 0.4s;
  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }
`

export const transformAnimationLeft = css`
  transition: transform 0.5s ease-in;
  animation: toast-in-left 0.4s;
  @keyframes toast-in-left {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }
`

export const StyledNotification = styled.div<any>`
  background: ${THEME.COLORS.WHITE};
  pointer-events: auto;
  margin: 0 0 6px;
  margin-bottom: 15px;
  width: 100%;
  padding: 8px 15px 10px 10px;
  max-height: 200px;
  border-radius: 0 4px 4px 0px;
  border-left: 3px solid ${THEME.COLORS.WHITE};
  box-shadow: 0 3px 7px rgb(0 0 0 / 30%);
  color: #000000;
  opacity: 0.9;
  background-position: 15px;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  ${({ position }) => {
    switch (position) {
      case 'top-right':
        return css`
          top: 12px;
          right: 12px;
          ${transformAnimationRight}
        `
      case 'bottom-right':
        return css`
          bottom: 12px;
          right: 12px;
          ${transformAnimationRight}
        `
      case 'top-left':
        return css`
          top: 12px;
          left: 12px;
          ${transformAnimationLeft}
        `
      case 'bottom-left':
        return css`
          bottom: 12px;
          left: 12px;
          ${transformAnimationLeft}
        `

      default:
        return ''
    }
  }}

  ${({ type }) => {
    switch (type) {
      case 'success':
        return css`
          ${StyledTitle} {
            color: ${THEME.COLORS.SNACK_SUCCESS};
          }
          &:hover {
            box-shadow: 0 0 12px rgb(67 181 129 / 60%);
            opacity: 1;
            cursor: default;
          }
          border-color: ${THEME.COLORS.SNACK_SUCCESS};
        `
      case 'danger':
        return css`
          ${StyledTitle} {
            color: ${THEME.COLORS.WARNING_YELLOW};
          }
          &:hover {
            box-shadow: 0 0 12px rgb(255 153 0 / 60%);
            opacity: 1;
            cursor: default;
          }
          border-color: ${THEME.COLORS.WARNING_YELLOW};
        `
      case 'warning':
        return css`
          ${StyledTitle} {
            color: ${THEME.COLORS.WARNING};
          }
          &:hover {
            box-shadow: 0 0 12px rgb(240 71 71 / 60%);
            opacity: 1;
            cursor: default;
          }
          border-color: ${THEME.COLORS.WARNING};
        `
      case 'info':
        return css`
          ${StyledTitle} {
            color: ${THEME.COLORS.BLUE};
          }
          &:hover {
            box-shadow: 0 0 12px rgb(0 119 187 / 60%);
            opacity: 1;
            cursor: default;
          }

          border-color: ${THEME.COLORS.BLUE};
        `

      default:
        return ''
    }
  }}
`
export const StyledButton = styled.button`
  position: relative;
  right: -15px;
  top: -84px;
  float: right;
  font-weight: 700;
  opacity: 0.8;
  line-height: 0;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  width: 20px;
  height: 20px;
  outline: none;
`
export const StyledTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  width: 300px;
`

export const StyledBtn = styled.div<any>`
  background: ${THEME.COLORS.SUCCESS};
  color: ${THEME.COLORS.WHITE};
  border-radius: 15px;
  padding: 2px 8px;
  /* width: fit-content; */
  opacity: 0.8;
  font-size: 14px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  justify-content: center;
  cursor: pointer;

  ${({ alert }) =>
    alert &&
    css`
      background: ${THEME.COLORS.WARNING};
    `};
  &:hover {
    opacity: 1;
  }
`

export const StyledContent = styled.p`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const StyledButtons = styled.p`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`
export const StyledDescription = styled.p<any>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    opacity: 1 !important;
  }
`
