/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, ReactNode } from 'react'

import {
  StyledExpanderWrapper,
  StyledButton,
  StyledButtonWrapper,
  StyledGradient,
} from './ExpandedContainer.styles'

interface IExpandedContainerProps {
  children: ReactNode
  elements: number
  isFetching: boolean
}

export const ExpandedContainer: React.FC<IExpandedContainerProps> = ({
  children,
  elements,
  isFetching,
}): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (elements <= 5 && !isFetching) setExpanded(true)
  }, [elements])

  const handleClick = (): void => setExpanded(!expanded)

  return (
    <StyledExpanderWrapper expanded={expanded}>
      {children}
      <StyledButtonWrapper>
        <StyledButton onClick={handleClick}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
            <path d='M16.232 8.303a.953.953 0 011.395 0l.084.09c.385.404.385 1.06 0 1.464l-4.317 4.536c-.77.81-2.018.81-2.788 0L6.289 9.857a1.073 1.073 0 010-1.465l.084-.089a.953.953 0 011.395 0l3.535 3.715a.953.953 0 001.394 0l3.535-3.715z' />
          </svg>
        </StyledButton>
      </StyledButtonWrapper>
      <StyledGradient />
    </StyledExpanderWrapper>
  )
}
