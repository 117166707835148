import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GlobalState } from 'index'
import { removeUndoAction } from 'features/undoAction/undoActionSlice'

export const useUndoAction: any = () => {
  const dispatch = useDispatch()
  const undoAction = useSelector((state: GlobalState) => state.undoAction)

  const removeHandler = useCallback(
    (id) => {
      dispatch(removeUndoAction(id))
    },
    [dispatch]
  )

  return [undoAction[1], removeHandler]
}
