/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'

import { Button, Select, Module } from 'components'
import { useGetLoanPersonalData } from 'features/loan/loanSelectors'
import { IPersonalData } from 'features/loan/types'
import { fetchPersonalData, updatePersonalData } from 'features/loan/extra'
import {
  InputField,
  calculateAge,
  declOfNum,
  isValidForm,
  normalizeValues,
  timeZoneMask,
  validationSchemaPersonal,
} from 'helpers'

import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'
import { maritalStatus } from 'helpers/dictionary'

import { ReactComponent as CloseSVG } from 'assets/close.svg'
import { StyledSelectBlock, StyledResetFilter, StyledButtonBlock } from './Modules.styles'
import { IModuleProps } from './types'
import { GlobalState } from 'index'
import { useLocation } from 'react-router-dom'

const moduleId = 'personal'
export const PersonalData: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  claimsResolve,
  userId,
}): JSX.Element => {
  const dispatch = useDispatch()
  const [editable, setEditable] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<IPersonalData | unknown>({})
  const passportData = useSelector((store: GlobalState) => store.singleLoan.passportData)
  const location = useLocation()

  // Проверяем, содержит ли адресная строка '/agent/'
  const isAgentPage = location.pathname.includes('/agent/')

  const data = useGetLoanPersonalData()
  const isOpen = openedModules.includes(moduleId)

  useEffect(() => {
    if (isOpen && userId && data.isFetching) dispatch(fetchPersonalData({ id: userId }))
  }, [isOpen, userId])

  useEffect(() => {
    if (data && !editable) {
      setInitialValues(data)
    }
  }, [data, editable])

  const handleChangeEditable = (): void => setEditable(!editable)

  const handleCancel = (resetForm) => (): void => {
    resetForm()
    setEditable(!editable)
  }
  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = async (values: any): Promise<void> => {
    setInitialValues(values)

    // required
    const body = {
      email: values.email,
    }
    // optional
    if (values.login && !isAgentPage) Object.assign(body, { login: values.login })
    if (values.time_zone) Object.assign(body, { time_zone: values.time_zone })
    if (values.marital_status) Object.assign(body, { marital_status: values.marital_status })

    dispatch(
      updatePersonalData({
        id: userId,
        body,
        values,
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Данные не сохранены`,
            })
          )
        },
        onSuccess: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: `Данные сохранены`,
            })
          )
          dispatch(fetchPersonalData({ id: userId }))
          setEditable(false)
        },
      })
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaPersonal}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Персональные данные</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              {claimsResolve && <Module.Button handleChangeEditable={handleChangeEditable} />}
              <>
                <Module.Content>
                  {data.isFetching && <Module.LoaderBlock />}
                  {editable ? (
                    <Edit {...formikProps} isAgentPage={isAgentPage} />
                  ) : (
                    <View {...formikProps} passportData={passportData} />
                  )}
                </Module.Content>
              </>
            </Module.ContentWrapper>
            <StyledButtonBlock>
              {editable && (
                <div style={{ margin: '32px 0' }}>
                  <Button
                    disabled={isValidForm(errors) || data.isUpdating || !dirty}
                    type='standardBig'
                    onClick={() => dirty && handleSubmit()}
                    pending={data.isUpdating}
                  >
                    Сохранить
                  </Button>
                  <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                    Отменить
                  </Button>
                </div>
              )}
            </StyledButtonBlock>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue, isAgentPage } = props

  const StatusesList = Object.keys(maritalStatus).map((value) => ({
    label: maritalStatus[value],
    value,
  }))

  return (
    <>
      {!isAgentPage && (
        <Module.Column column={1}>
          <InputField
            name='login'
            onChange={setFieldValue}
            placeholder='Номер телефона'
            value={values.login}
            {...props}
          />
        </Module.Column>
      )}
      <Module.Column column={1}>
        <InputField
          name='time_zone'
          onChange={setFieldValue}
          placeholder='Временная зона'
          value={values.time_zone}
          mask={timeZoneMask}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='email'
          onChange={setFieldValue}
          placeholder='Email'
          value={values.email}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <StyledSelectBlock>
          <Select
            data={StatusesList}
            placeholder='Семейный статус'
            value={StatusesList?.find((v) => v.value === values.marital_status) || ''}
            onChange={(v) => setFieldValue('marital_status', v.value)}
          />
          {values.marital_status && (
            <StyledResetFilter onClick={() => setFieldValue('marital_status', '')}>
              <CloseSVG />
            </StyledResetFilter>
          )}
        </StyledSelectBlock>
      </Module.Column>
    </>
  )
}

function checkAge(date_of_birth, targetAges) {
  if (!date_of_birth) return false
  const dob = new Date(date_of_birth)
  const now = new Date()

  let age = now.getFullYear() - dob.getFullYear()

  if (
    now.getMonth() < dob.getMonth() ||
    (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())
  ) {
    age--
  }

  return targetAges.includes(age)
}

const View = (props): JSX.Element => {
  const { values, passportData } = props
  const db = passportData?.date_of_birth
  return (
    <>
      <>
        <Module.Column column={1}>
          <Module.Title>Номер телефона</Module.Title>
          <Module.Value>{normalizeValues(values.login)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Дата регистрации</Module.Title>
          <Module.Value>{normalizeValues(values.date_register, 'date')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Временная зона</Module.Title>
          <Module.Value>{normalizeValues(values.time_zone)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Семейный статус</Module.Title>
          <Module.Value>{normalizeValues(values.marital_status, 'marital_status')}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Email</Module.Title>
          <Module.Value>{normalizeValues(values.email)}</Module.Value>
        </Module.Column>
        <Module.Column column={1}>
          <Module.Title>Дата рождения</Module.Title>
          <Module.Value alarm={checkAge(db, [20, 45])}>
            <div>
              {passportData && normalizeValues(db, 'date')} ({calculateAge(db)}{' '}
              {declOfNum(+calculateAge(db), ['год', 'года', 'лет'])})
            </div>
          </Module.Value>
        </Module.Column>
      </>
    </>
  )
}
