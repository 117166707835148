/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { SdlBlock } from './SdlBlock'
import { STRUCTURE } from '../structure'
import { StyledButtonsBlock } from './Modules.styles'
import { Button } from 'components'
import { fetchSdlAvailabilityData } from 'features/sdl/extra'
import { useEffect } from 'react'

export const Step1 = ({ props }): JSX.Element => {
  const {
    setSteps,
    userId,
    documents,
    setDocuments,
    setShowWindow,
    showWindow,
    additionalSelectionStep,
  } = props
  const dispatch = useDispatch()
  const handleClickClear = (): void => setDocuments({})

  const nextStep = (step): void => {
    const successAction = (): void => setSteps(step)
    if (step === 2) dispatch(fetchSdlAvailabilityData({ userId, successAction }))
    if (step === 2.1) setSteps(2.1)
  }

  const handleClickNext = (): void => {
    setShowWindow({
      ...showWindow,
      show: false,
    })
    let checkAdditionalStep = false
    if (!isEmpty(additionalSelectionStep)) {
      Object.keys(additionalSelectionStep).forEach((el) => {
        if (!isEmpty(additionalSelectionStep[el]?.data)) checkAdditionalStep = true
      })
    }
    checkAdditionalStep ? nextStep(2.1) : nextStep(2)
  }

  const isActive = (): boolean => {
    let condition = false
    for (const key in documents) {
      const check = Object.values(documents[key]).some((item) => {
        if (item) return true
        return false
      })
      if (check) condition = true
    }
    return condition
  }

  return (
    <>
      {STRUCTURE.map((item, index) => {
        const name = Object.keys(item).join()
        return <SdlBlock {...props} key={index} name={name} item={item[name]} />
      })}
      <StyledButtonsBlock>
        <Button
          type='standardBig'
          disabled={!isActive()}
          onClick={isActive() ? handleClickNext : null}
          style={{ marginRight: '20px' }}
        >
          Продолжить
        </Button>
        <Button
          type='bigSuccess'
          disabled={!isActive()}
          onClick={isActive() ? handleClickClear : null}
        >
          Очистить все
        </Button>
      </StyledButtonsBlock>
    </>
  )
}
