/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { GlobalState } from 'index'
import { Pti, PtiBRS } from 'features/loan/types'
import { Module } from 'components'
import { normalizeValues } from 'helpers'
import { StyledPtiContent } from './Modules.styles'

const moduleId = 'deptLoanBRS'

export const DeptLoanBRS: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const { ptiBRSData } = useSelector((state: GlobalState) => state.singleLoan)
  const [data, setData] = useState<PtiBRS[]>([])

  const isOpen = openedModules.includes(moduleId)

  useEffect(() => {
    if (ptiBRSData.data.length > 0) setData(ptiBRSData.data)
  }, [ptiBRSData])

  const handleExpand = (): void => onToggleModule(moduleId)

  return (
    <Module.Wrapper isOpen={isOpen} editable={false} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            Долговая нагрузка БРС
            {!data.length ? (
              <span style={{ color: ptiBRSData.fetching ? '#2333cb' : 'gray', opacity: '0.5' }}>
                / {ptiBRSData.fetching ? 'запрашивается' : 'отсутствует'}
              </span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <StyledPtiContent>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <Module.Column column={1}>
                <Module.Title>Номер займа</Module.Title>
                <Module.Value>{normalizeValues(item.loan_number)}</Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Дата расчёта</Module.Title>
                <Module.Value>{normalizeValues(item.calc_date, 'full_date')}</Module.Value>
              </Module.Column>
              <Module.Column column={1}>
                <Module.Title>Значение </Module.Title>
                <Module.Value>{normalizeValues(item.pdn * 100)} %</Module.Value>
              </Module.Column>
            </React.Fragment>
          ))}
        </StyledPtiContent>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
