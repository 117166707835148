/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosPromise } from 'axios'

const URL: string =
  (window as any)?.env?.REACT_APP_EXTERNAL_SCORING_URL ||
  process.env.REACT_APP_EXTERNAL_SCORING_URL ||
  ''

export const fetchExternalScoringResult = (loanId: string): AxiosPromise =>
  axios.get(`${URL}/external-scoring/result?loan_id=${loanId}`).then((response) => response)
