import { InputField } from 'helpers/form'
import { validationSchemaAuth } from 'helpers'
import { Formik } from 'formik'
import { Button } from 'components'
import { ReactComponent as LogoSVG } from 'assets/logo.svg'
import {
  StyledWrapper,
  StyledTitle,
  StyledContent,
  StyledButtonBlock,
  StyledAuthWrapper,
} from './Auth.styles'

export const Auth: any = ({ handleSignIn, pending }): JSX.Element => {
  const submitForm = async (values) => {
    const { username, password } = values
    handleSignIn({ username, password })
  }

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaAuth}
    >
      {(formikProps) => {
        const { errors, dirty, setFieldValue, values, handleSubmit } = formikProps

        return (
          <StyledWrapper>
            <StyledAuthWrapper>
              <LogoSVG style={{ maxWidth: '180px', height: 'auto' }} />
              <StyledTitle>Авторизация в CRM</StyledTitle>
              <StyledContent>
                <InputField
                  name='username'
                  onChange={setFieldValue}
                  placeholder='Логин'
                  value={values.username}
                  autoComplete='off'
                  {...formikProps}
                />
                <InputField
                  name='password'
                  onChange={setFieldValue}
                  placeholder='Пароль'
                  value={values.password}
                  autoComplete='off'
                  type='password'
                  {...formikProps}
                />
              </StyledContent>

              <StyledButtonBlock>
                <Button
                  disabled={pending || !dirty || (dirty && !!Object.keys(errors).length)}
                  type='standardBig'
                  onClick={handleSubmit}
                  pending={false}
                >
                  Войти
                </Button>
              </StyledButtonBlock>
            </StyledAuthWrapper>
          </StyledWrapper>
        )
      }}
    </Formik>
  )
}
