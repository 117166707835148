/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { formatDate, getAdminFio, loanStatuses, normalizeValues } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { GlobalState } from 'index'
import { CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'

import {
  StyledMobileHeaderAll,
  StyledMobileDescription,
  StyledMobileItemWrapper,
  StyledMobileLoanNumbers,
  StyledMobileSection,
  StyledMobileTopAll,
  StyledMobileValue,
  StyledMobileCheckedHead,
  StyledCreateDate,
  StyledStatusWrapper,
  StyledStatusMobile,
  StyledMobileDividerSection,
  StyledHead,
} from './Table.styles'

import { LoanStatus } from 'features/loan/types'
import { fetchAssignTheLoan, fetchLoansCount } from 'features/loans/extra'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { LoanType } from 'features/loans/types'

export const AllView: any = ({ loan, type }): JSX.Element => {
  const dictData = useSelector((state: GlobalState) => state.dict)
  const dispatch = useDispatch()
  const history = useHistory()
  const { path } = useRouteMatch()

  const handleOpenLoan = (e) => {
    e.preventDefault()
    history.push(`${path}/${loan.loans_loan_id}?closedCount=${loan.closed_count}`)
  }

  const handleClick = (e) => {
    e.stopPropagation()

    if (loan?.loan_statuses_status_name === LoanStatus.setting_a_limit) {
      dispatch(
        fetchAssignTheLoan({
          loanId: loan.loans_loan_id,
          type,
          username: loan.admin_username,
          onSuccess: () => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Поздравляем',
                description: 'Заявка успешно взята в работу',
              })
            )
            handleOpenLoan(e)
          },
        })
      )
      dispatch(fetchLoansCount())
    } else handleOpenLoan(e)
  }

  return (
    <StyledMobileItemWrapper onClick={handleOpenLoan}>
      <StyledMobileHeaderAll>
        <StyledMobileTopAll>
          <StyledStatusWrapper>
            <StyledStatusMobile status={loan.loan_statuses_status_name} />
            {loanStatuses[loan.loan_statuses_status_name]}
          </StyledStatusWrapper>
        </StyledMobileTopAll>
      </StyledMobileHeaderAll>
      <br />

      <StyledMobileSection>
        <StyledMobileSection>
          <StyledMobileCheckedHead>
            <StyledHead>
              <div>
                <StyledMobileDescription>Номер заявки/Дата создания</StyledMobileDescription>
                <StyledMobileLoanNumbers>
                  {normalizeValues(loan.loans_loan_number)}
                </StyledMobileLoanNumbers>
              </div>
              <StyledCreateDate>{formatDate(loan?.loans_loan_date_create)}</StyledCreateDate>
            </StyledHead>
          </StyledMobileCheckedHead>
        </StyledMobileSection>
        <StyledMobileSection>
          <div>
            <StyledMobileDescription>ФИО клиента</StyledMobileDescription>
            <StyledMobileValue>
              {loan?.passport_last_name} {loan?.passport_first_name} {loan?.passport_middle_name}
            </StyledMobileValue>
          </div>
          <br />
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Номер телефона</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.users_login)}</StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Регион</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.residence_address)}</StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>

        <StyledMobileSection>
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Сумма займа, ₽</StyledMobileDescription>
              <StyledMobileValue>
                {normalizeValues(loan.loans_loan_ask_sum, 'sum_without_symbol')}
              </StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Кол-во закрытых</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.closed_count)}</StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>
        <StyledMobileSection>
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Тип продукта</StyledMobileDescription>
              <StyledMobileValue>
                {CreditProductTranslate[CreditProductCodes[loan?.loans_product_id]]}
              </StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Андеррайтер</StyledMobileDescription>

              <StyledMobileValue>
                {!loan.admin_username?.length ? (
                  <div onClick={handleClick}>
                    <b style={{ color: '#24C665' }}>Взять в работу</b>
                  </div>
                ) : (
                  <div>
                    {Array.isArray(loan.admin_username)
                      ? getAdminFio(loan.admin_username[0], dictData)
                      : getAdminFio(loan.admin_username, dictData)}
                  </div>
                )}
              </StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>
      </StyledMobileSection>
    </StyledMobileItemWrapper>
  )
}

export const InWorkView: any = ({ loan }): JSX.Element => {
  const dictData = useSelector((state: GlobalState) => state.dict)
  const dispatch = useDispatch()

  const handleClick = () => {
    if (loan?.loan_statuses_status_name === LoanStatus.setting_a_limit) {
      dispatch(
        fetchAssignTheLoan({
          loanId: loan.loans_loan_id,
          type: LoanType.unassigned,
          username: loan.admin_username[0],
          onSuccess: () =>
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Поздравляем',
                description: 'Заявка успешно взята в работу',
              })
            ),
        })
      )
      dispatch(fetchLoansCount())
    }
  }

  return (
    <StyledMobileItemWrapper>
      <StyledMobileHeaderAll>
        <StyledMobileTopAll>
          <StyledStatusWrapper>
            <StyledStatusMobile status={loan.loan_statuses_status_name} />
            {loanStatuses[loan.loan_statuses_status_name]}
          </StyledStatusWrapper>
        </StyledMobileTopAll>
      </StyledMobileHeaderAll>
      <br />

      <StyledMobileSection>
        <StyledMobileSection>
          <StyledMobileCheckedHead>
            <StyledHead>
              <div>
                <StyledMobileDescription>Номер заявки/Дата создания</StyledMobileDescription>
                <StyledMobileLoanNumbers>
                  {normalizeValues(loan.loans_loan_number)}
                </StyledMobileLoanNumbers>
              </div>
              <StyledCreateDate>{formatDate(loan?.loans_loan_date_create)}</StyledCreateDate>
            </StyledHead>
          </StyledMobileCheckedHead>
        </StyledMobileSection>
        <StyledMobileSection>
          <div>
            <StyledMobileDescription>ФИО клиента</StyledMobileDescription>
            <StyledMobileValue>
              {loan?.passport_last_name} {loan?.passport_first_name} {loan?.passport_middle_name}
            </StyledMobileValue>
          </div>
          <br />
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Номер телефона</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.users_login)}</StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Регион</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.residence_address)}</StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>

        <StyledMobileSection>
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Сумма займа, ₽</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.loans_loan_ask_sum)}</StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Кол-во закрытых</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.closed_count)}</StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>
        <StyledMobileSection>
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Тип продукта</StyledMobileDescription>
              <StyledMobileValue>
                {CreditProductTranslate[CreditProductCodes[loan?.loans_product_id]]}
              </StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Андеррайтер</StyledMobileDescription>

              <StyledMobileValue>
                {!loan.admin_username?.length ? (
                  <div onClick={handleClick}>
                    <b style={{ color: '#24C665' }}>Взять в работу</b>
                  </div>
                ) : (
                  <div>{normalizeValues(getAdminFio(loan.admin_username[0], dictData))})</div>
                )}
              </StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>
      </StyledMobileSection>
    </StyledMobileItemWrapper>
  )
}

export const FreeView: any = ({ loan }): JSX.Element => {
  const dictData = useSelector((state: GlobalState) => state.dict)
  const dispatch = useDispatch()

  const handleClick = () => {
    if (loan?.loan_statuses_status_name === LoanStatus.setting_a_limit) {
      dispatch(
        fetchAssignTheLoan({
          loanId: loan.loans_loan_id,
          type: LoanType.unassigned,
          username: loan.admin_username[0],
          onSuccess: () =>
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Поздравляем',
                description: 'Заявка успешно взята в работу',
              })
            ),
        })
      )
      dispatch(fetchLoansCount())
    }
  }

  return (
    <StyledMobileItemWrapper>
      <StyledMobileHeaderAll>
        <StyledMobileTopAll>
          <StyledStatusWrapper>
            <StyledStatusMobile status={loan.loan_statuses_status_name} />
            {loanStatuses[loan.loan_statuses_status_name]}
          </StyledStatusWrapper>
        </StyledMobileTopAll>
      </StyledMobileHeaderAll>
      <br />

      <StyledMobileSection>
        <StyledMobileSection>
          <StyledMobileCheckedHead>
            <StyledHead>
              <div>
                <StyledMobileDescription>Номер заявки/Дата создания</StyledMobileDescription>
                <StyledMobileLoanNumbers>
                  {normalizeValues(loan.loans_loan_number)}
                </StyledMobileLoanNumbers>
              </div>
              <StyledCreateDate>{formatDate(loan?.loans_loan_date_create)}</StyledCreateDate>
            </StyledHead>
          </StyledMobileCheckedHead>
        </StyledMobileSection>
        <StyledMobileSection>
          <div>
            <StyledMobileDescription>ФИО клиента</StyledMobileDescription>
            <StyledMobileValue>
              {loan?.passport_last_name} {loan?.passport_first_name} {loan?.passport_middle_name}
            </StyledMobileValue>
          </div>
          <br />
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Номер телефона</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.users_login)}</StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Регион</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.residence_address)}</StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>

        <StyledMobileSection>
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Сумма займа, ₽</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.loans_loan_ask_sum)}</StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Кол-во закрытых</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(loan.closed_count)}</StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>
        <StyledMobileSection>
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Тип продукта</StyledMobileDescription>
              <StyledMobileValue>
                {CreditProductTranslate[CreditProductCodes[loan?.loans_product_id]]}
              </StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Андеррайтер</StyledMobileDescription>

              <StyledMobileValue>
                {!loan.admin_username?.length ? (
                  <div onClick={handleClick}>
                    <b style={{ color: '#24C665' }}>Взять в работу</b>
                  </div>
                ) : (
                  <div>{normalizeValues(getAdminFio(loan.admin_username[0], dictData))})</div>
                )}
              </StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>
      </StyledMobileSection>
    </StyledMobileItemWrapper>
  )
}
