import styled from 'styled-components/macro'
import { THEME } from '../../core/theme'

export const StyledHeaderWrapper = styled.div`
  background-color: ${THEME.COLORS.SMOOTH};
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 20px 35px;

  @media (max-width: 480px) {
    padding: 8px;
    div {
      display: none;
    }
  }
`

export const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 15px;
    cursor: pointer;
  }
`

export const StyledHeaderUserActions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  /* gap: 34px; */
  align-items: center;

  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledItem = styled.div`
  cursor: pointer;
`

export const StyledTitle = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: ${THEME.COLORS.BLACK};

  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 25px;
  }
`

export const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 16px;
  align-items: center;
  flex-shrink: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  color: #fff;
  background-color: #738be1;
`

export const StyledHeaderStatus = styled.div`
  border: 1px solid #cfced8;
  box-sizing: border-box;
  border-radius: 19px;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 7px 12px 4px 12px;
  margin-left: 12px;
  color: #73717e;
`
export const StyledCreateLoan = styled.div`
  background: #ffffff;
  border-radius: 50px;
  padding: 10px 15px;
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
`
export const StyledCreateLoanMobileView = styled.div`
  background: #ffffff;
  border-radius: 50px;
  padding: 10px 15px;
  display: none;
  gap: 6px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 480px) {
    display: flex;
    margin-left: auto;
    font-size: 14px;
    padding: 5px 10px;
    line-height: 15px;

    & svg {
      width: 40px;
      height: 30px;
    }
  }
`
