/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { GlobalState } from 'index'

import { STRUCTURE } from './structure'

import { scrollHorizontallyToCenter } from 'helpers'

import {
  StyledControlsWrapper,
  StyledTitle,
  StyledApplications,
  StyledFilters,
  StyledRequest,
  StyledValue,
  StyledItemWrapper,
  StyledFiltersValue,
  StyledContent,
} from './Controls.styles'

import { ReactComponent as FilterSVG } from 'assets/filter.svg'
import { ReactComponent as SearchSVG } from 'assets/search.svg'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const Controls: any = ({
  filtersVisible = true,
  toggleFilters,
  toggleSearch,
  type,
  setType,
  pageControlsStructure,
}): JSX.Element => {
  const q = useQuery()
  const tabsRef = useRef(null)
  const loans = useSelector((store: GlobalState) => store.loans)
  const agentLoans = useSelector((store: GlobalState) => store.agentLoans)
  const clients = useSelector((store: GlobalState) => store.clients)
  const contracts = useSelector((store: GlobalState) => store.contracts)
  const collectorLoans = useSelector((store: GlobalState) => store.collector)
  const filters = useSelector((store: GlobalState) => store.filters.data)

  const queryType = q.get('type')

  const { ...collectorFilters } = useSelector((store: GlobalState) => store.collectorFilters.data)
  const { loan_status, credit_product, ...agentFilters } = useSelector(
    (store: GlobalState) => store.agentFilters.data
  )
  const { loan_status: contractStatusFilter, ...contractFilters } = useSelector(
    (store: GlobalState) => store.contractFilters.data
  )

  const underwriterFiltersCount = Object.keys(filters).filter(
    (v) => filters[v] && v !== 'search'
  ).length

  const collectorsFiltersCount = Object.keys(collectorFilters).filter((v) => v !== 'search').length

  const agentFiltersCount = Object.keys(agentFilters).filter(
    (v) => v !== 'search' && v !== 'product'
  ).length

  const contractsFiltersCount = Object.keys(contractFilters).filter(
    (v) => v !== 'search' && v !== 'product'
  ).length

  const setTypeHandler = (currentType) => (e) => {
    setType(currentType)
    scrollHorizontallyToCenter(tabsRef.current, e.target)
  }

  let filtersCountResult
  let listing

  switch (pageControlsStructure) {
    case 'contracts':
      filtersCountResult = contractsFiltersCount
      listing = contracts
      break

    case 'agent':
      filtersCountResult = agentFiltersCount
      listing = agentLoans
      break

    case 'underwriter':
      filtersCountResult = underwriterFiltersCount
      listing = loans
      break

    case 'collector':
      filtersCountResult = collectorsFiltersCount
      listing = collectorLoans
      break

    case 'operator':
      filtersCountResult = underwriterFiltersCount
      listing = loans
      break

    case 'waiting':
      listing = agentLoans
      break

    case 'clients':
      listing = clients
      break

    default:
      break
  }

  return (
    <StyledControlsWrapper>
      <StyledApplications ref={tabsRef}>
        {STRUCTURE[pageControlsStructure].map((item) => (
          <StyledRequest
            key={item.id}
            onClick={setTypeHandler(item.value)}
            active={type === item.value}
          >
            <StyledTitle>{item.title}</StyledTitle>
            <StyledValue>{listing.totalAmount[item.value] || 0}</StyledValue>
          </StyledRequest>
        ))}
      </StyledApplications>
      <StyledFilters>
        {filtersVisible && (
          <StyledItemWrapper onClick={toggleFilters}>
            <FilterSVG />
            <StyledContent>Фильтры</StyledContent>
            {filtersCountResult ? (
              <StyledFiltersValue>{filtersCountResult}</StyledFiltersValue>
            ) : null}
          </StyledItemWrapper>
        )}
        {toggleSearch && (
          <StyledItemWrapper onClick={toggleSearch}>
            <SearchSVG />
            <StyledContent>Поиск</StyledContent>
          </StyledItemWrapper>
        )}
      </StyledFilters>
    </StyledControlsWrapper>
  )
}
