import { ReactNode } from 'react'

export interface INavMenu {
  opened: boolean
  setOpened: any
}

interface ISubMenuData {
  title: string
  id: string
  url: string
  name: string
}

export interface ISubMenuStyled {
  opened: boolean
  isExpanded: boolean
}

export interface ISubMenu {
  totalCounts?: any
  activeList: boolean
  opened: boolean
  isExpanded: boolean
  mobileView: boolean
  parentUrl: string
  data: ISubMenuData[]
  list: string
  setOpened: any
}

export interface IStyledSidebarItem {
  active: boolean
  opened: boolean
}

export interface ISidebarProps {
  children?: ReactNode
}

export interface IMenuItems {
  id: string
  title: string
  url: string
  name: string
  data: MenuItem[]
}

interface MenuItem {
  title: string
  id: string
  url: string
  name: string
}

export enum TabsType {
  menu = 'menu',
  notification = 'notification',
  profile = 'profile',
}
