import { THEME } from 'core/theme'
import styled from 'styled-components/macro'
import { StyledRowWrapper } from './Table/Table.styles'

export const StyledPageWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  overflow: hidden;
`

export const StyledTableWrapper = styled.div`
  width: 100%;

  height: calc(100vh - 208px);
  .infinite-scroll-component {
    overflow: inherit !important;
  }

  @media (max-width: 480px) {
    background: ${THEME.COLORS.SMOOTH};
    padding-top: 25px;

    .infinite-scroll-component__outerdiv {
      background: ${THEME.COLORS.SMOOTH};
      padding-bottom: 100px;
    }
  }
`
//
export const StaledContainer = styled.div`
  &:nth-child(even) {
    ${StyledRowWrapper} {
      background: #ebeeff3c;
    }
  }
  @media (max-width: 480px) {
    background: ${THEME.COLORS.SMOOTH};
    &:nth-child(even) {
      ${StyledRowWrapper} {
        background: ${THEME.COLORS.WHITE};
      }
    }
  }
`

export const StyledLoaderPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 1111111;
  pointer-events: none;
`

export const StyledEmptyLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`
