/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'

import {
  fetchAssignTheLoan,
  fetchAssignTheLoanFromDetail,
  fetchCollectorLoans,
  fetchCollectorLoansCount,
  fetchCollectorLoanUserPassportData,
  fetchCollectorLoanUserPersonalData,
  fetchLoanPageById,
  fetchPhonesData,
  fetchUnAssignTheLoan,
  fetchUnAssignTheLoanFromDetail,
  fetchUserLoans,
  fetchLoanDebt,
  fetchLoanTransactions,
  fetchCollectorTasks,
  addCollectorTasks,
} from './extra'
import { ICollectorSlice, ILoanData } from './types'

const initialState: ICollectorSlice = {
  totalAmount: {
    pending: false,
    error: false,
    free: 0,
    inWork: 0,
    all: 0,
  },
  all: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  inWork: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  free: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  passportData: null,
  personalData: null,
  loanData: null,
  userData: null,
  phonesData: null,
  userLoans: null,
  isFetchingDept: false,
  dataDept: [],
  isFetchingTransactions: false,
  dataTransactions: [],
  isFetchingAssignInDetail: false,
  collectorUserName: null,
  tasks: [],
  isFetchAddTask: false,
}

export const collectorLoansSlice = createSlice({
  name: 'collectorLoans',
  initialState,
  reducers: {
    resetCollectorLoans: (state, { payload }) =>
      payload
        ? {
            ...state,
            totalAmount: state.totalAmount,
            [payload]: initialState[payload],
          }
        : {
            ...initialState,
            totalAmount: state.totalAmount,
          },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectorLoans.pending, (state, payload: any) => {
        const {
          meta: {
            arg: { type },
          },
        } = payload
        state[type].pending = true
      })
      .addCase(fetchCollectorLoans.rejected, (state, payload: any) => {
        const {
          meta: {
            arg: { type },
          },
        } = payload
        state[type].error = true
        state[type].pending = false
        state[type].data = {}
      })
      .addCase(fetchCollectorLoans.fulfilled, (state, { payload }: PayloadAction<any>) => {
        const { data, type, iteration, refresh } = payload
        state[type].pending = false
        const normalizeData = keyBy(data.data, 'loans_loan_id')
        state[type].data = !refresh
          ? {
              ...(state[type].data || {}),
              ...normalizeData,
            }
          : normalizeData
        state[type].totalCount = data.total
        state[type].finish = !data.count
        state[type].iteration = iteration || state[type].iteration + 1
      })
      .addCase(fetchCollectorLoansCount.pending, (state) => {
        state.totalAmount.pending = true
      })
      .addCase(fetchCollectorLoansCount.rejected, (state) => {
        state.totalAmount.error = true
        state.totalAmount.pending = false
      })
      .addCase(fetchCollectorLoansCount.fulfilled, (state, { payload }: any) => {
        const { totalCount, inWorkCount, freeCount } = payload

        state.totalAmount = {
          all: totalCount,
          inWork: inWorkCount,
          free: freeCount,
          pending: false,
        }
      })
      .addCase(fetchLoanPageById.pending, () => {})
      .addCase(fetchLoanPageById.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loanData = payload.loan_data
        state.userData = payload.user_data
        state.collectorUserName = payload.collector_username
      })
      .addCase(fetchCollectorLoanUserPassportData.pending, () => {})
      .addCase(fetchCollectorLoanUserPassportData.rejected, () => {})
      .addCase(fetchCollectorLoanUserPassportData.fulfilled, (state, { payload }) => {
        state.passportData = payload?.data?.passport
      })
      .addCase(fetchCollectorLoanUserPersonalData.pending, () => {})
      .addCase(fetchCollectorLoanUserPersonalData.rejected, () => {})
      .addCase(fetchCollectorLoanUserPersonalData.fulfilled, (state, { payload }) => {
        state.personalData = payload?.data
      })
      .addCase(fetchPhonesData.fulfilled, (state, { payload }) => {
        state.phonesData = payload
      })
      .addCase(fetchUserLoans.fulfilled, (state, { payload }: PayloadAction<Array<ILoanData>>) => {
        state.userLoans = payload
      })

      .addCase(fetchAssignTheLoan.pending, (state, payload: any) => {
        const {
          meta: {
            arg: { type, loanId },
          },
        } = payload
        state[type].data[loanId] = {
          ...state[type].data[loanId],
          pending: true,
        }
      })
      .addCase(fetchAssignTheLoan.rejected, (state, payload) => {
        const {
          meta: {
            arg: { type, loanId },
          },
        } = payload
        state[type].data[loanId] = {
          ...state[type].data[loanId],
          pending: false,
        }
      })
      .addCase(fetchAssignTheLoan.fulfilled, (state, { payload }) => {
        const { type, loanId, assign_time, username } = payload

        state[type].data[loanId] = {
          ...state[type].data[loanId],
          collector_username: username?.username,
          collector_added: assign_time,
          pending: false,
        }
      })

      .addCase(fetchAssignTheLoanFromDetail.pending, (state, payload: any) => {
        state.isFetchingAssignInDetail = true
      })
      .addCase(fetchAssignTheLoanFromDetail.rejected, (state, payload) => {
        state.isFetchingAssignInDetail = false
      })
      .addCase(fetchAssignTheLoanFromDetail.fulfilled, (state, { payload }) => {
        const { collectorUserName } = payload

        state.isFetchingAssignInDetail = false
        state.collectorUserName = collectorUserName
      })
      .addCase(fetchUnAssignTheLoanFromDetail.pending, (state, payload: any) => {
        state.isFetchingAssignInDetail = true
      })
      .addCase(fetchUnAssignTheLoanFromDetail.rejected, (state, payload) => {
        state.isFetchingAssignInDetail = false
      })
      .addCase(fetchUnAssignTheLoanFromDetail.fulfilled, (state, { payload }) => {
        state.isFetchingAssignInDetail = false
        state.collectorUserName = ''
      })
      .addCase(fetchUnAssignTheLoan.pending, (state, payload: any) => {
        const {
          meta: {
            arg: { type, loanId },
          },
        } = payload
        state[type].data[loanId] = {
          ...state[type].data[loanId],
          pending: true,
        }
      })
      .addCase(fetchUnAssignTheLoan.rejected, (state, payload) => {
        const {
          meta: {
            arg: { type, loanId },
          },
        } = payload
        state[type].data[loanId] = {
          ...state[type].data[loanId],
          pending: false,
        }
      })
      .addCase(fetchUnAssignTheLoan.fulfilled, (state, { payload }) => {
        const { type, loanId } = payload
        state[type].data[loanId] = {
          ...state[type].data[loanId],
          collector_username: '',
          collector_added: '',
          pending: false,
        }
      })

      .addCase(fetchLoanDebt.pending, (state) => {
        state.isFetchingDept = true
      })
      .addCase(fetchLoanDebt.rejected, (state) => {
        state.isFetchingDept = false
      })
      .addCase(fetchLoanDebt.fulfilled, (state, { payload }) => {
        state.isFetchingDept = false
        state.dataDept = payload.data
      })
      .addCase(fetchLoanTransactions.pending, (state) => {
        state.isFetchingDept = true
      })
      .addCase(fetchLoanTransactions.rejected, (state) => {
        state.isFetchingDept = false
      })
      .addCase(fetchLoanTransactions.fulfilled, (state, { payload }) => {
        state.isFetchingDept = false
        state.dataTransactions = payload.data
      })
      .addCase(fetchCollectorTasks.fulfilled, (state, { payload }) => {
        state.tasks = payload.data
      })
      .addCase(addCollectorTasks.pending, (state) => {
        state.isFetchAddTask = true
      })
      .addCase(addCollectorTasks.rejected, (state) => {
        state.isFetchAddTask = false
      })
      .addCase(addCollectorTasks.fulfilled, (state) => {
        state.isFetchAddTask = false
      })
  },
})

export const { resetCollectorLoans } = collectorLoansSlice.actions

export default collectorLoansSlice.reducer
