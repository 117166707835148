/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import styled, { css } from 'styled-components/macro'
import { THEME } from '../../../core/theme'

const STYLED_OPTIONS = {
  boldGreen: {
    color: THEME.COLORS.SUCCESS,
    fontSize: '16px',
    fontWeight: 'bold',
  },
}
export const StyledPassportWrapper = styled.div`
  display: flex;
`

export const StyledLoansDebtWrapper = styled.div``

export const StyledLoanTransactionContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
`

export const StyledItemTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #73717e;
  text-align: center;
  margin: 8px;
`

export const StyledDescription = styled.p<{ styles?: string }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #08003a;
  text-align: center;
  margin: 8px;

  ${({ styles }) =>
    styles &&
    css`
      ${STYLED_OPTIONS[styles]}
    `}
`

export const StyledButtons = styled.div`
  display: flex;
  margin-top: 15px;
`

export const StyledButtonBlock = styled.div`
  margin: 0;
  padding: 0 32px;
  transition: 0.3s;
`
export const StyledSelectBlock = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  //margin-bottom: 20px;
`
export const StyledResetFilter = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: white;
  cursor: pointer;
`
export const StyledTaskButtonBlock = styled.div`
  margin: 30px;
`

export const StyledCommentTitle = styled.p`
  margin: 30px 0;
  font-weight: bold;
`

export const StyledHistoryColumnWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: ${THEME.COLORS.TABLE_HOVER};
  }
`
export const StyledCommentsBlock = styled.div`
  padding: 0 0 30px 30px;
`

export const StyledCreateTaskBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: 50px;
`

export const StyledLoanHistoryContent = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
`
export const StyledOverflowWrapper = styled.div`
  overflow: visible;

  @media (max-width: 480px) {
    overflow: auto;
  }
`

export const StyledTasksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
`

export const StyledImageDescription = styled.div`
  padding-bottom: 8px;
  margin-bottom: 5px;
  margin-top: 10px;

  &:not(:first-child) {
    padding-top: 20px;
  }
`

export const StyledPassportPhotoWrapper = styled.div`
  width: 50%;
  border-right: 1px solid #dfe0eb;
  margin-right: 20px;

  @media (max-width: 480px) {
    width: 100%;
    border-right: 1px solid transparent;
    margin-right: 0;
  }
`

export const StyledPersonalPhotoWrapper = styled.div`
  width: 50%;
  @media (max-width: 480px) {
    width: 100%;
  }
`

export const StyledPassportPhotoItem = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    display: block;
  }
`

export const StyledHeadTitle = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding: 15px 0;

  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 15px;
  }
`

export const StyledLimitWrapper = styled.div`
  padding: 0 32px;
`

export const StyledLimitTitle = styled(StyledHeadTitle)`
  margin: 32px 0;
`

export const StyledLimitButtons = styled.div`
  display: flex;
  margin-top: 15px;
  gap: 15px;
`

export const StyledTextArea = styled.textarea<any>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  width: 100%;
  max-width: 640px;
  height: 120px;
  resize: none;

  &:focus {
    outline: none;
  }
`
