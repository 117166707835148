import { THEME } from 'core/theme'
import styled, { css } from 'styled-components/macro'
import { StyledTableWrapper } from '../ContractPage.styles'

export const StyledFiltersContent = styled.div`
  max-height: 0px;
  overflow: scroll;
  transition: all 0.2s linear;
`

export const StyledFiltersBlock = styled.div<{ showFilters: boolean }>`
  visibility: hidden;
  overflow-y: auto;

  ${({ showFilters }) =>
    showFilters &&
    css`
      visibility: visible;
      height: 100%;

      ${StyledFiltersContent} {
        max-height: 800px;
        overflow: auto;

        @media (max-width: 480px) {
          padding-bottom: 300px;
        }
      }
      ~ #scrollable ${StyledTableWrapper} {
        height: calc(100vh - 509px);
      }
    `}
`

export const StyledFiltersWrapper = styled.div`
  width: 100%;
  padding: 0 35px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  grid-gap: 13px;
  margin-top: 24px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`

export const StyledActionsBlock = styled.div`
  border-bottom: 1px solid #dfe0eb;
  display: flex;
  padding: 30px 35px;
  height: 90px;

  @media (max-width: 480px) {
    flex-wrap: wrap;
    height: 100%;
  }
`

export const StyledAction = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 30px;

  @media (max-width: 480px) {
    margin-bottom: 25px;
  }
`

export const StyledTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${THEME.COLORS.DARK_BLUE};
  margin-left: 5px;
`

export const StyledSelectBlock = styled.div`
  position: relative;
`

export const StyledResetFilter = styled.div`
  position: absolute;
  right: 20px;
  /* z-index: 900; */
  bottom: 20px;
  background: white;
  cursor: pointer;
`

export const StyledAmnestyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px;
`
