/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */

import { Module, CheckBox } from 'components'
import { StyledWrapper, StyledTitle, StyledValue } from './Modules.styles'

export const SdlBlock: any = ({
  openedModules,
  onToggleModule,
  name,
  list,
  item: items,
  documents,
  setDocuments,
  setShowWindow,
  additionalSelectionStep,
  setAdditionalSubSelectionStep,
  ...props
}): JSX.Element => {
  const isOpen = openedModules.includes(name)
  const handleExpand = (): void => onToggleModule(name)

  const checkAdditionalSelectionStep = (name, title, subSelection, isActive): void => {
    if (subSelection) {
      if (isActive) {
        setAdditionalSubSelectionStep({ ...additionalSelectionStep, [name]: false })
      } else {
        setAdditionalSubSelectionStep({
          ...additionalSelectionStep,
          [name]: { data: subSelection, title },
        })
      }
    }
  }

  const handleChangeDocuments = (val): void => {
    if (documents[name] && documents[name][val]) {
      setDocuments({ ...documents, [name]: { ...documents[name], [val]: !documents[name][val] } })
    } else {
      setDocuments({ ...documents, [name]: { ...documents[name], [val]: true } })
    }
  }

  const title = list.filter((item) => item.anchor === name)[0]?.title

  return (
    <Module.Wrapper isOpen={isOpen} editable={false} id={name}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>{title}</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <StyledWrapper>
          {items.map((item) => {
            const isActive = documents[name] ? documents[name][item.value] : false
            return (
              <StyledValue key={item.value}>
                <CheckBox
                  label=''
                  active={isActive}
                  onChange={(val) => {
                    handleChangeDocuments(val)
                    checkAdditionalSelectionStep(val, item.title, item?.subSelection, isActive)
                  }}
                  value={item.value}
                />
                <StyledTitle>
                  <span>{item.value.toUpperCase()}</span> - {}
                  {item.title}
                </StyledTitle>
                {/* <StyledActionButton active={isActive} onClick={() => setShowWindow(true)}>
                  Открыть письмо
                </StyledActionButton> */}
              </StyledValue>
            )
          })}
        </StyledWrapper>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
