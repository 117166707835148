export const MODAL_STYLE = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#ffffff',
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    border: 'none',
    width: '90%',
    height: '90%',
    maxWidth: '1400px',
    overflow: 'initial',
    zIndex: '999',
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.411)',
    // backdropFilter: 'blur(3px)',
  },
}

export const MODAL_STYLE_LOANS = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#ffffff',
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    border: 'none',
    width: '90%',
    height: '50%',
    maxWidth: '1600px',
    overflow: 'initial',
    zIndex: '999',
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.411)',
  },
}
export const MODAL_STYLE_CONFIRM = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#ffffff',
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    border: 'none',
    width: '500px',
    maxWidth: '500px',
    overflow: 'initial',
    zIndex: '999',
    borderRadius: '24px',
    padding: '40px',
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.411)',
  },
}
export const MODAL_MOBILE_STYLE_CONFIRM = {
  content: {
    top: '50%',
    left: '46%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#ffffff',
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    border: 'none',
    width: '80%',
    maxWidth: '500px',
    overflow: 'initial',
    zIndex: '999',
    borderRadius: '24px',
    padding: '15px',
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.411)',
    // backdropFilter: 'blur(3px)',
  },
}
