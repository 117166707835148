import styled from 'styled-components/macro'
import { ILayoutProps } from './types'

export const StyledLayoutWrapper = styled.div<ILayoutProps>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;
  overflow: hidden;

  @media (max-width: 480px) {
    grid-template-rows: 0 1fr;
  }
`
