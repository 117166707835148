/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

export class ProjectClientsService extends ServiceBase {
  public static getClients(): AxiosPromise {
    return this.get(`agent_api/installment/agent_users`)
  }
}
