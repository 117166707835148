import { Input } from 'components'
import styled from 'styled-components/macro'

export const StyledPageWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  overflow: hidden;
`
export const StyledButtonBlock = styled.div`
  margin: 0;
  padding: 0 32px;
  transition: 0.3s;
`
export const StyledInputWrapper = styled.div`
  margin: 32px 32px;
`
export const StyledInput = styled(Input)`
  background: #f1f0f5;
  box-sizing: border-box;
  border-radius: 16px;

  border: 1px solid #cfced8;
  border-radius: 16px;
`
export const StyledErrorLabel = styled.div`
  margin-top: 15px;
  font-weight: bold;
  color: #ff601a;
  margin-bottom: -22px;
`
