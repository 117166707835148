/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'

import {
  InputField,
  DatePickerField,
  divisionCodeMask,
  normalizeDateForApi,
  numberPassportCodeMask,
  serialPassportCodeMask,
  validationSchemaPassport,
} from 'helpers'

import {
  StyledInfoTitle,
  StyledInfoContent,
  StyledInfoDescTitle,
  StyledInfoDescContent,
  StyledButtonBlockModal,
  StyledInfoContentWrapper,
  StyledHr,
  StyledPassportVerificationBlock,
  StyledPassportVerificationTitle,
} from '../Modal.styles'

import { Button } from 'components/Button'
import { IPassportDataUpdate } from 'features/loan/types'
import {
  changeVerifyPassportData,
  checkVerifyPassportData,
  updatePassportData,
} from 'features/loan/extra'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { CheckBox } from 'components'
import { GlobalState } from 'index'

export const PassportData = (props): JSX.Element => {
  const { isEditable, isOpenItem, handleOpen, passport, userId, claimsResolve } = props
  const dispatch = useDispatch()
  const { verifyPassportData, loadData } = useSelector((store: GlobalState) => store.singleLoan)

  function isValidForm(errors: unknown): boolean {
    return !!Object.keys(errors).length
  }

  const handleSubmitPassport = (resetForm, values) => {
    const {
      first_name,
      last_name,
      middle_name,
      gender,
      date_of_birth,
      place_of_birth,
      serial,
      number,
      date_of_issue,
      place_of_issue,
      code_division,
    } = values

    const data: IPassportDataUpdate = {
      first_name,
      last_name,
      middle_name,
      gender,
      date_of_birth: normalizeDateForApi(date_of_birth),
      place_of_birth,
      serial,
      number,
      date_of_issue: normalizeDateForApi(date_of_issue),
      place_of_issue,
      code_division,
    }
    dispatch(
      updatePassportData({
        id: userId,
        body: data,
        values: data,
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Данные не сохранены`,
            })
          )
        },
        onSuccess: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: `Данные сохранены`,
            })
          )
        },
      })
    )
    resetForm()
  }

  const handleChangePassportVerification = (value) => {
    dispatch(
      changeVerifyPassportData({
        loanId: loadData?.loan_id,
        status: !value,
        onSuccess: () => dispatch(checkVerifyPassportData({ loanId: loadData?.loan_id })),
      })
    )
  }

  return (
    <>
      <StyledInfoContentWrapper
        isOpen={isOpenItem.id === 'passport'}
        onClick={handleOpen({ id: 'passport' })}
      >
        <Formik
          initialValues={passport}
          enableReinitialize
          onSubmit={() => null}
          validationSchema={validationSchemaPassport}
        >
          {(formikProps) => {
            const { errors, dirty, resetForm, setFieldValue, values } = formikProps

            return (
              <div>
                <StyledInfoTitle>Паспорт</StyledInfoTitle>
                {isEditable && claimsResolve ? (
                  <>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <InputField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='last_name'
                        onChange={setFieldValue}
                        placeholder='Фамилия'
                        value={values?.last_name}
                        noMove
                        {...formikProps}
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <InputField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='first_name'
                        onChange={setFieldValue}
                        placeholder='Имя'
                        value={values?.first_name}
                        noMove
                        {...formikProps}
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <InputField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='middle_name'
                        onChange={setFieldValue}
                        placeholder='Отчество'
                        value={values?.middle_name}
                        noMove
                        {...formikProps}
                      />
                    </StyledInfoContent>

                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <InputField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='serial'
                        onChange={setFieldValue}
                        placeholder='Серия'
                        value={values?.serial}
                        noMove
                        mask={serialPassportCodeMask}
                        {...formikProps}
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <InputField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='number'
                        onChange={setFieldValue}
                        placeholder='Номер'
                        value={values?.number}
                        noMove
                        mask={numberPassportCodeMask}
                        {...formikProps}
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <InputField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='place_of_issue'
                        onChange={setFieldValue}
                        placeholder='Место выдачи'
                        value={values?.place_of_issue}
                        noMove
                        {...formikProps}
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <DatePickerField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='date_of_issue'
                        onChange={setFieldValue}
                        placeholder='Дата выдачи'
                        value={values?.date_of_issue}
                        closeBtn={false}
                        format='dd.MM.yyyy'
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <InputField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='code_division'
                        onChange={setFieldValue}
                        placeholder='Код подразделения'
                        value={values?.code_division}
                        mask={divisionCodeMask}
                        noMove
                        {...formikProps}
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <DatePickerField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='date_of_birth'
                        onChange={setFieldValue}
                        placeholder='Дата рождения'
                        value={values?.date_of_birth}
                        closeBtn={false}
                        format='dd.MM.yyyy'
                        noMove
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <InputField
                        style={{ minHeight: '50px', height: '50px' }}
                        name='place_of_birth'
                        onChange={setFieldValue}
                        placeholder='Место рождения'
                        value={values?.place_of_birth}
                        noMove
                        {...formikProps}
                      />
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <StyledPassportVerificationBlock>
                        <StyledPassportVerificationTitle>
                          Альтернативная проверка
                        </StyledPassportVerificationTitle>
                        <CheckBox
                          active={verifyPassportData}
                          onChange={(value) => handleChangePassportVerification(value)}
                          value={verifyPassportData}
                          label={verifyPassportData ? 'Да' : 'Нет'}
                        />
                      </StyledPassportVerificationBlock>
                    </StyledInfoContent>
                  </>
                ) : (
                  <>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Фамилия</StyledInfoDescTitle>
                        <StyledInfoDescContent>{passport?.last_name}</StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Имя</StyledInfoDescTitle>
                        <StyledInfoDescContent>{passport?.first_name}</StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Отчество</StyledInfoDescTitle>
                        <StyledInfoDescContent>{passport?.middle_name}</StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Серия паспорта</StyledInfoDescTitle>
                        <StyledInfoDescContent>{passport?.serial}</StyledInfoDescContent>
                      </>
                    </StyledInfoContent>

                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Номер паспорта</StyledInfoDescTitle>
                        <StyledInfoDescContent>{passport?.number}</StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Место выдачи</StyledInfoDescTitle>
                        <StyledInfoDescContent>{passport?.place_of_issue}</StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Дата выдачи</StyledInfoDescTitle>
                        <StyledInfoDescContent>
                          {passport?.date_of_issue
                            ? moment(passport?.date_of_issue).format('DD-MM-YYYY')
                            : ''}
                        </StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Код подразделения</StyledInfoDescTitle>
                        <StyledInfoDescContent>{passport?.code_division}</StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable}>
                      <>
                        <StyledInfoDescTitle>Дата рождения</StyledInfoDescTitle>
                        <StyledInfoDescContent>
                          {passport?.date_of_birth
                            ? moment(passport?.date_of_birth).format('DD-MM-YYYY')
                            : ''}
                        </StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    <StyledInfoContent edit={isEditable && claimsResolve}>
                      <>
                        <StyledInfoDescTitle>Место рождения</StyledInfoDescTitle>
                        <StyledInfoDescContent>{passport?.place_of_birth}</StyledInfoDescContent>
                      </>
                    </StyledInfoContent>
                    {claimsResolve && (
                      <StyledInfoContent edit={isEditable && claimsResolve}>
                        <>
                          <StyledInfoDescTitle>Альтернативная проверка</StyledInfoDescTitle>
                          <StyledInfoDescContent>
                            <CheckBox
                              disabled
                              active={verifyPassportData}
                              onChange={null}
                              value={verifyPassportData}
                              label={verifyPassportData ? 'Да' : 'Нет'}
                            />
                          </StyledInfoDescContent>
                        </>
                      </StyledInfoContent>
                    )}
                  </>
                )}

                <StyledInfoContent>
                  <StyledButtonBlockModal>
                    {dirty && (
                      <>
                        <Button
                          type='smallSuccess'
                          onClick={() =>
                            isValidForm(errors) ? null : handleSubmitPassport(resetForm, values)
                          }
                          // pending={passportData.isUpdating}
                          disabled={isValidForm(errors)}
                        >
                          Сохранить
                        </Button>
                        <Button type='emptySmall' onClick={resetForm}>
                          Отменить
                        </Button>
                      </>
                    )}
                  </StyledButtonBlockModal>
                  <br />
                </StyledInfoContent>
                <StyledHr />
              </div>
            )
          }}
        </Formik>
      </StyledInfoContentWrapper>
    </>
  )
}
