/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'

const initialState: any = [0, [], []]

export const dictSlice = createSlice({
  name: 'undoAction',
  initialState,
  reducers: {
    addUndoAction: (state, { payload }) => {
      const id = state[0] + 1
      return [id, [...state[1], { ...payload, id }], [...state[2], payload?.id]]
    },
    removeUndoAction: (state, { payload }) => {
      const tmp = state[1].filter((undoAction) => undoAction.id !== payload)
      return [state[0], tmp.length ? tmp : [], state[2]]
    },
    removeUndoIdAction: (state, { payload }) => {
      const ids = [...state[2]]
      const idx = findIndex(ids, (v) => v === payload)
      ids.splice(idx, 1)
      return [state[0], state[1], ids]
    },
  },
})

export const { addUndoAction, removeUndoAction, removeUndoIdAction } = dictSlice.actions

export default dictSlice.reducer
