/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { uniqBy, keyBy, find } from 'lodash'
import { useState, useEffect, Fragment } from 'react'
import { Button, CheckBox, Module } from 'components'

import {
  StyledWrapper,
  StyledSection,
  StyledCheckTitle,
  StyledWarningTitle,
  StyledTitle,
  StyledValue,
  StyledCaptchaBlock,
  StyledInput,
  StyledCaptchaValue,
  StyledCaptchaSum,
  StyledCaptchaFilterDrop,
  StyledButtonsBlock,
} from './Modules.styles'

export const Step3 = ({ props }): JSX.Element => {
  const { setSteps, additionalRequirements } = props
  const [answer, setAnswer] = useState('')
  const [checkDataRequirements, setCheckDataRequirements] = useState({})

  const [captcha, setCaptcha] = useState({
    sum: null,
    x: null,
    y: null,
  })

  useEffect(() => {
    if (additionalRequirements.length) {
      setCheckDataRequirements(
        keyBy(
          additionalRequirements.map((i) => ({
            ...i,
            data: i.data.map((d) => ({ value: d, active: false })),
          })),
          'value'
        )
      )
    }
  }, [additionalRequirements])

  const handleChange = (val: boolean, item: string): void => {
    const replacedData = [...checkDataRequirements[item].data]
    const changedItem = find(replacedData, { value: val })
    changedItem.active = !changedItem.active

    setCheckDataRequirements({
      ...checkDataRequirements,
      [item]: {
        ...checkDataRequirements[item],
        data: [...replacedData],
      },
    })
  }

  const handleSetAnswer = (e): void => setAnswer(e.target.value)
  const handleClickNext = (): void => setSteps(4)

  useEffect(() => {
    const randomIntFromInterval = (min, max): number =>
      Math.floor(Math.random() * (max - min + 1) + min)
    const x = randomIntFromInterval(1, 10)
    const y = randomIntFromInterval(1, 10)
    const sum = x + y
    setCaptcha({
      sum,
      x,
      y,
    })
  }, [])

  const isActive = (): boolean => captcha.sum === Number(answer)

  const isAllChecked = (): boolean => {
    let condition = false

    for (const item of Object.keys(checkDataRequirements)) {
      if (checkDataRequirements[item].requredAll) {
        condition = checkDataRequirements[item].data.every((x) => x.active === true)
        if (!condition) break
      } else {
        condition = checkDataRequirements[item].data.some((x) => x.active === true)
        if (!condition) break
      }
    }
    return condition
  }

  return (
    <StyledWrapper>
      {/* <StyledSection>
        <Module.HeadTitle>T09 — T36 Другие требования</Module.HeadTitle>
        <StyledCheckTitle>
          Требование о возврате начислений по договору займа (дополнительные услуги оформлялись,
          имеется не погашенный заём, принято решение оплату по доп. услугам засчитать в счет
          погашения текущего займа, абзацы по погашенным займам вставляются по их количеству) (12
          рабочих дней)
        </StyledCheckTitle>
      </StyledSection> */}
      <StyledWarningTitle>Обязательные действия</StyledWarningTitle>

      {Object.keys(checkDataRequirements).map((item, index) => {
        return (
          <Fragment key={index}>
            <StyledSection>
              {checkDataRequirements[item].data.map((i) => {
                return (
                  <StyledValue key={i.value}>
                    <CheckBox
                      label=''
                      active={
                        checkDataRequirements[item].data[
                          checkDataRequirements[item].data.findIndex((f) => f.value === i.value)
                        ].active
                      }
                      onChange={(val) => handleChange(val, item)}
                      value={i.value}
                    />
                    <StyledTitle>{i.value}</StyledTitle>
                  </StyledValue>
                )
              })}
            </StyledSection>
          </Fragment>
        )
      })}

      <Module.HeadTitle>Решите пример</Module.HeadTitle>
      <StyledCaptchaBlock>
        <StyledCaptchaValue>
          <StyledCaptchaFilterDrop />
          <StyledCaptchaSum>{captcha.x}</StyledCaptchaSum>
          <StyledCaptchaSum>+</StyledCaptchaSum>
          <StyledCaptchaSum>{captcha.y}</StyledCaptchaSum>
        </StyledCaptchaValue>
        <StyledInput
          name='last_name'
          onChange={handleSetAnswer}
          placeholder='Ответ'
          value={answer}
        />
      </StyledCaptchaBlock>
      <StyledButtonsBlock>
        <Button
          type='standardBig'
          onClick={isActive() ? handleClickNext : null}
          disabled={!isActive() || !isAllChecked()}
        >
          Продолжить
        </Button>
      </StyledButtonsBlock>
    </StyledWrapper>
  )
}
