import styled from 'styled-components/macro'

export const StyledWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: auto 1fr;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    /* grid-template-rows: 62px 1fr;
    grid-template-columns: 1fr; */
  }
`
