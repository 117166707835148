import styled, { css } from 'styled-components/macro'
import { THEME } from '../../core/theme'

interface IStyledLoaderWrapper {
  button?: boolean
}

export const StyledLoaderWrapper = styled.div<IStyledLoaderWrapper>`
  display: flex;
  align-items: center;

  ${({ button }) =>
    button &&
    css`
      position: absolute;
      top: -6px;
      left: 48px;
      ${StyledLoaderRing} {
        width: 10px;
        height: 10px;

        & div {
          width: 17px;
          height: 17px;
          border-color: ${THEME.COLORS.SMOOTH} transparent transparent transparent;
        }
      }
    `}
`

export const StyledLoaderRing = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    margin: 8px;
    border: 3px solid ${THEME.COLORS.LIGHTLY_GRAY};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${THEME.COLORS.LIGHTLY_GRAY} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
