import styled, { css } from 'styled-components/macro'
import { THEME } from '../../../core/theme/theme'
import { ISubMenuStyled, IStyledSidebarItem } from '../types'

interface IStyledSubMenuItemTitle {
  active: boolean
}

export const StyledSidebarItem = styled.div<IStyledSidebarItem>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(250px, 1fr);

  & a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${THEME.COLORS.WHITE};
    opacity: 0.6;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-out;

    &:hover {
      opacity: 1;
    }

    & svg {
      width: 24px;
      margin-right: 10px;
    }
  }

  ${({ active }) =>
    active &&
    css`
      & a {
        opacity: 1;
      }
    `}

  ${({ opened }) =>
    opened &&
    css`
      & a {
        ${StyledTitle} {
          opacity: 0;
          visibility: hidden;
          transition: visibility 0s, opacity 0.5s linear;
        }
      }
    `}

  @media (max-width: 480px) {
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #6a6868;
  }
`

export const StyledTitle = styled.p`
  position: absolute;
  left: 30px;
  white-space: nowrap;

  @media (max-width: 480px) {
    font-size: 24px;
  }
`

export const StyledSubMenuItemWrapper = styled.div<ISubMenuStyled>`
  display: flex;
  flex-direction: column;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s;

  ${({ opened }) =>
    opened &&
    css`
      ${StyledSubMenuItemTitle} {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s, opacity 0.5s linear;
      }
    `};

  ${({ isExpanded, opened }) =>
    isExpanded &&
    !opened &&
    css`
      max-height: 500px;
    `};

  @media (max-width: 480px) {
    max-height: 100%;
  }
`

export const StyledSubMenuItemTitle = styled.div<IStyledSubMenuItemTitle>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${THEME.COLORS.WHITE};
  padding: 4px 30px;
  border-radius: 12px;
  margin-bottom: 5px;
  cursor: pointer;
  display: grid;
  position: relative;
  display: flex;

  &:hover {
    color: ${THEME.COLORS.DARK_BLUE};
    background: #f8cc46;
  }

  & span {
    margin-left: 5px;
    font-size: 11px;
    font-weight: bold;
    position: relative;
    top: -3px;
  }

  ${({ active }) =>
    active &&
    css`
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        background: #ffffff;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
      }
      color: ${THEME.COLORS.DARK_BLUE};
      background: #f8cc46;
      font-weight: bold;
    `}

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 5px;
    white-space: nowrap;
    padding: 12px 30px;
    margin: 7px 0;
  }
`
