/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components/macro'

export const StyledSelectWrapper = styled.div`
  position: relative;
`

export const StyledSelectPlaceholder = styled.p`
  position: absolute;
  pointer-events: none;
  left: 18px;
  top: 5px;
  transition: 0.2s ease all;
  z-index: 1;
  font-size: 12px;
  line-height: 20px;
  color: #808080;
`
