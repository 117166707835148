/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

import { GlobalState } from 'index'
import { Button, Module } from 'components'

import { StyledTableWrapper, StyledTableRow, StyledTableData, StyledError } from '../Modules.styles'
import {
  StyledButtonsBlockWrapper,
  StyledPDLLimitWrapper,
  StyledPDLLimitTitle,
  StyledTableBlockWrapper,
  StyledFirstTableWrapper,
  StyledSecondTableWrapper,
  StyledTitleWrapper,
  StyledLoader,
  StyledLoaderWrapper,
  StyledModuleWrapper,
  StyledFirstBlock,
  StyledSecondBlock,
  StyledTop,
  StyledSecondTop,
} from './PDNLimitCalculate.styles'

const CREDIT_INFO_EXTERNAL_AUTH_TOKEN = 'Bearer 46|rr2hQtjaXppajONiFdKChlpZESYNVbf5UkDvG9kz625d7617'
const PDL_CALCULATE_LIMIT_API_URL_V2 = 'https://alf.tech.papazaim.com/api/credit-info/get-pti-array'
const SUM_TO_FETCH_ADDITIONAL_DATA = 50000

export const PDNLimitCalculate: React.FC<{
  showRefetch?: boolean
  showRecalculate?: boolean
  storeData?: 'contract' | 'singleLoan'
}> = memo(({ showRefetch = false, showRecalculate = false, storeData = 'singleLoan' }) => {
  const { loan_number, loan_ask_sum } = useSelector(
    (store: GlobalState) => store.singleLoan.loadData
  )
  const loanData = useSelector((store: GlobalState) => store.contracts.loanData)

  const [data, setData] = useState<any>(null)
  const [secondData, setSecondData] = useState<any>(null)
  const [thirdData, setThirdData] = useState<any>(null)
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingSecondStair, setIsLoadingSecondStair] = useState<boolean>(true)
  const [isLoadingThirdStair, setIsLoadingThirdStair] = useState<boolean>(true)
  const [hasFetched, setHasFetched] = useState<boolean>(false)

  const loanStore = {
    singleLoan: {
      loan_number,
      loan_ask_sum,
    },
    contract: {
      loan_number: loanData?.loan_number,
      loan_ask_sum: loanData?.loan_ask_sum,
    },
  }

  const fetchData = async (
    stairType = '',
    setState,
    setIsLoadingFunction,
    additionalParams = ''
  ) => {
    setIsLoadingFunction(true)
    let url = `${PDL_CALCULATE_LIMIT_API_URL_V2}/${loanStore[storeData].loan_number}?loan_sum=${loanStore[storeData].loan_ask_sum}${additionalParams}`
    if (stairType === 'second_stair') {
      url = `${PDL_CALCULATE_LIMIT_API_URL_V2}/${loanStore[storeData].loan_number}?loan_sum=${
        loanStore[storeData].loan_ask_sum / 2
      }${additionalParams}`
    } else if (stairType === 'third_stair') {
      url = `${PDL_CALCULATE_LIMIT_API_URL_V2}/${loanStore[storeData].loan_number}?loan_sum=${
        loanStore[storeData].loan_ask_sum / 2
      }&second_stair=true${additionalParams}`
    }

    try {
      const results = await axios
        .get(url, { headers: { Authorization: CREDIT_INFO_EXTERNAL_AUTH_TOKEN } })
        .then((response) => response.data)
      setState(results)
      setError('')
    } catch (err) {
      setError(`Ошибка ${err.message}`)
    } finally {
      setIsLoadingFunction(false)
    }
  }

  const refetchAll = (param) => {
    fetchData('', setData, setIsLoading, param)
    fetchData('second_stair', setSecondData, setIsLoadingSecondStair, param)
    fetchData('third_stair', setThirdData, setIsLoadingThirdStair, param)
  }

  useEffect(() => {
    if (hasFetched || !loanStore[storeData].loan_number) return
    fetchData('', setData, setIsLoading)
    setHasFetched(true)
  }, [loanStore[storeData].loan_number])

  useEffect(() => {
    if (
      loanStore[storeData].loan_ask_sum >= SUM_TO_FETCH_ADDITIONAL_DATA &&
      loanStore[storeData].loan_number
    ) {
      fetchData('second_stair', setSecondData, setIsLoadingSecondStair)
      fetchData('third_stair', setThirdData, setIsLoadingThirdStair)
    }
  }, [loanStore[storeData].loan_number, loanStore[storeData].loan_ask_sum])

  const showLoader =
    loanStore[storeData].loan_ask_sum >= SUM_TO_FETCH_ADDITIONAL_DATA
      ? isLoading || isLoadingSecondStair || isLoadingThirdStair
      : isLoading

  return (
    <StyledPDLLimitWrapper>
      {error && <StyledError>Ошибка: {error}</StyledError>}
      {showLoader ? (
        <StyledLoaderWrapper>
          <StyledPDLLimitTitle>Загрузка расчитаного ПДН при выставлении лимита</StyledPDLLimitTitle>
          <StyledLoader>
            <Module.LoaderBlock />
          </StyledLoader>
        </StyledLoaderWrapper>
      ) : (
        <StyledModuleWrapper>
          <StyledFirstBlock>
            <StyledTop>
              <StyledTitleWrapper>
                <StyledPDLLimitTitle>Рассчитанный ПДН при выставлении лимита:</StyledPDLLimitTitle>
              </StyledTitleWrapper>
              <StyledButtonsBlockWrapper>
                {showRecalculate && (
                  <Button
                    type='smallSuccess'
                    onClick={() => refetchAll('&local_force=true')}
                    disabled={showLoader}
                    pending={showLoader}
                  >
                    Пересчитать
                  </Button>
                )}
                {showRefetch && (
                  <Button
                    type='smallDanger'
                    onClick={() => refetchAll('&super_force=true')}
                    disabled={showLoader}
                    pending={showLoader}
                  >
                    Перезапросить
                  </Button>
                )}
              </StyledButtonsBlockWrapper>
            </StyledTop>

            {data && (
              <StyledTableBlockWrapper>
                <StyledFirstTableWrapper>
                  <PDNTable data={data} />
                </StyledFirstTableWrapper>
              </StyledTableBlockWrapper>
            )}
          </StyledFirstBlock>
          <>
            {loan_ask_sum >= SUM_TO_FETCH_ADDITIONAL_DATA && (
              <StyledSecondBlock>
                <StyledSecondTop>
                  <StyledPDLLimitTitle>
                    Рассчитанный ПДН при дроблении до {loan_ask_sum / 2}:
                  </StyledPDLLimitTitle>
                </StyledSecondTop>

                {secondData && thirdData && (
                  <StyledTableBlockWrapper>
                    <>
                      {secondData && (
                        <StyledSecondTableWrapper>
                          <PDNTable data={secondData} />
                        </StyledSecondTableWrapper>
                      )}
                      {thirdData && <PDNTable data={thirdData} />}
                    </>
                  </StyledTableBlockWrapper>
                )}
              </StyledSecondBlock>
            )}
          </>
        </StyledModuleWrapper>
      )}
    </StyledPDLLimitWrapper>
  )
})

const PDNTable = ({ data }) => {
  return (
    <>
      <StyledTableWrapper>
        {data.map((item, index) => (
          <StyledTableRow key={index}>
            <StyledTableData isSum>{`${item.income.toLocaleString()} (${
              item?.other_sources_income?.toLocaleString() || '0'
            })`}</StyledTableData>
            <StyledTableData>{`${item?.PDN || '%'}`}</StyledTableData>
          </StyledTableRow>
        ))}
      </StyledTableWrapper>
    </>
  )
}
