/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchAuth, getUserInfo } from './extra'

const initialState = { user: null, pending: false, error: false, success: false }

export const loanSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.pending = payload
    },
    resetFetchingStatus: (state) => {
      state.pending = false
      state.error = false
      state.success = false
    },
    onLogout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.pending, (state) => {
        state.pending = true
      })
      .addCase(fetchAuth.rejected, (state) => {
        state.error = true
        state.pending = false
      })
      .addCase(fetchAuth.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pending = false
        state.user = payload?.data
        state.success = true
      })
      .addCase(getUserInfo.pending, (state) => {
        state.pending = true
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.error = true
        state.pending = false
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.pending = false
        state.user = payload?.data
        state.success = true
      })
  },
})

export const { resetFetchingStatus, onLogout } = loanSlice.actions

export default loanSlice.reducer
