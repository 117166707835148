/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import {
  fetchSdl,
  fetchSdlUserPassportData,
  fetchSdlUserPersonalData,
  fetchSdlAvailabilityData,
  fetchSdlAtype,
  fetchSdlStype,
  fetchSdlBtype,
  fetchLetterContent,
  fetchSdlRtype,
  fetchSdlTtype,
} from './extra'

const initialState: any = {
  listing: [],
  isFetching: false,
  passportData: null,
  personalData: null,
  docsData: null,
  stepIsFetching: false,
  availabilityData: {},
  errorSend: false,
  reportData: {
    typeA: { isFetching: false, data: null },
    typeB: { isFetching: false, data: null },
    typeS: { isFetching: false, data: null },
    typeR: { isFetching: false, data: null },
    typeT: { isFetching: false, data: null },
  },
  letterContent: { isFetching: false, content: null },
}

export const sdlSlice = createSlice({
  name: 'sdl',
  initialState,
  reducers: {
    setStepIsFetching: (state, { payload }) => {
      state.stepIsFetching = payload
    },
    clearReportData: (state) => {
      state.reportData = initialState.reportData
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSdl.pending, (state) => {
        state.isFetching = true
      })
      .addCase(fetchSdl.rejected, (state) => {
        state.isFetching = false
      })
      .addCase(fetchSdl.fulfilled, (state, { payload }) => {
        state.listing = payload.data
        state.isFetching = false
      })
      .addCase(fetchSdlUserPassportData.pending, () => {})
      .addCase(fetchSdlUserPassportData.rejected, () => {})
      .addCase(fetchSdlUserPassportData.fulfilled, (state, { payload }) => {
        state.passportData = payload?.data?.passport
        state.docsData = payload?.data?.other_docs
      })
      .addCase(fetchSdlUserPersonalData.pending, () => {})
      .addCase(fetchSdlUserPersonalData.rejected, () => {})
      .addCase(fetchSdlUserPersonalData.fulfilled, (state, { payload }) => {
        state.personalData = payload?.data
      })
      .addCase(fetchSdlAvailabilityData.pending, (state) => {
        state.stepIsFetching = true
      })
      .addCase(fetchSdlAvailabilityData.rejected, (state) => {
        state.stepIsFetching = false
      })
      .addCase(fetchSdlAvailabilityData.fulfilled, (state, { payload }) => {
        state.availabilityData = payload
        state.stepIsFetching = false
      })
      .addCase(fetchSdlAtype.pending, (state) => {})
      .addCase(fetchSdlAtype.rejected, (state) => {
        state.letterContent = { isFetching: false, content: null }
        state.reportData.typeA.data = {}
        state.errorSend = true
      })
      .addCase(fetchSdlAtype.fulfilled, (state, { payload }) => {
        state.reportData.typeA.data = payload
      })

      .addCase(fetchSdlRtype.pending, (state) => {})
      .addCase(fetchSdlRtype.rejected, (state) => {
        state.letterContent = { isFetching: false, content: null }
        state.reportData.typeA.data = {}
        state.errorSend = true
      })
      .addCase(fetchSdlRtype.fulfilled, (state, { payload }) => {
        state.reportData.typeA.data = payload
      })

      .addCase(fetchSdlTtype.pending, (state) => {})
      .addCase(fetchSdlTtype.rejected, (state) => {
        state.letterContent = { isFetching: false, content: null }
        state.reportData.typeT.data = {}
        state.errorSend = true
      })
      .addCase(fetchSdlTtype.fulfilled, (state, { payload }) => {
        state.reportData.typeT.data = payload
      })

      .addCase(fetchSdlBtype.pending, (state) => {})
      .addCase(fetchSdlBtype.rejected, (state) => {
        state.letterContent = { isFetching: false, content: null }
        state.reportData.typeB.data = {}
        state.errorSend = true
      })
      .addCase(fetchSdlBtype.fulfilled, (state, { payload }) => {
        state.reportData.typeB.data = payload
      })

      .addCase(fetchSdlStype.pending, (state) => {})
      .addCase(fetchSdlStype.rejected, (state) => {
        state.letterContent = { isFetching: false, content: null }
        state.reportData.typeS.data = {}
        state.errorSend = true
      })
      .addCase(fetchSdlStype.fulfilled, (state, { payload }) => {
        state.reportData.typeS.data = payload
      })

      .addCase(fetchLetterContent.pending, (state) => {
        state.letterContent.isFetching = true
      })
      .addCase(fetchLetterContent.rejected, (state) => {
        state.letterContent = { isFetching: false, content: null }
      })
      .addCase(fetchLetterContent.fulfilled, (state, { payload }) => {
        state.letterContent.isFetching = false
        state.letterContent.content = payload
      })
  },
})

export const { setStepIsFetching, clearReportData } = sdlSlice.actions
export default sdlSlice.reducer
