/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable prefer-const */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { find } from 'lodash'

import { GlobalState } from 'index'
import { Button, ModalConfirm } from 'components'
import { AGENT_LOANS_ROUTE } from 'constants/pagesRoutes'

import { LoanStatuses } from 'helpers'
import { changeStatus, fetchAgentLoanAccept, refuseLoan } from 'features/agentLoans/extra'

import {
  StyledLimitWrapper,
  StyledLimitTitle,
  StyledLimitButtons,
  StyledTextArea,
  StyledRefuseWrapper,
  StyledRefuseButtonWrapper,
  StyledRefuseDescription,
  StyledRefuseTypesWrapper,
  StyledRefuseTypeTitle,
  StyledRefuseTypeTextWrapper,
  StyledBanInfo,
} from '../Modules.styles'

import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { APPROVE_DATA, REFUSE_DATA } from './data'
import { CreditProduct } from 'constants/creditProduct'

import { IoMdWarning } from 'react-icons/io'

type DecisionType = 'approve' | 'refuse'

const commentTypes = {
  approve: APPROVE_DATA,
  refuse: REFUSE_DATA,
}

export const SetLimit: any = ({ loan_id }): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loanData, actionPending } = useSelector((store: GlobalState) => store.agentLoans)

  const [comment, setComment] = useState<string | null>(null)
  const [decision, setDecision] = useState<DecisionType>(null)
  const [optionType, setOptionType] = useState(null)
  const [refuseByClient, setRefuseByClient] = useState<boolean>(false)
  const [refuse, setRefuse] = useState<boolean>(false)
  const [pending, setPending] = useState(false)
  const [showModal, setShowModal] = useState({
    show: false,
    title: '',
    style: '',
  })

  const setTypeHandler = (type): void => setOptionType(type)

  const getTypes = () => {
    if (!decision || !loanData?.credit_product) return []

    const decisionTypes = commentTypes[decision]
    if (!decisionTypes) return []

    const data = decisionTypes[loanData.credit_product]
    return data || []
  }

  const commentWithPrefixType = refuseByClient
    ? ''
    : `${find(getTypes(), { title: optionType?.title || '' })?.value || ''} ${comment}`

  const handleSubmit = async () => {
    setPending(true)
    await dispatch(
      fetchAgentLoanAccept({
        loan_id: loanData.loan_id,
        comment: commentWithPrefixType,
        successAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: `Заявка успешно одобрена`,
            })
          )
        },
        errorAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Заявка не одобрена`,
            })
          )
        },
      })
    )
    setPending(false)
    handleCloseModal()
  }

  const handleChangeSubmit = async () => {
    setPending(true)

    await dispatch(
      changeStatus({
        loanId: loanData.loan_id,
        comment: commentWithPrefixType,
        statusName: LoanStatuses.loan_approved,
        successAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: `Заявка успешно одобрена`,
            })
          )
        },
        errorAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Заявка не одобрена`,
            })
          )
        },
      })
    )
    setPending(false)
    handleCloseModal()
  }

  const openModalApprove = (): void => {
    setDecision('approve')
    setShowModal({
      show: true,
      title:
        loanData?.credit_product === CreditProduct.installment_20_30
          ? 'Одобрить займ'
          : 'Отправить на проверку',
      style: 'success',
    })
  }

  const openModalRefuseByClient = () => {
    setRefuse(false)
    setRefuseByClient(true)
    setShowModal({
      show: true,
      title: 'Отказ клиента',
      style: 'warning',
    })
  }

  const openModalRefuse = () => {
    setRefuseByClient(false)
    setDecision('refuse')
    setRefuse(true)
    setShowModal({
      show: true,
      title: 'Отказать в займе',
      style: 'warning',
    })
  }

  const onRefuse = () => {
    if (refuse)
      dispatch(
        refuseLoan({
          id: loan_id,
          comment: commentWithPrefixType || '',
          byClient: false,
          notice: true,
          ban_user: optionType?.banUser,
          reject_code_id: optionType?.reject_code_id,
          onSuccess: () => {
            history.push(AGENT_LOANS_ROUTE)
            handleCloseModal()
          },
          onError: () => {
            handleCloseModal()
            dispatch(
              addToast({
                type: ToastTypes.warning,
                title: 'Ошибка',
                description: `Во время выполнения запроса произошла ошибка`,
              })
            )
          },
        })
      )
    else
      dispatch(
        refuseLoan({
          id: loan_id,
          comment,
          byClient: true,
          notice: false,
          ban_user: false,
          onSuccess: () => {
            history.push(AGENT_LOANS_ROUTE)
            handleCloseModal()
          },
          onError: () => {
            handleCloseModal()
            dispatch(
              addToast({
                type: ToastTypes.warning,
                title: 'Ошибка',
                description: `Во время выполнения запроса произошла ошибка, статус не обновлен`,
              })
            )
          },
        })
      )
  }

  const handleCloseModal = (): void => {
    setRefuseByClient(false)
    setRefuse(false)
    setShowModal({ show: false, title: '', style: '' })
    setDecision(null)
    setOptionType(null)
  }

  return (
    <StyledLimitWrapper id='limit'>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        title={showModal.title}
        buttons={false}
        content={() => (
          <>
            {refuseByClient ? (
              <StyledRefuseWrapper>
                <StyledRefuseTypeTextWrapper>
                  <StyledTextArea
                    placeholder='Комментарий по заявке'
                    value={comment || ''}
                    onChange={(e) => setComment(e?.target?.value)}
                  />
                </StyledRefuseTypeTextWrapper>
                <StyledRefuseButtonWrapper>
                  <Button
                    type='standardBig'
                    onClick={actionPending ? null : () => onRefuse()}
                    pending={actionPending}
                  >
                    Отказать клиенту
                  </Button>
                  <Button type='emptyBig' onClick={handleCloseModal}>
                    Отмена
                  </Button>
                </StyledRefuseButtonWrapper>
              </StyledRefuseWrapper>
            ) : (
              <StyledRefuseWrapper>
                {getTypes() && (
                  <>
                    <StyledRefuseDescription>Выберите тип:</StyledRefuseDescription>
                    <StyledRefuseTypesWrapper>
                      {(getTypes() || []).map((type, i) => (
                        <StyledRefuseTypeTitle
                          key={i}
                          onClick={() => setTypeHandler(type)}
                          isActive={type.title === optionType?.title}
                        >
                          {type.title}
                        </StyledRefuseTypeTitle>
                      ))}
                    </StyledRefuseTypesWrapper>
                  </>
                )}
                <>
                  {decision === 'refuse' && optionType && !optionType?.banUser && (
                    <StyledBanInfo>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <IoMdWarning color='white' />
                        Клиент не будет забанен
                      </span>
                    </StyledBanInfo>
                  )}
                </>
                <StyledRefuseTypeTextWrapper>
                  <StyledTextArea
                    placeholder='Комментарий по заявке'
                    value={comment || ''}
                    onChange={(e) => setComment(e?.target?.value)}
                  />
                </StyledRefuseTypeTextWrapper>
                <StyledRefuseButtonWrapper>
                  {refuse ? (
                    <Button
                      type='standardBig'
                      onClick={
                        !comment ||
                        actionPending ||
                        pending ||
                        (getTypes().length > 0 && !optionType)
                          ? null
                          : () => onRefuse()
                      }
                      disabled={
                        !comment ||
                        actionPending ||
                        pending ||
                        !optionType ||
                        (getTypes().length > 0 && !optionType)
                      }
                      pending={actionPending}
                    >
                      Отказать
                    </Button>
                  ) : (
                    <>
                      {loanData?.credit_product === CreditProduct.installment_20_30 ? (
                        <Button
                          type='bigSuccess'
                          onClick={
                            !comment || pending || actionPending ? null : () => handleChangeSubmit()
                          }
                          disabled={
                            !comment ||
                            pending ||
                            actionPending ||
                            (getTypes().length > 0 && !optionType)
                          }
                          pending={pending}
                        >
                          Одобрить заявку
                        </Button>
                      ) : (
                        <Button
                          type='bigSuccess'
                          onClick={handleSubmit}
                          disabled={
                            !comment ||
                            actionPending ||
                            pending ||
                            (getTypes().length > 0 && !optionType)
                          }
                          pending={actionPending || pending}
                        >
                          Отправить на проверку
                        </Button>
                      )}
                    </>
                  )}
                </StyledRefuseButtonWrapper>
              </StyledRefuseWrapper>
            )}
          </>
        )}
      />
      <StyledLimitTitle>
        {loanData?.credit_product === CreditProduct.installment_20_30
          ? 'Одобрение займа'
          : 'Отправить заявку на проверку'}
      </StyledLimitTitle>
      <StyledTextArea
        value={comment || ''}
        maxLength={1200}
        placeholder='Комментарий по заявке'
        onChange={(e) => setComment(e?.target?.value)}
      />
      <StyledLimitButtons>
        {loanData?.credit_product === CreditProduct.installment_20_30 ? (
          <Button
            type='bigSuccess'
            onClick={openModalApprove}
            disabled={pending || actionPending}
            pending={pending || actionPending}
          >
            Одобрить
          </Button>
        ) : (
          <Button
            type='bigSuccess'
            onClick={openModalApprove}
            disabled={pending || actionPending}
            pending={pending || actionPending}
          >
            Отправить
          </Button>
        )}

        <Button
          type='emptyBig'
          onClick={!actionPending || actionPending ? openModalRefuse : null}
          disabled={actionPending || pending}
          pending={actionPending || pending}
        >
          Отказать
        </Button>
        <Button
          type='emptyBig'
          onClick={!pending || actionPending ? openModalRefuseByClient : null}
          disabled={pending || actionPending}
          pending={pending || actionPending}
        >
          Отказ клиента
        </Button>
      </StyledLimitButtons>
    </StyledLimitWrapper>
  )
}
