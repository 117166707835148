export const MESSAGES_404 = {
  'singleLoan/loadPageById/rejected': 'Заявка не найдена',
  'loans/userLoans/rejected': 'Заявки пользователя не найдены',
  'singleLoan/updatedINN/rejected': 'ИНН не найден',
  'singleLoan/fetchExternalScoringResult/rejected': 'Скоринг балл не был запрошен',
}
export const MESSAGES_204 = {
  'singleLoan/fetchExternalScoringResult/rejected': 'Скоринг балл ещё не завершён',
}

export const EXCEPTIONS = {
  'singleLoan/NBKIHistory/rejected': true,
  'singleLoan/fetchPtiBRSData/rejected': true,
  'singleLoan/fetchPti/rejected': true,
  'singleLoan/loadAutoCheckData/rejected': true,
  'singleLoan/getComments/rejected': true,
  'singleLoan/loadAgentPhoto/rejected': true,
  'singleLoan/NBKIScoring/rejected': true,
  'singleLoan/loadBankCardData/rejected': true,
  'operator/fetchLoanDebt/rejected': true,
  'operator/fetchLoanTransactions/rejected': true,
  'collector/fetchLoanDebt/rejected': true,
  'collector/fetchLoanTransactions/rejected': true,
  'agent/loadAgentPhoto/rejected': true,
  'singleLoan/fetchLoanDebt/rejected': true,
  'singleLoan/fetchLoanTransactions/rejected': true,
  'singleLoan/fetchAdditionalContacts/rejected': true,
  'singleLoan/fetchVideoVerification/rejected': true,
  'agent/getComments/rejected': true,
  'contracts/getComments/rejected': true,
}

export const MESSAGES_403 = {
  'singleLoan/createComment/rejected': 'Действие запрещено,комментарий не сохранен',
  'singleLoan/fetchOnBan/rejected': 'Действие запрещено, пользователь не забанен',
  'singleLoan/updatePersonalData/rejected': 'Действие запрещено, данные не сохранены',
  'singleLoan/updateRegistrationData/rejected': 'Действие запрещено, данные не сохранены',
  'singleLoan/updateResidenceData/rejected': 'Действие запрещено, данные не сохранены',
  'singleLoan/updateDetailsData/rejected': 'Действие запрещено, данные не сохранены',
  'singleLoan/patchINN/rejected': 'Действие запрещено, данные не сохранены',
  'singleLoan/updateComment/rejected': 'Действие запрещено, коментарий не сохранен',
  'singleLoan/loadBankCardData/rejected': 'Действие запрещено, карта не удалена',
}

export const MESSAGES_422 = {
  'singleLoan/loadFsspData/rejected': 'Данные из ФССП запрошены не верно',
}
