import styled, { css } from 'styled-components/macro'
import { THEME } from '../../core/theme'

export const StyledTextArea = styled.textarea<any>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  height: 155px;
  resize: none;
  font-size: 18px;
  width: 100%;

  &:focus {
    outline: none;
  }
`

export const StyledSaveBtnClock = styled.div``

export const StyledEditInPassport = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledEditInPassportBtn = styled.div`
  border: 1px solid #a7a7a7;
  padding: 1px 5px;
  border-radius: 8px;
  background-color: ${THEME.COLORS.YELLOW};
  cursor: pointer;

  & svg {
    width: 25px;
    height: 25px;
  }

  & svg path {
    fill: ${THEME.COLORS.BLACK};
  }

  &:hover {
    background-color: ${THEME.COLORS.YELLOW_HOVER};
  }
`

export const StyledSaveInPassport = styled.div<{ disable: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ disable }) =>
    disable &&
    css`
      ${StyledSaveInPassportBtn} {
        cursor: not-allowed;
        opacity: 0.3;
      }
    `}

  & textarea {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const StyledPassportBtn = styled.div`
  border: 1px solid #a7a7a7;
  padding: 1px 5px;
  border-radius: 8px;
  margin-left: 12px;
  cursor: pointer;
  position: relative;
  margin-bottom: 5px;
`

export const StyledSaveInPassportBtn = styled(StyledPassportBtn)`
  background-color: ${THEME.COLORS.SUCCESS};

  & svg {
    width: 25px;
    height: 25px;
  }

  & svg path {
    fill: ${THEME.COLORS.WHITE};
  }

  &:hover {
    background-color: ${THEME.COLORS.SUCCESS_LIGHT};
  }
`

export const StyledSCloseInPassportBtn = styled(StyledPassportBtn)`
  background-color: ${THEME.COLORS.WARNING};

  & svg {
    position: relative;
    width: 25px;
    height: 25px;
    top: 2px;
  }

  & svg path {
    fill: ${THEME.COLORS.WHITE};
  }
`

export const StyledSCopyInPassportBtn = styled(StyledPassportBtn)`
  background-color: ${THEME.COLORS.BLUE};

  & svg {
    position: relative;
    width: 25px;
    height: 25px;
    top: 2px;
  }

  & svg path {
    fill: ${THEME.COLORS.WHITE};
  }
`

export const StyledInPassportTitleBlock = styled.div`
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`
export const StyledInPassportTitle = styled.div`
  margin-right: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: gray;

  & svg {
    margin-right: 3px;
    background-color: yellow;
    border-radius: 8px;
    width: 15px;
    height: 15px;
  }

  & svg path {
    fill: ${THEME.COLORS.BLACK};
  }
`

export const StyledHr = styled.div`
  height: 1px;
  border-bottom: 1px solid #dfdede;
`

export const StyledInfoDescTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #828282;
`

export const StyledInfoDescContent = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.DARK_BLUE};
  margin-bottom: 5px;
`
