/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

const loanFields = [
  'users.email',
  'loans.loan_id',
  'loans.loan_number',
  'passport.first_name',
  'passport.last_name',
  'passport.middle_name',
  'users.login',
  'passport.number',
  'passport.serial',
  'registration_address',
  'loan_statuses.status_name',
  'admin_username',
  'registration_address',
]

export class ProjectSdlService extends ServiceBase {
  protected static LOAN_PATH = 'loans'

  protected static SDL_PATH = 'sdl_api/sdl'

  public static getSdlList(filters: any): AxiosPromise {
    const data = JSON.stringify({
      fields: loanFields,
      ...filters,
    })
    return this.post(`${this.LOAN_PATH}/get`, data).then((response) => response.data)
  }

  public static getSdlAvailability(userId: string): AxiosPromise {
    return this.get(`${this.SDL_PATH}/availability?user_id=${userId}`).then(
      (response) => response.data
    )
  }

  public static getAtype(userId: string, data: string[]): AxiosPromise {
    const body = JSON.stringify({
      code: data,
    })
    return this.post(`${this.SDL_PATH}/a_type/${userId}`, body).then((response) => response.data)
  }

  public static getBtype(userId: string, data: string[]): AxiosPromise {
    const body = JSON.stringify({
      code: data,
    })
    return this.post(`${this.SDL_PATH}/b_type/${userId}`, body).then((response) => response.data)
  }

  public static getRtype(userId: string, data: string[]): AxiosPromise {
    const body = JSON.stringify({
      code: data,
    })
    return this.post(`${this.SDL_PATH}/r_type/${userId}`, body).then((response) => response.data)
  }

  public static getTtype(userId: string, data: string[]): AxiosPromise {
    const body = JSON.stringify({
      code: data,
    })
    return this.post(`${this.SDL_PATH}/t_type/${userId}`, body).then((response) => response.data)
  }

  public static getStype(userId: string): AxiosPromise {
    return this.get(`${this.SDL_PATH}/s_type/${userId}`).then((response) => response.data)
  }

  public static getLetterContent(userId: string, letterId: string): AxiosPromise {
    const body = {
      code: letterId,
    }
    return this.post(`${this.SDL_PATH}/text_msg/${userId}`, body).then((response) => response.data)
  }
}
