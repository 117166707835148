import styled, { css } from 'styled-components/macro'
import { THEME } from '../../../core/theme'
import { MIN_WIDTH } from './options'

interface IStyledTitle {
  select: boolean
  pending: boolean
}

export const StyledRowWrapper = styled.div<{ indicate?: boolean; template: string }>`
  padding: 0 35px;
  display: grid;
  grid-template-columns: ${(props) => props.template};
  column-gap: 6px;
  min-width: ${MIN_WIDTH};
  border-bottom: 1px solid ${THEME.COLORS.TABLE_BORDER};
  /* &:hover {
    background: ${THEME.COLORS.TABLE_HOVER}!important;
  } */

  ${({ indicate }) =>
    indicate &&
    css`
      background: ${THEME.COLORS.TABLE_INDURATE}!important;
    `}
`

export const StyledValue = styled.div`
  padding: 18px 5px;
  word-break: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const StyledTitle = styled.p<IStyledTitle>`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ select }) =>
    select &&
    css`
      &:hover {
        color: ${THEME.COLORS.BLUE};
      }
    `}
  ${({ pending }) =>
    pending &&
    css`
      color: rgb(8 0 58 / 38%);
      &:hover {
        color: rgb(8 0 58 / 38%);
      }
    `}

  & svg {
    margin-left: 5px;
  }
`
export const StyledActionTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledUnLockActionTable = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid ${THEME.COLORS.SUCCESS};
  padding: 5px 8px;
  border-radius: 40px;
  margin-bottom: 7px;

  & svg {
    margin-right: 10px;
  }

  &:hover {
    background: ${THEME.COLORS.SUCCESS};
    color: white;
  }
`
export const StyledUnlockActionTitle = styled.p`
  font-size: 14px;
`

export const StyledLockActionTable = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid ${THEME.COLORS.WARNING};
  padding: 5px 8px;
  border-radius: 40px;
  margin-bottom: 7px;

  & svg {
    margin-right: 10px;
  }

  &:hover {
    background: ${THEME.COLORS.WARNING};
    color: white;
  }
`
export const StyledLockActionTitle = styled.p`
  font-size: 14px;
`

export const StyledCommentsBlock = styled.div`
  padding: 0 0 30px 30px;
`
export const StyledCommentTitle = styled.p`
  margin: 30px 0;
  font-weight: bold;
`
export const StyledTextArea = styled.textarea<any>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  width: 100%;
  max-width: 640px;
  height: 120px;
  resize: none;

  &:focus {
    outline: none;
  }
`

export const StyledButtons = styled.div`
  display: flex;
  margin-top: 15px;
`
