/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { NavMenuType } from 'App'
import { CollectorType } from 'features/collector/types'

import { GlobalState } from 'index'
import { resetCollectorLoans } from 'features/collector/collectorSlice'
import { fetchCollectorLoans, fetchCollectorLoansCount } from 'features/collector/extra'

import { Controls } from '../../components/Controls'
import { Filters } from './Filters'
import { Search } from '../../components'
import { Table } from './Table'
import { StyledPageWrapper } from './CollectorList.styles'

import { COLLECTOR_LOANS_ROUTE } from '../../constants'
import { NavigationContext } from 'app/contexts'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const CollectorList = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const q = useQuery()
  const location: any = useLocation()

  const [type, setType] = useState(null)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const { setNavigation, setNavMenuType } = useContext(NavigationContext)
  const { data: filters } = useSelector((store: GlobalState) => store.collectorFilters)
  const loans = useSelector((store: GlobalState) => store.collector)
  const { username = '' } = useSelector((store: GlobalState) => store.auth?.user || {})

  useEffect(() => {
    setNavMenuType(NavMenuType.collector)
  }, [])

  useEffect(() => {
    const queryType = q.get('type')
    setType(queryType ? CollectorType[queryType] : CollectorType.all)
  }, [])

  useEffect(() => {
    if (loans[type]?.data === null && !loans[type]?.pending && username !== null) {
      let currentFilters = filters
      if (username) {
        currentFilters = { ...currentFilters }
        dispatch(
          fetchCollectorLoans({
            username,
            iteration: 1,
            type,
            filters: currentFilters,
          })
        )
      }

      dispatch(fetchCollectorLoansCount())
    }
  }, [type, filters, loans, username])

  useEffect(() => {
    if (loans[type]?.data !== null) dispatch(resetCollectorLoans(null))
  }, [filters])

  const fetchMoreData = () => {
    let currentFilters = filters
    if (username) {
      currentFilters = { ...currentFilters }
    }
    dispatch(
      fetchCollectorLoans({
        username,
        iteration: loans[type]?.iteration + 1,
        type,
        filters: currentFilters,
      })
    )
  }

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Коллектор`,
      backTo: false,
    })
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()
    if (type) {
      params.append('type', type)
      history.push({
        pathname: COLLECTOR_LOANS_ROUTE,
        search: params.toString(),
        state: { type },
      })
    }
  }, [type, history])

  useEffect(() => {
    if (location) {
      const { type: stateType } = location?.state || {}
      if (stateType) {
        setType(stateType)
      }
    }
  }, [location])

  const toggleFilters = (): void => {
    setShowFilters(!showFilters)
    setShowSearch(false)
  }

  const toggleSearch = (): void => {
    setShowSearch(!showSearch)
    setShowFilters(false)
  }

  const checkHasMore = () => !loans[type]?.finish

  const filterProps = {
    toggleFilters,
    toggleSearch,
    setShowFilters,
    showFilters,
    showSearch,
    setShowSearch,
    type,
    setType,
    pageControlsStructure: 'collector',
    actionType: 'collector',
    pending: loans[type]?.pending,
  }

  const tableProps = {
    fetchMoreData,
    checkHasMore,
    loans,
    type,
  }

  return (
    <StyledPageWrapper>
      <Controls {...filterProps} />
      <Filters {...filterProps} />
      <Search {...filterProps} pending={false} />
      <Table {...tableProps} />
    </StyledPageWrapper>
  )
}
