/* eslint-disable import/order */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useContext } from 'react'

import { NavMenuType } from 'App'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { fetchClients } from 'features/clients/extra'
import { resetClients } from 'features/clients/clientsSlice'
import { useHistory } from 'react-router-dom'
import { UNDERWRITER_LOANS_ROUTE } from 'constants/pagesRoutes'
import { Controls } from '../../components/Controls'
import { StyledPageWrapper } from './ClientsList.styles'

import { Table } from './Table'
import { NavigationContext } from 'app/contexts'

export const ClientsList = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setNavigation, isAgentRole, setNavMenuType } = useContext(NavigationContext)

  useEffect(() => {
    if (isAgentRole === false) {
      history.push(UNDERWRITER_LOANS_ROUTE)
      setNavMenuType(NavMenuType.underwriter)
    } else {
      setNavMenuType(NavMenuType.agent)
    }
  }, [isAgentRole])

  const { clients }: any = useSelector((store: GlobalState) => store.clients)

  useEffect(() => {
    dispatch(fetchClients())
  }, [])

  useEffect(() => {
    dispatch(resetClients(null))
  }, [])

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Список клиентов`,
      backTo: false,
    })
  }, [])

  const filterProps = {
    pageControlsStructure: 'clients',
    filtersVisible: false,
  }

  return (
    <StyledPageWrapper>
      <Controls {...filterProps} />
      <Table clients={clients} />
    </StyledPageWrapper>
  )
}
