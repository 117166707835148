/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PageScope } from 'pages/App/App'
import { NavMenuType } from 'App'
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { NavigationContext } from 'app/contexts'
import { Button, Tooltip, ModalConfirm } from 'components'
import { StyledTooltipMenuWrapper } from 'components/Tooltip/Tooltip.styles'
import {
  StyledHeaderWrapper,
  StyledHeaderContent,
  StyledHeaderUserActions,
  StyledTitle,
  StyledItem,
  StyledAvatar,
  StyledCreateLoan,
  StyledCreateLoanMobileView,
} from './Header.styles'

import { IHeaderProps } from './types'
// import { ReactComponent as LetterSVG } from '../../assets/letter.svg'
// import { ReactComponent as BellSVG } from '../../assets/bell.svg'
import { ReactComponent as BackToSVG } from '../../assets/back-arrow.svg'
import { ReactComponent as PlusSVG } from '../../assets/plus.svg'

interface IHeadData {
  content: string
  backTo: string
}
interface IModal {
  show: boolean
  title: string
  action?: any
  style: string
}

export const Header = (props: IHeaderProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const { handleLogout } = useContext(PageScope)
  const {
    isAgentRole,
    navMenuType,
    navigation: { headContent, backTo },
  } = useContext(NavigationContext)
  const { first_name: firstName = '', last_name: lastName = '' } = useSelector(
    (store: GlobalState) => store.auth?.user || {}
  )
  const [headData, setHeadData] = useState<IHeadData>({
    content: '',
    backTo: '',
  })
  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    title: '',
    action: null,
    style: '',
  })

  useEffect(() => {
    setHeadData({
      content: headContent,
      backTo,
    })
  }, [headContent, backTo])

  const handleClickToBack = (): void => {
    if (!backTo) return
    history.push(`${backTo}`, { from: location.pathname })
  }

  const handleClick = (): void => {
    const action = (): void => {
      handleLogout()
    }
    setShowModal({ show: true, title: 'Выйти из системы ?', action, style: 'success' })
  }

  const handleCloseModal = (): void =>
    setShowModal({ show: false, title: '', action: null, style: '' })

  const handleCreateLoan = () => {
    history.push('/agent/create-loan')
  }

  return (
    <StyledHeaderWrapper>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        title={showModal.title}
        action={showModal.action}
        style={showModal.style}
      />
      <StyledHeaderContent>
        {backTo && <BackToSVG onClick={handleClickToBack} />}
        <StyledTitle>{headData.content}</StyledTitle>
        {isAgentRole && navMenuType === NavMenuType.agent && (
          <StyledCreateLoanMobileView onClick={handleCreateLoan}>
            <PlusSVG />
            Привязать клиента
          </StyledCreateLoanMobileView>
        )}
        {/* {isAgentRole && <StyledHeaderStatus>Агент</StyledHeaderStatus>} */}
      </StyledHeaderContent>
      <StyledHeaderUserActions>
        {isAgentRole && navMenuType === NavMenuType.agent && (
          <StyledCreateLoan onClick={handleCreateLoan}>
            <PlusSVG />
            Привязать клиента
          </StyledCreateLoan>
        )}
        {/* <StyledItem>
          <LetterSVG />
        </StyledItem>
        <StyledItem>
          <BellSVG />
        </StyledItem> */}
        <StyledItem>
          <Tooltip
            content={
              <StyledAvatar>
                {firstName?.charAt(0)}
                {lastName?.charAt(0)}
              </StyledAvatar>
            }
            coward={false}
          >
            <StyledTooltipMenuWrapper>
              <Button type='emptyBig' onClick={handleClick}>
                Выход
              </Button>
            </StyledTooltipMenuWrapper>
          </Tooltip>
        </StyledItem>
      </StyledHeaderUserActions>
    </StyledHeaderWrapper>
  )
}
