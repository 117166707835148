/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectBanListService } from 'services/banListService'
import { ProjectLoansService } from 'services/loanService'

export const fetchBanListBySearch = createAsyncThunk<any, { search: string }>(
  'banList/fetchList',
  async (props) => {
    const { search } = props
    const response = await ProjectBanListService.getBannedUserBySearch(search).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchOnBan = createAsyncThunk<
  any,
  {
    id: string
    options: { [key: string]: boolean | number | string }
    onSuccess?: () => void
  }
>('banList/fetchOnBan', async (props) => {
  const { id, options, onSuccess } = props
  const response = await ProjectLoansService.onBan(id, options).then(({ data }) => {
    onSuccess && onSuccess()
    return data
  })
  return response
})

export const fetchOnUnban = createAsyncThunk<any, { id: string; onSuccess?: () => void }>(
  'banList/fetchOnUnban',
  async (props) => {
    const { id, onSuccess } = props
    const response = await ProjectLoansService.onUnban(id).then(({ data }) => {
      onSuccess && onSuccess()
      return data
    })
    return response
  }
)
