/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import {
  fetchAgentDataPhoto,
  fetchAgentLoanAccept,
  fetchAgentLoans,
  fetchAgentLoansCount,
  fetchAgentLoanUserPassportData,
  fetchAgentLoanUserPersonalData,
  fetchAgentWaitingLoans,
  fetchLoanPageById,
  fetchPersonalAgentsDataPhoto,
  fetchPhonesData,
  fetchRegistrationAgentsDataPhoto,
  fetchUserLoans,
  createLoanComments,
  getLoanComments,
  updateLoanComments,
  getAgentUsers,
  refuseLoan,
} from './extra'
import { IAgentLoanSlice, ILoanData, ILoanRequest, IPhoto } from './types'

const initialState: IAgentLoanSlice = {
  totalAmount: {
    pending: false,
    error: false,
    all_app: 0,
    waiting: 0,
    on_check: 0,
    agent_in_work: 0,
    setting_a_limit: 0,
    loan_approved: 0,
  },
  all_app: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  waiting: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  on_check: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  agent_in_work: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  setting_a_limit: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  loan_approved: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  pre_approved: {
    pending: false,
    error: false,
    data: null,
    totalCount: 0,
    iteration: 1,
    activePage: '',
  },
  passportData: null,
  personalData: null,
  loanData: null,
  userData: null,
  assign_info: null,
  currentLoanIsBelongsToAgent: false,
  collector_username: null,
  imageData: {
    personalDataPhoto: [],
    registrationDataPhoto: [],
    agentDataPhoto: [],
  },
  commentsData: {
    isFetching: true,
    isUpdating: false,
    pending: false,
    data: [],
  },
  phonesData: null,
  userLoans: null,
  actionPending: false,
}

export const agentLoansSlice = createSlice({
  name: 'agentLoans',
  initialState,
  reducers: {
    resetAgentsLoans: (state, { payload }) =>
      payload
        ? {
            ...state,
            totalAmount: state.totalAmount,
            [payload]: initialState[payload],
          }
        : {
            ...initialState,
            totalAmount: state.totalAmount,
          },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentLoans.pending, (state, payload: any) => {
        const {
          meta: {
            arg: { type },
          },
        } = payload
        state[type].pending = true
      })
      .addCase(fetchAgentLoans.rejected, (state, payload: any) => {
        const {
          meta: {
            arg: { type },
          },
        } = payload
        state[type].error = true
        state[type].pending = false
        state[type].data = {}
      })
      .addCase(
        fetchAgentLoans.fulfilled,
        (state, { payload }: PayloadAction<ILoanRequest | any>) => {
          const { data, type, iteration, refresh } = payload
          state[type].pending = false
          const normalizeData = keyBy(data.data, 'loans_loan_id')
          state[type].data = !refresh
            ? {
                ...(state[type].data || {}),
                ...normalizeData,
              }
            : normalizeData
          state[type].totalCount = data.total
          state[type].finish = !data.count
          state[type].iteration = iteration || state[type].iteration + 1
        }
      )
      .addCase(fetchAgentWaitingLoans.pending, (state) => {
        state.waiting.pending = true
      })
      .addCase(fetchAgentWaitingLoans.rejected, (state) => {
        state.waiting.error = true
        state.waiting.pending = false
        state.waiting.data = {}
      })
      .addCase(
        fetchAgentWaitingLoans.fulfilled,
        (state, { payload }: PayloadAction<ILoanRequest | any>) => {
          state.waiting.pending = false
          state.waiting.data = payload
          state.waiting.totalCount = payload.length
          // state.waiting.totalCount = data.total
          state.totalAmount = {
            ...state.totalAmount,
            waiting: payload.length,
          }
        }
      )
      .addCase(fetchAgentLoansCount.pending, (state) => {
        state.totalAmount.pending = true
      })
      .addCase(fetchAgentLoansCount.rejected, (state) => {
        state.totalAmount.error = true
        state.totalAmount.pending = false
      })
      .addCase(
        fetchAgentLoansCount.fulfilled,
        (state, { payload }: PayloadAction<ILoanRequest | any>) => {
          const { all_app, on_check, agent_in_work, setting_a_limit, loan_approved } = payload
          state.totalAmount = {
            all_app,
            waiting: state.waiting.totalCount,
            on_check,
            agent_in_work,
            setting_a_limit,
            loan_approved,
            pending: false,
          }
        }
      )

      .addCase(fetchAgentLoanUserPassportData.pending, () => {})
      .addCase(fetchAgentLoanUserPassportData.rejected, () => {})
      .addCase(fetchAgentLoanUserPassportData.fulfilled, (state, { payload }) => {
        state.passportData = payload?.data?.passport
      })
      .addCase(fetchAgentLoanUserPersonalData.pending, () => {})
      .addCase(fetchAgentLoanUserPersonalData.rejected, () => {})
      .addCase(fetchAgentLoanUserPersonalData.fulfilled, (state, { payload }) => {
        state.personalData = payload?.data
      })
      .addCase(fetchLoanPageById.pending, () => {})
      .addCase(fetchLoanPageById.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loanData = payload.loan_data
        state.userData = payload.user_data
        state.assign_info = payload.assign_info
        state.collector_username = payload.collector_username
      })
      .addCase(fetchPersonalAgentsDataPhoto.pending, () => {})
      .addCase(
        fetchPersonalAgentsDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          state.imageData.personalDataPhoto = payload
        }
      )

      .addCase(fetchRegistrationAgentsDataPhoto.pending, () => {})
      .addCase(
        fetchRegistrationAgentsDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          state.imageData.registrationDataPhoto = payload
        }
      )

      .addCase(fetchAgentDataPhoto.pending, () => {})
      .addCase(
        fetchAgentDataPhoto.fulfilled,
        (state, { payload }: PayloadAction<Array<IPhoto>>) => {
          state.imageData.agentDataPhoto = payload
        }
      )
      .addCase(fetchAgentDataPhoto.rejected, () => {})
      .addCase(fetchPhonesData.fulfilled, (state, { payload }) => {
        state.phonesData = payload
      })

      .addCase(fetchUserLoans.fulfilled, (state, { payload }: PayloadAction<Array<ILoanData>>) => {
        state.userLoans = payload
      })
      .addCase(fetchAgentLoanAccept.fulfilled, (state) => {
        state.loanData = {
          ...state.loanData,
          loan_status: 'setting_a_limit',
        }
      })
      // get comments
      .addCase(getLoanComments.pending, (state) => {
        state.commentsData.isFetching = true
      })
      .addCase(getLoanComments.fulfilled, (state, { payload }) => {
        state.commentsData.isFetching = false
        state.commentsData.data = payload
      })
      .addCase(getLoanComments.rejected, (state) => {
        state.commentsData.isFetching = false
        state.commentsData.data = []
      })
      // create comments
      .addCase(createLoanComments.pending, (state) => {
        state.commentsData.pending = true
      })
      .addCase(createLoanComments.fulfilled, (state) => {
        state.commentsData.pending = false
      })
      .addCase(createLoanComments.rejected, (state) => {
        state.commentsData.pending = false
      })
      .addCase(updateLoanComments.pending, (state) => {
        state.commentsData.isUpdating = true
      })
      .addCase(updateLoanComments.fulfilled, (state) => {
        state.commentsData.isUpdating = false
      })
      .addCase(updateLoanComments.rejected, (state) => {
        state.commentsData.isUpdating = false
      })
      .addCase(getAgentUsers.fulfilled, (state, { payload }) => {
        if (payload.length > 0) state.currentLoanIsBelongsToAgent = true
        else state.currentLoanIsBelongsToAgent = false
      })
      // refuseLoan
      .addCase(refuseLoan.pending, (state) => {
        state.actionPending = true
      })
      .addCase(refuseLoan.fulfilled, (state) => {
        state.actionPending = false
      })
      .addCase(refuseLoan.rejected, (state) => {
        state.actionPending = false
      })
  },
})

export const { resetAgentsLoans } = agentLoansSlice.actions

export default agentLoansSlice.reducer
