import { THEME } from 'core/theme'
import styled, { css } from 'styled-components/macro'

export const StyledLoaderPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledHead = styled.div`
  padding: 10px 32px;
  border-bottom: 1px solid #dfe0eb;
`

export const StyledTitle = styled.p`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #08003a;
`

export const StyledTaskButtonBlock = styled.div`
  margin-top: 10px;
`

export const StyledResetFilter = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: white;
  cursor: pointer;
`

export const StyledWrapper = styled.div`
  padding-bottom: 30px;
`

export const StyledBody = styled.div``

export const StyledGroup = styled.div`
  padding: 20px 30px;
  padding-bottom: 800px;
  height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
`

export const StyledGroupTitle = styled.p`
  font-weight: 800;
  margin-top: 10px;
  font-size: 24px;
  line-height: 35px;
  color: rgb(8, 0, 58);
`

export const StyledSubGroup = styled.div``

export const StyledSubGroupHead = styled.p`
  font-size: 18px;
  font-weight: 800;
  opacity: 0.3;
  margin: 5px 0;
`

export const StyledSubGroupItem = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
`

export const StyledSubGroupTitle = styled.p<{ isHighlighted: boolean; isEdited: boolean }>`
  margin: 5px 0;
  margin-right: 15px;

  & a {
    text-decoration: none;
    color: black;
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      font-weight: bold;
    `}

  ${({ isEdited }) =>
    isEdited &&
    css`
      & a {
        font-weight: bold;
        color: ${THEME.COLORS.BLUE};
        text-decoration: underline;
      }
    `}
`

export const StyledTitleDate = styled.p`
  margin-left: 12px;
  position: relative;
  font-weight: 400;
  font-size: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  margin-right: 15px;
  top: -3px;

  & span {
    font-size: 11px;
    /* font-weight: bold; */
    background-color: gray;
    padding: 2px 3px;
    border-radius: 5px;
    color: white;
    opacity: 0.3;
  }
`

export const StyledIcon = styled.div<{ edited?: boolean }>`
  cursor: pointer;
  & svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &:hover {
    & svg path {
      fill: ${THEME.COLORS.BLUE};
    }
  }

  ${({ edited }) =>
    edited &&
    css`
      & svg path {
        fill: ${THEME.COLORS.BLUE};
      }
    `}
`

export const StyledCommentsBlock = styled.div`
  padding: 0 0 30px 30px;
`

export const StyledCommentTitle = styled.p`
  margin: 30px 0;
  font-weight: bold;
`

export const StyledTextArea = styled.textarea<any>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  width: 100%;
  height: 120px;
  resize: none;

  &:focus {
    outline: none;
  }
`

export const StyledButtons = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
`

export const StyledSelectBlock = styled.div`
  position: relative;
`

export const StyledContentWrapper = styled.div`
  max-height: 0px;
  overflow: scroll;
  transition: all 0.2s linear;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledContentGrid = styled.div`
  display: flex;
  padding: 20px 0;
`

export const StyledContentGridItem = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  width: 30%;
`

export const StyledContentBlock = styled.div<{ showFilters: boolean }>`
  visibility: hidden;
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ showFilters }) =>
    showFilters &&
    css`
      visibility: visible;

      ${StyledContentWrapper} {
        max-height: 800px;
        overflow: auto;
      }
    `}
`

export const StyledHeadDocument = styled.p`
  margin: 15px 0;
  margin-right: 15px;
  font-weight: bold;
`

export const StyledPlaceHolder = styled.p`
  font-size: 12px;
  opacity: 0.5;
  font-weight: bold;
  padding: 5px 10px;
`
