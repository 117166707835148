import styled, { css } from 'styled-components/macro'
import { THEME } from '../../../core/theme'
import { MIN_WIDTH } from './tableStructure'
import { StyledTimeLimitationTitle } from '../CollectorLimitation.styles'

export const StyledRowWrapper = styled.a<{ indicate?: boolean; template: string }>`
  text-decoration: none;
  padding: 0 35px;
  display: grid;
  grid-template-columns: ${(props) => props.template};
  column-gap: 6px;
  min-width: ${MIN_WIDTH};
  border-bottom: 1px solid ${THEME.COLORS.TABLE_BORDER};
  cursor: pointer;

  &:hover {
    background: ${THEME.COLORS.TABLE_HOVER}!important;

    ${StyledTimeLimitationTitle} {
      display: block;
    }
  }

  ${({ indicate }) =>
    indicate &&
    css`
      background: ${THEME.COLORS.TABLE_INDURATE}!important;
    `}
`

export const StyledValue = styled.div`
  padding: 18px 5px;
  word-break: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const StyledTitle = styled.div<{ select?: boolean; pending?: boolean }>`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ select }) =>
    select &&
    css`
      cursor: pointer;

      &:hover {
        color: ${THEME.COLORS.BLUE};
      }
    `}

  ${({ pending }) =>
    pending &&
    css`
      color: rgb(8 0 58 / 38%);
    `}

  & svg {
    margin-left: 5px;
    &:hover {
      path {
        fill: ${THEME.COLORS.BLUE};
      }
    }
  }
`

export const StyledActionTable = styled.div`
  cursor: pointer;
`

export const StyledCounterMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${THEME.COLORS.BLUE};
`

export const StyledInfo = styled.div`
  background: ${THEME.COLORS.SUCCESS};
  color: ${THEME.COLORS.WHITE};
  border-radius: 15px;
  padding: 3px 4px 0 0;
  width: fit-content;
  opacity: 0.8;
  font-size: 14px;
  transition: 0.3s;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-left: 4px;
  & svg {
    color: ${THEME.COLORS.WHITE};
    margin-left: 5px;
  }
`
