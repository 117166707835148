/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
// import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'
import { LoanStatus } from 'features/loan/types'
import { HiOutlineClipboardCopy } from 'react-icons/hi'
import { copyToClipboard } from 'helpers'
import { ITableProps, ITableSdlPros } from './types'
import { COLUMN_TEMPLATE } from './options'
import { StyledRowWrapper, StyledValue, StyledTitle, StyledInfo } from './Table.styles'

export const Table = (props: ITableProps | ITableSdlPros): JSX.Element => {
  const {
    loanNumber,
    passportFirstName,
    passportLastName,
    passportMiddleName,
    passportNumber,
    passportSerial,
    // loanId,
    userLogin,
    // adminUsername,
    // username,
    regAddress,
    userId,
  } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const { path } = useRouteMatch()

  const handleOpenLoan = (e) => {
    e.preventDefault()
    history.push(`${path}/${userId}`)
  }

  const tableStructure = [
    {
      title: loanNumber,
      id: uuid(),
      copy: true,
      onClick: (e) => {
        e.stopPropagation()
        e.preventDefault()
        copy(loanNumber)
      },
    },
    {
      title: `${passportLastName} ${passportFirstName} ${passportMiddleName}`,
      id: uuid(),
    },
    { title: `${passportSerial} ${passportNumber} `, id: uuid() },
    { title: userLogin, id: uuid() },
    { title: regAddress, id: uuid() },
  ]
  const copy = (value) => {
    copyToClipboard(value)
      .then(() => {
        dispatch(
          addToast({
            type: ToastTypes.success,
            title: 'Успех',
            description: `Данные скопированны`,
          })
        )
      })
      .catch((err) => {
        console.log('Something went wrong', err)
      })
  }
  return (
    <>
      <StyledRowWrapper
        indicate={false}
        onClick={handleOpenLoan}
        template={COLUMN_TEMPLATE.all}
        href={`${path}/${userId}`}
      >
        {tableStructure.map((column) => (
          <StyledValue key={column.id} onClick={column.onClick}>
            <StyledTitle select={null} pending={false}>
              <>
                {column.title}
                {column.copy && (
                  <StyledInfo>
                    <HiOutlineClipboardCopy />
                  </StyledInfo>
                )}
              </>
            </StyledTitle>
          </StyledValue>
        ))}
      </StyledRowWrapper>
    </>
  )
}
