import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GlobalState } from 'index'
import { ignoreUnassignedLoan } from 'features/unassignedModal/unassignedModalSlice'

export const useUnassignedModal: any = () => {
  const dispatch = useDispatch()
  const list = useSelector((state: GlobalState) => state.unassignedModal)

  const removeHandler = useCallback(
    (id) => {
      dispatch(ignoreUnassignedLoan(id))
    },
    [dispatch]
  )
  const loans = Object.values(list).filter((v: any) => !v.ignore)
  return [loans, removeHandler]
}
