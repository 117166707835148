/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Module, UploadLoanImage } from 'components'
import { ImageModal } from 'containers'
import { GlobalState } from 'index'
import { StyledPassportWrapper } from './Modules.styles'

const moduleId = 'form'
export const Agreement: any = ({ openedModules, onToggleModule, userId }): JSX.Element => {
  const images = useSelector((store: GlobalState) => store.contracts.imageData)
  const { userData } = useSelector((store: GlobalState) => store.contracts)
  const isOpen = openedModules.includes(moduleId)
  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })

  const handleExpand = (): void => onToggleModule(moduleId)

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const fetchImages = false // TODO
  const imageContent = { ...showModal.content }

  return (
    <Module.Wrapper isOpen={isOpen} editable={false} isEdited={false} id={moduleId}>
      <ImageModal.ModalContent
        userId={userId}
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        imageContent={imageContent}
        onClose={handleCloseModal}
        type='agentsScans'
      />
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Скан документов</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <StyledPassportWrapper>
          <Module.ImageBlock>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {fetchImages && <Module.ImageLoader />}
              {images &&
                images?.agentDataPhoto.length > 0 &&
                images.agentDataPhoto.map((image) => (
                  <Module.ImageWrapper onClick={handleShowModal({ image })} key={image.image_id}>
                    <Module.SmallImage url={image.photo_url} />
                  </Module.ImageWrapper>
                ))}
              <UploadLoanImage
                type='agent-photo'
                userId={userData?.user_id}
                capture='environment'
              />
            </div>
          </Module.ImageBlock>
        </StyledPassportWrapper>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
