import styled, { css } from 'styled-components/macro'
import { THEME } from '../../../core/theme'
import { MIN_WIDTH } from './options'
import { OVERTIME } from './types'
import { LoanStatuses } from 'helpers'

export const StyledRowWrapper = styled.a<{ indicate?: boolean; template: string }>`
  text-decoration: none;
  padding: 0 35px;
  display: grid;
  grid-template-columns: ${(props) => props.template};
  column-gap: 6px;
  min-width: ${MIN_WIDTH};
  border-bottom: 1px solid ${THEME.COLORS.TABLE_BORDER};
  cursor: pointer;
  &:hover {
    background: ${THEME.COLORS.TABLE_HOVER}!important;
  }

  ${({ indicate }) =>
    indicate &&
    css`
      background: ${THEME.COLORS.TABLE_INDURATE}!important;
    `}
`

export const StyledValue = styled.div`
  padding: 18px 5px;
  word-break: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const StyledTitle = styled.p<{
  select: boolean
  pending: boolean
}>`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ select }) =>
    select &&
    css`
      cursor: pointer;

      &:hover {
        color: ${THEME.COLORS.BLUE};
      }
    `}
  ${({ pending }) =>
    pending &&
    css`
      color: rgb(8 0 58 / 38%);
      &:hover {
        color: rgb(8 0 58 / 38%);
      }
    `}

  & svg {
    margin-left: 5px;
  }
`

export const StyledStatusDateToolTip = styled.span`
  display: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 12px;
  top: -3px;
  left: 185px;
  z-index: 99;
  width: 270px;
  font-size: 12px;
  font-weight: bold;
  color: black;
`

export const StyledStatusDate = styled.span<{ overTime: string }>`
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    ${StyledStatusDateToolTip} {
      display: block;
    }
  }

  & svg {
    margin-right: 5px;
  }

  ${({ overTime }) => {
    switch (overTime) {
      case OVERTIME['0_24']:
        return css``
      case OVERTIME['24-48']:
        return css`
          background: #f8cc46;
          padding: 5px 10px;
          border-radius: 12px;
        `
      case OVERTIME['48-more']:
        return css`
          color: white;
          background: #ff4646;
          padding: 5px 10px;
          border-radius: 12px;

          & svg path {
            fill: white;
          }
        `
      default:
        return ''
    }
  }}
`

export const StyledInfo = styled.div`
  background: ${THEME.COLORS.SUCCESS};
  color: ${THEME.COLORS.WHITE};
  border-radius: 15px;
  padding: 3px 4px 0 0;
  width: fit-content;
  opacity: 0.8;
  font-size: 14px;
  transition: 0.3s;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-left: 4px;
  & svg {
    color: ${THEME.COLORS.WHITE};
    margin-left: 5px;
  }
`

export const StyledMobileValue = styled.p<{ styles?: string }>`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  margin-top: 5px;

  ${({ styles }) => styles && css``}
`

export const StyledMobileLoanNumbers = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: ${THEME.COLORS.BLUE};
  margin-top: 7px;
`

export const StyledMobilePersonal = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`

export const StyledMobileSum = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledMobileStaff = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledMobileCopyLink = styled.div`
  margin-top: 12px;
`
export const StyledMobileRequiredHead = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledMobileCheckedHead = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledMobileDescription = styled.p`
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: ${THEME.COLORS.GRAY};
`

export const StyledMobileHeaderAll = styled.div``

export const StyledStatusWrapper = styled.div`
  display: flex;

  padding: 6px 10px;
  background: #f5f6fa;
  border-radius: 18.5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #08003a;
`
export const StyledStatusMobile = styled.div<any>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;

  ${({ status }) => {
    switch (status) {
      case LoanStatuses.loan_issued: {
        return css`
          background-color: ${THEME.COLORS.SUCCESS};
        `
      }
      case LoanStatuses.loan_overdue: {
        return css`
          background-color: ${THEME.COLORS.WARNING};
        `
      }
      case LoanStatuses.failure_automatic: {
        return css`
          background-color: ${THEME.COLORS.WARNING};
        `
      }
      case LoanStatuses.setting_a_limit: {
        return css`
          background-color: ${THEME.COLORS.YELLOW};
        `
      }
      default:
        return ''
    }
  }}
`

export const StyledMobileTopAll = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledMobileDividerSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`

export const StyledMobileSection = styled.div`
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  padding-bottom: 15px;
  margin-bottom: 15px;
`

export const StyledMobileItemWrapper = styled.div`
  width: 100%;
  display: none;

  ${StyledMobileSection} {
    &:last-child {
      border: none;
      margin-bottom: 0;
    }
  }

  @media (max-width: 480px) {
    display: block;
  }
`

export const StyledHead = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

export const StyledCreateDate = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  color: #979798;
  height: fit-content;
  position: absolute;
  bottom: 3px;
  left: 104px;
`
