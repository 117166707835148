import { AgentLoanType } from 'features/agentLoans/types'
import styled, { css } from 'styled-components/macro'

import { THEME } from '../../../core/theme'
import { MIN_WIDTH } from './tableStructure'
import { LoanStatuses } from 'helpers'

export const StyledRowWrapper = styled.a<{ indicate?: boolean; template: string }>`
  text-decoration: none;
  padding: 0 35px;
  display: grid;
  grid-template-columns: ${(props) => props.template};
  /* column-gap: 6px; */
  min-width: ${MIN_WIDTH};
  border-bottom: 1px solid ${THEME.COLORS.TABLE_BORDER};
  cursor: pointer;
  &:hover {
    background: ${THEME.COLORS.TABLE_HOVER}!important;
  }

  ${({ indicate }) =>
    indicate &&
    css`
      background: ${THEME.COLORS.TABLE_INDURATE}!important;
    `}

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    min-width: auto;
    padding: 24px;
    margin: 0 16px;
    margin-bottom: 25px;
    border-radius: 24px;
    background: ${THEME.COLORS.WHITE};
  }
`

export const StyledValue = styled.div`
  padding: 18px 5px;
  word-break: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;

  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledTitle = styled.div<{ select?: boolean; pending?: boolean }>`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ select }) =>
    select &&
    css`
      cursor: pointer;

      &:hover {
        color: ${THEME.COLORS.BLUE};
      }
    `}
  ${({ pending }) =>
    pending &&
    css`
      color: rgb(8 0 58 / 38%);
      &:hover ${StyledMessagesWrapper} {
        color: rgb(8 0 58 / 38%);
      }
    `}

  & svg {
    margin-left: 5px;
  }
`
export const StyledStatusWrapper = styled.div`
  display: flex;

  padding: 6px 10px;
  background: #f5f6fa;
  border-radius: 18.5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #08003a;
`
export const StyledStatus = styled.div<any>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;

  ${({ status }) => {
    switch (status) {
      // case AgentLoanType.failureAutomatic: {
      //   return css`
      //     background-color: ${THEME.COLORS.SUCCESS};
      //   `
      // }
      // case AgentLoanType.agentRefusal: {
      //   return css`
      //     background-color: ${THEME.COLORS.SUCCESS};
      //   `
      // }
      // case 'loan_issued':
      // case AgentLoanType.underwriterRefusal: {
      //   return css`
      //     background-color: ${THEME.COLORS.SUCCESS};
      //   `
      // }
      // case AgentLoanType.clientRefusal: {
      //   return css`
      //     background-color: ${THEME.COLORS.SUCCESS};
      //   `
      // }
      // case 'on_check':

      case AgentLoanType.failureAutomatic:
      case AgentLoanType.underwriterRefusal:
      case AgentLoanType.clientRefusal:
      case AgentLoanType.agentRefusal: {
        return css`
          background-color: ${THEME.COLORS.WARNING};
        `
      }
      case 'loan_issued':
      case 'loan_approved': {
        return css`
          background-color: ${THEME.COLORS.SUCCESS};
        `
      }
      default:
        return css`
          background-color: ${THEME.COLORS.YELLOW};
        `
    }
  }}
`

export const StyledMessagesWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledValueCount = styled.div`
  color: ${THEME.COLORS.BLUE};
  margin-left: 5px;
  font-weight: bold;
  font-size: 14px;
`

export const StyledActionTable = styled.div`
  cursor: pointer;
`

export const StyledCopyLink = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${THEME.COLORS.TABLE_BORDER};
  border-radius: 40px;
  padding: 5px 12px;
  background-color: ${THEME.COLORS.WHITE};

  & svg {
    margin-right: 8px;
  }

  @media (max-width: 480px) {
    background: ${THEME.COLORS.SMOOTH};
    border: 5px solid ${THEME.COLORS.SMOOTH};
    width: fit-content;
  }
`

export const StyledMobileSection = styled.div`
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  padding-bottom: 15px;
  margin-bottom: 15px;
`

export const StyledMobileItemWrapper = styled.div`
  width: 100%;
  display: none;

  ${StyledMobileSection} {
    &:last-child {
      border: none;
      margin-bottom: 0;
    }
  }

  @media (max-width: 480px) {
    display: block;
  }
`

export const StyledMobileHeaderAll = styled.div`
  margin-bottom: 20px;
`

export const StyledMobileTopAll = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`
export const StyledStatusAllWrapper = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  background-color: ${THEME.COLORS.SMOOTH};
  border-radius: 18.5px;
  padding: 6px 10px;

  & svg {
    margin-right: 5px;
  }
`

export const StyledCounterMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${THEME.COLORS.BLUE};
`
export const StyledMobileDescription = styled.p`
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: ${THEME.COLORS.GRAY};
`

export const StyledMobileValue = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  margin-top: 5px;
`

export const StyledMobileLoanNumbers = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: ${THEME.COLORS.BLUE};
  margin-top: 7px;
`

export const StyledMobilePersonal = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const StyledMobileDateCreate = styled.div`
  display: flex;
  align-items: center;
`

export const StyledMobileDateValue = styled.div`
  color: #73717e;
  margin-left: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  margin-top: 7px;
`

export const StyledMobileSum = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
export const StyledMobileStaff = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledMobileCopyLink = styled.div`
  margin-top: 12px;
`
export const StyledMobileRequiredHead = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledMobileCheckedHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const StyledInfo = styled.div`
  background: ${THEME.COLORS.SUCCESS};
  color: ${THEME.COLORS.WHITE};
  border-radius: 15px;
  padding: 3px 4px 0 0;
  width: fit-content;
  opacity: 0.8;
  font-size: 14px;
  transition: 0.3s;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-left: 4px;
  & svg {
    color: ${THEME.COLORS.WHITE};
    margin-left: 5px;
  }
`

export const StyledHead = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

export const StyledCreateDate = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  color: #979798;
  height: fit-content;
  position: absolute;
  bottom: 3px;
  left: 104px;
  display: none;

  @media (max-width: 480px) {
    display: block;
  }
`

export const StyledStatusMobile = styled.div<any>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;

  ${({ status }) => {
    switch (status) {
      case LoanStatuses.loan_issued: {
        return css`
          background-color: ${THEME.COLORS.SUCCESS};
        `
      }
      case LoanStatuses.loan_overdue:
      case LoanStatuses.underwriter_refusal: {
        return css`
          background-color: ${THEME.COLORS.WARNING};
        `
      }
      case LoanStatuses.failure_automatic: {
        return css`
          background-color: ${THEME.COLORS.WARNING};
        `
      }
      case LoanStatuses.setting_a_limit:
      case LoanStatuses.agent_in_work: {
        return css`
          background-color: ${THEME.COLORS.YELLOW};
        `
      }
      default:
        return ''
    }
  }}
`

export const StyledMobileDividerSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`
