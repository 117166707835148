/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable guard-for-in */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState, useCallback, useContext } from 'react'
import { useDispatch, batch } from 'react-redux'
import { Formik } from 'formik'
import { isEqual } from 'lodash'

import {
  AccessWrapper,
  getAddressState,
  InputField,
  isValidForm,
  normalizeValues,
  postMask,
  validationSchemaRegistration,
} from 'helpers'

import { fetchPassportData, updateRegistrationData } from 'features/loan/extra'
import { useGetRegistrationData } from 'features/loan/loanSelectors'
import { IRegistrationDataUpdate } from 'features/loan/types'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { Button, Module, Dadata, EditInPassport } from 'components'
import { StyledButtonBlock } from './Modules.styles'
import { IModuleProps } from './types'
import { generatePassportValue } from 'components/EditInPassport/EditInPassport'
import { rolesType } from 'constants/rtoles'
import { PermissionContext } from 'app/contexts'

const moduleId = 'address'

export const Registration: React.FC<IModuleProps> = React.memo(
  ({ openedModules, onToggleModule, claimsResolve, userId, inPassport }): JSX.Element => {
    const dispatch = useDispatch()
    const [editable, setEditable] = useState<boolean>(false)
    const data = useGetRegistrationData()
    const isOpen = useMemo(() => openedModules.includes(moduleId), [openedModules])
    const { permissions } = useContext(PermissionContext)

    const [inPassportEditable, setInPassportEditable] = useState(false)
    const [inPassportContent, setInPassportContent] = useState('')

    const getInPassportValue: string = useMemo(() => {
      return generatePassportValue(data)
    }, [data])

    const initialValues = useMemo(() => data || {}, [data])
    const equalInPassport: boolean = isEqual(
      !inPassport ? getInPassportValue : inPassport,
      inPassportContent
    )

    useEffect(() => {
      if (!inPassport) setInPassportContent(getInPassportValue)
      else setInPassportContent(inPassport)
    }, [inPassport, getInPassportValue])

    useEffect(() => {
      if (isOpen && userId && data.isFetching) {
        dispatch(fetchPassportData({ id: userId }))
      }
    }, [isOpen, userId, data.isFetching, dispatch])

    const handleChangeEditable = useCallback(() => {
      setEditable((prevEditable) => !prevEditable)
    }, [])

    const handleCancel = useCallback(
      (resetForm) => () => {
        resetForm()
        setEditable(false)
        handleResetInPassport()
      },
      []
    )

    const handleExpand = useCallback(() => {
      onToggleModule(moduleId)
    }, [onToggleModule])

    const handleResetInPassport = useCallback(() => {
      setInPassportEditable(false)
      setInPassportContent(!inPassport ? getInPassportValue : inPassport)
    }, [inPassport, getInPassportValue])

    const submitForm = useCallback(
      async (values) => {
        const {
          postal_code,
          region,
          district,
          city,
          street,
          building,
          house_number,
          block,
          apartment,
          other,
        } = values

        const body: IRegistrationDataUpdate = {
          postal_code,
          region,
          city,
          street,
          house_number,
          // optional
          ...(district && { district }),
          ...(building && { building }),
          ...(block && { block }),
          ...(apartment && { apartment }),
          ...(other && { other }),
          ...(!equalInPassport && { in_passport: inPassportContent.replace(/\s+/g, ' ').trim() }), //  Убираем лишние пробелы
        }

        dispatch(
          updateRegistrationData({
            id: userId,
            body,
            values,
            onError: () => {
              dispatch(
                addToast({
                  type: ToastTypes.warning,
                  title: 'Ошибка',
                  description: `Данные не сохранены`,
                })
              )
            },
            onSuccess: () => {
              batch(() => {
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Данные сохранены`,
                })
                dispatch(fetchPassportData({ id: userId }))
              })
              handleResetInPassport()
              setEditable(false)
            },
          })
        )
      },
      [equalInPassport, inPassportContent, dispatch, userId, handleResetInPassport]
    )

    const handleChangeInPassportContent = useCallback((evt) => {
      setInPassportContent(evt.target.value)
    }, [])

    const handleCopyInPassport = useCallback(
      () => setInPassportContent(getInPassportValue),
      [getInPassportValue]
    )

    const editInPassportProps = {
      inPassportEditable,
      getInPassportValue,
      handleChangeInPassportContent,
      inPassportContent,
      handleResetInPassport,
      handleCopyInPassport,
      setInPassportEditable,
      equalInPassport,
    }

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitForm}
        validationSchema={validationSchemaRegistration}
      >
        {(formikProps) => {
          const { errors, dirty, resetForm, handleSubmit } = formikProps

          return (
            <Module.Wrapper
              isOpen={isOpen}
              editable={editable}
              isEdited={dirty && editable}
              id={moduleId}
            >
              <Module.Header onClick={handleExpand}>
                <Module.Actions isOpen={isOpen}>
                  <Module.HeadTitle>Адрес регистрации</Module.HeadTitle>
                  <Module.Arrow isOpen={isOpen} />
                </Module.Actions>
              </Module.Header>
              <Module.ContentWrapper>
                <AccessWrapper
                  condition={permissions.includes(rolesType.regionalManager) || claimsResolve}
                  access={[rolesType.agent, rolesType.regionalManager]}
                >
                  <Module.Button handleChangeEditable={handleChangeEditable} />
                </AccessWrapper>
                <Module.Content>
                  {data.isFetching && <Module.LoaderBlock />}
                  {editable ? <Edit {...formikProps} /> : <View {...formikProps} />}
                  <Module.Column column={4}>
                    <div style={{ width: '500px', maxWidth: '500' }}>
                      <EditInPassport {...editInPassportProps} />
                    </div>
                  </Module.Column>
                </Module.Content>
              </Module.ContentWrapper>
              <StyledButtonBlock>
                {(editable || (!equalInPassport && inPassportEditable)) && (
                  <div style={{ margin: '32px 0' }}>
                    <Button
                      disabled={
                        !equalInPassport
                          ? equalInPassport
                          : isValidForm(errors) || data.isUpdating || !dirty
                      }
                      type='standardBig'
                      onClick={() => (dirty || !equalInPassport) && handleSubmit()}
                      pending={data.isUpdating}
                    >
                      Сохранить
                    </Button>
                    <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                      Отменить
                    </Button>
                  </div>
                )}
              </StyledButtonBlock>
            </Module.Wrapper>
          )
        }}
      </Formik>
    )
  }
)

const Edit = (props): JSX.Element => {
  const { values, setFieldValue } = props
  const [address, setAddress] = useState({ value: '', data: {} })

  useEffect(() => {
    if (address.value) {
      const addressBody = getAddressState(address)
      for (const key in addressBody) {
        setFieldValue(key, addressBody[key])
      }
    }
  }, [address, setFieldValue])

  const inputFieldsData = useMemo(
    () => [
      { name: 'postal_code', placeholder: 'Почтовый индекс', column: 1, mask: postMask },
      { name: 'region', placeholder: 'Регион', column: 1 },
      { name: 'area', placeholder: 'Область', column: 1 },
      { name: 'district', placeholder: 'Район', column: 1 },
      { name: 'city', placeholder: 'Город', column: 1 },
      { name: 'street', placeholder: 'Улица', column: 1 },
      { name: 'building', placeholder: 'Строение', column: 1 },
      { name: 'house_number', placeholder: 'Дом', column: 1 },
      { name: 'block', placeholder: 'Корпус', column: 1 },
      { name: 'apartment', placeholder: 'Квартира', column: 1 },
      { name: 'other', placeholder: 'Дополнительные значения адреса', column: 2 },
    ],
    []
  )

  const inputFields = useMemo(
    () => (
      <>
        <Dadata
          name='address'
          type='address'
          placeholder='Адрес (поиск)'
          value={address}
          onChange={(val) => setAddress(val)}
          required
        />

        {inputFieldsData.map(({ name, placeholder, column, mask }, index) => (
          <Module.Column key={index} column={column}>
            <InputField
              name={name}
              onChange={setFieldValue}
              placeholder={placeholder}
              value={values[name]}
              noMove
              mask={mask}
              {...props}
            />
          </Module.Column>
        ))}
      </>
    ),
    [address, inputFieldsData, props, setFieldValue, values]
  )

  return inputFields
}

const View = ({ values }) => {
  const infoData = [
    { label: 'Почтовый индекс', value: normalizeValues(values.postal_code), column: 4 },
    { label: 'Регион', value: normalizeValues(values.region), column: 1 },
    { label: 'Область', value: normalizeValues(values.area), column: 1 },
    { label: 'Район', value: normalizeValues(values.district), column: 1 },
    { label: 'Город', value: normalizeValues(values.city), column: 1 },
    { label: 'Улица', value: normalizeValues(values.street), column: 4 },
    { label: 'Строение', value: normalizeValues(values.building), column: 1 },
    { label: 'Дом', value: normalizeValues(values.house_number), column: 1 },
    { label: 'Корпус', value: normalizeValues(values.block), column: 1 },
    { label: 'Квартира', value: normalizeValues(values.apartment), column: 1 },
    { label: 'Дополнительные значения адреса', value: normalizeValues(values.other), column: 2 },
  ]

  return (
    <>
      {infoData.map(({ label, value, column }, index) => (
        <Module.Column key={index} column={column}>
          <Module.Title>{label}</Module.Title>
          <Module.Value>{value}</Module.Value>
        </Module.Column>
      ))}
    </>
  )
}
