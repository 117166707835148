/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
import { useRef, useEffect, useState } from 'react'

import axios from 'axios'
import { debounce } from 'helpers/common'
import { StyledInput } from 'helpers/form'
import { StyledDadataWrapper, StyledDadataSuggestions, StyledButton } from './Dadata.styles'

const getDadata = async ({ type, query }: any) => {
  const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${type}`
  const token = '5f9e41af2a7c7eace0a68f05444f4ac22380cfd7'

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  }

  const {
    data: { suggestions },
  } = await axios.post(url, { query, count: 10 }, options)
  return suggestions
}

export const Dadata: any = (props) => {
  const { type, onChange, placeholder, name, value } = props
  const [query, setQuery] = useState('')
  const [inputQuery, setInputQuery] = useState('')
  const [suggestionIndex, setSuggestionIndex] = useState(-1)
  const [suggestions, setSuggestions] = useState([])
  const [displaySuggestions, setDisplaySuggestions] = useState(true)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const newQuery = value ? value.value : ''
    if (query !== newQuery || inputQuery !== newQuery) {
      setInputQuery(newQuery)
      setQuery(newQuery)
    }
  }, [value])

  const performFetchSuggestions = () => {
    const value = inputRef?.current?.value || ''
    const minChars = 2
    if (typeof minChars === 'number' && minChars > 0 && value.length < minChars) {
      setSuggestionIndex(-1)
      setSuggestions([])
      return
    }
    const body = {
      type,
      query: value,
    }
    getDadata(body).then((list) => {
      setSuggestionIndex(-1)
      setSuggestions(list)
    })
  }
  const fetchSuggestions = debounce(performFetchSuggestions, 400)

  const handleInputChange = (event) => {
    const { value } = event.target
    setInputQuery(value)
    setDisplaySuggestions(!!value)
    setQuery(value)
    fetchSuggestions()
    onChange(value)
  }

  const handleInputKeyPress = (event) => {
    if (event.which === 40) {
      // Arrow down
      event.preventDefault()
      if (suggestionIndex < suggestions.length - 1) {
        const newSuggestionIndex = suggestionIndex + 1
        const newInputQuery = suggestions[newSuggestionIndex].value
        setSuggestionIndex(newSuggestionIndex)
        setQuery(newInputQuery)
      }
    } else if (event.which === 38) {
      // Arrow up
      event.preventDefault()
      if (suggestionIndex >= 0) {
        const newSuggestionIndex = suggestionIndex - 1
        const newInputQuery =
          newSuggestionIndex === -1 ? inputQuery : suggestions[newSuggestionIndex].value
        setSuggestionIndex(newSuggestionIndex)
        setQuery(newInputQuery)
      }
    } else if (event.which === 13) {
      // Enter
      event.preventDefault()
      if (suggestionIndex >= 0) {
        selectSuggestion(suggestionIndex)
      }
    }
  }

  const selectSuggestion = (index: number, isSilent = false) => {
    if (suggestions.length >= index - 1) {
      const suggestion = suggestions[index]

      setInputQuery(suggestion.value)
      setDisplaySuggestions(false)
      setQuery(suggestion.value)
      if (!isSilent) {
        fetchSuggestions()
      }

      if (onChange) {
        onChange(suggestion)
      }
    }
  }
  const handleInputFocus = () => {
    setIsFocused(true)
    if (suggestions.length === 0) {
      fetchSuggestions()
    }
  }

  const handleInputBlur = () => {
    setIsFocused(false)
    if (suggestions.length === 0) {
      fetchSuggestions()
    }
  }
  const onSuggestionClick = (index: number, event) => {
    event.stopPropagation()
    selectSuggestion(index)
  }
  const optionsExpanded = isFocused && suggestions && displaySuggestions && suggestions.length > 0
  const inputRef: any = useRef(null)

  return (
    <StyledDadataWrapper>
      <StyledInput
        autoComplete='off'
        value={query}
        ref={inputRef}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        onKeyDown={handleInputKeyPress}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder={placeholder}
      />

      {optionsExpanded && (
        <StyledDadataSuggestions id={`suggestions-${name}`} aria-expanded role='listbox'>
          {suggestions.map((suggestion, index) => (
            <StyledButton
              role='option'
              aria-selected={index === suggestionIndex ? 'true' : 'false'}
              key={index}
              onMouseDown={(e) => onSuggestionClick(index, e)}
              current={index === suggestionIndex}
            >
              <p>{suggestion.value}</p>
            </StyledButton>
          ))}
        </StyledDadataSuggestions>
      )}
    </StyledDadataWrapper>
  )
}
