/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import { calcTime, normalizeValues } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'index'

import { Button, EmptyValueReplacer } from 'components'

import { ReactComponent as AlertSVG } from 'assets/alert_rectangle.svg'
import { ReactComponent as PlaySVG } from 'assets/small-play-button.svg'
import { ReactComponent as PauseSVG } from 'assets/pause-button.svg'

import {
  fetchAssignTheLoanFromDetail,
  fetchUnAssignTheLoanFromDetail,
} from 'features/collector/extra'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import {
  StyledHeadWrapper,
  StyledHeadActionBlock,
  StyledAction,
  StyledHeadLoanInfo,
  StyledInfoWrapper,
  StyledTitle,
  StyledValue,
  StyledCloseExpandedElements,
  StyledRollIcon,
  StyledHeadTitle,
  StyledHeadLoanInfoWrapper,
  StyledFioBlock,
  StyledFioValue,
  StyledTimeLimitation,
  StyledTimeLimitationTitle,
  StyledUserTimeWrapper,
} from './Head.styles'

export const HeadInfo: React.FC<any> = ({
  onRollUp,
  onRollDown,
  loanId,
  collectorUserName,
}): JSX.Element => {
  const collectorData = useSelector((store: GlobalState) => store.auth)
  const isFetching = useSelector((store: GlobalState) => store.collector.isFetchingAssignInDetail)
  const dispatch = useDispatch()

  const inWork = !!collectorUserName
  const inWorkThisCollector = collectorData?.user?.username === collectorUserName
  const isFree = collectorUserName === ''

  const handleAssignActionsLoan = (): void => {
    dispatch(
      fetchAssignTheLoanFromDetail({
        loanId,
        collectorUserName: collectorData?.user?.username,
        onSuccess: () =>
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Заявка взята',
              description: 'Заявка успешно взята в работу',
            })
          ),
        onError: () =>
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Заявка не взята',
              description: 'Во время выполнения запроса, произошла ошибка',
            })
          ),
      })
    )
  }

  const fetchUnAssignTheLoan = (): void => {
    dispatch(
      fetchUnAssignTheLoanFromDetail({
        loanId,
        collectorUserName,
        onSuccess: () =>
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Заявка откреплена',
              description: `Заявка успешно откреплена от коллектора ${collectorUserName}  и возвращена в свободные`,
            })
          ),
        onError: () =>
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Заявка не взята',
              description: 'Во время выполнения запроса, произошла ошибка',
            })
          ),
      })
    )
  }

  return (
    <StyledHeadActionBlock>
      {isFree && (
        <Button
          disabled={isFetching}
          type='smallSuccess'
          onClick={isFetching ? null : handleAssignActionsLoan}
        >
          <PlaySVG />
          Взять в работу
        </Button>
      )}
      {inWork && !inWorkThisCollector && (
        <Button
          disabled={isFetching}
          type='smallHeader'
          onClick={isFetching ? null : fetchUnAssignTheLoan}
        >
          <PauseSVG />
          Открепить заявку от {collectorUserName} и вернуть в свободные
        </Button>
      )}
      {inWorkThisCollector && (
        <Button
          disabled={isFetching}
          type='smallHeader'
          onClick={isFetching ? null : fetchUnAssignTheLoan}
        >
          <PauseSVG />
          Вернуть в свободные
        </Button>
      )}

      <StyledAction>
        <StyledCloseExpandedElements onClick={onRollDown}>
          <StyledRollIcon>+</StyledRollIcon> Развернуть все
        </StyledCloseExpandedElements>
        <StyledCloseExpandedElements onClick={onRollUp}>
          <StyledRollIcon>-</StyledRollIcon> Свернуть все
        </StyledCloseExpandedElements>
      </StyledAction>
    </StyledHeadActionBlock>
  )
}

export const Head: React.FC<any> = (): JSX.Element => {
  const passportData = useSelector((store: GlobalState) => store.collector.passportData)
  const personalData = useSelector((store: GlobalState) => store.collector.personalData)
  const LATE_HOURS = 22
  const EARLY_HOURS = 9

  const currentUserHours = calcTime(personalData?.time_zone?.split(':')[0]).split(':')[0]

  const overTime = personalData?.time_zone
    ? currentUserHours >= LATE_HOURS || currentUserHours < EARLY_HOURS
    : false

  return (
    <StyledHeadWrapper>
      <StyledHeadLoanInfoWrapper>
        <StyledInfoWrapper>
          <StyledHeadTitle>Клиент</StyledHeadTitle>
          <StyledFioBlock>
            <StyledTitle>ФИО</StyledTitle>
            <StyledFioValue>
              {passportData &&
                normalizeValues(
                  `${passportData.last_name} ${passportData.first_name} ${passportData.middle_name}`
                )}
            </StyledFioValue>
          </StyledFioBlock>
        </StyledInfoWrapper>
      </StyledHeadLoanInfoWrapper>
      <StyledHeadLoanInfo>
        <StyledInfoWrapper>
          <StyledTitle>Номер телефона</StyledTitle>
          <StyledValue styles='boldGreen'>
            {personalData && normalizeValues(personalData.login)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Паспорт</StyledTitle>
          <StyledValue>
            {passportData && normalizeValues(`${passportData.serial} ${passportData.number}`)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Дата рождения</StyledTitle>
          <StyledValue>{passportData && normalizeValues(passportData.date_of_birth)}</StyledValue>
        </StyledInfoWrapper>

        <StyledInfoWrapper>
          <StyledTitle>Кем выдан</StyledTitle>
          <StyledValue>{passportData && normalizeValues(passportData.place_of_issue)}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Tекущее время клиента</StyledTitle>
          <StyledValue>
            {personalData ? (
              <StyledUserTimeWrapper>
                {calcTime(personalData?.time_zone.split(':')[0])}
                {overTime && (
                  <StyledTimeLimitation>
                    <AlertSVG />
                    <StyledTimeLimitationTitle>
                      Действует ограничение звонков из-за местного времени клиента! с 9 до 22
                    </StyledTimeLimitationTitle>
                  </StyledTimeLimitation>
                )}
              </StyledUserTimeWrapper>
            ) : (
              <EmptyValueReplacer />
            )}
          </StyledValue>
        </StyledInfoWrapper>
      </StyledHeadLoanInfo>
    </StyledHeadWrapper>
  )
}
