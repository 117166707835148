/* eslint-disable @typescript-eslint/no-unused-vars */
import 'react-router-dom'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { get } from 'lodash'

import { UNDERWRITER_LOANS_ROUTE, OPERATOR_LOANS_ROUTE } from '../../../constants'
import { StyledRowWrapper, StyledValue, StyledTitle } from '../Modal.styles'

export const Table: any = (props): JSX.Element => {
  const {
    createdAt,
    loanNumber,
    sum,
    passportFirstName,
    passportLastName,
    passportMiddleName,
    passportNumber,
    passportSerial,
    requestsCount,
    userLogin,
    loanId,
    regAddress,
    utmSource,
    claimsResolve,
  } = props

  const handleOpenLoan = (): void => {
    window.open(
      `${window.location.origin}${
        claimsResolve ? UNDERWRITER_LOANS_ROUTE : OPERATOR_LOANS_ROUTE
      }/${loanId}`,
      '_blank'
    )
  }

  const tableStructure = [
    { title: loanNumber, id: uuid() },
    { title: utmSource || '-----', id: uuid() },
    {
      title: moment(new Date(createdAt)).add(3, 'hours').format('DD-MM-YYYY / HH:mm'),
      id: uuid(),
    },
    { title: requestsCount, id: uuid() },
    {
      title: `${passportLastName} ${passportFirstName} ${passportMiddleName}`,
      id: uuid(),
    },
    { title: `${passportSerial} ${passportNumber} `, id: uuid() },
    { title: userLogin, id: uuid() },
    { title: sum.toLocaleString(), id: uuid() },
    { title: get(regAddress, '[0].city', '-----'), id: uuid() },
  ]

  return (
    <>
      <StyledRowWrapper indicate={requestsCount > 1} onClick={handleOpenLoan}>
        {tableStructure.map((column) => (
          <StyledValue key={column.id}>
            <StyledTitle>{column.title}</StyledTitle>
          </StyledValue>
        ))}
      </StyledRowWrapper>
    </>
  )
}
