export const STRUCTURE = [
  {
    a: [
      {
        title: 'У меня есть закрытый займ, но я не могу подать заявку',
        value: 'a02',
      },
      {
        title: 'Почему подача заявки заблокирована?',
        value: 'a03',
      },
      {
        title: 'Как долго ждать решения по заявке?',
        value: 'a04',
      },
      {
        title: 'Как воспользоваться скидкой на следующий займ? просят промо код',
        value: 'a06',
      },
      {
        title: 'Почему я не могу добавить новую карту?',
        value: 'a07',
      },
      {
        title: 'Какие критерии должны быть, чтобы добавить новую карту?',
        value: 'a08',
      },
      {
        title: 'Почему мне отказали?',
        value: 'a09',
      },
      {
        title: 'Сколько у меня есть времени, чтобы взять займ?',
        value: 'a10',
      },
      {
        title: 'Сменились паспортные данные, как изменть?',
        value: 'a11',
      },
      {
        title: 'Займ переведен! а деньги не поступили на карту!',
        value: 'a12',
      },
      {
        title: 'Сколько я должен ?',
        value: 'a13',
      },
      {
        title: 'Могу ли я повторно в этот же день взять займ?',
        value: 'a14',
      },
      {
        title: 'Как поменять номер телефона',
        value: 'a15',
      },
      {
        title: 'Не могу зайти в личный кабинет, не отправляется код',
        value: 'a16',
      },
      {
        title: 'Какие условия продления',
        value: 'a17',
      },
      {
        title: 'Верните рубль, гады',
        value: 'a18',
      },
      {
        title: 'Я не могу оплатить займ – нет денег',
        value: 'a19',
      },
      {
        title: 'Почему такая большая сумма к оплате',
        value: 'a20',
      },
      {
        title: 'Где я могу посмотреть договор займа',
        value: 'a21',
      },
      {
        title: 'Как восстановить пароль',
        value: 'a22',
      },
      {
        title: 'Не могу зарегистрироваться. Слетает после первого',
        value: 'a23',
      },
      {
        title: 'Нет именной карты',
        value: 'a24',
      },
      {
        title: 'Запрос на отключение СМС рассылки',
        value: 'a25',
      },
      {
        title: 'Деньги на карте есть, но они не списываются',
        value: 'a26',
      },
      {
        title: 'Влияет ли сумма займа на одобрение ?',
        value: 'a27',
      },
      {
        title: 'При загрузки файлов с паспортными данными сообщает что недостаточно памяти',
        value: 'a28',
      },
      {
        title: 'Досрочное погашение займа',
        value: 'a29',
      },
      {
        title: 'Как долго будет обрабатываться мой запрос',
        value: 'a30',
      },
      {
        title: 'Вы у меня сняли деньги ',
        value: 'a31',
      },
      {
        title: 'Не поступили деньги, но займ одобрен.',
        value: 'a32',
      },
      {
        title: 'Оплатил по реквизитам, деньги не поступили до сих пор',
        value: 'a33',
      },
      {
        title: 'Условия займа',
        value: 'a34',
      },
      {
        title: 'Как я могу оплатить займ ? ',
        value: 'a35',
      },
      {
        title: 'Если не приходит код регистрации',
        value: 'a36',
      },
      {
        title: 'Прошу отменить заявку',
        value: 'a37',
      },
      {
        title: 'Могу ли я платить частями? (PDL)',
        value: 'a38',
      },
      {
        title: 'Могу ли я платить частями? (Installment)',
        value: 'a39',
      },
      {
        title: 'Что делать если карта есть , но находится на перевыпуске ?',
        value: 'a40',
      },
      {
        title: 'Отключите платные подписки',
        value: 'a41',
      },
      {
        title: 'Разблокировка ЛК после закрытия займа день в день',
        value: 'a42',
      },
      {
        title: 'Не удалось оформить продление, деньги внес, а кнопки продление нет',
        value: 'a43',
      },
      {
        title: 'На время технических сбоев',
        value: 'a44',
      },
      {
        title: 'На случай проблем с Мандарином',
        value: 'a45',
      },
      {
        title: 'Ошибочное смс клиентам. Технический сбой. ',
        value: 'a46',
      },
    ],
  },
  {
    b: [
      {
        title: 'Блокировка Личного кабинета',
        value: 'b01',
        additionalRequiremnts: {
          requredAll: true,
          data: ['ЛК забокирован'],
        },
      },
    ],
  },
  {
    s: [
      {
        title: 'Справка об отсутствии и наличии задолженности (1 рабочий день)',
        value: 's01',
      },
    ],
  },
  {
    r: [
      {
        title: 'Одобрение реструктуризации',
        value: 'r01',
      },
      {
        title: 'Отказ в рестуктуризации',
        value: 'r02',
      },
    ],
  },
  {
    t: [
      {
        title:
          'Требование об отзыве согласия на обработку персональных данных (имеется незакрытый заём)',
        value: 't01',
      },
      {
        title: 'Требование об отзыве согласия на обработку персональных данных (займов нет)',
        value: 't03',
        checkAction: true,
        additionalRequiremnts: {
          requredAll: true,
          data: [
            'ЛК заблокирован',
            'Акт об уничтожении/обезличивании персональных данных - отправлен',
          ],
        },
      },
      {
        title:
          'Требование об отзыве согласия на обработку персональных данных  (займы есть закрытые)',
        value: 't04',
      },
      {
        title:
          'Требование об отказе от взаимодействия с должником путем телефонных переговоров, личных встреч (заявление не по форме или срок просрочки менее 4 месяцев)',
        value: 't06',
        checkAction: true,
        additionalRequiremnts: {
          requredAll: false,
          data: [
            'ЛК заблокирован',
            'Акт об уничтожении/обезличивании персональных данных - отправлен',
          ],
        },
      },
      {
        title:
          'Требование об отказе от взаимодействия с должником путем телефонных переговоров, личных встреч (заявление по форме и истек срок 4 месяца со дня возникновения просрочки)',
        value: 't07',
        checkAction: true,
        additionalRequiremnts: {
          requredAll: true,
          data: [
            'Заявление (Требование) представленно по форме',
            'Статус Просроченного займа более 4-х месяцев',
          ],
        },
      },
      {
        title: 'Требование об отзыве согласия на взаимодействие с третьими лицами',
        value: 't08',
      },
      {
        title: 'Требование о перерасчете начислений по договору займа',
        value: 't09',
      },
      {
        title:
          'Требование о возврате начислений по договору займа  (дополнительные услуги  оформлялись, имеется не погашенный заём, зачисление в счёт просрочки)',
        value: 't17',
        checkAction: true,
        additionalRequiremnts: {
          repited: 't17/t18/t19',
          requredAll: true,
          data: [
            'У клиента уточнили намерение о перерасчёте начислений по займу',
            'Деактивирована услуга/услуги у предыдущих займов',
            'Сделан перерасчёт действующего займа',
          ],
        },
      },
      {
        title:
          'Требование о возврате начислений по договору займа  (дополнительные услуги  оформлялись, имеется не погашенный заём, зачисление в счёт активного займа)',
        value: 't18',
        checkAction: true,
        additionalRequiremnts: {
          repited: 't17/t18/t19',
          requredAll: true,
          data: [
            'У клиента уточнили намерение о перерасчёте начислений по займу',
            'Деактивирована услуга/услуги у предыдущих займов',
            'Сделан перерасчёт действующего займа',
          ],
        },
      },
      {
        title:
          'Требование о возврате начислений по договору займа  (дополнительные услуги  оформлялись, имеется не погашенный заём, зачисление на банковский счёт)',
        value: 't19',
        checkAction: true,
        additionalRequiremnts: {
          repited: 't17/t18/t19',
          requredAll: true,
          data: [
            'У клиента уточнили намерение о перерасчёте начислений по займу',
            'Деактивирована услуга/услуги у предыдущих займов',
            'Сделан перерасчёт действующего займа',
          ],
        },
      },

      {
        title: 'Отказ от Договора страхования (о расторжении) и возврате страховой премии  ',
        value: 't20',
        subSelection: [
          {
            title: 'Форма заявления',
            value: 't20_1',
          },
          {
            title: 'Ответ',
            value: 't20_2',
          },
        ],
        additionalRequiremnts: {
          requredAll: true,
          data: [
            'Форма заявления на отказ от доп услуг отправлена Клиенту',
            'Галка по страховке премии на карту Клиента снята',
            'Ответ клиенту отправлен',
            'Документ в бухгалтерию  на перевод Клиенту отправлен',
          ],
        },
      },
      {
        title:
          'Отказ от Соглашения (о расторжении) об оказании услуги Премиум Аккаунт и возврате стоимости услуги',
        value: 't21',
        subSelection: [
          {
            title: 'Форма заявления',
            value: 't21_1',
          },
          {
            title: 'Ответ',
            value: 't21_2',
          },
        ],
        additionalRequiremnts: {
          requredAll: true,
          data: [
            'Форма заявления на отказ от доп услуг отправлена Клиенту',
            'Галка по страховке премии на карту Клиента снята',
            'Стаховка убрана из графика',
            'Ответ Клиенту отправлен',
            'Документ в бухгалтерию  на перевод Клиенту отправлен',
          ],
        },
      },
      {
        title: 'Отказ от заключения Договора страхования. (Возврата денег не происходит)',
        value: 't22',
        subSelection: [
          {
            title: 'Форма заявления',
            value: 't22_1',
          },
          {
            title: 'Ответ',
            value: 't22_2',
          },
        ],
        additionalRequiremnts: {
          requredAll: true,
          data: [
            'Форма заявления на отказ от доп услуг отправлена Клиенту?',
            'Галка по страховке премии на карту Клиента снята?',
            'Стаховка убрана из графика?',
            'Ответ Клиенту отправлен?',
          ],
        },
      },
      {
        title: 'Требование о предоставлении сведений об обработке персональных данных',
        value: 't23',
      },
      {
        title:
          'Требование о расторжении договора, прекращении начисления процентов и инициировании судебного взыскания долга',
        value: 't25',
      },
      {
        title: 'Требование об уведомлении о передачи в коллекторское агентство',
        value: 't27',
      },
      {
        title:
          'Требование о направлении копии уведомления о передачи в коллекторское агентство (если уведомление уже направлено)',
        value: 't29',
      },
      {
        title:
          'Требование об осуществлении взаимодействия, направленного на взыскание просроченной задолженности через представителя',
        value: 't31',
      },
      {
        title: 'Требование об отзыве согласия на безакцептное списание',
        value: 't33',
      },
      {
        title:
          'Требование об отзыве согласия на передачу прав требования третьим лицам (активные + банкрот + умер)',
        value: 't34',
        additionalRequiremnts: {
          requredAll: true,
          data: ['Галку на уступку сняли'],
        },
      },
      {
        title:
          'Требование об отзыве согласия на передачу прав требования третьим лицам (займы цессии)',
        value: 't35',
        additionalRequiremnts: {
          requredAll: true,
          data: [' Договоро вернули из цессии', 'Галку на уступку сняли'],
        },
      },
    ],
  },
]
