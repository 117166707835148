import { rolesType } from 'constants/rtoles'
import { useEffect, useState } from 'react'
import SnackbarProvider from 'react-simple-snackbar'
import Modal from 'react-modal'

import { useGetAgentUserNames } from 'features/dict/dictSelectors'

import { Nullable } from 'commonTypes'
import { Toaster } from 'components/Toaster'
import { UndoAction } from 'components/UndoAction'
import { UnassignedModal } from 'components/UnassignedModal'
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'
import RoutesPage from './routes/index'
import { GlobalStyle } from './core/theme'
import { StyledAppWrapper } from './App.styles'
import { NavigationContext, PermissionContext } from 'app/contexts'

Modal.setAppElement('#root')

interface INavigation {
  activeMenu: null
  headContent: string
  backTo: Nullable<string>
  slug: Nullable<string>
}
export enum NavMenuType {
  underwriter,
  agent,
  operator,
  collector,
  sdl,
  settings,
}

const App = (): JSX.Element => {
  const isDevelopment = process.env.NODE_ENV === 'development'

  const [navigation, setNavigation] = useState<INavigation>({
    activeMenu: null,
    headContent: ``,
    backTo: null,
    slug: null,
  })

  const { adminsRoles = [], agentManagerUsernames = [] } = useSelector(
    (store: any) => store.dict.data
  )
  const { username = '' } = useSelector((store: GlobalState) => store.auth?.user || {})
  const authUser = useSelector((store: any) => store.auth.user)
  const agentUsernames = useGetAgentUserNames()

  const [isSuperUser, setIsSuperUser] = useState<Nullable<boolean>>(null)
  const [isAgent, setIsAgent] = useState<Nullable<boolean>>(null)
  const [isAgentRole, setIsAgentRole] = useState<Nullable<boolean>>(null)
  const [isUnderwriterRole, setIsUnderwriterRole] = useState<Nullable<boolean>>(null)
  const [isOperatorRole, setIsOperatorRole] = useState<Nullable<boolean>>(null)
  const [isCollectorRole, setIsCollectorRole] = useState<Nullable<boolean>>(null)
  const [isManager, setIsManager] = useState<Nullable<boolean>>(null)
  const [isRegionalManager, setIsRegionalManager] = useState<Nullable<boolean>>(null)
  const [isHeadOfDirectionRole, setISHeadOfDirectionRole] = useState<Nullable<boolean>>(null)
  const [navMenuType, setNavMenuType] = useState<Nullable<keyof typeof NavMenuType>>(null)

  const [focused, setFocus] = useState(true)
  const handleFocus = () => setFocus(true)
  const handleBlur = () => setFocus(false)

  useEffect(() => {
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)
    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  })

  useEffect(() => {
    if (adminsRoles && adminsRoles.length) {
      setIsAgentRole(adminsRoles.includes(rolesType.agent))
      setIsUnderwriterRole(adminsRoles.includes(rolesType.underwriter))
      setIsOperatorRole(true)
      setIsCollectorRole(adminsRoles.includes(rolesType.collector))
      setIsRegionalManager(adminsRoles.includes(rolesType.regionalManager))
      setISHeadOfDirectionRole(adminsRoles.includes(rolesType.headOfDirection))
    }
  }, [adminsRoles])

  useEffect(() => {
    if (agentUsernames && agentUsernames.length) {
      const checkAgent = agentUsernames.find((v) => v === username)
      setIsAgent(!!checkAgent)
    }
  }, [agentUsernames])

  useEffect(() => {
    if (agentManagerUsernames && agentManagerUsernames.length) {
      const checkManager = agentManagerUsernames.find((v) => v === username)
      setIsManager(!!checkManager)
    }
  }, [agentManagerUsernames])

  useEffect(() => {
    if (authUser && authUser.is_superuser) setIsSuperUser(true)
  }, [authUser])

  return (
    <SnackbarProvider>
      <PermissionContext.Provider value={{ permissions: adminsRoles }}>
        <NavigationContext.Provider
          value={{
            focused,
            navigation,
            setNavigation,
            isDevelopment,
            isSuperUser,
            isAgent,
            isManager,
            isAgentRole,
            isOperatorRole,
            isUnderwriterRole,
            isCollectorRole,
            isHeadOfDirectionRole,
            isRegionalManager,
            navMenuType,
            setNavMenuType,
          }}
        >
          <StyledAppWrapper>
            <GlobalStyle />
            <RoutesPage />
            <Toaster />
            <UndoAction />
            <UnassignedModal />
          </StyledAppWrapper>
        </NavigationContext.Provider>
      </PermissionContext.Provider>
    </SnackbarProvider>
  )
}

export default App
