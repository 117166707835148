import styled, { css } from 'styled-components/macro'
import { THEME } from '../../core/theme'

interface IStyledCheckboxContainer {
  disabled?: boolean
}
interface IStyledCheckbox {
  disabled?: boolean
  checked?: boolean
}

export const StyledCheckboxContainerWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  cursor: pointer;
  user-select: none;
`
export const StyledCheckboxContainer = styled.div<IStyledCheckboxContainer>`
  vertical-align: middle;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      ${StyledCheckbox} {
        box-shadow: 0 0 0 1px rgb(194 214 226);
      }
    `}
`

export const StyledIcon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 2px;
`

export const StyledCheckbox = styled.div<IStyledCheckbox>`
  cursor: pointer;
  border-radius: 5px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  background: ${({ checked }) => (checked ? THEME.COLORS.YELLOW : 'white')};
  transition: all 150ms;
  box-shadow: 0 0 0 1px rgb(194 214 226);
  ${StyledIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

export const StyledLabel = styled.p`
  margin-left: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #08003a;
`
