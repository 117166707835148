/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

interface IFilterState {
  data: {
    [key: string]: string | string[]
    search?: string
  }
}

export const initialFiltersState: IFilterState = { data: {} }

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: initialFiltersState,
  reducers: {
    setFilters: (state, { payload }) => {
      state.data = payload
    },
  },
})

export const { setFilters } = filtersSlice.actions

export default filtersSlice.reducer
