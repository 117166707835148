import styled, { css } from 'styled-components/macro'
import { THEME } from '../../core/theme'

export const StyledSidebarWrapper = styled.div<{ opened: boolean }>`
  max-width: 260px;
  height: 100%;
  background: ${THEME.COLORS.DARK_GRAY};
  padding: 20px 15px 20px 25px;
  transition: all 0.3s;
  max-height: 100vh;
  overflow-y: scroll;
  transition: max-width 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }

  & svg {
    width: 143.5px;
    height: 41px;
    position: relative;
    left: -10px;
  }

  @media (max-width: 480px) {
    padding: 0;
    position: absolute;
    z-index: 999;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  ${({ opened }) =>
    opened &&
    css`
      max-width: 77px;

      @media (max-width: 480px) {
        max-width: 0px;
        left: -40px;
      }

      & svg {
        width: 45px;
      }
      ${StyledArrow} {
        & svg {
          transform: rotate(180deg);
        }
      }
    `}
`

export const StyledContainer = styled.div`
  @media (max-width: 480px) {
    margin: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 80vh;
    padding-bottom: 50%;
  }
`

export const StyledSidebarContent = styled.div`
  margin-top: 45px;
  position: relative;
  left: 10px;

  @media (max-width: 480px) {
    margin-top: 10px;
    width: calc(100% - 10px);
  }
`

export const StyledLogo = styled.div`
  width: fit-content;

  @media (max-width: 480px) {
    display: none;

    & svg {
      width: 180px;
      height: 55px;
      margin-left: 30px;
      margin-top: 10px;
    }
  }
`

export const StyledMobileRoleSelector = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
`

export const StyledArrow = styled.div`
  cursor: pointer;
  margin-top: auto;
  bottom: 25px;
  position: fixed;

  & svg {
    width: 30px;
    transform: rotate(0);
    transition: width 0.3s;
  }

  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledHead = styled.div`
  width: 100%;
  display: none;
  border-bottom: 1px solid #73717e;
  z-index: 9999999;
  padding: 15px 22px;

  & svg {
    position: relative;
    left: -50px;
  }

  @media (max-width: 480px) {
    display: flex;
    justify-content: space-between;

    & svg {
      position: relative;
      left: 0;
      width: 50px;
    }
  }
`

export const StyledMobileLogo = styled.div`
  display: block;
`

export const StyledMobileLogout = styled.div`
  display: none;

  @media (max-width: 480px) {
    opacity: 0.8;
    margin-top: 30px;
    border: 2px solid gray;
    padding: 3px 10px;
    width: 100%;
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    font-size: 26px;
    color: #fff;
    justify-content: center;

    & div {
      display: flex;
      align-items: center;
      & svg {
        width: 30px;
      }
    }
  }
`
