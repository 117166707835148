/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import { normalizeValues } from 'helpers'
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'

import {
  StyledHeadWrapper,
  StyledHeadActionBlock,
  StyledAction,
  StyledHeadLoanInfo,
  StyledInfoWrapper,
  StyledTitle,
  StyledValue,
  StyledCloseExpandedElements,
  StyledRollIcon,
  StyledHeadTitle,
  StyledHeadLoanInfoWrapper,
  StyledFioBlock,
  StyledFioValue,
} from './Head.styles'

export const HeadInfo: React.FC<any> = ({ onRollUp, onRollDown }): JSX.Element => (
  <StyledHeadActionBlock>
    <StyledAction>
      <StyledCloseExpandedElements onClick={onRollDown}>
        <StyledRollIcon>+</StyledRollIcon> Развернуть все
      </StyledCloseExpandedElements>
      <StyledCloseExpandedElements onClick={onRollUp}>
        <StyledRollIcon>-</StyledRollIcon> Свернуть все
      </StyledCloseExpandedElements>
    </StyledAction>
  </StyledHeadActionBlock>
)

export const Head: React.FC<any> = (): JSX.Element => {
  const passportData = useSelector((store: GlobalState) => store.sdl.passportData)
  const personalData = useSelector((store: GlobalState) => store.sdl.personalData)
  const docsData = useSelector((store: GlobalState) => store.sdl.docsData)

  return (
    <StyledHeadWrapper>
      <StyledHeadLoanInfoWrapper>
        <StyledInfoWrapper>
          <StyledHeadTitle>Клиент</StyledHeadTitle>
          <StyledFioBlock>
            <StyledTitle>ФИО</StyledTitle>
            <StyledFioValue>
              {passportData &&
                normalizeValues(
                  `${passportData.last_name} ${passportData.first_name} ${passportData.middle_name}`
                )}
            </StyledFioValue>
          </StyledFioBlock>
        </StyledInfoWrapper>
      </StyledHeadLoanInfoWrapper>
      <StyledHeadLoanInfo>
        <StyledInfoWrapper>
          <StyledTitle>Номер договора</StyledTitle>
          <StyledValue>323242152</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Кол-во займов</StyledTitle>
          <StyledValue>1</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Номер паспорта</StyledTitle>
          <StyledValue>
            {passportData && normalizeValues(`${passportData.serial} ${passportData.number}`)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Дата рождения</StyledTitle>
          <StyledValue>{passportData && normalizeValues(passportData.date_of_birth)}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Город</StyledTitle>
          <StyledValue>{passportData && normalizeValues(passportData.place_of_birth)}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>ИНН</StyledTitle>
          <StyledValue> {docsData && normalizeValues(docsData.inn_number)}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Номер телефона</StyledTitle>
          <StyledValue styles='boldGreen'>
            {personalData && normalizeValues(personalData.login)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>E-mail </StyledTitle>
          <StyledValue> {personalData && normalizeValues(personalData.email)}</StyledValue>
        </StyledInfoWrapper>
      </StyledHeadLoanInfo>
    </StyledHeadWrapper>
  )
}
