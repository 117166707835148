/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState: any = [0, []]

export const dictSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    addToast: (state, { payload }) => {
      const id = state[0] + 1
      return [id, [...state[1], { ...payload, id }]]
    },
    removeToast: (state, { payload }) => {
      const tmp = state[1].filter((toast) => toast.id !== payload)
      return [state[0], tmp.length ? tmp : []]
    },
  },
})

export const { addToast, removeToast } = dictSlice.actions

export default dictSlice.reducer
