/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectOperatorService } from 'services/operatorService'

export const fetchLoanDebt = createAsyncThunk<any, { loanId: string }>(
  'operator/fetchLoanDebt',
  async (props) => {
    const { loanId } = props
    const response = await ProjectOperatorService.getLoanDebt(loanId).then(({ data }) => ({
      data,
    }))
    return response
  }
)
export const fetchLoanTransactions = createAsyncThunk<any, { loan_number: string }>(
  'operator/fetchLoanTransactions',
  async (props) => {
    const { loan_number } = props
    const response = await ProjectOperatorService.getLoanTransactions(loan_number).then(
      ({ data }) => ({
        data,
      })
    )
    return response
  }
)
