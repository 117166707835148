/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useContext } from 'react'
import { uniqBy } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import StickyBox from 'react-sticky-box'

import { GlobalState } from 'index'
import { fetchSdl } from 'features/sdl/extra'
import { NavMenuType } from 'App'
import { Controls } from '../../components/Controls'
import { Loader, Search, TableHead } from '../../components'
import {
  StaledContainer,
  StyledEmptyLabel,
  StyledLoaderPage,
  StyledPageWrapper,
  StyledTableWrapper,
} from './SdlList.styles'

import { STRUCTURE } from './Table/tableStructure'
import { COLUMN_TEMPLATE } from './Table/options'
import { Table } from './Table'
import { NavigationContext } from 'app/contexts'

export const SdlList = (): JSX.Element => {
  const dispatch = useDispatch()
  const { username } = useSelector((store: GlobalState) => store.auth?.user || {})
  const { data: filters } = useSelector((store: GlobalState) => store.filters)
  const { listing, isFetching } = useSelector((store: GlobalState) => store.sdl)

  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [filteredListing, setFilteredListing] = useState<unknown[] | false>(false)
  const { setNavigation, setNavMenuType } = useContext(NavigationContext)
  useEffect(() => {
    setNavMenuType(NavMenuType.sdl)
  }, [])

  useEffect(() => {
    if (listing) setFilteredListing(uniqBy(listing, 'loans_user_id'))
  }, [listing])

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `SDL`,
      backTo: false,
    })
  }, [])

  useEffect(() => {
    if (filters?.search) dispatch(fetchSdl({ filters }))
  }, [filters])

  const toggleSearch = (): void => setShowSearch(!showSearch)

  const setShowFilters = () => null

  const filterProps = {
    toggleSearch,
    showSearch,
    setShowSearch,
    filtersVisible: false,
    setShowFilters,
    pageControlsStructure: 'sdl',
    actionType: 'sdl',
  }

  return (
    <StyledPageWrapper>
      {isFetching && (
        <StyledLoaderPage>
          <Loader />
        </StyledLoaderPage>
      )}
      <Controls {...filterProps} />
      <Search {...filterProps} pending={false} />
      <div style={{ overflow: 'auto' }} id='scrollable'>
        <StickyBox>
          <TableHead structure={STRUCTURE} template={COLUMN_TEMPLATE.all} type='all' />
        </StickyBox>
        <StyledTableWrapper>
          <>
            {filteredListing ? (
              filteredListing?.map((sdl: any, index) => (
                <StaledContainer key={index}>
                  <Table
                    status={sdl.loan_statuses_status_name}
                    loanId={sdl.loans_loan_id}
                    userId={sdl.loans_user_id}
                    loanNumber={sdl.loans_loan_number}
                    passportFirstName={sdl.passport_first_name}
                    passportLastName={sdl.passport_last_name}
                    passportMiddleName={sdl.passport_middle_name}
                    passportNumber={sdl.passport_number}
                    passportSerial={sdl.passport_serial}
                    regAddress={sdl.registration_address}
                    userLogin={sdl.users_login}
                    adminUsername={sdl.admin_username}
                    username={username}
                    type='all'
                  />
                </StaledContainer>
              ))
            ) : (
              <StyledEmptyLabel>{!isFetching && <p>Ничего не найдено</p>}</StyledEmptyLabel>
            )}
          </>
        </StyledTableWrapper>
      </div>
    </StyledPageWrapper>
  )
}
