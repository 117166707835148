import styled, { css } from 'styled-components/macro'
import { THEME } from '../../core/theme'

export const StyledPendingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledButtonWrapper = styled.div<any>`
  position: relative;
  border: 1px solid transparent;

  @media (max-width: 480px) {
    font-size: 14px !important;
    padding: 10px 20px !important;
  }

  ${({ type }) => {
    switch (type) {
      case 'standard':
        return css`
          background: ${THEME.COLORS.YELLOW};
          padding: 9px 22px;
          border-radius: 30px;
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
          color: ${THEME.COLORS.DARK_BLUE};
          cursor: pointer;
          display: inline-flex;
          align-items: center;

          &:hover {
            background: ${THEME.COLORS.YELLOW_HOVER};
          }
        `
      case 'standardBig':
        return css`
          background: ${THEME.COLORS.YELLOW};
          padding: 20px 50px;
          border-radius: 30px;
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
          color: ${THEME.COLORS.DARK_BLUE};
          cursor: pointer;
          display: inline-flex;
          align-items: center;

          &:hover {
            background: ${THEME.COLORS.YELLOW_HOVER};
          }
        `
      case 'border':
        return css`
          border-radius: 30px;
          font-weight: 800;
          line-height: 22px;
          color: ${THEME.COLORS.DARK_BLUE};
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          border: 1px solid gray;
          background: transparent;
          padding: 10px 20px;
          font-size: 14px;
          &:hover {
            background: rgb(248, 204, 70);
          }
        `
      case 'limit':
        return css`
          background: ${THEME.COLORS.YELLOW};
          padding: 20px 30px;
          border-radius: 30px;
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
          color: ${THEME.COLORS.DARK_BLUE};
          cursor: pointer;
          align-items: center;
          width: fit-content;

          &:hover {
            background: ${THEME.COLORS.YELLOW_HOVER};
          }
        `
      case 'bigSuccess':
        return css`
          background: ${THEME.COLORS.SUCCESS};
          padding: 20px 30px;
          border-radius: 30px;
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
          color: ${THEME.COLORS.WHITE};
          cursor: pointer;
          align-items: center;
          width: fit-content;
          min-width: 190px;
          text-align: center;

          &:hover {
            background: ${THEME.COLORS.SUCCESS_LIGHT};
          }
        `
      case 'bigDanger':
        return css`
          background: ${THEME.COLORS.WARNING};
          padding: 20px 30px;
          border-radius: 30px;
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
          color: ${THEME.COLORS.WHITE};
          cursor: pointer;
          align-items: center;
          width: fit-content;
          min-width: 190px;
          text-align: center;

          &:hover {
            background: ${THEME.COLORS.SNACK_ERROR};
          }
        `
      case 'emptyBig':
        return css`
          background: none;
          padding: 20px 50px;
          border-radius: 30px;
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
          color: #73717e;
          cursor: pointer;
          display: inline-flex;
          align-items: center;

          &:hover {
            color: ${THEME.COLORS.DARK_BLUE};
          }

          @media (max-width: 480px) {
            font-size: 18px;
          }
        `
      case 'emptySmall':
        return css`
          background: none;
          color: #73717e;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
          line-height: 18px;
          border-radius: 30px;
          display: inline-flex;
          padding: 9px 14px;

          &:hover {
            color: ${THEME.COLORS.DARK_BLUE};
          }
        `
      case 'smallHeader':
        return css`
          cursor: pointer;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: ${THEME.COLORS.DARK_BLUE};
          background: ${THEME.COLORS.YELLOW};
          border-radius: 30px;
          display: inline-flex;
          padding: 9px 14px;
          align-items: center;

          & svg {
            margin-right: 15px;
          }

          &:hover {
            background: ${THEME.COLORS.YELLOW_HOVER};
          }
        `
      case 'smallSuccess':
        return css`
          cursor: pointer;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: ${THEME.COLORS.WHITE};
          background: ${THEME.COLORS.SUCCESS};
          border-radius: 30px;
          display: inline-flex;
          padding: 9px 14px;
          align-items: center;

          & svg {
            margin-right: 15px;
          }

          &:hover {
            background: ${THEME.COLORS.SUCCESS_LIGHT};
          }
        `
      case 'smallDanger':
        return css`
          cursor: pointer;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: ${THEME.COLORS.WHITE};
          background: ${THEME.COLORS.WARNING};
          border-radius: 30px;
          display: inline-flex;
          padding: 9px 14px;
          align-items: center;

          & svg {
            margin-right: 15px;
          }

          &:hover {
            background: ${THEME.COLORS.SNACK_ERROR};
          }
        `
      default:
        return ''
    }
  }}
  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid #d1d1d1;
      background: white !important;
      cursor: default !important;
      color: #b3b3b3;
      user-select: none;
      &:hover {
        background: white;
      }
    `}
  ${({ pending }) =>
    pending &&
    css`
      border: 1px solid #d1d1d1;
      background: white !important;
      cursor: default !important;
      color: #b3b3b3;
      user-select: none;
      &:hover {
        background: white;
      }
    `}
  ${({ active }) =>
    active &&
    css`
      background: white !important;
      cursor: default !important;
      color: lightgray !important;
      user-select: none;
      &:hover {
        background: white !important;
      }
    `}
`
