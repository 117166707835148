/* eslint-disable @typescript-eslint/no-unused-vars */

import { StyledWrapper } from './Notification.styles'
import { TabsType } from '../types'

export const Notification = (props): JSX.Element => {
  const { toggleOpen, setActiveTab } = props

  return <StyledWrapper>notification</StyledWrapper>
}
