/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

export class ProjectOperatorService extends ServiceBase {
  protected static OPERATORS_PATH = 'operator_api'

  protected static LOANS_PATH = 'loans'

  public static getLoanDebt(loanId: string): AxiosPromise {
    return this.get(`${this.OPERATORS_PATH}/${this.LOANS_PATH}/loan_debt/${loanId}`)
  }

  public static getLoanTransactions(loan_number: string): AxiosPromise {
    return this.get(`${this.OPERATORS_PATH}/${this.LOANS_PATH}/loan_transactions/${loan_number}`)
  }
}
