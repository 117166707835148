/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { normalizeValues } from 'helpers'
import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'
import { ITableProps } from './types'
import { COLUMN_TEMPLATE } from './options'
import {
  StyledRowWrapper,
  StyledValue,
  StyledTitle,
  StyledActionTableWrapper,
  StyledUnLockActionTable,
  StyledUnlockActionTitle,
  StyledLockActionTable,
  StyledLockActionTitle,
  StyledCommentsBlock,
  StyledCommentTitle,
  StyledTextArea,
  StyledButtons,
} from './Table.styles'

import { ReactComponent as UnlockSVG } from 'assets/unlock.svg'
import { Button, ModalConfirm } from 'components'
import { fetchOnBan, fetchOnUnban } from 'features/banList/extra'
import { Nullable } from 'commonTypes'
import { resetState } from 'features/banList/banListSlice'

export const Table = (props: ITableProps): JSX.Element => {
  const {
    adminUsername,
    middleName,
    lastName,
    firstName,
    comment,
    unlockDate,
    lockDate,
    userId,
    isBanned,
    isPermanent,
    type,
  } = props

  interface IModal {
    show: boolean
    title: string
    description: string
    action?: Nullable<(adminComment: string) => void>
    style: string
    content: Nullable<'ban' | 'permanentBan' | 'unban'>
  }

  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    description: '',
    title: '',
    action: null,
    style: '',
    content: null,
  })
  const [newComment, setNewComment] = useState<string>('')

  const dispatch = useDispatch()

  const tableStructure = [
    {
      title: `${lastName} ${firstName} ${middleName}`,
      id: uuid(),
    },
    {
      title: `${normalizeValues(lockDate, 'full_date')}`,
      id: uuid(),
    },
    {
      title: `${normalizeValues(unlockDate, 'full_date')}`,
      id: uuid(),
    },
    {
      title: `${normalizeValues(comment)}`,
      id: uuid(),
    },
    {
      title: `${normalizeValues(adminUsername)}`,
      id: uuid(),
    },
    {
      title: `${isBanned ? 'ДА' : 'НЕТ'}`,
      id: uuid(),
    },
    {
      title: `${isPermanent ? 'ДА' : 'НЕТ'}`,
      id: uuid(),
    },
    {
      title: ``,
      id: uuid(),
      action: true,
    },
  ]

  const handleCloseModal = (): void => {
    setShowModal({
      show: false,
      title: '',
      action: null,
      style: '',
      description: '',
      content: null,
    })
    setNewComment('')
  }

  const handleUnLock = (userName: string, id: string): void => {
    const action = (): void => {
      dispatch(
        fetchOnUnban({
          id,
          onSuccess: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Пользователь разбанен`,
                })
              )
              dispatch(resetState())
            })
            handleCloseModal()
          },
        })
      )
    }
    setShowModal({
      show: true,
      title: `Разблокировать пользователя`,
      description: `Разблокировать пользователя ${userName}?`,
      action,
      style: 'success',
      content: 'unban',
    })
  }

  const handlePermanentLock = (userName: string, id: string): void => {
    const action = (adminComment: string): void => {
      dispatch(
        fetchOnBan({
          id,
          options: { is_permanent: true, comment: adminComment },
          onSuccess: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Пользователь забанен`,
                })
              )
              dispatch(resetState())
            })
            handleCloseModal()
          },
        })
      )
    }
    setShowModal({
      show: true,
      title: `Блокировка пользователя`,
      description: `Вы подтверждаете отправку пользователя ${userName} в бан-лист на всегда ?`,
      action,
      style: 'warning',
      content: 'permanentBan',
    })
  }

  const handleLock = (userName: string, id: string): void => {
    const action = (adminComment: string): void => {
      dispatch(
        fetchOnBan({
          id,
          options: { days: 90, comment: adminComment },
          onSuccess: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Пользователь забанен`,
                })
              )
              dispatch(resetState())
            })
          },
        })
      )
    }
    setShowModal({
      show: true,
      title: `Блокировка пользователя`,
      description: `Вы подтверждаете отправку пользователя ${userName} в бан-лист на 90 дней ?`,
      action,
      style: 'warning',
      content: 'ban',
    })
  }

  return (
    <>
      <StyledRowWrapper indicate={false} template={COLUMN_TEMPLATE.all}>
        <ModalConfirm
          isOpen={showModal.show}
          onRequestClose={handleCloseModal}
          onClose={handleCloseModal}
          description={showModal.description}
          title={showModal.title}
          action={showModal.action}
          style={showModal.style}
          buttons={showModal.content !== 'ban' && showModal.content !== 'permanentBan'}
          content={
            showModal.content === 'ban' || showModal.content === 'permanentBan'
              ? () => (
                  <>
                    <StyledCommentsBlock>
                      <StyledCommentTitle>Добавить комментарий</StyledCommentTitle>
                      <StyledTextArea
                        value={newComment || ''}
                        maxLength={999}
                        placeholder='Комментарий'
                        onChange={(e) => setNewComment(e?.target?.value)}
                      />

                      <StyledButtons>
                        <Button
                          type='bigDanger'
                          onClick={() =>
                            newComment.trim() === '' ? null : showModal.action(newComment)
                          }
                          disabled={newComment.trim() === ''}
                        >
                          Заблокировать
                        </Button>
                        <Button type='emptyBig' disabled={false} onClick={handleCloseModal}>
                          Отмена
                        </Button>
                      </StyledButtons>
                    </StyledCommentsBlock>
                  </>
                )
              : null
          }
        />

        {tableStructure.map((column) => (
          <StyledValue key={column.id}>
            <StyledTitle select={null} pending={false}>
              {column.title}
            </StyledTitle>
            {column?.action && (
              <StyledActionTableWrapper>
                {isBanned && (
                  <StyledUnLockActionTable
                    onClick={() => handleUnLock(`${lastName} ${firstName} ${middleName}`, userId)}
                  >
                    <UnlockSVG />
                    <StyledUnlockActionTitle>Снять блокировку</StyledUnlockActionTitle>
                  </StyledUnLockActionTable>
                )}
                {!isBanned && (
                  <StyledLockActionTable
                    onClick={() => handleLock(`${lastName} ${firstName} ${middleName}`, userId)}
                  >
                    <UnlockSVG />
                    <StyledLockActionTitle>Заблокировать на 90 дней</StyledLockActionTitle>
                  </StyledLockActionTable>
                )}
                {isPermanent === false && (
                  <StyledLockActionTable
                    onClick={() =>
                      handlePermanentLock(`${lastName} ${firstName} ${middleName}`, userId)
                    }
                  >
                    <UnlockSVG />
                    <StyledLockActionTitle>Заблокировать навсегда</StyledLockActionTitle>
                  </StyledLockActionTable>
                )}
              </StyledActionTableWrapper>
            )}
          </StyledValue>
        ))}
      </StyledRowWrapper>
    </>
  )
}
