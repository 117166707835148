import styled from 'styled-components/macro'
import { StyledRowWrapper } from './Table/Table.styles'
import { THEME } from 'core/theme'

export const StyledPageWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  overflow: hidden;
`

export const StyledTableWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 208px);
  position: relative;
  .infinite-scroll-component {
    overflow: inherit !important;
  }

  @media (max-width: 480px) {
    height: calc(100vh - 200px);
  }
`
//
export const StaledContainer = styled.div`
  &:nth-child(even) {
    ${StyledRowWrapper} {
      background: #ebeeff3c;
    }
  }
`

export const StyledLoaderPage = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  top: ${({ top }) => `${top}px`};
  z-index: 1111111;
`

export const StyledInfiniteLoader = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledEmptyLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`

export const StyledDesktopTable = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledMobileTable = styled.div`
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    min-width: auto;
    padding: 24px;
    margin: 0 16px;
    margin-bottom: 25px;
    border-radius: 24px;
    background: ${THEME.COLORS.WHITE};
  }
`

export const StyledWrapper = styled.div`
  @media (max-width: 480px) {
    background: #f5f6fa;
    padding-bottom: 350px;
  }
`
