import { StyledButtonWrapper } from 'components/Button/Button.styles'
import { StyledInput } from 'components/Input/Input.styles'
import styled, { css } from 'styled-components/macro'
import { StyledTableWrapper } from '../../pages/UnderwriterList/Underwriter.styles'

interface IStyledSearchBlock {
  showSearch: boolean
}

export const StyledSearchContent = styled.div`
  border: 1px solid #e8e8e8;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s linear;
  transition: padding 0.05s linear;
  display: grid;
  grid-template-columns: minmax(250px, 1fr) 140px;
  padding: 0;

  @media (max-width: 480px) {
    grid-template-columns: 1fr 100px;
  }
`

export const StyledSearchBlock = styled.div<IStyledSearchBlock>`
  visibility: hidden;

  ${({ showSearch }) =>
    showSearch &&
    css`
      visibility: visible;
      ${StyledSearchContent} {
        max-height: 300px;
        padding: 24px 37px;

        @media (max-width: 480px) {
          max-height: auto;
          padding: 5px;
          gap: 5px;
        }
      }
      ~ #scrollable ${StyledTableWrapper} {
        height: calc(100vh - 303px);

        @media (max-width: 480px) {
          height: calc(100vh - 271px);
        }
      }
    `}
`

export const StyledSearchWrapper = styled.div`
  position: relative;

  ${StyledInput} {
    border: 2px solid #f8cc46;

    @media (max-width: 480px) {
      font-weight: 500;
      border: 2px solid #ebeeff;
      box-shadow: none;
      border-radius: 0;
      padding: 15px !important;
      padding-right: 40px !important;
      border-radius: 10px !important;
      margin-right: 10px !important;

      & span {
        top: 0 !important;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }
`

export const StyledActionsBlock = styled.div<any>`
  ${StyledButtonWrapper} {
    height: 100%;
    width: 100%;
    text-align: center;
    border: 2px solid #f8cc46;
    box-sizing: border-box;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -14px;
    padding-left: 44px;
    font-size: 14px;

    @media (max-width: 480px) {
      padding: 0;
      margin: 0;
      border-radius: 10px;
      height: 50px;
      display: flex;
      justify-content: center;
      position: relative;
      top: 5px;
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      ${StyledButtonWrapper} {
        background: #ffc0005c !important;
        color: #5a5a5a;
        &:hover {
          background: #ffc0005c !important;
        }
      }
    `}
`

export const StyledReset = styled.div`
  position: absolute;
  right: 20px;
  z-index: 900;
  bottom: 18px;
  cursor: pointer;

  @media (max-width: 480px) {
    right: 10px;
  }
`
