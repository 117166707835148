import { THEME } from './theme'

export const STYLE_OPTIONS = {
  DEFAULT: {
    position: 'bottom-right',
    style: {
      backgroundColor: THEME.COLORS.WHITE,
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '22px',
      boxShadow: '0px 10px 23px rgba(0, 0, 0, 0.12)',
      color: THEME.COLORS.DARK_BLUE,
      padding: '10px 20px',
      marginBottom: '50px',
      marginRight: '150px',
    },
    closeStyle: {
      color: THEME.COLORS.DARK_BLUE,
      fontSize: '18px',
    },
  },
  SUCCESS: {
    position: 'bottom-right',
    style: {
      backgroundColor: THEME.COLORS.SNACK_SUCCESS,
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '22px',
      boxShadow: '0px 10px 23px rgba(0, 0, 0, 0.12)',
      color: THEME.COLORS.WHITE,
      padding: '10px 20px',
      marginBottom: '50px',
      marginRight: '150px',
    },
    closeStyle: {
      color: THEME.COLORS.WHITE,
      fontSize: '18px',
    },
  },
  WARNING: {
    position: 'bottom-right',
    style: {
      backgroundColor: THEME.COLORS.WARNING_YELLOW,
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '22px',
      boxShadow: '0px 10px 23px rgba(0, 0, 0, 0.12)',
      color: THEME.COLORS.DARK_BLUE,
      padding: '10px 20px',
      marginBottom: '50px',
      marginRight: '150px',
    },
    closeStyle: {
      color: THEME.COLORS.DARK_BLUE,
      fontSize: '18px',
    },
  },
  ERROR: {
    position: 'bottom-right',
    style: {
      backgroundColor: THEME.COLORS.SNACK_ERROR,
      fontSize: '20px',
      textAlign: 'center',
      borderRadius: '22px',
      boxShadow: '0px 10px 23px rgba(0, 0, 0, 0.12)',
      color: THEME.COLORS.WHITE,
      padding: '10px 20px',
      marginBottom: '50px',
      marginRight: '150px',
    },
    closeStyle: {
      color: THEME.COLORS.WHITE,
      fontSize: '18px',
    },
  },
}
