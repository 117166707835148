import { extendTheme } from '@chakra-ui/react'
import { THEME } from 'core/theme'

const themeColors = Object.keys(THEME.COLORS).reduce((acc, colorKey) => {
  acc[colorKey.toLowerCase()] = { base: THEME.COLORS[colorKey] }
  return acc
}, {})

export const chakraTheme = extendTheme({
  colors: {
    ...themeColors,
  },
})
