/* eslint-disable prefer-object-spread */
/* eslint-disable prefer-const */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { Fragment, useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'

import { fetchSdlAvailabilityData } from 'features/sdl/extra'
import { Button, CheckBox } from 'components'

import {
  StyledWrapper,
  StyledSection,
  StyledButtonsBlock,
  StyledValue,
  StyledTitle,
  StyledSdlOptionalTitle,
} from './Modules.styles'

export const Step2AdditionalSelection = ({ props }): JSX.Element => {
  const {
    setSteps,
    userId,
    documents,
    setDocuments,
    additionalSelectionStep,
    sePreviousDocumentsState,
  } = props
  const dispatch = useDispatch()
  const successAction = (): void => setSteps(2)
  const [additionalValues, setAdditionalValues] = useState({})

  useEffect(() => {
    sePreviousDocumentsState(cloneDeep(documents))
  }, [])

  const handleClickNext = (): void => {
    const replacedDocuments = cloneDeep(documents)
    Object.keys(replacedDocuments).forEach((item) => {
      Object.keys(replacedDocuments[item]).forEach((nestedItem) => {
        if (additionalValues[nestedItem]) {
          delete replacedDocuments[item][nestedItem]
          replacedDocuments[item][additionalValues[nestedItem]] = true
        }
      })
    })
    setDocuments({ ...replacedDocuments })
    dispatch(fetchSdlAvailabilityData({ userId, successAction }))
  }

  const checkIsAvailable = (): boolean => {
    let available = false
    for (let key in additionalValues) {
      if (!additionalValues[key]) {
        available = true
        break
      }
    }
    return available
  }

  const additionalSelectionStepKeys = Object.keys(additionalSelectionStep)

  useEffect(() => {
    let candidate = {}
    additionalSelectionStepKeys.forEach((item) => {
      candidate = { ...candidate, [item]: null }
    })
    setAdditionalValues(candidate)
  }, [])

  return (
    <StyledWrapper>
      <StyledSdlOptionalTitle>Выберете опцию</StyledSdlOptionalTitle>
      {additionalSelectionStepKeys.map((item) => {
        return (
          <Fragment key={item}>
            <h3>{additionalSelectionStep[item].title}</h3>
            {additionalSelectionStep[item].data.map((additionalItem, index) => {
              const isActive = additionalValues[item] === additionalItem.value
              return (
                <Fragment key={index}>
                  <StyledValue>
                    <CheckBox
                      label=''
                      active={isActive}
                      onChange={(val) => {
                        setAdditionalValues({ ...additionalValues, [item]: val })
                      }}
                      value={additionalItem.value}
                    />
                    <StyledTitle>
                      <span style={{ fontSize: '14px' }}>{additionalItem.title}</span>
                    </StyledTitle>
                  </StyledValue>
                </Fragment>
              )
            })}
          </Fragment>
        )
      })}

      <>
        <StyledSection>
          <StyledButtonsBlock>
            <Button type='standardBig' onClick={handleClickNext} disabled={checkIsAvailable()}>
              Продолжить
            </Button>
          </StyledButtonsBlock>
        </StyledSection>
      </>
    </StyledWrapper>
  )
}
