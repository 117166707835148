/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Module } from 'components'
import { normalizeValues } from 'helpers'
import { GlobalState } from 'index'
import { orderBy } from 'lodash'

import {
  StyledLoanHistoryContent,
  StyledHistoryColumnWrapper,
  StyledOverflowWrapper,
} from './Modules.styles'
import { COLLECTOR_LOANS_ROUTE, CreditProductCodes } from '../../../constants'

const moduleId = 'loansHistory'

export const LoansHistory: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const isOpen = openedModules.includes(moduleId)
  const userLoans = useSelector((store: GlobalState) => store.collector.userLoans)
  const handleExpand = (): void => onToggleModule(moduleId)

  const handleOpenLoan = (loanId: string): void => {
    window.open(`${window.location.origin}${COLLECTOR_LOANS_ROUTE}/${loanId}`, '_blank')
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            История займов
            {!userLoans || !userLoans.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <StyledOverflowWrapper>
          <StyledLoanHistoryContent>
            <TableHead />
          </StyledLoanHistoryContent>
          {userLoans &&
            userLoans.length > 0 &&
            orderBy(userLoans, ['loans_loan_date_create'], ['desc']).map((item, index) => (
              <Fragment key={item?.loans_loan_id || index}>
                <StyledHistoryColumnWrapper onClick={() => handleOpenLoan(item.loans_loan_id)}>
                  <StyledLoanHistoryContent>
                    <Table {...item} key={item?.loans_loan_id || index} />
                  </StyledLoanHistoryContent>
                </StyledHistoryColumnWrapper>
              </Fragment>
            ))}
        </StyledOverflowWrapper>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Номер заявки</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Создание заявки / Выдача займа</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Сумма займа ₽</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Тип продукта</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const {
    loans_loan_date_create: createDate,
    loans_loan_issue_date: issueDate,
    loans_loan_number: number,
    loans_loan_sum: sum,
    loans_product_id: creditProduct,
  } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(number)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {normalizeValues(createDate, 'date')} / {normalizeValues(issueDate, 'date')}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(sum)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{CreditProductCodes[creditProduct]}</Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
