/* eslint-disable react/no-array-index-key */
import { useUndoAction } from 'helpers/hooks/useUndoAction'
import { Undo } from 'lib/undo/Undo'
import { StyledUndoAction } from './UndoAction.styles'

export const UndoAction: any = ({ position }) => {
  const [toasts, removeHandler] = useUndoAction()

  return (
    <StyledUndoAction position={position}>
      {toasts?.map((i) => (
        <Undo
          key={i?.id}
          removeHandler={removeHandler}
          position={position}
          title={i?.title}
          action={i?.action}
          cancelAction={i?.cancelAction}
          id={i?.id}
        />
      ))}
    </StyledUndoAction>
  )
}

UndoAction.defaultProps = {
  position: 'bottom-right',
  isAutoDelete: true,
}
