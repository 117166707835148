/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

export class ProjectBanListService extends ServiceBase {
  protected static USERS_PATH = 'users'

  protected static BAN_LIST = 'ban-list'

  protected static COUNT_PAGE_REQUEST = 20

  public static getBannedUserBySearch(search: string): AxiosPromise {
    return this.get(
      `${this.USERS_PATH}/${
        this.BAN_LIST
      }?unlock_date_sort=desc&skip_permanent_banned=false&p=1&page_size=10&search=${search || ''}`
    )
  }
}
