/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { Module } from 'components'

import { normalizeValues } from 'helpers'

import { getPaymentsResult } from 'features/loan/extra'
import { orderBy } from 'lodash'
import {
  StyledHeadTitle,
  StyledLoanHistoryContent,
  StyledHistoryColumnWrapper,
} from './Modules.styles'

const moduleId = 'payments'

export const Payments: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const dispatch = useDispatch()
  const { loadData } = useSelector((store: GlobalState) => store.singleLoan)

  const isOpen = openedModules.includes(moduleId)
  const {
    payments: { isFetching, data },
  } = useSelector((state: GlobalState) => state.singleLoan)

  const handleExpand = (): void => onToggleModule(moduleId)

  useEffect(() => {
    if (loadData.loan_id) {
      dispatch(getPaymentsResult({ id: loadData.loan_id }))
    }
  }, [loadData.loan_id])

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <StyledHeadTitle>
            График платежей
            {!data.length ? (
              <span style={{ color: isFetching ? '#2333cb' : 'gray', opacity: '0.5' }}>
                / {isFetching ? 'запрашивается' : 'отсутствует'}
              </span>
            ) : null}
          </StyledHeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <StyledLoanHistoryContent>
          <TableHead />
        </StyledLoanHistoryContent>
        {data && data.length
          ? orderBy(data, ['payment_date'], ['asc']).map((item, index) => (
              <StyledHistoryColumnWrapper key={item?.loan_id || index}>
                <StyledLoanHistoryContent>
                  <Table {...item} key={item?.loan_id || index} />
                </StyledLoanHistoryContent>
              </StyledHistoryColumnWrapper>
            ))
          : null}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата платежа</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Сумма платежа</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Погашение основного долга</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Погашение процентов</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Остаток основного долга</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Статус платежа</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const { payment_date, payment_amount, debt_pricipal, debt_interest, debt, paid } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue> {normalizeValues(payment_date, 'date')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {payment_amount ? `${payment_amount.toLocaleString('ru-RU')}  ₽` : ''}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {debt_pricipal ? `${debt_pricipal.toLocaleString('ru-RU')}  ₽` : ''}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {debt_interest ? `${debt_interest.toLocaleString('ru-RU')}  ₽` : ''}
        </Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{debt ? `${debt.toLocaleString('ru-RU')}  ₽` : ''}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {paid ? <b style={{ color: '#24C665' }}>Оплачен</b> : 'Не оплачен'}
        </Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
