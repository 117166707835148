/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {}

export const dictSlice = createSlice({
  name: 'unassignedModal',
  initialState,
  reducers: {
    addUnassignedLoans: (state, { payload }) => {
      const newState = state
      const payloadData = payload?.data || {}

      for (const id in payloadData) {
        if (!newState[id]) {
          newState[id] = {
            ...payloadData[id],
            ignore: false,
            sound: true,
          }
        } else {
          newState[id] = {
            ...newState[id],
            sound: false,
          }
        }
      }
      for (const id in newState) {
        if (!payloadData[id]) {
          delete newState[id]
        }
      }
      return newState
    },
    ignoreUnassignedLoan: (state, { payload }) => {
      state[payload].ignore = true
      return state
    },
  },
})

export const { addUnassignedLoans, ignoreUnassignedLoan } = dictSlice.actions

export default dictSlice.reducer
