/* eslint-disable import/order */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchSdlAtype,
  fetchSdlBtype,
  fetchSdlStype,
  fetchSdlRtype,
  fetchSdlTtype,
} from 'features/sdl/extra'
import { clearReportData } from 'features/sdl/sdlSlice'

import { GlobalState } from 'index'
import {
  StyledWrapper,
  StyledSuccessBlock,
  StyledSuccessTitle,
  StyledWarningTitle,
  StyledBadgeInfo,
  StyledErrorSendBlock,
  StyledErrorSendTitle,
  StyledActionButton,
} from './Modules.styles'

import { ReactComponent as BigSuccessSVG } from 'assets/success-big.svg'
import { Badge } from 'components'
import { STRUCTURE } from '../structure'

export const Step4 = ({ props }): JSX.Element => {
  const dispatch = useDispatch()
  const { typeA, typeB, typeS, typeR, typeT } = useSelector(
    (store: GlobalState) => store.sdl.reportData
  )
  const errorSend = useSelector((store: GlobalState) => store.sdl.errorSend)
  const { userId, setShowWindow, checkedDocuments, additionalRequirements, documents } = props
  const [errorsMessages, setErrorsMessages] = useState<{ title: string; value: string }[]>([])

  useEffect(() => {
    if (checkedDocuments?.a && !typeA.data) {
      const typeAKes = Object.keys(checkedDocuments.a).filter(
        (filteredKey) => checkedDocuments.a[filteredKey]
      )
      dispatch(fetchSdlAtype({ userId, body: typeAKes }))
    }

    if (checkedDocuments?.r && !typeA.data) {
      const typeRKes = Object.keys(checkedDocuments.r).filter(
        (filteredKey) => checkedDocuments.r[filteredKey]
      )
      dispatch(fetchSdlRtype({ userId, body: typeRKes }))
    }

    if (checkedDocuments?.t && !typeT.data) {
      const typeTKes = Object.keys(checkedDocuments.t).filter(
        (filteredKey) => checkedDocuments.t[filteredKey]
      )
      dispatch(fetchSdlTtype({ userId, body: typeTKes }))
    }

    if (checkedDocuments?.b && !typeB.data) {
      const typeBKes = Object.keys(checkedDocuments.b).filter(
        (filteredKey) => checkedDocuments.b[filteredKey]
      )
      dispatch(fetchSdlBtype({ userId, body: typeBKes }))
    }

    if (checkedDocuments?.s && !typeS.data) dispatch(fetchSdlStype({ userId }))

    return () => {
      dispatch(clearReportData())
      setErrorsMessages([])
    }
  }, [])

  useEffect(() => {
    let errorKeysData = []
    if (typeA.data) {
      errorKeysData = [
        ...errorKeysData,
        ...Object.keys(typeA.data).filter((filteredKey) => {
          return typeA.data[filteredKey]?.send === false
        }),
      ]
    }
    if (typeR.data) {
      errorKeysData = [
        ...errorKeysData,
        ...Object.keys(typeR.data).filter((filteredKey) => {
          return typeR.data[filteredKey]?.send === false
        }),
      ]
    }
    if (typeT.data) {
      errorKeysData = [
        ...errorKeysData,
        ...Object.keys(typeT.data).filter((filteredKey) => {
          return typeT.data[filteredKey]?.send === false
        }),
      ]
    }
    if (typeB.data) {
      errorKeysData = [
        ...errorKeysData,
        ...Object.keys(typeB.data).filter((filteredKey) => {
          return typeB.data[filteredKey]?.send === false
        }),
      ]
    }
    if (typeS.data) {
      errorKeysData = [
        ...errorKeysData,
        ...Object.keys(typeS.data).filter((filteredKey) => {
          return typeS.data[filteredKey]?.send === false
        }),
      ]
    }

    const convertedStructure = []
    STRUCTURE.forEach((srt) => {
      const key = Object.keys(srt)[0]
      srt[key].forEach((subStr) => {
        convertedStructure.push(subStr)
      })
    })
    setErrorsMessages(convertedStructure.filter((message) => errorKeysData.includes(message.value)))
  }, [typeA.data, typeB.data, typeS.data, typeR.data, typeT.data])

  const handleOpenLetterContent = (letterId: string): void => {
    setShowWindow({
      letterId,
      show: true,
    })
  }

  return (
    <StyledWrapper>
      <StyledSuccessBlock>
        {!errorSend && <BigSuccessSVG />}
        <StyledSuccessTitle>
          {errorSend ? (
            <span style={{ color: 'red' }}>Ошибка, запрос не выполнен</span>
          ) : (
            'Запрос выполнен'
          )}{' '}
        </StyledSuccessTitle>
      </StyledSuccessBlock>

      {errorsMessages.length > 0 && (
        <StyledWarningTitle>
          Внимание следующие письма не отправились из за ошибки!
        </StyledWarningTitle>
      )}

      {errorsMessages &&
        errorsMessages.map((error) => (
          <StyledErrorSendBlock key={error.value}>
            <StyledBadgeInfo>
              <Badge type='danger' title='Не отправленно' />
            </StyledBadgeInfo>
            <StyledErrorSendTitle>{error.title}</StyledErrorSendTitle>
            <StyledActionButton active onClick={() => handleOpenLetterContent(error.value)}>
              Открыть письмо
            </StyledActionButton>
          </StyledErrorSendBlock>
        ))}
    </StyledWrapper>
  )
}
