export const scrollHorizontallyToCenter = (container, element) => {
  const containerRect = container.getBoundingClientRect()
  const elementRect = element.getBoundingClientRect()

  const containerOuterWidth = containerRect.width

  const itemOuterWidth = elementRect.width
  const itemOffsetLeft = elementRect.left
  const containerScrollLeft = container.scrollLeft

  const positionCetner = containerOuterWidth / 2 - itemOuterWidth / 2

  const scrollLeftUpd = containerScrollLeft + itemOffsetLeft - positionCetner

  container.scrollTo({
    left: scrollLeftUpd,
    behavior: 'smooth',
  })
}
