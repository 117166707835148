import styled from 'styled-components/macro'
import { THEME } from '../../../core/theme'

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const StyledTitleWrapper = styled.div`
  color: white;
  font-weight: bold;
`
export const StyledMobileSidebarMenuLeft = styled.div`
  display: flex;
  justify-content: center;
  gap: 18px;
  align-items: center;
`

export const StyledMobileSidebarMenu = styled.div`
  /* position: fixed; */
  z-index: 9999999;
  /* bottom: -2px;
  left: 0; */
  height: 62px;
  width: 100%;
  padding: 15px 18px;
  display: none;
  justify-content: space-between;
  background: ${THEME.COLORS.DARK_GRAY};

  & svg#back path {
    fill: ${THEME.COLORS.WHITE};
  }
  @media (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
  }
`
