import styled, { css } from 'styled-components'
import { Input } from '../Input'

export const StyledRangeInputWrapper = styled.div`
  position: relative;
`

export const StyledIcon = styled.div`
  position: absolute;
  right: 18px;
  bottom: 14px;
  z-index: 2;
  pointer-events: none;
`

export const StyledInput = styled(Input)`
  border-radius: 16px;
  border: 1px solid #cfced8;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `};
`

export const StyledLabel = styled.h5`
  font-size: 12px;
  letter-spacing: 0.1px;
  padding-bottom: 10px;
  margin: 0;
  font-size: 18px;
  color: #08003a;
`

export const StyledRangeSlider = styled.div`
  position: absolute;
  width: 90%;
  left: 6px;
  bottom: 0px;
  z-index: 2;
`
