export const employees = {
  less_than_10: '< 10',
  ten_fifty: '10 - 50',
  fifty_two_hundred: '50 - 200',
  greater_than_two_hundred: '> 200',
}

export const maritalStatus = {
  single: 'Холост / не замужем',
  married: 'В браке',
  widowed: 'Вдова / Вдовец',
  divorced: 'В разводе',
  cohabitation: 'совместное проживание',
}

export const taskStatus = {
  payment_promised: 'Обещанный платёж',
  payment: 'Погашение',
  payment_refused: 'Отказ от оплаты',
}

export const collectorStatus = {
  new_client: 'Новый',
  receptive: 'Контактный',
  unreceptive: 'Не контактный',
  submitted_to_court: 'Работа приостановлена (передача в суд)',
  submitted_to_ca: 'Работа приостановлена (передача в КА)',
  wrong_contact: 'Ложный контакт',
  concessions: 'Согласие на уступку',
  returned_from_ca: 'Возврат из КА',
}

export const agentFilterStatus = {
  loan_approved: 'Займ одобрен',
  failure_automatic: 'Отказ автоматический',
  agent_refusal: 'Отказ агента',
  underwriter_refusal: 'Отказ андеррайтера',
  client_refusal: 'Отказ клиента',
  bankruptcy_refusal: 'Отказ по банкротству',
}

export const employmentType = {
  finances: 'Бухгалтерия, финансы, банки',
  public_services: 'Государственные службы, НКО',
  IT: 'ИТ и Интернет, Телекоммуникации и связь',
  medicine: 'Медицина и фармация',
  education: 'Образование, наука, языки',
  security: 'Охрана, безопасность',
  PR: 'Полиграфия, издательства, СМИ, Маркетинг, реклама, PR',
  manufacturing: 'Промышленность',
  catering: 'Рестораны, кафе, общепит',
  real_estate: 'Строительство, недвижимость',
  service_industry: 'Сфера услуг',
  retailing: 'Торговля розничная',
  hotel: 'Туризм, гостиничное дело',
  other: 'Другое',
  agriculture: 'Сельское хозяйство',
}

export const workType = {
  maternity_leave: 'Декретный отпуск',
  self_employed: 'Самозанятый: предприниматель или фриланс',
  retied: 'пенсионер',
  outsource: 'Работа по найму',
  student: 'Студент',
  unemployed: 'Безработный',
  security_or_military: 'Военнослужащий / силовые структуры / охрана',
  civil_servant: 'Госслужащий',
  dependant: 'Иждивенец',
  temp_office_laborer: 'Наёмный офисный рабочий',
  temp_physical_laborer: 'Наёмный рабочий физического труда',
  sole_trader: 'ИП',
  other: 'Другое',
}

export const levelOfEducation = {
  higher: 'высшее',
  incomplete_higher: 'неоконченное высшее',
  post_secondary: 'пту, среднее специальное',
  secondary: 'среднее',
  other: 'другое',
}

export const familyStatus = {
  single: 'Не женат',
  married: 'Женат',
  widowed: 'Вдовец',
  divorced: 'Разведен',
  cohabitation: 'Гражданский брак',
}

export const loanStatuses = {
  on_check: 'На проверке',
  setting_a_limit: 'Выставление лимита',
  pending_issuance_request: 'Ожидание запроса на выдачу',
  active_credit: 'Активный кредит',
  issuance_error: 'Ошибка при выдаче',
  loan_overdue: 'Просрочен',
  underwriter_refusal: 'Отказ андеррайтера',
  failure_automatic: 'Отказ автоматический',
  client_refusal: 'Отказ клиента',
  agent_refusal: 'Отказ агента',
  customer_revision: 'Доработка клиента',
  closed: 'Закрыт',
  closed_overdue: 'Закрыт с просрочкой',
  frozen_collector: 'Заморожен Коллектором',
  signing_offer: 'Подписание оферты',
  loan_approved: 'Займ одобрен',
  requesting_loan: 'Подача заявки на займ',
  loan_issued: 'Займ выдан',
  wrong_form_data: 'Заявка заполнена не корректно',
  money_transfer: 'Перевод денег',
  loan_extended: 'Займ продлен',
  loan_repayment_date: 'День погашения займа',
  close_or_extend: 'Проверка на закрытие или продление займа',
  submitted_to_court: 'Передано в суд',
  cession: 'Передано по цессии',
  automatic_check: 'Проверка автоматическая',
  period_paid: 'Период оплачен',
  client_definition: 'Определение клиента',
  process_definition: 'Определение процесса',
  checking_new_client: 'Проверка нового клиента',
  checking_new_client_a: 'Проверка нового клиента А',
  checking_new_client_b: 'Проверка нового клиента B',
  checking_repeat_client: 'Проверка повторного клиента',
  checking_repeat_client_a: 'Проверка повторного клиента А',
  checking_repeat_client_b: 'Проверка повторного клиента B',
  ab_test_new: 'АБ тест (NEW)',
  ab_test_old: 'АБ тест (OLD)',
  bankrupt: 'Банкрот',
  client_died: 'Клиент умер',
  qiwi_validation: 'Проверка QIWI',
  pre_approved: 'Предварительно одобрен',
  extra_checks: 'Дополнительные проверки',
  agent_in_work: 'В работе у агента',
  bankruptcy_check: 'Проверка на банкротство',
  bankruptcy_refusal: 'Отказ по банкротству',
  bankruptcy_proceedings: 'Конкурсное производство',
  nbki_check: 'Проверка НБКИ',
  auth_errorr: 'Ошибка аутентификации',
  debt_discarded: 'Долг списан',
  temp_close: 'Предварительно закрыт',
}

export const LoanStatuses = {
  on_check: 'on_check',
  setting_a_limit: 'setting_a_limit',
  pending_issuance_request: 'pending_issuance_request',
  active_credit: 'active_credit',
  issuance_error: 'issuance_error',
  loan_overdue: 'loan_overdue',
  underwriter_refusal: 'underwriter_refusal',
  failure_automatic: 'failure_automatic',
  client_refusal: 'client_refusal',
  closed: 'closed',
  closed_overdue: 'closed_overdue',
  frozen_collector: 'frozen_collector',
  signing_offer: 'signing_offer',
  loan_approved: 'loan_approved',
  requesting_loan: 'requesting_loan',
  loan_issued: 'loan_issued',
  wrong_form_data: 'wrong_form_data',
  money_transfer: 'money_transfer',
  loan_extended: 'loan_extended',
  loan_repayment_date: 'loan_repayment_date',
  close_or_extend: 'close_or_extend',
  submitted_to_court: 'submitted_to_court',
  cession: 'cession',
  automatic_check: 'automatic_check',
  period_paid: 'period_paid',
  client_definition: 'client_definition',
  process_definition: 'process_definition',
  checking_new_client_a: 'checking_new_client_a',
  bankrupt: 'bankrupt',
  checking_repeat_client_a: 'checking_repeat_client_a',
  checking_new_client_b: 'checking_new_client_b',
  checking_repeat_client_b: 'checking_repeat_client_b',
  ab_test_new: 'ab_test_new',
  ab_test_old: 'ab_test_old',
  pre_approved: 'pre_approved',
  extra_checks: 'extra_checks',
  agent_in_work: 'agent_in_work',
  customer_revision: 'customer_revision',
}

export const autoCheckDict = {
  is_banned: {
    title: 'Черный список ',
    passed: 'Проверка не пройдена ',
    refusal: 'Проверка пройдена',
    reversible: true,
  },
  is_central_bank_passed: {
    title: 'Проверка по ЦБ',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_rosfinmo_passed: {
    title: 'Росфинмониторинг',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_form_data_valid: {
    title: 'Анкетная информация',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_passport_valid: {
    title: 'Паспортная информация',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_region_banned: {
    title: 'Отказной регион',
    passed: 'Регион в списке отказных',
    refusal: 'Регион не в списке отказных ',
    reversible: true,
  },
  is_employment_banned: {
    title: 'Отрасль',
    passed: 'Отрасль не списке отказных',
    refusal: 'Отрасль в списке отказных',
  },
  is_passport_fsm_passed: {
    title: 'Проверка паспорта ФСМ',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_debt_limit_exceeded: {
    title: 'Лимит долга по ФССП',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_phone_verified: {
    title: 'Проверка номер телефона',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_inn_fns_passed: {
    title: 'Проверка ИНН',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_uprid_passed: {
    title: 'Проверка по УПРИД',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  is_nbki_actual: {
    title: 'Актульность НБКИ ',
    passed: 'Отчёт актуален',
    refusal: 'Отчёт не актуален',
  },
  is_nbki_passed: {
    title: 'Проверка НБКИ',
    passed: 'Проверка пройдена',
    refusal: 'Проверка не пройдена',
  },
  fin_card: {
    title: 'ФинКарта',
    passed: 'Выдача займа одобрена',
    refusal: 'Выдача займа не одобрена',
  },
  pre_approval: {
    title: 'Предодобрение ',
    passed: 'Возможно',
    refusal: 'Невозможно',
  },
  is_phone_valid_infosphere: {
    title: 'Инфосфера  (проверка номера)',
    passed: 'Номер валиден',
    refusal: 'Номер не валиден',
  },
}

export const genderType = {
  female: 'женский',
  male: 'мужской',
}

export const documentsGroup = {
  document: 'Документы',
  statutory: 'Уставные документы',
  base: 'Базовые стандарты',
  archive: 'Архив',
}

export const familiarTypes = {
  relative: 'Родственник',
  friend: 'Друг',
  colleague: 'Коллега',
}

export const documentSubGroup = {
  statute: 'Устав',
  rules: 'Правила предоставления займов',
  conditions: 'Общие условия договора',
  individual: 'Индивидуальные условия',
  aCommon: '',
}

export const employeesNumbersDict = {
  less_than_10: 'меньше 10', // # < 10
  ten_fifty: 'от 10 до 50', // # 10 - 50
  fifty_two_hundred: 'от 50 до 200', // # 50 - 200
  greater_than_two_hundred: 'больше 200', // # > 200
}

export const workingExperienceDict = {
  8: '1 месяц',
  9: '2 месяца',
  10: '3 месяца',
  11: '4 месяца',
  12: '5 месяцев',
  7: '6 месяцев',
  1: 'от 6 месяцев до 1 года',
  2: 'от 1 до 3 лет',
  3: 'от 3 до 5 лет',
  4: 'от 5 до 10 лет',
  5: 'от 10 до 20 лет',
  6: 'более 20 лет ',
}

export const overdue = (value: string): JSX.Element | string =>
  value === '' ? <span style={{ color: 'gray', opacity: '0.5' }}>без просрочки</span> : value
