/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'index'

import {
  StyledLetterWindowHeader,
  StyledLetterWindowWrapper,
  StyledLetterTitle,
  StyledLetterWindowBody,
  StyleCloseModal,
  StyledLoaderWrapper,
} from './Modules.styles'

import { ReactComponent as CloseModalSVG } from 'assets/close-modal.svg'
import { Loader } from 'components'
import { fetchLetterContent } from 'features/sdl/extra'

export const LetterWindow = ({ setShowWindow, userId, showWindow }): JSX.Element => {
  const { letterContent } = useSelector((store: GlobalState) => store.sdl)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchLetterContent({ userId, letterId: showWindow.letterId }))
  }, [userId, showWindow.letterId])

  return (
    <StyledLetterWindowWrapper>
      <StyleCloseModal
        onClick={() =>
          setShowWindow({
            ...showWindow,
            show: false,
          })
        }
      >
        <CloseModalSVG />
      </StyleCloseModal>
      <StyledLetterWindowHeader>
        <StyledLetterTitle>Содержимое письма</StyledLetterTitle>
      </StyledLetterWindowHeader>
      {letterContent.isFetching ? (
        <StyledLoaderWrapper>
          <Loader />
        </StyledLoaderWrapper>
      ) : (
        <StyledLetterWindowBody>
          <pre
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: letterContent.content }}
          />
        </StyledLetterWindowBody>
      )}
    </StyledLetterWindowWrapper>
  )
}
