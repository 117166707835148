import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import {
  HOME_ROUTE,
  ARM_ROUTE,
  ARM_LOANS_ROUTE,
  ARM_LOAN_ID_ROUTE,
  UNDERWRITER_LOANS_ROUTE,
  UNDERWRITER_LOAN_ID_ROUTE,
  AGENT_LOANS_ROUTE,
  AGENT_LOAN_ID_ROUTE,
  SDL_ROUTE_ROUTE,
  SDL_ROUTE_ID_ROUTE,
  WAITING_LOANS_ROUTE,
  CLIENTS_ROUTE,
  CONTRACTS_ITEMS_ROUTE,
  CONTRACT_ID_ROUTE,
  AGENT_LOANS_CREATE,
  UNDERWRITER_LOAN_BAN_LIST,
  OPERATOR_LOANS_ROUTE,
  OPERATOR_LOAN_ID_ROUTE,
  COLLECTOR_LOANS_ROUTE,
  COLLECTOR_LOAN_ID_ROUTE,
  SETTINGS_DOCUMENTS_ROUTE,
} from '../constants'

import RoutesPage from './routes-page'

const RouteNotExist = () => <p>Sorry this page do not exist</p>

const Routing = (): JSX.Element => (
  <Router>
    <Switch>
      <Route
        exact
        path={[
          HOME_ROUTE,
          ARM_ROUTE,
          ARM_LOANS_ROUTE,
          ARM_LOAN_ID_ROUTE,
          UNDERWRITER_LOANS_ROUTE,
          UNDERWRITER_LOAN_ID_ROUTE,
          UNDERWRITER_LOAN_BAN_LIST,
          AGENT_LOANS_ROUTE,
          AGENT_LOAN_ID_ROUTE,
          AGENT_LOANS_CREATE,
          WAITING_LOANS_ROUTE,
          CLIENTS_ROUTE,
          CONTRACTS_ITEMS_ROUTE,
          CONTRACT_ID_ROUTE,
          SDL_ROUTE_ROUTE,
          SDL_ROUTE_ID_ROUTE,
          OPERATOR_LOANS_ROUTE,
          OPERATOR_LOAN_ID_ROUTE,
          COLLECTOR_LOANS_ROUTE,
          COLLECTOR_LOAN_ID_ROUTE,
          SETTINGS_DOCUMENTS_ROUTE,
        ]}
        component={RoutesPage}
      />
      <Route exact path='/404' component={RouteNotExist} />
      <Redirect to='/404' />
    </Switch>
  </Router>
)

export default Routing
