/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { orderBy } from 'lodash'

import { getLoanComments, createLoanComments, updateLoanComments } from 'features/loan/extra'
import { addToast } from 'features/toast/toastSlice'
import { GlobalState } from 'index'
import { Nullable } from 'commonTypes'
import { IModuleProps } from './types'
import { ICommentsData } from 'features/loan/types'

import { ToastTypes } from 'lib/toast/Toast'
import { Button, Module } from 'components'
import { getAdminFio, normalizeValues, useWindowSize } from 'helpers'

import { ReactComponent as EditButtonSVG } from 'assets/edit-button.svg'

import {
  StyledCommentsContent,
  StyledCommentsButtonBlock,
  StyledTextArea,
  StyledLimitButtons,
  StyledCommentsBlock,
  StyledCommentTitle,
  StyledEditCommentWrapper,
  StyledMobileCommentsWrapper,
  StyledMobileComment,
  StyledMobileSection,
  StyledMobileValue,
  StyledMobileDescription,
  StyledMobileCommentInfo,
} from './Modules.styles'

const moduleId = 'comments'
interface ICommentsProps extends IModuleProps {
  loan_id: string
}

export const Comments: React.FC<ICommentsProps> = ({
  openedModules,
  onToggleModule,
  loan_id,
  claimsResolve,
}): JSX.Element => {
  const dispatch = useDispatch()
  const {
    userId,
    commentsData: { data, pending },
  } = useSelector((state: GlobalState) => state.singleLoan)
  const user = useSelector((state: GlobalState) => state.auth.user)
  const isOpen = openedModules.includes(moduleId)
  const [comment, setComment] = useState<Nullable<string>>(null)
  const [showTextAria, setShowTextAria] = useState<boolean>(false)
  const [comments, setComments] = useState<ICommentsData[]>([])
  const { width } = useWindowSize()

  const handleExpand = (): void => {
    onToggleModule(moduleId)
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const filtered = [...data].sort(
        (a: any, b: any) => new Date(a.added_at).getTime() - new Date(b.added_at).getTime()
      )
      setComments(filtered)
    }
  }, [data])

  const handleClick = (): void => {
    if (comment && !pending) {
      dispatch(
        createLoanComments({
          userId,
          loanId: loan_id,
          comment,
          successAction: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Комментарий добавлен`,
                })
              )
              dispatch(getLoanComments({ id: userId }))
            })
          },
          errorAction: () => {
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: `Комментарий не добавлен`,
              })
            )
          },
        })
      )
      setShowTextAria(false)
      setComment(null)
    }
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            Комментарии
            {comments.length === 0 ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {width >= 480 ? (
          <StyledCommentsContent>
            <TableHead />
            {comments.length > 0 &&
              orderBy(comments, ['added_at'], ['desc']).map((item) => (
                <Table {...item} key={item?.added_at} user={user} userId={userId} />
              ))}
          </StyledCommentsContent>
        ) : (
          <StyledMobileCommentsWrapper>
            {comments.length > 0 &&
              orderBy(comments, ['added_at'], ['desc']).map((item) => (
                <MobileTable {...item} key={item?.added_at} user={user} userId={userId} />
              ))}
          </StyledMobileCommentsWrapper>
        )}

        {showTextAria ? (
          <StyledCommentsBlock>
            <StyledCommentTitle>Добавить комментарий</StyledCommentTitle>
            <StyledTextArea
              value={comment || ''}
              maxLength={1200}
              placeholder='Комментарий'
              onChange={(e) => setComment(e?.target?.value)}
            />
            <StyledLimitButtons>
              <Button type='bigSuccess' onClick={handleClick} disabled={pending || !comment}>
                Сохранить
              </Button>
              <Button
                type='emptyBig'
                disabled={pending}
                onClick={() => {
                  setShowTextAria(false)
                  setComment(null)
                }}
              >
                Отмена
              </Button>
            </StyledLimitButtons>
          </StyledCommentsBlock>
        ) : (
          <>
            {claimsResolve && (
              <StyledCommentsButtonBlock>
                <Button
                  disabled={false}
                  type='bigSuccess'
                  pending={false}
                  onClick={() => setShowTextAria(true)}
                >
                  Добавить новый
                </Button>
              </StyledCommentsButtonBlock>
            )}
          </>
        )}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const MobileTable = (props): JSX.Element => {
  const {
    username,
    comment,
    added_at: time,
    user,
    userId,
    comment_id: commentId,
    admin_id: adminId,
  } = props
  const dispatch = useDispatch()
  const [editable, setEditable] = useState<boolean>(false)
  const [editComment, setEditComment] = useState<string>('')
  const dictData = useSelector((state: GlobalState) => state.dict)

  useEffect(() => {
    setEditComment(comment)
  }, [])

  const handleSave = (disabled: boolean): void => {
    if (!disabled) setEditable(!editable)
    dispatch(
      updateLoanComments({
        loanId: commentId,
        comment: editComment,
        successAction: () => {
          batch(() => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Комментарий обновлен`,
              })
            )
            dispatch(getLoanComments({ id: userId }))
          })
        },
        errorAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Ошибка',
              description: `Комментарий не обновнен`,
            })
          )
        },
      })
    )
  }

  const handleClick = (): void => setEditable(!editable)

  const handleCancel = (): void => {
    setEditComment(comment)
    setEditable(false)
  }

  return (
    <StyledMobileComment>
      <StyledMobileSection>
        <StyledMobileCommentInfo>
          <div>
            <StyledMobileDescription>Дата и время</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(time, 'full_date')}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Пользователь</StyledMobileDescription>
            <StyledMobileValue>{getAdminFio(username, dictData)}</StyledMobileValue>
          </div>
        </StyledMobileCommentInfo>
      </StyledMobileSection>
      <div>
        {editable ? (
          <>
            <p style={{ marginBottom: '30px' }}>Изменить комментарий</p>
            <StyledTextArea
              value={editComment || ''}
              placeholder='Комментарий'
              onChange={(e) => setEditComment(e?.target?.value)}
            />

            <StyledLimitButtons>
              <Button
                type='standardBig'
                onClick={() => handleSave(editComment === comment)}
                disabled={editComment === comment}
              >
                Сохранить
              </Button>
              <Button type='emptyBig' disabled={false} onClick={handleCancel}>
                Отмена
              </Button>
            </StyledLimitButtons>
          </>
        ) : (
          <span style={{ fontSize: '12px' }}>{normalizeValues(comment)}</span>
        )}
        {user?.admin_id === adminId && (
          <StyledEditCommentWrapper onClick={handleClick} editable={editable}>
            <EditButtonSVG />
          </StyledEditCommentWrapper>
        )}
      </div>
    </StyledMobileComment>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата и время</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Пользователь</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Комментарий</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const {
    username,
    comment,
    added_at: time,
    user,
    userId,
    comment_id: commentId,
    admin_id: adminId,
  } = props
  const dispatch = useDispatch()
  const [editable, setEditable] = useState<boolean>(false)
  const [editComment, setEditComment] = useState<string>('')
  const dictData = useSelector((state: GlobalState) => state.dict)

  useEffect(() => {
    setEditComment(comment)
  }, [])

  const handleSave = (disabled: boolean): void => {
    if (!disabled) setEditable(!editable)
    dispatch(
      updateLoanComments({
        loanId: commentId,
        comment: editComment,
        successAction: () => {
          batch(() => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Комментарий обновлен`,
              })
            )
            dispatch(getLoanComments({ id: userId }))
          })
        },
        errorAction: () => {
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Ошибка',
              description: `Комментарий не обновнен`,
            })
          )
        },
      })
    )
  }

  const handleClick = (): void => setEditable(!editable)

  const handleCancel = (): void => {
    setEditComment(comment)
    setEditable(false)
  }

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(time, 'full_date')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{getAdminFio(username, dictData)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          {editable ? (
            <>
              <p style={{ marginBottom: '30px' }}>Изменить комментарий</p>
              <StyledTextArea
                value={editComment || ''}
                placeholder='Комментарий'
                onChange={(e) => setEditComment(e?.target?.value)}
                style={{ width: 'calc(100% - 80px)' }}
              />

              <StyledLimitButtons>
                <Button
                  type='standardBig'
                  onClick={() => handleSave(editComment === comment)}
                  disabled={editComment === comment}
                >
                  Сохранить
                </Button>
                <Button type='emptyBig' disabled={false} onClick={handleCancel}>
                  Отмена
                </Button>
              </StyledLimitButtons>
            </>
          ) : (
            <span style={{ marginRight: '60px' }}>{normalizeValues(comment)}</span>
          )}
        </Module.TableValue>
        {user?.admin_id === adminId && (
          <StyledEditCommentWrapper onClick={handleClick} editable={editable}>
            <EditButtonSVG />
          </StyledEditCommentWrapper>
        )}
      </Module.TableColumn>
    </>
  )
}
