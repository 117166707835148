import React, { useEffect, useState } from 'react'
import { ICheckBox } from './types'
import {
  StyledCheckboxContainerWrapper,
  StyledCheckboxContainer,
  StyledIcon,
  StyledCheckbox,
  StyledLabel,
} from './CheckBox.styles'

export const CheckBox: React.FC<ICheckBox> = ({
  active,
  onChange,
  value,
  disabled,
  label = null,
}) => {
  const [checked, setChecked] = useState<boolean>(active)

  const toggleCheckboxChange = () => {
    !disabled && onChange && onChange(value)
  }

  useEffect(() => {
    setChecked(active)
  }, [active])

  return (
    <StyledCheckboxContainerWrapper onClick={toggleCheckboxChange}>
      <StyledCheckboxContainer>
        <StyledCheckbox checked={checked}>
          <StyledIcon viewBox='0 0 24 24'>
            <polyline points='20 6 9 17 4 12' />
          </StyledIcon>
        </StyledCheckbox>
      </StyledCheckboxContainer>
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledCheckboxContainerWrapper>
  )
}
