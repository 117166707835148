/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch, batch } from 'react-redux'
import { isEqual } from 'lodash'
import { Formik } from 'formik'

import {
  StyledHr,
  StyledInfoTitle,
  StyledInfoContent,
  StyledInfoDescTitle,
  StyledInfoDescContent,
  StyledButtonBlockModal,
  StyledInfoContentWrapper,
} from '../Modal.styles'

import { fetchPassportData, updateRegistrationData } from 'features/loan/extra'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'

import { InputField, validationSchemaRegistration } from 'helpers'

import { Button } from 'components/Button'
import { EditInPassport } from 'components'
import { generatePassportValue } from 'components/EditInPassport/EditInPassport'

export const RegistrationData = (props): JSX.Element => {
  const { isOpenItem, handleOpen, registration, isEditable, userId, inPassport, claimsResolve } =
    props
  const [inPassportEditable, setInPassportEditable] = useState(false)
  const [inPassportContent, setInPassportContent] = useState('')
  const dispatch = useDispatch()

  const getInPassportValue: string = useMemo(() => {
    return generatePassportValue(registration)
  }, [registration])

  useEffect(() => {
    if (!inPassport) setInPassportContent(getInPassportValue)
    else setInPassportContent(inPassport)
  }, [inPassport, getInPassportValue])

  const isValidForm = useCallback((errors) => !!Object.keys(errors).length, [])

  const allowEdit = (): boolean => claimsResolve && isEditable

  const equalInPassport: boolean = isEqual(
    !inPassport ? getInPassportValue : inPassport,
    inPassportContent
  )

  const handleChangeInPassportContent = useCallback((evt) => {
    setInPassportContent(evt.target.value)
  }, [])

  const handleResetInPassport = useCallback(() => {
    setInPassportEditable(false)
    setInPassportContent(!inPassport ? getInPassportValue : inPassport)
  }, [inPassport, getInPassportValue])

  const handleCopyInPassport = useCallback(
    () => setInPassportContent(getInPassportValue),
    [getInPassportValue]
  )

  const handleCancelEditInPassport = useCallback(
    () => handleResetInPassport(),
    [handleResetInPassport]
  )

  const handleSubmitRegistration = useCallback(
    (resetForm, values) => {
      const {
        postal_code,
        region,
        district,
        city,
        street,
        building,
        house_number,
        block,
        apartment,
        other,
      } = values

      const body = {
        postal_code,
        region,
        city,
        street,
        house_number,
        // optional
        ...(district && { district }),
        ...(building && { building }),
        ...(block && { block }),
        ...(apartment && { apartment }),
        ...(other && { other }),
        ...(!equalInPassport && { in_passport: inPassportContent.replace(/\s+/g, ' ').trim() }), //  Убираем лишние пробелы
      }

      dispatch(
        updateRegistrationData({
          id: userId,
          body,
          values,
          onError: () => {
            dispatch(
              addToast({
                type: ToastTypes.warning,
                title: 'Ошибка',
                description: `Данные не сохранены`,
              })
            )
          },
          onSuccess: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Данные сохранены`,
                })
              )
              dispatch(fetchPassportData({ id: userId }))
            })
          },
        })
      )
      resetForm()
    },
    [dispatch, userId, equalInPassport, inPassportContent]
  )

  const valuesArray = [
    { fieldName: 'Регион', key: 'region', value: registration?.region },
    { fieldName: 'Район', key: 'district', value: registration?.district },
    { fieldName: 'Город', key: 'city', value: registration?.city },
    { fieldName: 'Улица', key: 'street', value: registration?.street },
    { fieldName: 'Корпус', key: 'block', value: registration?.block || '---' },
    { fieldName: 'Строение', key: 'building', value: registration?.building || '---' },
    { fieldName: 'Дом', key: 'house_number', value: registration?.house_number },
    { fieldName: 'Квартира', key: 'apartment', value: registration?.apartment },
  ]

  const editInPassportProps = {
    inPassportEditable,
    getInPassportValue,
    handleChangeInPassportContent,
    inPassportContent,
    handleResetInPassport,
    handleCopyInPassport,
    setInPassportEditable,
    equalInPassport,
  }

  return (
    <StyledInfoContentWrapper
      isOpen={isOpenItem.id === 'registration'}
      onClick={handleOpen({ id: 'registration' })}
    >
      <Formik
        initialValues={registration}
        enableReinitialize
        onSubmit={() => null}
        validationSchema={validationSchemaRegistration}
      >
        {(formikProps) => {
          const { errors, dirty, resetForm, setFieldValue, values, initialValues } = formikProps

          return (
            <div>
              <StyledInfoTitle>Прописка</StyledInfoTitle>
              {valuesArray.map(({ fieldName, key, value }, index) => (
                <StyledInfoContent edit={isEditable} key={index}>
                  {!allowEdit() ? (
                    <>
                      <StyledInfoDescTitle>{fieldName}</StyledInfoDescTitle>
                      <StyledInfoDescContent>{value}</StyledInfoDescContent>
                    </>
                  ) : (
                    <InputField
                      name={key}
                      onChange={setFieldValue}
                      placeholder={fieldName}
                      value={values?.[key]}
                      noMove
                      style={{ minHeight: '50px', height: '50px' }}
                      {...formikProps}
                    />
                  )}
                </StyledInfoContent>
              ))}
              <StyledInfoContent>
                <EditInPassport {...editInPassportProps} />
              </StyledInfoContent>
              <StyledInfoContent>
                <StyledButtonBlockModal>
                  {(dirty || !equalInPassport) && (
                    <>
                      <Button
                        type='smallSuccess'
                        onClick={() =>
                          isValidForm(errors) ? null : handleSubmitRegistration(resetForm, values)
                        }
                        disabled={isValidForm(errors)}
                      >
                        Сохранить
                      </Button>
                      <Button
                        type='emptySmall'
                        onClick={() => {
                          handleCancelEditInPassport()
                          resetForm()
                        }}
                      >
                        Отменить
                      </Button>
                    </>
                  )}
                </StyledButtonBlockModal>
              </StyledInfoContent>
              <br />
              <StyledHr />
            </div>
          )
        }}
      </Formik>
    </StyledInfoContentWrapper>
  )
}
