/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import styled, { css } from 'styled-components/macro'
import { THEME } from '../../../core/theme'

const STYLED_OPTIONS = {
  boldGreen: {
    color: THEME.COLORS.SUCCESS,
    fontSize: '16px',
    fontWeight: 'bold',
  },
}
export const StyledPassportWrapper = styled.div`
  display: flex;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const StyledMobileCommentsWrapper = styled.div`
  background: #f5f6fa;
  padding: 20px 0;

  @media (max-width: 480px) {
    margin: -20px;
  }
`

export const StyledRefuseDescription = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.GRAY};
  margin-top: 16px;

  @media (max-width: 420px) {
    margin-top: 0px;
  }
`

export const StyledRefuseTypesWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: 420px) {
    margin-top: 10px;
  }
`

export const StyledRefuseTypeTextWrapper = styled.div`
  margin-top: 40px;

  @media (max-width: 420px) {
    margin-top: 12px;
  }
`

export const StyledRefuseTypeTitle = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 40px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${THEME.COLORS.BLUE};
      color: ${THEME.COLORS.WHITE};
      cursor: default;
    `};

  @media (max-width: 420px) {
    padding: 5px 8px;
    font-size: 14px;
    font-weight: 600;
  }
`

export const StyledMobileSection = styled.div`
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  padding-bottom: 15px;
  margin-bottom: 15px;
`

export const StyledMobileDescription = styled.p`
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: ${THEME.COLORS.GRAY};
`

export const StyledMobileCommentInfo = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`

export const StyledMobileValue = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  margin-top: 5px;
`

export const StyledMobileComment = styled.div`
  position: relative;
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    min-width: auto;
    padding: 24px;
    margin: 0 16px;
    margin-bottom: 7px;
    background: ${THEME.COLORS.WHITE};
  }
`

export const StyledHistoryMobileTableWrapper = styled.div`
  display: none;
  flex-direction: column;

  @media (max-width: 480px) {
    display: flex;
  }
`
export const StyledHistoryMobileItem = styled.div`
  display: grid;
  gap: 5px 0;
  grid-template-columns: 1fr 1fr;
  margin: 15px 20px;
  padding: 15px 12px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
`

export const StyledItemTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #73717e;
  text-align: center;
  margin: 8px;
`

export const StyledDescription = styled.p<{ styles?: string }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #08003a;
  text-align: center;
  margin: 8px;

  ${({ styles }) =>
    styles &&
    css`
      ${STYLED_OPTIONS[styles]}
    `}
`

export const StyledButtonBlock = styled.div`
  margin: 0;
  padding: 0 32px;
  transition: 0.3s;
`
export const StyledSelectBlock = styled.div`
  position: relative;
`
export const StyledResetFilter = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: white;
  cursor: pointer;
`

export const StyledHistoryColumnWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: ${THEME.COLORS.TABLE_HOVER};
  }
`
export const StyledLoanHistoryContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
`
export const StyledOverflowWrapper = styled.div`
  overflow: visible;

  @media (max-width: 480px) {
    overflow: auto;
  }
`

export const StyledImageDescription = styled.div`
  padding-bottom: 8px;
  margin-bottom: 5px;
  margin-top: 10px;

  &:not(:first-child) {
    padding-top: 20px;
  }
`

export const StyledPassportPhotoWrapper = styled.div`
  width: 50%;
  border-right: 1px solid #dfe0eb;
  margin-right: 20px;

  @media (max-width: 480px) {
    width: 100%;
    border-right: 1px solid transparent;
    margin-right: 0;
  }
`

export const StyledPersonalPhotoWrapper = styled.div`
  width: 50%;
  @media (max-width: 480px) {
    width: 100%;
  }
`

export const StyledPassportPhotoItem = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    display: block;
  }
`

export const StyledHeadTitle = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding: 15px 0;

  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 15px;
  }
`

export const StyledLimitWrapper = styled.div`
  padding: 0 32px;

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`

export const StyledLimitTitle = styled(StyledHeadTitle)`
  margin: 32px 0;

  @media (max-width: 480px) {
    margin: 15px 0;
  }
`

export const StyledLimitButtons = styled.div`
  display: flex;
  margin-top: 15px;
  gap: 15px;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;
  }
`

export const StyledTextArea = styled.textarea<any>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  width: 100%;
  max-width: 640px;
  height: 120px;
  resize: none;

  &:focus {
    outline: none;
  }

  @media (max-width: 480px) {
    width: 95%;
  }

  @media (max-width: 420px) {
    padding: 8px;
  }
`

export const StyledCommentsContent = styled.div`
  display: grid;
  grid-template-columns: 180px 180px 1fr;
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
`
export const StyledCommentsButtonBlock = styled.div`
  margin: 30px;
`

export const StyledCommentsBlock = styled.div`
  padding: 0 0 30px 30px;
`

export const StyledCommentTitle = styled.p`
  margin: 30px 0;
  font-weight: bold;
`

export const StyledEditCommentWrapper = styled.div<{ editable?: boolean }>`
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  border-bottom: 1px dotted ${THEME.COLORS.TABLE_BORDER};

  & svg {
    cursor: pointer;
  }

  ${({ editable }) =>
    editable &&
    css`
      & svg path {
        fill: ${THEME.COLORS.BLUE};
      }
    `}

  @media (max-width: 480px) {
    right: 15px;
    top: 26px;
    border-bottom: none;
  }
`

export const StyledRefuseWrapper = styled.div``

export const StyledRefuseButtonWrapper = styled.div`
  margin-top: 36px;

  @media (max-width: 480px) {
    margin-top: 16px;
  }
`

export const StyledTitleWinActionButton = styled.div`
  display: flex;
  align-items: center;
`

export const StyledActionButton = styled.div<{
  fetching?: boolean
}>`
  margin-left: 15px;
  font-size: 14px;
  background: #24c665;
  color: white;
  border-radius: 15px;
  padding: 2px 8px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  opacity: 0.8;
  position: relative;
  top: -4px;

  &:hover {
    opacity: 1;
  }

  ${({ fetching }) =>
    fetching &&
    css`
      color: ${THEME.COLORS.LIGHTLY_GRAY};
      pointer-events: none;
      cursor: auto;

      & svg path {
        fill: ${THEME.COLORS.LIGHTLY_GRAY};
      }
    `}

  & svg {
    color: ${THEME.COLORS.WHITE};
    margin-left: 5px;
  }
`

export const StyledBanInfo = styled.p`
  margin-top: 15px;
  background: ${THEME.COLORS.RED};
  width: fit-content;
  color: white;
  padding: 3px 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18px;

  & svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
    & svg {
      width: 14px;
      height: 14px;
      margin-right: 3px;
      position: relative;
      top: -1px;
    }
  }
`
