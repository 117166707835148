/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as HomeSVG } from 'assets/sidebar_home.svg'
import { ReactComponent as LineSVG } from 'assets/sidebar_line.svg'
import { ReactComponent as UserSVG } from 'assets/sidebar_user.svg'
import { ReactComponent as BillSVG } from 'assets/sidebar_bill.svg'
import { ReactComponent as CloseSVG } from 'assets/sidebar-close.svg'
import { ReactComponent as BackToSVG } from 'assets/back-arrow.svg'
import { ReactComponent as PlusSVG } from 'assets/plus.svg'

import { useHistory, useLocation } from 'react-router-dom'
import {
  StyledMobileSidebarMenu,
  StyledIconWrapper,
  StyledTitleWrapper,
  StyledMobileSidebarMenuLeft,
} from './MobileSidebarMenu.styles'
import { TabsType } from '../types'
import { useContext, useEffect, useState } from 'react'
import { NavigationContext } from 'app/contexts/NavigationContext'
import { StyledCreateLoan } from 'components/Header/Header.styles'
import { NavMenuType } from 'App'
import { ReactComponent as ShortLogoSVG } from 'assets/short-logo.svg'

export const MobileSidebarMenu = (props: any): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const { opened, setOpened, setActiveTab } = props
  const {
    isAgentRole,
    navMenuType,
    navigation: { headContent, backTo },
  } = useContext(NavigationContext)

  const [headData, setHeadData] = useState<any>({
    content: '',
    backTo: '',
  })

  useEffect(() => {
    const splN = headContent.split('№')
    let content = headContent
    if (splN.length > 1) {
      content = `№${splN[splN.length - 1]}`
    }
    setHeadData({
      content,
      backTo,
    })
  }, [headContent, backTo])

  const handleClick = (tab): void => {
    setActiveTab(tab)
    setOpened(false)
  }
  const toggleOpen = (): void => {
    setOpened(!opened)
  }
  const handleClickToBack = (): void => {
    if (!backTo) return
    history.push(`${backTo}`, { from: location.pathname })
  }
  const handleCreateLoan = () => {
    history.push('/agent/create-loan')
  }

  return (
    <StyledMobileSidebarMenu>
      {/* <StyledIconWrapper>
        <HomeSVG />
      </StyledIconWrapper> */}

      <StyledMobileSidebarMenuLeft>
        {!opened ? (
          <CloseSVG onClick={toggleOpen} />
        ) : (
          <StyledIconWrapper onClick={() => handleClick(TabsType.menu)}>
            <LineSVG />
          </StyledIconWrapper>
        )}

        <StyledTitleWrapper>{headData.content}</StyledTitleWrapper>
      </StyledMobileSidebarMenuLeft>

      <StyledMobileSidebarMenuLeft>
        {opened && isAgentRole && navMenuType === NavMenuType.agent && (
          <StyledCreateLoan onClick={handleCreateLoan}>
            <PlusSVG />
          </StyledCreateLoan>
        )}
        {opened && backTo && <BackToSVG onClick={handleClickToBack} id='back' />}
        {!opened && <ShortLogoSVG />}
      </StyledMobileSidebarMenuLeft>
    </StyledMobileSidebarMenu>
  )
}
