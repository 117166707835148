export const MIN_WIDTH = '1500px'

export const COLUMN_TEMPLATE = {
  underwriter: {
    all: `
      minmax(100px, 1fr) minmax(80px, 200px) minmax(150px, 1fr)
      minmax(100px, 180px) minmax(50px, 150px) minmax(150px, 1fr) minmax(60px, 150px)
      minmax(120px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) 
      `,
    unassigned: `
    minmax(100px, 1fr) minmax(140px, 1fr) minmax(100px, 180px)
    minmax(50px, 150px) minmax(100px, 1fr) minmax(60px, 1fr)
    minmax(120px, 1fr) minmax(60px, 150px) minmax(100px, 1fr) 
      `,
    pre_approved: `
      minmax(100px, 1fr) minmax(140px, 1fr) minmax(150px, 230px)
      minmax(50px, 150px) minmax(100px, 1fr) minmax(60px, 1fr)
      minmax(120px, 1fr) minmax(60px, 150px) minmax(100px, 1fr) 
      minmax(100px, 1fr)
        `,
    processing: `
    minmax(100px, 1fr) minmax(140px, 1fr) minmax(100px, 180px)
    minmax(50px, 150px) minmax(100px, 1fr) minmax(60px, 1fr)
    minmax(120px, 1fr) minmax(60px, 150px) minmax(100px, 1fr) 
      `,
  },
  operator: {
    all: `
    minmax(100px, 1fr) minmax(150px, 300px) minmax(150px, 1fr)
    minmax(100px, 180px) minmax(100px, 150px) minmax(150px, 1fr) minmax(60px, 150px)
    minmax(120px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)  minmax(100px, 1fr)  minmax(100px, 1fr) 
    `,
  },
}
