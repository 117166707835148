import styled, { css } from 'styled-components/macro'
import { THEME } from '../../core/theme'

interface IStyledPlaceholder {
  error: boolean
}
interface IStyledInput {
  noMove: boolean
  placeholderTop: boolean
  error: boolean
  ph: boolean
}

export const PlaceholderTop = css`
  top: 8px;
  bottom: 10px;
  left: 16px;
  font-size: 12px;
  opacity: 1;
`

export const NoPlaceholderTop = css`
  display: none;
`

export const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const StyledPlaceholder = styled.span<IStyledPlaceholder>`
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 21px;
  transition: 0.2s ease all;
  z-index: 1;
  font-size: 14px;
  line-height: 20px;
  /* color: rgb(45 54 70 / 0.5); */
  color: #808080;
  font-weight: 300;

  ${({ error }) =>
    error &&
    css`
      color: #ff601a;
    `};

  @media (max-width: 480px) {
    font-size: 14px;
    top: 10px;
  }
`

export const StyledInput = styled.input<IStyledInput | any>`
  outline: 0;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: 600;
  position: relative;
  z-index: 1;
  background: ${THEME.COLORS.WHITE};
  padding-left: 16px;
  box-shadow: 0px 1px 3px rgba(0, 44, 119, 0.04);
  border-radius: 3px;
  font-size: 14px;
  color: ${THEME.COLORS.DARK_BLUE};
  min-height: 60px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
  &:focus ~ ${StyledPlaceholder} {
    ${PlaceholderTop}
  }

  ${({ value, isValue, noMove }) =>
    (value || isValue || noMove) &&
    css`
      ~ ${StyledPlaceholder} {
        ${PlaceholderTop}
      }
      &:invalid ~ ${StyledPlaceholder} {
        color: #ff601a;
      }
    `}

  ${({ error, ph }) =>
    error &&
    !ph &&
    css`
      color: #ff601a;
    `};

  ${({ ph }) =>
    ph &&
    css`
      padding-top: 16px;
    `}
`
