/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'

import { Module } from 'components'

import { normalizeValues, validationSchemaBankrupt } from 'helpers'

import { IModuleProps } from './types'
import { GlobalState } from 'index'

const moduleId = 'information'

export const Information: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
}): JSX.Element => {
  const [initialValues, setInitialValues] = useState<any>(false)
  const { data, isFetching } = useSelector(
    (store: GlobalState) => store.singleLoan.loanGeneralInfoData
  )

  const isOpen = openedModules.includes(moduleId)

  const handleExpand = (): void => onToggleModule(moduleId)

  useEffect(() => {
    if (data) setInitialValues(data)
  }, [data])

  const submitForm = (values: any) => {
    setInitialValues(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaBankrupt}
    >
      {(formikProps) => {
        return (
          <Module.Wrapper isOpen={isOpen} editable={false} id={moduleId}>
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>
                  Информация по займу{' '}
                  {!data ? (
                    <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
                  ) : null}
                </Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <>
                <Module.Content>
                  {isFetching && <Module.LoaderBlock />}
                  <>{data && <View {...formikProps} bankruptData={initialValues} />}</>
                </Module.Content>
              </>
            </Module.ContentWrapper>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const View = (props): JSX.Element => {
  const { values } = props

  return (
    <>
      <Module.Column column={1}>
        <Module.Title>Ближайший платеж</Module.Title>
        <Module.Value>{normalizeValues(values?.weekly_payment_sum)}</Module.Value>
      </Module.Column>

      <Module.Column column={1}>
        <Module.Title>Последнее пополнение баланса</Module.Title>
        <Module.Value>{normalizeValues(values?.last_deposit)}</Module.Value>
      </Module.Column>

      <Module.Column column={1}>
        <Module.Title>Дата оплаты последнего еженедельного платежа</Module.Title>
        <Module.Value>
          {values?.last_actual_payment_date &&
            normalizeValues(values?.last_actual_payment_date, 'date')}
        </Module.Value>
      </Module.Column>

      <Module.Column column={1}>
        <Module.Title>Остаток по графику</Module.Title>
        <Module.Value>{normalizeValues(values?.left_to_pay_sum)}</Module.Value>
      </Module.Column>

      <Module.Column column={1}>
        <Module.Title>Текущий срок просрочки</Module.Title>
        <Module.Value>{normalizeValues(values?.last_overdue_days)}</Module.Value>
      </Module.Column>

      <Module.Column column={1}>
        <Module.Title>Общий срок просрочки</Module.Title>
        <Module.Value>{normalizeValues(values?.total_overdue_days)}</Module.Value>
      </Module.Column>

      <Module.Column column={1}>
        <Module.Title>Активация ДП</Module.Title>
        <Module.Value>
          {values?.has_current_early_repayment
            ? normalizeValues(values?.early_repayment_create_dt, 'source_full_date')
            : 'Нет'}
        </Module.Value>
      </Module.Column>

      <Module.Column column={1}>
        <Module.Title>Дата ДП</Module.Title>
        <Module.Value>
          {values?.early_repayment_date && normalizeValues(values?.early_repayment_date, 'date')}
        </Module.Value>
      </Module.Column>

      <Module.Column column={1}>
        <Module.Title>Сумма для закрытия ДП</Module.Title>
        <Module.Value>{normalizeValues(values?.early_repayment_amount)}</Module.Value>
      </Module.Column>
    </>
  )
}
