/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useState } from 'react'
import { getAdminFio, normalizeValues } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'index'

import { Nullable } from 'commonTypes'
import { Button, EmptyValueReplacer, ModalConfirm, Tooltip } from 'components'

import { ReactComponent as DottedSVG } from 'assets/dotted-vertical.svg'
import { ReactComponent as PlaySVG } from 'assets/small-play-button.svg'

import { fetchFsspData, fetchLoanPageById } from 'features/loan/extra'
import { useGetLoanPassportData, useGetNBKIScoring } from 'features/loan/loanSelectors'
import moment from 'moment'
import { THEME } from 'core/theme'
import { useParams } from 'react-router-dom'
import { resetAgentsLoans } from 'features/agentLoans/loanSlice'
import { CreditProduct, CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'
import {
  StyledHeadWrapper,
  StyledHeadActionBlock,
  StyledAction,
  StyledHeadLoanInfo,
  StyledInfoWrapper,
  StyledTitle,
  StyledValue,
  StyledCloseExpandedElements,
  StyledRollIcon,
  StyledHeadTitle,
  StyledHeadLoanInfoWrapper,
  StyledFioBlock,
  StyledFioValue,
  StyledTooltipMenuWrapper,
  StyledActionButton,
  StyledCommentsBlock,
  StyledCommentTitle,
  StyledTextArea,
  StyledButtons,
  StyledInfo,
} from './Head.styles'
import { ProjectAgentLoansService } from 'services/agentLoanService'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { ProjectLoansService } from 'services/loanService'

interface IModal {
  show: boolean
  title: string
  description: string
  action?: Nullable<(adminComment: string) => void>
  style: string
}

export const HeadInfo: React.FC<any> = ({
  onRollUp,
  onRollDown,
  handleRefuseLoan,
  loanCanBeRefuse,
  onChangeAgent,
  isAssignLoan,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string | undefined }>()
  const [newComment, setNewComment] = useState<string>('')
  const { userData } = useSelector((store: GlobalState) => store.agentLoans)
  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    description: '',
    title: '',
    action: null,
    style: '',
  })

  const handleCloseModal = (): void => {
    setShowModal({
      show: false,
      title: '',
      action: null,
      style: '',
      description: '',
    })
    setNewComment('')
  }

  const openUserPersonalArea = () => {
    ProjectLoansService.getUserToken(userData?.user_id).then(({ data: { token } }) => {
      window.open(
        `https://papa-zaim.ru/personal-area?token=${token}&userId=${userData?.user_id}&underwriter=true`,
        '_blank'
      )
    })
  }

  const handleClick = (): void => {
    const action = (): void => handleRefuseLoan(newComment)

    setShowModal({
      show: true,
      title: `Отказ клиента`,
      description: `Поставить статус отказ клиента ?`,
      action,
      style: 'success',
    })
  }

  const handleAssignLoan = (): void => {
    ProjectAgentLoansService.getAssignTheLoan(id)
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        dispatch(
          addToast({
            type: ToastTypes.warning,
            title: 'Ошибка',
            description: `заявка в работу не взята`,
          })
        )
      })
  }

  return (
    <StyledHeadActionBlock>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        description={showModal.description}
        title={showModal.title}
        action={showModal.action}
        style={showModal.style}
        buttons={false}
        content={() => (
          <>
            <StyledCommentsBlock>
              <StyledCommentTitle>Добавить комментарий</StyledCommentTitle>
              <StyledTextArea
                value={newComment || ''}
                maxLength={999}
                placeholder='Комментарий'
                onChange={(e) => setNewComment(e?.target?.value)}
              />

              <StyledButtons>
                <Button
                  type='standardBig'
                  onClick={() => (newComment.trim() === '' ? null : showModal.action(newComment))}
                  disabled={newComment.trim() === ''}
                >
                  Подтвердить
                </Button>
                <Button type='emptyBig' disabled={false} onClick={handleCloseModal}>
                  Отмена
                </Button>
              </StyledButtons>
            </StyledCommentsBlock>
          </>
        )}
      />
      {isAssignLoan && (
        <Button type='smallSuccess' onClick={handleAssignLoan}>
          <PlaySVG />
          Взять в работу
        </Button>
      )}
      <StyledAction>
        <StyledCloseExpandedElements onClick={onRollDown}>
          <StyledRollIcon>+</StyledRollIcon> Развернуть все
        </StyledCloseExpandedElements>
        <StyledCloseExpandedElements onClick={onRollUp}>
          <StyledRollIcon>-</StyledRollIcon> Свернуть все
        </StyledCloseExpandedElements>
        <Tooltip
          content={
            <StyledActionButton>
              Действия
              <DottedSVG />
            </StyledActionButton>
          }
          coward={false}
        >
          <StyledTooltipMenuWrapper style={{ cursor: 'pointer' }}>
            {/* {loanCanBeRefuse && (
              <Button type='emptyBig' onClick={handleClick}>
                Отказ клиента
              </Button>
            )} */}
            <Button type='emptyBig' onClick={onChangeAgent}>
              Передать клиента
            </Button>
            <Button type='emptyBig' onClick={openUserPersonalArea}>
              Личный кабинет клиента
            </Button>
          </StyledTooltipMenuWrapper>
        </Tooltip>
      </StyledAction>
    </StyledHeadActionBlock>
  )
}
export const getAmountRefound = (amount: number, term: number, percent: number) => {
  if (!amount || !term || !percent) return undefined
  return (
    +amount *
    ((((percent * 7) / 100) * (1 + (percent * 7) / 100) ** term) /
      ((1 + (percent * 7) / 100) ** term - 1))
  )
}

export const Head: React.FC<any> = (): JSX.Element => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string | undefined }>()
  const passportData = useSelector((store: GlobalState) => store.agentLoans.passportData)
  const personalData = useSelector((store: GlobalState) => store.agentLoans.personalData)
  const loanData = useSelector((store: GlobalState) => store.agentLoans.loanData)
  const { assign_info, userData } = useSelector((store: GlobalState) => store.agentLoans)
  const dictData = useSelector((state: GlobalState) => state.dict)
  const scoring = useGetNBKIScoring()

  const {
    loadData: { loan_id: loanId, loan_source: loanSource = '', loan_number: currentLoanNumber },
    userId,
    ptiData: { data: ptiData },
    isOverdue,
    fssp,
    finScoringMfo,
    loanRating,
    selectedLoanData,
  } = useSelector((store: GlobalState) => store.singleLoan)

  useEffect(() => {
    dispatch(fetchLoanPageById({ id }))
    return () => {
      dispatch(resetAgentsLoans(false))
    }
  }, [])
  const {
    first_name: firstName = '',
    last_name: lastName = '',
    middle_name: middleName = '',
    registration_address: regAddress,
    date_of_birth: birthDate,
    serial,
    number,
    date_of_issue: dateOfIssue,
    place_of_birth: placeOfBirth,
    place_of_issue: placeOfIssue,
    registration_address: address,
  } = useGetLoanPassportData()

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      userId &&
      currentLoanNumber &&
      loanData?.credit_product !== CreditProduct.pdl_08
    ) {
      dispatch(
        fetchFsspData({
          data: {
            last_name: lastName,
            first_name: firstName,
            mid_name: middleName,
            birth_date: moment(new Date(birthDate)).format('DD.MM.YYYY'),
          },
          userId,
          number: currentLoanNumber,
          force: false,
        })
      )
    }
  }, [
    firstName,
    lastName,
    userId,
    currentLoanNumber,
    birthDate,
    loanData?.credit_product,
    dispatch,
    middleName,
  ])

  const handleUpdateFssp = (): void => {
    dispatch(
      fetchFsspData({
        data: {
          last_name: lastName,
          first_name: firstName,
          mid_name: middleName,
          birth_date: moment(new Date(birthDate)).format('DD.MM.YYYY'),
        },
        userId,
        number: currentLoanNumber,
        force: true,
      })
    )
  }

  const getSum = () => {
    let PERCENT
    if (+loanData?.loan_ask_period === 13) {
      PERCENT = 11.0335
      return +((+loanData?.loan_ask_sum * PERCENT) / 100).toFixed(2)
    }
    if (+loanData?.loan_ask_period === 25) {
      PERCENT = 7.5278
    }

    if (+loanData?.loan_ask_period === 52) {
      if (+loanData?.product_percent_day === 0.48) {
        PERCENT = 4.0931
      } else if (+loanData?.product_percent_day >= 0.45) {
        PERCENT = 3.9343
      } else {
        PERCENT = 4.257
      }
    }
    const result = +((+loanData?.loan_ask_sum * PERCENT) / 100).toFixed(2)

    return result ? `${result} ₽` : null
  }

  const showButtons = ![
    CreditProduct.installment_30_100k,
    CreditProduct.pdl_08,
    CreditProduct.pdl,
  ].includes(loanData?.credit_product)

  const getScoreStyleNBKI = useCallback(
    (score: number) => {
      if (score < 80) return 'red'
      if (score > 90) return 'green'
      return null
    },
    [scoring?.score]
  )

  function getScoreStyle(score: any): string {
    throw new Error('Function not implemented.')
  }

  return (
    <StyledHeadWrapper>
      <StyledHeadLoanInfoWrapper>
        <StyledInfoWrapper>
          <StyledHeadTitle>Клиент</StyledHeadTitle>
          <StyledFioBlock>
            <StyledTitle>ФИО</StyledTitle>
            <StyledFioValue>
              {passportData &&
                normalizeValues(
                  `${passportData.last_name} ${passportData.first_name} ${passportData.middle_name}`
                )}
            </StyledFioValue>
          </StyledFioBlock>
        </StyledInfoWrapper>
      </StyledHeadLoanInfoWrapper>
      <StyledHeadLoanInfo>
        <StyledInfoWrapper>
          <StyledTitle>Номер телефона</StyledTitle>
          <StyledValue styles='boldGreen'>
            {personalData && normalizeValues(personalData.login)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Запрашиваемая сумма займа</StyledTitle>
          <StyledValue>
            {loanData?.loan_ask_sum && normalizeValues(loanData.loan_ask_sum)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Разрешенная сумма</StyledTitle>
          <StyledValue>
            {loanData?.loan_date_create && normalizeValues(loanData.loan_permitted_sum)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Создание заявки</StyledTitle>
          <StyledValue>
            {loanData?.loan_date_create && normalizeValues(loanData.loan_date_create, 'full_date')}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Андеррайтер</StyledTitle>
          <StyledValue>
            {assign_info?.admin_username &&
              assign_info?.admin_username.length &&
              getAdminFio(assign_info?.admin_username[0], dictData)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Агент</StyledTitle>
          <StyledValue>
            {selectedLoanData?.agent_username &&
              getAdminFio(selectedLoanData?.agent_username, dictData)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Статус заявки</StyledTitle>
          <StyledValue>
            {loanData?.loan_status && normalizeValues(loanData.loan_status, 'loan_status')}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Процентная ставка</StyledTitle>
          <StyledValue> {loanData?.product_percent_day} %</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Скорр балл НБКИ</StyledTitle>
          <StyledValue alarm={getScoreStyleNBKI(scoring?.score)}>
            {scoring?.score || <EmptyValueReplacer />}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Тип продукта</StyledTitle>
          <StyledValue>{CreditProductTranslate[loanData?.credit_product]}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>ФССП</StyledTitle>
          {!fssp.isFetching && fssp.data && !fssp.data?.detail && (
            <StyledValue>
              <span style={{ color: fssp.data.found ? THEME.COLORS.WARNING : 'initial' }}>
                {fssp.data.found ? 'Найден' : 'Не найден'}
              </span>
            </StyledValue>
          )}
          {showButtons && (
            <StyledValue>
              <StyledInfo
                onClick={handleUpdateFssp}
                style={{ cursor: 'pointer', gridColumn: 'span 1' }}
              >
                запросить
              </StyledInfo>
            </StyledValue>
          )}

          {fssp.isFetching && <StyledValue>запрашивается...</StyledValue>}
        </StyledInfoWrapper>

        <StyledInfoWrapper>
          <StyledTitle>Еженедельный платеж</StyledTitle>
          <StyledValue>
            {loanData?.loan_ask_sum && loanData?.loan_ask_period && +loanData?.product_percent_day
              ? `${Math.ceil(
                  getAmountRefound(
                    loanData?.loan_ask_sum,
                    loanData?.loan_ask_period,
                    +loanData?.product_percent_day
                  )
                )}   ₽`
              : null}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Уступка прав требований</StyledTitle>
          <StyledValue alarm={loanData?.is_third_transfer_approved ? 'green' : 'red'}>
            {loanData?.is_third_transfer_approved ? 'Да' : 'Нет'}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Согласие передачи в БКИ</StyledTitle>
          <StyledValue alarm={loanData?.is_transfer_bki_approved ? 'green' : 'red'}>
            {loanData?.is_transfer_bki_approved ? 'Да' : 'Нет'}
          </StyledValue>
        </StyledInfoWrapper>
      </StyledHeadLoanInfo>
    </StyledHeadWrapper>
  )
}
