import { v4 as uuid } from 'uuid'

export const STRUCTURE = {
  THEAD: [
    { title: 'Номер', id: uuid() },
    { title: 'Трафик', id: uuid() },
    { title: 'Дата и время создания', id: uuid(), sort: 'date' },

    { title: 'Кол-во обращений', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Паспорт', id: uuid() },
    { title: 'Телефон', id: uuid() },
    { title: 'Запрашиваемая сумма, ₽', id: uuid() },
    { title: 'Город подачи заявки', id: uuid() },
  ],
  TABLE: [
    { title: '№20210504216', id: uuid() },
    { title: 'C2M', id: uuid() },
    { title: '27.03.2021 14:55', id: uuid(), sort: 'date' },
    { title: '3', id: uuid() },
    { title: 'Константинов Константин Иванов', id: uuid() },
    { title: '4632 332 192', id: uuid() },
    { title: '8 926 111 32 32', id: uuid() },
    { title: '15 000', id: uuid() },
    { title: 'Долгопрудный', id: uuid() },
  ],
}
