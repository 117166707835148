/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeEvent } from 'react'
import { isEqual } from 'lodash'

import {
  StyledSaveInPassport,
  StyledInPassportTitleBlock,
  StyledInfoDescTitle,
  StyledInPassportTitle,
  StyledTextArea,
  StyledEditInPassport,
  StyledInfoDescContent,
  StyledSaveBtnClock,
  StyledSCloseInPassportBtn,
  StyledEditInPassportBtn,
  StyledSCopyInPassportBtn,
} from './EditInPassport.styles'

import { AiFillCopy, AiFillEdit, AiOutlineClose, AiOutlineWarning } from 'react-icons/ai'

interface IEditInPassportProps {
  inPassportEditable: boolean
  getInPassportValue: string
  handleChangeInPassportContent: (event: ChangeEvent<HTMLInputElement>) => void
  inPassportContent: string
  handleResetInPassport: () => void
  handleCopyInPassport: () => void
  setInPassportEditable: (boolean) => void
  equalInPassport: boolean
}

interface IGetPassportData {
  region?: string
  district?: string
  city?: string
  street?: string
  block?: string
  building?: string
  house_number?: string
  apartment?: string
}

export const generatePassportValue = (data: IGetPassportData): string => {
  return [
    data?.region && `Регион ${data.region}`,
    data?.district && `Район ${data.district}`,
    data?.city && `Город ${data.city}`,
    data?.street && `Улица ${data.street}`,
    data?.block && `Корпус ${data.block}`,
    data?.building && `Строение ${data.building}`,
    data?.house_number && `Дом ${data.house_number}`,
    data?.apartment && `Квартира ${data.apartment}`,
  ]
    .filter(Boolean) // Убираем пустые значения
    .join(' ')
}

export const EditInPassport = (props: IEditInPassportProps): JSX.Element => {
  const {
    inPassportEditable,
    getInPassportValue,
    handleChangeInPassportContent,
    inPassportContent,
    handleResetInPassport,
    handleCopyInPassport,
    setInPassportEditable,
    equalInPassport,
  } = props

  return (
    <>
      {inPassportEditable ? (
        <StyledSaveInPassport disable={equalInPassport}>
          <div style={{ width: '100%' }}>
            <StyledInPassportTitleBlock>
              <StyledInfoDescTitle>Как в паспорте</StyledInfoDescTitle>
              <br />
              {!isEqual(getInPassportValue, inPassportContent) && (
                <StyledInPassportTitle>
                  <AiOutlineWarning />
                  Различаются с пропиской
                </StyledInPassportTitle>
              )}
            </StyledInPassportTitleBlock>
            <StyledTextArea
              onChange={handleChangeInPassportContent}
              placeholder='Как в паспорте'
              value={inPassportContent}
              noMove
            />
          </div>
          <StyledSaveBtnClock>
            <StyledSCloseInPassportBtn onClick={handleResetInPassport}>
              <AiOutlineClose />
            </StyledSCloseInPassportBtn>
            <StyledSCopyInPassportBtn onClick={handleCopyInPassport}>
              <AiFillCopy />
            </StyledSCopyInPassportBtn>
          </StyledSaveBtnClock>
        </StyledSaveInPassport>
      ) : (
        <StyledEditInPassport>
          <div>
            <StyledInPassportTitleBlock>
              <StyledInfoDescTitle>Как в паспорте</StyledInfoDescTitle>
              <br />
              {!isEqual(getInPassportValue, inPassportContent) && (
                <StyledInPassportTitle>
                  <AiOutlineWarning />
                  Различаются с пропиской
                </StyledInPassportTitle>
              )}
            </StyledInPassportTitleBlock>
            <StyledInfoDescContent>{inPassportContent}</StyledInfoDescContent>
          </div>

          <StyledEditInPassportBtn onClick={() => setInPassportEditable(true)}>
            <AiFillEdit />
          </StyledEditInPassportBtn>
        </StyledEditInPassport>
      )}
    </>
  )
}
