import { removeToast } from 'features/toast/toastSlice'
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GlobalState } from 'index'

export const useToaster: any = () => {
  const dispatch = useDispatch()
  const toasts = useSelector((state: GlobalState) => state.toaster)

  const removeHandler = useCallback(
    (id) => {
      dispatch(removeToast(id))
    },
    [dispatch]
  )

  return [toasts[1], removeHandler]
}
