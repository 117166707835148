// import { useEffect, useState } from 'react'
import { CreditProductCodes } from 'constants/creditProduct'
import { EmptyValueReplacer } from 'components/EmptyValueReplacer/EmptyValueReplacer'

import {
  StyledButton,
  // StyledDescription,
  StyledNotification,
  StyledTitle,
  StyledBtn,
  StyledContent,
  StyledButtons,
  StyledDescription,
} from './UnassignedModal.styles'

export enum ToastPositions {
  'top-right' = 'top-right',
  'bottom-right' = 'bottom-right',
  'top-left' = 'top-left',
  'bottom-left' = 'bottom-left',
}

export enum ToastTypes {
  'success' = 'success',
  'danger' = 'danger',
  'warning' = 'warning',
  'info' = 'info',
}

interface IToast {
  // id: number
  // removeHandler: (id: number) => void
  position: ToastPositions
  title: string
  apply: any
  cancelAction: any
  key: any
  loanProduct: any
  sum: any
  period: any
  requestsCount: any
  region: any
}

export const Unassigned: any = ({
  // id,
  // removeHandler,
  position,
  title,
  apply,
  cancelAction,
  key,
  loanProduct,
  sum,
  period,
  requestsCount,
  region,
}: IToast) => (
  <StyledNotification position={position} type={ToastTypes.success} key={key}>
    <StyledButton onClick={cancelAction}>x</StyledButton>

    <StyledContent>
      <StyledTitle>
        Свободная заявка № <b>{title}</b>
      </StyledTitle>
      {/* <StyledDescription>{time}</StyledDescription> */}
      <StyledDescription>
        <div>
          Тип продукта:{' '}
          <b>{loanProduct ? CreditProductCodes[loanProduct] : <EmptyValueReplacer />}</b>
        </div>
        <div>
          Сумма: <b>{sum ? sum.toLocaleString() : <EmptyValueReplacer />}</b>
        </div>
        <div>
          Срок: <b>{period || <EmptyValueReplacer />}</b>
        </div>
        <div>
          Количество заявок: <b>{requestsCount || <EmptyValueReplacer />}</b>
        </div>
        <div>
          Регион: <b>{region || <EmptyValueReplacer />}</b>
        </div>
      </StyledDescription>
      <StyledButtons>
        <StyledBtn onClick={apply}>Взять</StyledBtn>
        <StyledBtn onClick={cancelAction} alert>
          Игнорировать
        </StyledBtn>
      </StyledButtons>
    </StyledContent>
  </StyledNotification>
)
