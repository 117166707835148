/* eslint-disable import/order */
import { forwardRef } from 'react'
import { SmallLoader } from '../SmallLoader'

import {
  StyledModuleWrapper,
  StyledHeaderModule,
  StyledContentWrapper,
  StyledEditButton,
  StyledActions,
  StyledColumn,
  StyledTitle,
  StyledValue,
  StyledContent,
  StyledLoaderWrapper,
  StyledLoaderScreen,
  StyledArrow,
  StyledHeadTitle,
  StyledTableColumn,
  StyledTableValue,
  StyledTableTitle,
  StyledImageLoaderWrapper,
  StyledImage,
  StyledDeleteImage,
  StyledImagesBlock,
  StyledImageWrapper,
} from './Module.styles'

import { ReactComponent as DropdownIndicatorSVG } from 'assets/arrow-drop-down.svg'
import { ReactComponent as EditButtonSVG } from 'assets/edit-button.svg'

export const Wrapper: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledModuleWrapper {...(rest || {})} ref={ref}>
      {children}
    </StyledModuleWrapper>
  )
})

export const Header: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props
  return (
    <StyledHeaderModule {...(rest || {})} ref={ref}>
      {children}
    </StyledHeaderModule>
  )
})

export const ContentWrapper: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledContentWrapper {...(rest || {})} ref={ref}>
      {children}
    </StyledContentWrapper>
  )
})

export const Button: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, handleChangeEditable, ...rest } = props

  return (
    <StyledEditButton {...(rest || {})} ref={ref}>
      <EditButtonSVG onClick={handleChangeEditable} />
      {children}
    </StyledEditButton>
  )
})

export const Actions: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledActions {...(rest || {})} ref={ref}>
      {children}
    </StyledActions>
  )
})

export const Column: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledColumn {...(rest || {})} ref={ref}>
      {children}
    </StyledColumn>
  )
})

export const Title: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledTitle {...(rest || {})} ref={ref}>
      {children}
    </StyledTitle>
  )
})

export const Value: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledValue {...(rest || {})} ref={ref}>
      {children}
    </StyledValue>
  )
})

export const Content: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledContent {...(rest || {})} ref={ref}>
      {children}
    </StyledContent>
  )
})

export const LoaderBlock: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledLoaderScreen {...(rest || {})} ref={ref}>
      <StyledLoaderWrapper>
        <SmallLoader />
        {children}
      </StyledLoaderWrapper>
    </StyledLoaderScreen>
  )
})

export const Arrow: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledArrow {...(rest || {})} ref={ref}>
      <DropdownIndicatorSVG />
      {children}
    </StyledArrow>
  )
})

export const HeadTitle: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledHeadTitle {...(rest || {})} ref={ref}>
      {children}
    </StyledHeadTitle>
  )
})

export const TableColumn: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledTableColumn {...(rest || {})} ref={ref}>
      {children}
    </StyledTableColumn>
  )
})

export const TableValue: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledTableValue {...(rest || {})} ref={ref}>
      {children}
    </StyledTableValue>
  )
})

export const TableTitle: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledTableTitle {...(rest || {})} ref={ref}>
      {children}
    </StyledTableTitle>
  )
})

export const ImageLoader: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledImageLoaderWrapper {...(rest || {})} ref={ref}>
      <SmallLoader />
      {children}
    </StyledImageLoaderWrapper>
  )
})

export const SmallImage: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, url, ...rest } = props

  return (
    <StyledImage {...(rest || {})} ref={ref}>
      <img src={url} alt='img' />
      {children}
    </StyledImage>
  )
})

export const ImageDelete: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { ...rest } = props

  return (
    <StyledDeleteImage {...(rest || {})} ref={ref}>
      x
    </StyledDeleteImage>
  )
})

export const ImageBlock: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledImagesBlock {...(rest || {})} ref={ref}>
      {children}
    </StyledImagesBlock>
  )
})

export const ImageWrapper: React.FC<any> = forwardRef((props, ref: any): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledImageWrapper {...(rest || {})} ref={ref}>
      {children}
    </StyledImageWrapper>
  )
})
