/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import { normalizeValues } from 'helpers'
import { useSelector } from 'react-redux'
import { GlobalState } from 'index'

import {
  StyledHeadWrapper,
  StyledHeadActionBlock,
  StyledAction,
  StyledHeadLoanInfo,
  StyledInfoWrapper,
  StyledTitle,
  StyledValue,
  StyledCloseExpandedElements,
  StyledRollIcon,
  StyledHeadTitle,
  StyledHeadLoanInfoWrapper,
  StyledFioBlock,
  StyledFioValue,
  StyledActionButton,
} from './Head.styles'
import { CreditProductTranslate } from 'constants/creditProduct'
import { Button, Tooltip } from 'components'

import { ReactComponent as DottedSVG } from 'assets/dotted-vertical.svg'
import { StyledTooltipMenuWrapper } from 'components/Tooltip/Tooltip.styles'
import { ProjectLoansService } from 'services/loanService'

export const HeadInfo: React.FC<any> = ({ onRollUp, onRollDown }): JSX.Element => {
  const { userData } = useSelector((store: GlobalState) => store.contracts)

  const openUserPersonalArea = () => {
    ProjectLoansService.getUserToken(userData?.user_id).then(({ data: { token } }) => {
      window.open(
        `https://papa-zaim.ru/personal-area?token=${token}&userId=${userData?.user_id}&underwriter=true`,
        '_blank'
      )
    })
  }

  return (
    <StyledHeadActionBlock>
      <StyledAction>
        <StyledCloseExpandedElements onClick={onRollDown}>
          <StyledRollIcon>+</StyledRollIcon> Развернуть все
        </StyledCloseExpandedElements>
        <StyledCloseExpandedElements onClick={onRollUp}>
          <StyledRollIcon>-</StyledRollIcon> Свернуть все
        </StyledCloseExpandedElements>
        <Tooltip
          content={
            <StyledActionButton>
              Действия
              <DottedSVG />
            </StyledActionButton>
          }
          coward={false}
        >
          <StyledTooltipMenuWrapper style={{ cursor: 'pointer' }}>
            {/* {loanCanBeRefuse && (
              <Button type='emptyBig' onClick={handleClick}>
                Отказ клиента
              </Button>
            )} */}
            <Button type='emptyBig' onClick={openUserPersonalArea}>
              Личный кабинет клиента
            </Button>
          </StyledTooltipMenuWrapper>
        </Tooltip>
      </StyledAction>
    </StyledHeadActionBlock>
  )
}

export const Head: React.FC<any> = (): JSX.Element => {
  const passportData = useSelector((store: GlobalState) => store.contracts.passportData)
  const personalData = useSelector((store: GlobalState) => store.contracts.personalData)
  const { loanData, loan_balance } = useSelector((store: GlobalState) => store.contracts)

  return (
    <StyledHeadWrapper>
      <StyledHeadLoanInfoWrapper>
        <StyledInfoWrapper>
          <StyledHeadTitle>Клиент</StyledHeadTitle>
          <StyledFioBlock>
            <StyledTitle>ФИО</StyledTitle>
            <StyledFioValue>
              {passportData &&
                normalizeValues(
                  `${passportData.last_name} ${passportData.first_name} ${passportData.middle_name}`
                )}
            </StyledFioValue>
          </StyledFioBlock>
        </StyledInfoWrapper>
      </StyledHeadLoanInfoWrapper>
      <StyledHeadLoanInfo>
        <StyledInfoWrapper>
          <StyledTitle>Номер телефона</StyledTitle>
          <StyledValue styles='boldGreen'>
            {personalData && normalizeValues(personalData.login)}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Сумма займа</StyledTitle>
          <StyledValue>{loanData?.loan_sum && normalizeValues(loanData.loan_sum)}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Создание заявки</StyledTitle>
          <StyledValue>
            {loanData?.loan_sum && normalizeValues(loanData.loan_date_create, 'full_date')}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Статус заявки</StyledTitle>
          <StyledValue>
            {loanData?.loan_status && normalizeValues(loanData.loan_status, 'loan_status')}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Выдача займа</StyledTitle>
          <StyledValue>
            {loanData?.loan_sum && normalizeValues(loanData.loan_issue_date, 'full_date')}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Процентная ставка</StyledTitle>
          <StyledValue> {loanData?.product_percent_day} %</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Тип продукта</StyledTitle>
          <StyledValue> {CreditProductTranslate[loanData?.credit_product]}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Баланс</StyledTitle>
          <StyledValue> {loan_balance}</StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Уступка прав требований</StyledTitle>
          <StyledValue alarm={loanData?.is_third_transfer_approved ? 'green' : 'red'}>
            {loanData?.is_third_transfer_approved ? 'Да' : 'Нет'}
          </StyledValue>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledTitle>Согласие передачи в БКИ</StyledTitle>
          <StyledValue alarm={loanData?.is_transfer_bki_approved ? 'green' : 'red'}>
            {loanData?.is_transfer_bki_approved ? 'Да' : 'Нет'}
          </StyledValue>
        </StyledInfoWrapper>
      </StyledHeadLoanInfo>
    </StyledHeadWrapper>
  )
}
