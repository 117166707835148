/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Module, UploadLoanImage } from 'components'
import { ImageModal } from 'containers'
import { GlobalState } from 'index'
import {
  StyledImageDescription,
  StyledPassportPhotoItem,
  StyledPassportPhotoWrapper,
  StyledPassportWrapper,
  StyledPersonalPhotoWrapper,
} from './Modules.styles'

const moduleId = 'passport'
export const Passport: any = ({ openedModules, onToggleModule, userId }): JSX.Element => {
  const images = useSelector((store: GlobalState) => store.contracts.imageData)
  const { userData } = useSelector((store: GlobalState) => store.contracts)
  const [editable, setEditable] = useState<boolean>(false)
  const isOpen = openedModules.includes(moduleId)
  const [showModal, setShowModal] = useState({
    show: false,
    content: null,
  })

  const handleExpand = (): void => onToggleModule(moduleId)

  const handleCloseModal = (): void =>
    setShowModal({
      ...showModal,
      show: false,
    })

  const handleShowModal =
    ({ image }) =>
    () =>
      setShowModal({
        ...showModal,
        show: true,
        content: image,
      })

  const fetchImages = false // TODO
  const imageContent = { ...showModal.content }

  return (
    <Module.Wrapper isOpen={isOpen} editable={editable} isEdited={false} id={moduleId}>
      <ImageModal.ModalContent
        userId={userId}
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        imageContent={imageContent}
        onClose={handleCloseModal}
        type='contracts'
      />
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Паспортные данные</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <StyledPassportWrapper>
          <Module.ImageBlock>
            <StyledPassportPhotoWrapper>
              <StyledImageDescription>Основная страница</StyledImageDescription>
              <StyledPassportPhotoItem>
                {fetchImages && <Module.ImageLoader />}
                {images &&
                  images?.personalDataPhoto.length > 0 &&
                  images.personalDataPhoto.map((image) => (
                    <Module.ImageWrapper onClick={handleShowModal({ image })} key={image.image_id}>
                      <Module.SmallImage url={image.photo_url} />
                    </Module.ImageWrapper>
                  ))}
                <UploadLoanImage
                  type='passport-main-page-photo'
                  userId={userData?.user_id}
                  capture='environment'
                />
              </StyledPassportPhotoItem>
            </StyledPassportPhotoWrapper>

            <StyledPersonalPhotoWrapper>
              <StyledImageDescription>Страница регистрации</StyledImageDescription>
              <StyledPassportPhotoItem>
                {fetchImages && <Module.ImageLoader />}
                {images &&
                  images?.registrationDataPhoto.length > 0 &&
                  images.registrationDataPhoto.map((image) => (
                    <Module.ImageWrapper onClick={handleShowModal({ image })} key={image.image_id}>
                      <Module.SmallImage url={image.photo_url} />
                    </Module.ImageWrapper>
                  ))}
                <UploadLoanImage
                  type='passport-registration-page-photo'
                  userId={userData?.user_id}
                  capture='environment'
                />
              </StyledPassportPhotoItem>
            </StyledPersonalPhotoWrapper>
          </Module.ImageBlock>
        </StyledPassportWrapper>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
