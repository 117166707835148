import { forwardRef } from 'react'
import { SmallLoader } from 'components'
import { IButton } from './types'
import { StyledButtonWrapper, StyledPendingWrapper } from './Button.styles'

export const Button: React.FC<IButton | any> = forwardRef((props, ref: any) => {
  const { children, pending, ...rest } = props

  return (
    <StyledButtonWrapper {...(rest || {})} ref={ref} pending={pending}>
      {children}
      {pending && (
        <StyledPendingWrapper>
          <SmallLoader />
        </StyledPendingWrapper>
      )}
    </StyledButtonWrapper>
  )
})
