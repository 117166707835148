/* eslint-disable react/no-array-index-key */
import { useUnassignedModal } from 'helpers/hooks/useUnassignedModal'
import { Unassigned } from 'lib/unassigned-modal/UnassignedModal'
import { ProjectLoansService } from 'services/loanService'
import { useDispatch, batch } from 'react-redux'
import { setAssignLoanToThisUser } from 'features/loan/singleLoanSlice'
import { getLoanInWork } from 'features/loans/loanSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'
import { StyledUnassignedModal } from './UnassignedModal.styles'

export const UnassignedModal: any = ({ position }) => {
  const dispatch = useDispatch()
  const [list, removeHandler] = useUnassignedModal()

  const handleAssignLoan = (loanId): void => {
    ProjectLoansService.getAssignTheLoan(loanId)
      .then(() => {
        batch(() => {
          dispatch(setAssignLoanToThisUser())
        })
        dispatch(getLoanInWork())
      })
      .catch(() => {
        dispatch(
          addToast({
            type: ToastTypes.warning,
            title: 'Ошибка',
            description: `заявка в работу не взята`,
          })
        )
      })
      .finally(() => {
        removeHandler(loanId)
      })
  }
  return (
    <StyledUnassignedModal position={position}>
      {list?.map((i) => (
        <Unassigned
          key={i?.id}
          removeHandler={removeHandler}
          position={position}
          title={i?.loans_loan_number}
          apply={() => handleAssignLoan(i?.loans_loan_id)}
          cancelAction={() => removeHandler(i?.loans_loan_id)}
          loanProduct={i?.loans_product_id}
          sum={i?.loans_loan_ask_sum}
          period={i?.loans_loan_ask_period}
          requestsCount={i?.requests_count}
          region={i?.registration_address}
          id={i?.id}
        />
      ))}
    </StyledUnassignedModal>
  )
}

UnassignedModal.defaultProps = {
  position: 'bottom-right',
  isAutoDelete: true,
}
