import styled from 'styled-components/macro'

export const StyledLoaderPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledWrapper = styled.div`
  display: flex;
`

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
  max-height: calc(100vh - 145px);
  overflow-y: scroll;
  padding-bottom: 1500px;
`

export const StyledBTN = styled.div``

export const StyledNavContent = styled.div`
  width: 100%;
`
