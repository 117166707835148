/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react'
import StickyBox from 'react-sticky-box'
import { GlobalState } from 'index'
import { useParams } from 'react-router-dom'
import { useDispatch, batch, useSelector } from 'react-redux'

import { Nullable } from 'commonTypes'
import { NavMenuType } from 'App'
import { Loader, Navigation, ScrollToTop } from 'components'
import { INavigationItem } from 'components/Navigation/Navigation'
import { fetchSdlUserPassportData, fetchSdlUserPersonalData } from 'features/sdl/extra'
import { HeadInfo, Head } from './Head'

import {
  StyledLoaderPage,
  StyledWrapper,
  StyledContent,
  StyledNavContent,
} from './SdlDetails.styles'

import { Step1, Step2, Step3 } from './Modules'
import { Step4 } from './Modules/Step4'
import { SDL_ROUTE_ROUTE } from '../../constants'
import { LetterWindow } from './Modules/LetterWindow'
import { LettersCount } from './Modules/LettersCount'
import { Step2AdditionalSelection } from './Modules/Step2.1'
import { NavigationContext } from 'app/contexts'

const navigationList = (): INavigationItem[] => {
  const list = [
    { title: 'Андеррайтинг', anchor: 'a' },
    {
      title: 'Блокировка ЛК',
      anchor: 'b',
    },
    { title: 'Справки', anchor: 's' },
    { title: 'Реструктуризация', anchor: 'r' },
    {
      title: 'Требования',
      anchor: 't',
    },
  ]
  return list
}

const list = navigationList()

export interface IadditionalRequirements {
  repited: boolean
  requredAll: boolean
  data: string[]
}
interface IShowWindow {
  show: boolean
  letterId: Nullable<string>
}

export const SdlDetails: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const { stepIsFetching } = useSelector((store: GlobalState) => store.sdl)
  const { id } = useParams<{ id: string | undefined }>()
  const { setNavigation, setNavMenuType } = useContext(NavigationContext)
  const [openedModules, setOpenedModules] = useState<string[]>([])
  const [showWindow, setShowWindow] = useState<IShowWindow>({
    show: false,
    letterId: null,
  })
  const [documents, setDocuments] = useState({})
  const [checkedDocuments, setCheckedDocuments] = useState({})
  const [prevDocumentsState, sePreviousDocumentsState] = useState({})
  const [documentsCount, setDocumentsCount] = useState<number>(0)
  const [steps, setSteps] = useState<number>(1)
  const [additionalSelectionStep, setAdditionalSubSelectionStep] = useState({})
  const [additionalRequirements, setAdditionalRequirements] =
    useState<Nullable<IadditionalRequirements>>(null)

  useEffect(() => {
    setNavMenuType(NavMenuType.sdl)
  }, [])

  const onToggleModule = (anchor: string): void => {
    const newOpenedModules = [...openedModules]
    const index = newOpenedModules.indexOf(anchor)
    index !== -1 ? newOpenedModules.splice(index, 1) : newOpenedModules.push(anchor)
    setOpenedModules(newOpenedModules)
  }

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `SDL`,
      backTo: `${SDL_ROUTE_ROUTE}`,
    })
  }, [])

  useEffect(() => {
    let count = 0
    const documentKeys = Object.keys(documents)
    documentKeys.forEach((obj) => {
      const nestedKeys = Object.keys(documents[obj])
      nestedKeys.forEach((nestedObj) => {
        if (documents[obj][nestedObj] === true) count += 1
      })
    })
    setDocumentsCount(count)
  }, [documents])

  useEffect(() => {
    if (id) {
      batch(() => {
        dispatch(fetchSdlUserPassportData({ userId: id }))
        dispatch(fetchSdlUserPersonalData({ userId: id }))
      })
    }
  }, [id])

  const onNavigationClick = (anchor: string): void => {
    if (!openedModules.includes(anchor)) setOpenedModules([...openedModules, anchor])
  }

  const props = {
    openedModules,
    onToggleModule,
    list,
    documents,
    setDocuments,
    setSteps,
    steps,
    userId: id,
    setShowWindow,
    showWindow,
    additionalSelectionStep,
    setAdditionalSubSelectionStep,
    prevDocumentsState,
    sePreviousDocumentsState,
    checkedDocuments,
    setCheckedDocuments,
    additionalRequirements,
    setAdditionalRequirements,
  }

  const onRollUp = (): void => {
    setOpenedModules([])
    const el = document.getElementById('loan-content')
    if (el) {
      el.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const onRollDown = (): void => {
    const anchors = list.map((v) => v.anchor)
    setOpenedModules(anchors)
  }

  return (
    <>
      {stepIsFetching ? (
        <StyledLoaderPage>
          <Loader />
        </StyledLoaderPage>
      ) : (
        <>
          <ScrollToTop />
          <StyledWrapper>
            {steps === 1 && (
              <Navigation
                list={navigationList()}
                containerId='loan-content'
                onChange={onNavigationClick}
              />
            )}
            <StyledNavContent>
              <StickyBox>
                <HeadInfo onRollUp={onRollUp} onRollDown={onRollDown} />
              </StickyBox>
              <StyledContent id='loan-content'>
                <Head />
                {showWindow.show && (
                  <LetterWindow setShowWindow={setShowWindow} userId={id} showWindow={showWindow} />
                )}
                {documentsCount > 0 && <LettersCount documentsCount={documentsCount} />}
                {steps === 1 && <Step1 props={props} />}
                {steps === 2.1 && <Step2AdditionalSelection props={props} />}
                {steps === 2 && <Step2 props={props} />}
                {steps === 3 && <Step3 props={props} />}
                {steps === 4 && <Step4 props={props} />}
                {/* <StyledButtonsBlock>
                  <Button type='standardBig' disabled={false} onClick={() => setSteps(steps + 1)}>
                    Продолжить
                  </Button>
                  <Button type='emptyBig' disabled={null} onClick={() => setSteps(steps - 1)}>
                    Изменить
                  </Button>
                </StyledButtonsBlock> */}
              </StyledContent>
            </StyledNavContent>
          </StyledWrapper>
        </>
      )}
    </>
  )
}
