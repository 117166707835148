import { v4 as uuid } from 'uuid'

export const STRUCTURE = {
  all: [
    { title: 'ФИО', id: uuid() },
    { title: 'Дата и время блокировки', id: uuid() },
    { title: 'Дата и время  разблокировки', id: uuid() },
    { title: 'Комментарий', id: uuid() },
    { title: 'Пользователь', id: uuid() },
    { title: 'Заблокирован', id: uuid() },
    { title: 'Заблокирован навсегда', id: uuid() },
    { title: 'Действие', id: uuid() },
  ],
}
