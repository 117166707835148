import { LOCAL_STORAGE } from 'constants/localStorage'
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios'

export const getApiConfig = (): AxiosRequestConfig => {
  const token = localStorage.getItem(LOCAL_STORAGE.AUTH_ITEMS)

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json; ',
    },
  }

  if (token) config.headers.Authorization = `bearer ${token}`

  return config
}

export const api: AxiosInstance = axios.create(getApiConfig())

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response === undefined) {
      // TODO:  error processing
    } else if (error.response.status === 401) {
      // TODO: authorization error
      // TODO: resetAuth();
      localStorage.removeItem(LOCAL_STORAGE.AUTH_ITEMS)
      ServiceBase.setAuthToken('')
      console.error('authorization filed')
    }
    return Promise.reject(error)
  }
)

export abstract class ServiceBase {
  protected static URL: string =
    (window as any)?.env?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || ''

  protected static serviceApi: AxiosInstance = api

  protected static accessToken: string

  public static setAuthToken(token: string): void {
    this.accessToken = token

    this.serviceApi.defaults.headers = {
      ...this.serviceApi.defaults.headers,
      Authorization: `bearer ${token}`,
    }
  }

  public static urlBuilder(url: string): string {
    return `${this.URL}/${url}`
  }

  protected static get(url: string, options?: AxiosRequestConfig): AxiosPromise {
    return this.serviceApi.get(this.urlBuilder(url), options)
  }

  protected static post(url: string, data?: unknown, options?: AxiosRequestConfig): AxiosPromise {
    return this.serviceApi.post(this.urlBuilder(url), data, options)
  }

  protected static put(url: string, data?: unknown, options?: AxiosRequestConfig): AxiosPromise {
    return this.serviceApi.put(this.urlBuilder(url), data, options)
  }

  protected static patch(url: string, data?: unknown, options?: AxiosRequestConfig): AxiosPromise {
    return this.serviceApi.patch(this.urlBuilder(url), data, options)
  }

  protected static delete(url: string, options?: AxiosRequestConfig): AxiosPromise {
    return this.serviceApi.delete(this.urlBuilder(url), options)
  }
}
