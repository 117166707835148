export enum LoanType {
  all = 'all',
  unassigned = 'unassigned',
  processing = 'processing',
  banned = 'banned',
  pre_approved = 'pre_approved',
}

export enum SdlType {
  all = 'all',
  active = 'active',
  outdated = 'outdated',
}

export interface ILoanPageData {
  pending: boolean
  error: boolean
  data: any
  totalCount: number
  iteration: number
  activePage: string
}

export interface ILoanSlice {
  totalAmount: any
  all: ILoanPageData
  unassigned: ILoanPageData
  pre_approved: ILoanPageData
  processing: ILoanPageData
}

export interface ILoanRequest {
  data: ILoanData[]
  total: number
}

export interface ILoanData {
  loans_loan_ask_sum: string
  loans_loan_date_create: Date
  loans_loan_id: string
  loans_loan_number: string
  loans_user_id: string
  passport_first_name: string
  passport_last_name: string
  passport_middle_name: string
  passport_number: string
  passport_serial: string
  registration_address: string
  // requests_count: string
  closed_count: string
  users_login: string
  utm_source: string
  total?: number
}

export interface ISelectedLoanData {
  loan_data: unknown
}
