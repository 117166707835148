/* eslint-disable react/no-array-index-key */
import { useToaster } from 'helpers/hooks/useToaster'
import { Toast } from 'lib/toast/Toast'
import { StyledToaster } from './Toaster.styles'

export const Toaster: any = ({ position, isAutoDelete, autoDeleteTime }) => {
  const [toasts, removeHandler] = useToaster()

  return (
    <>
      {toasts.length > 0 && (
        <StyledToaster position={position}>
          {toasts?.map((i, index) => (
            <Toast
              key={index}
              removeHandler={removeHandler}
              position={position}
              autoDeleteTime={autoDeleteTime}
              isAutoDelete={isAutoDelete}
              type={i?.type}
              title={i?.title}
              description={i?.description}
              id={i?.id}
            />
          ))}
        </StyledToaster>
      )}
    </>
  )
}

Toaster.defaultProps = {
  position: 'top-right',
  isAutoDelete: true,
  autoDeleteTime: 5000,
}
