import styled, { css } from 'styled-components/macro'

export const StyledToaster = styled.div<any>`
  width: 395px;
  padding: 10px 15px 10px 10px;
  font-size: 14px;
  box-sizing: border-box;
  z-index: 9999;
  position: fixed;

  ${({ position }) => {
    switch (position) {
      case 'top-right':
        return css`
          top: 12px;
          right: 12px;
          @media (max-width: 480px) {
            top: 54px;
          }
        `
      case 'bottom-right':
        return css`
          bottom: 12px;
          right: 12px;
        `
      case 'top-left':
        return css`
          top: 12px;
          left: 12px;
        `
      case 'bottom-left':
        return css`
          bottom: 12px;
          left: 12px;
        `

      default:
        return ''
    }
  }}
`
