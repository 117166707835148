import styled, { css } from 'styled-components/macro'
import { THEME } from '../../../core/theme'

interface IStyledValue {
  styles?: string
  alarm?: string
}

const STYLED_OPTIONS = {
  boldGreen: {
    color: THEME.COLORS.SUCCESS,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  error: {
    color: THEME.COLORS.WARNING,
    fontSize: '16px',
    fontWeight: 'bold',
  },
}

export const StyledHeadWrapper = styled.div``

export const StyledHeadActionBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  position: sticky;
  background-color: ${THEME.COLORS.WHITE};

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 10px 15px;
    /* display: none; */
  }
`

export const StyledStatus = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.GRAY};
  margin-left: 20px;

  & span {
    color: ${THEME.COLORS.DARK_BLUE};
    margin-left: 5px;
    font-size: 15px;
  }
`

export const StyledAction = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;

  & svg {
    margin-left: 12px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
  }
`

export const StyledActionButton = styled.div`
  display: flex;
  align-items: center;
`

export const StyledCloseExpandedElements = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`

export const StyledHeadLoanInfo = styled.div`
  display: grid;
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  grid-template-columns: repeat(4, 1fr);
  padding: 32px;
  gap: 30px 0;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const StyledInfo = styled.div`
  background: ${THEME.COLORS.SUCCESS};
  color: ${THEME.COLORS.WHITE};
  border-radius: 15px;
  padding: 2px 8px;
  width: fit-content;
  opacity: 0.8;
  font-size: 14px;
  position: absolute;
  left: 40px;
  top: -8px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  & svg {
    color: ${THEME.COLORS.WHITE};
    margin-left: 5px;
  }
`

export const StyledInfoWrapper = styled.div`
  position: relative;

  &:hover {
    ${StyledInfo} {
      opacity: 1;
    }
  }
`

export const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  display: flex;
`

export const StyledValue = styled.p<IStyledValue>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.DARK_BLUE};
  word-break: break-word;

  ${({ styles }) =>
    styles &&
    css`
      ${STYLED_OPTIONS[styles]}
    `}

  ${({ alarm }) =>
    alarm === 'red' &&
    css`
      color: red;
    `}

  ${({ alarm }) =>
    alarm === 'green' &&
    css`
      color: #24c665;
    `}
`

export const StyledBannedLabel = styled.p`
  border: 1px solid transparent;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.WHITE};
  background: ${THEME.COLORS.WARNING};
  border-radius: 30px;
  padding: 9px 14px;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  user-select: none;
  margin-right: 10px;
`

export const StyledRollIcon = styled.p`
  font-weight: 900;
  padding-right: 6px;
`
export const StyledLikelihood = styled.p<any>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  ${({ status }) => {
    switch (status) {
      case true:
        return css`
          color: ${THEME.COLORS.WARNING};
        `
      case false:
        return css`
          color: ${THEME.COLORS.SUCCESS};
        `
      case '':
        return css`
          color: gray;
          opacity: 0.5;
        `

      default:
        return ''
    }
  }}
`
export const StyledSelectAgent = styled.div`
  margin-bottom: 20px;
`

export const StyledUserStatus = styled.div`
  display: flex;
  align-items: center;
`
