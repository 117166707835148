import {
  ILoanSlice,
  ILoanData,
  IOtherDocs,
  IPassportData,
  IPersonalData,
  IBankCardsData,
  IUserDetails,
  IRegistrationData,
  IResidenceData,
  IImageLoanData,
  INbkiHitory,
  IAnalyzeData,
  IAssignInfo,
  IStatusLoan,
  IAutoCheck,
  IUserData,
  IImageLoanDataFetch,
  IPhones,
  IUpdateINN,
  IComments,
  IStatusHistory,
  IScoring,
  IPtiData,
  IAdditionalContactsData,
  IPtiBRSData,
  IVideoVerification,
  IBankruptData,
  ILoanGeneralInfoData,
  INBKITransfer,
} from './types'

export const initialLoanData: ILoanData = {
  loan_id: '',
  loan_number: '',
  loan_source: {},
  loan_date_create: '',
  loan_issue_date: '',
  loan_status: '',
  loan_ask_sum: 0,
  loan_ask_period: 0,
  loan_permitted_sum: '',
  loan_permitted_period: '',
  loan_settings: {},
  loan_repeated: false,
  requests_count: 0,
  product_percent_day: 0,
  credit_product: '',
  loan_content_id: '',
  is_third_transfer_approved: false,
  is_transfer_bki_approved: false,
}

export const initialUserData: IUserData = {
  user_id: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  customer_id: null,
}

export const initialOtherDocsData: IOtherDocs = {
  snils_number: '',
  inn_number: '',
}

export const initialFemidaData: any = {
  phone: null,
  passport: null,
  scoringLegal: null,
  scoringOneTimePhone: null,
  scoringFraudPhone: null,
}

export const initialBkiDocuments: any = []

export const initialPassportData: IPassportData = {
  isFetching: true,
  isUpdating: false,
  first_name: '',
  last_name: '',
  middle_name: '',
  gender: '',
  date_of_birth: '',
  place_of_birth: '',
  citizenship: '',
  serial: '',
  number: '',
  date_of_issue: '',
  place_of_issue: '',
  issued_department_url: '',
  code_division: '',
  previous_passport_data: '',
  registration_address: null,
  residence_address: null,
}

export const initialBankruptData: IBankruptData = {
  // data: {
  //   id: null,
  //   user_id: null,
  //   statement_date: '',
  //   bankrupt_date: '',
  //   is_all_debt_off: null,
  //   is_deliberate_bankrupt: null,
  //   is_fraud_credit: null,
  //   is_bankrupt_credit: null,
  //   bankrupt_company_name: '',
  //   bankrupt_credit_decision: '',
  // },
  data: null,
  isFetching: false,
  isUpdating: false,
}

export const initialLoanGeneralInfoData: ILoanGeneralInfoData = {
  data: null,
  isFetching: false,
}

export const InitialRegistrationData: IRegistrationData = {
  isFetching: true,
  isUpdating: false,
  residence_date: '',
  residence_place: '',
  postal_code: '',
  region: '',
  area: '',
  district: '',
  city: '',
  street: '',
  building: '',
  house_number: '',
  block: '',
  apartment: '',
  other: '',
}

export const InitialAdditionalContactsData: IAdditionalContactsData = {
  data: [],
  isFetching: false,
  isUpdating: false,
  error: false,
}

export const InitialResidenceData: IResidenceData = {
  isFetching: true,
  isUpdating: false,
  residence_date: '',
  residence_place: '',
  postal_code: '',
  region: '',
  area: '',
  district: '',
  city: '',
  street: '',
  building: '',
  house_number: '',
  block: '',
  apartment: '',
  other: '',
}

export const initialBankCardsData: Array<IBankCardsData> = []

export const initialPersonalData: IPersonalData = {
  isFetching: true,
  isUpdating: false,
  login: '',
  date_register: '',
  time_zone: '',
  check_word: '',
  maiden_name: '',
  marital_status: '',
  children_amount: '',
  email: '',
  additional_services: '',
  criminal_records: '',
  user_id: null,
}

export const initialUserDetailsData: IUserDetails = {
  isFetching: true,
  isUpdating: false,
  data: {
    education_level: '',
    activity_type: '',
    employment_type: '',
    work_position: '',
    company_name: '',
    company_address: '',
    company_employees_amount: '',
    company_chief_name: '',
    monthly_income: 0,
    monthly_outcome: '',
    other_income: '',
    next_salary_date: '',
    average_annual_income: '',
    work_experience: '',
    last_work_experience: '',
    has_a_car: false,
    monthly_credit_obligations: 0,
    is_banned_cabinet: false,
  },
}

export const initialNBKIHistory: Array<INbkiHitory> = []

export const initialStatusesHistory: IStatusHistory = {
  isFetching: true,
  data: [],
}

export const initialPayments: IStatusHistory = {
  isFetching: true,
  data: [],
}

export const initialVerificationData: IVideoVerification = {
  isFetching: true,
  data: [],
}

export const initialScoringHistory: IStatusHistory = {
  isFetching: true,
  data: [],
}

export const initialAnalyzeData: IAnalyzeData = {
  isFetching: true,
  intersection: {
    phones: [],
    registration_addresses: [],
    residence_addresses: [],
    bank_cards: [],
    passports: [],
    cookies: '',
  },
  ip: {
    loan_ip: '',
    region: '',
    first_login_date: '',
    register_place: [],
    loans_from_ip: [],
    approved_loans_from_ip: [],
  },
}

export const initialPhonesData: IPhones = {
  isFetching: true,
  dataPhones: [
    {
      number: '',
      number_type: '',
      is_default: false,
      is_active: false,
      comment: '',
    },
  ],
}

export const initialLoanImageData: IImageLoanData = {
  personalDataPhoto: [],
  registrationDataPhoto: [],
  bankCardDataPhoto: [],
  agentDataPhoto: [],
}
export const initialLoanImageDataFetch: IImageLoanDataFetch = {
  bankCardDataPhotoFetching: true,
  registrationDataPhotoFetching: true,
  personalDataPhotoFetching: true,
  agentDataPhotoFetching: true,
}

export const initialAssignInfo: IAssignInfo = {
  admin_id: [],
  total_time: 0,
  start_date: '',
  stop_date: '',
  is_paused: null,
}

export const initialStatues: IStatusLoan = {
  // same_address: null,
  is_free: null,
  is_in_work: null,
  is_paused: null,
}

// export const initialAutoCheck: Array<IAutoCheck> = []

export const initialUpdateINN: IUpdateINN = {
  isFetching: false,
}

export const initialFssp = {
  isFetching: false,
  data: null,
}

export const initialFinScoringMfoData = {
  isFetching: false,
  data: null,
}

export const initialLoanRatingData = {
  isFetching: false,
  data: null,
  token: null,
}

export const initialAutoCheck: IAutoCheck = {
  isFetching: true,
  autoCheckData: null,
}

export const initialComments: IComments = {
  isFetching: true,
  isUpdating: false,
  pending: false,
  data: [],
}

export const initialPtiData: IPtiData = {
  data: [],
  fetching: false,
  error: false,
}

export const initialScoring: IScoring = {
  error: null,
  verdict: null,
  score_points: null,
  verdict_thresholds: {
    grayzone: null,
    approve: null,
  },
}

export const initialPtiBRSData: IPtiBRSData = {
  data: [],
  fetching: false,
  error: false,
}

export const initialNBKITransferData: INBKITransfer = {
  isFetching: false,
  data: null,
}

export const initialState: ILoanSlice = {
  ptiBRSData: initialPtiBRSData,
  additionalContacts: InitialAdditionalContactsData,
  scoring: initialScoring,
  pending: false,
  resolve: null,
  selectedLoanData: null,
  nbkiScoringData: null,
  services: null,
  servicesPending: false,
  isOverdue: '',
  isFetchingLoanPage: false,
  fetchingLoanPageError: false,
  phonesData: initialPhonesData,
  videoVerification: initialVerificationData,
  loadData: initialLoanData,
  userData: initialUserData,
  passportData: initialPassportData,
  bankruptData: initialBankruptData,
  verifyPassportData: false,
  loanGeneralInfoData: initialLoanGeneralInfoData,
  femida: initialFemidaData,
  bkiDocuments: initialBkiDocuments,
  personalData: initialPersonalData,
  otherDocs: initialOtherDocsData,
  bankCardsData: initialBankCardsData,
  bankCardsFetch: true,
  userDetailsData: initialUserDetailsData,
  registrationData: InitialRegistrationData,
  residenceData: InitialResidenceData,
  loanImageData: initialLoanImageData,
  loanImageDataFetch: initialLoanImageDataFetch,
  analyzeData: initialAnalyzeData,
  commentsData: initialComments,
  expandCounterLoanItems: 0,
  assignInfo: initialAssignInfo,
  statusesLoan: initialStatues,
  statusesHistory: initialStatusesHistory,
  payments: initialPayments,
  scoringHistory: initialScoringHistory,
  nbkiHistoryData: initialNBKIHistory,
  ptiData: initialPtiData,
  isBanned: null,
  userId: null,
  same_address: null,
  autoCheck: initialAutoCheck,
  fssp: initialFssp,
  finScoringMfo: initialFinScoringMfoData,
  loanRating: initialLoanRatingData,
  updateINN: initialUpdateINN,
  dataTransactions: [],
  dataDept: [],
  in_passport: '',
  blacklisted: false,
  nbkiTransferData: initialNBKITransferData,
}
