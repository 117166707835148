/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectLoansService } from 'services/loanService'
import { ProjectAgentLoansService } from '../../services/agentLoanService'
import { AgentLoanStatusType, AgentLoanType } from './types'

export const fetchAgentLoans = createAsyncThunk<
  any,
  { iteration: number; type: string; filters: any; refresh?: boolean }
>('agent/loadAgentLoans', async (props) => {
  const { iteration, type, filters, refresh = false } = props

  const loan_status =
    filters?.loan_status && type === 'loan_approved'
      ? filters?.loan_status
      : AgentLoanStatusType[type]

  const response = await ProjectAgentLoansService.getAgentLoans(iteration, {
    ...filters,
    ...(loan_status ? { loan_status } : {}),
  }).then(({ data }) => ({
    data,
    type,
    refresh,
    iteration,
  }))
  return response
})

export const fetchAgentWaitingLoans = createAsyncThunk<any>(
  'agent/getAgentWaitingLoans',
  async () => {
    const response = await ProjectAgentLoansService.getAgentWaitingLoans().then(({ data }) => data)
    return response
  }
)

export const removeFromAgent = createAsyncThunk<
  any,
  { phone: string; successAction?: () => void; errorAction?: () => void }
>('agent/removeFromAgent', async (props) => {
  const { phone, successAction, errorAction } = props

  const response = await ProjectAgentLoansService.removeFromAgent(phone)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})

export const changeAgent = createAsyncThunk<
  any,
  { phone: string; agent_id: string; successAction?: () => void; errorAction?: () => void }
>('agent/changeAgent', async (props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { phone, successAction, errorAction, agent_id } = props
  const response = await ProjectAgentLoansService.changeAgent(phone, agent_id)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})
export const addToAgentAdmin = createAsyncThunk<
  any,
  { phone: string; admin_id: string; successAction?: () => void; errorAction?: () => void }
>('agent/changeAgent', async (props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { phone, successAction, errorAction, admin_id } = props
  const response = await ProjectAgentLoansService.changeAgentAdmin(phone, admin_id)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})

export const changeToAgentAdmin = createAsyncThunk<
  any,
  { phone: string; admin_id: string; successAction?: () => void; errorAction?: () => void }
>('agent/changeAgentLoan', async (props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { phone, successAction, errorAction, admin_id } = props
  const response = await ProjectAgentLoansService.changeAgentLoan(phone, admin_id)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})

export const fetchAgentLoansCount = createAsyncThunk<any>('agent/loadCount', async () => {
  const { loans_count: allApp } = await ProjectAgentLoansService.getAllAgentLoansCount().then(
    ({ data }) => data
  )
  const { loans_count: requiredDocs } =
    await ProjectAgentLoansService.getRequiredDocsAgentLoansCount().then(({ data }) => data)
  const { loans_count: checking } =
    await ProjectAgentLoansService.getCheckingAgentLoansCount().then(({ data }) => data)
  const { loans_count: checked } = await ProjectAgentLoansService.getCheckedAgentLoansCount().then(
    ({ data }) => data
  )
  const { loans_count: atWork } = await ProjectAgentLoansService.getAtWorkAgentLoansCount().then(
    ({ data }) => data
  )
  // const { loans_count: working } = await ProjectAgentLoansService.getWorkingAgentLoansCount().then(
  //   ({ data }) => data
  // )

  const response = {
    [AgentLoanType.allApp]: allApp,
    [AgentLoanType.requiredDocs]: requiredDocs,
    [AgentLoanType.checking]: checking,
    [AgentLoanType.atWork]: atWork,
    [AgentLoanType.checked]: checked,
  }
  return response
})

export const fetchAgentLoanUserPassportData = createAsyncThunk<any, { userId: string }>(
  'agent/fetchUserPassport',
  async (props) => {
    const { userId } = props
    const response = await ProjectAgentLoansService.getPassportData(userId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchAgentLoanUserPersonalData = createAsyncThunk<any, { userId: string }>(
  'agent/getPersonalData',
  async (props) => {
    const { userId } = props
    const response = await ProjectAgentLoansService.getPersonalData(userId).then(({ data }) => ({
      data,
    }))
    return response
  }
)

export const fetchLoanPageById = createAsyncThunk<any, { id }>(
  'agent/loadPageById',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getLoanPageById(id).then(({ data }) => data)
    return response
  }
)

export const fetchPersonalAgentsDataPhoto = createAsyncThunk<any, { id }>(
  'agent/loadPersonalPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPersonalDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchRegistrationAgentsDataPhoto = createAsyncThunk<any, { id }>(
  'agent/loadRegistrationPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getRegistrationDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchAgentDataPhoto = createAsyncThunk<any, { id }>(
  'agent/loadAgentPhoto',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getAgentDataPhoto(id).then(({ data }) => data) // TODO : CHANGE
    // const response = await ProjectLoansService.getRegistrationDataPhoto(id).then(({ data }) => data)
    return response
  }
)

export const fetchPhonesData = createAsyncThunk<any, { id }>(
  'agent/loadPhonesData',
  async (props) => {
    const { id } = props
    const response = await ProjectLoansService.getPhonesData(id).then(({ data }) => data)
    return response
  }
)
export const fetchUserLoans = createAsyncThunk<any, { ids: string[] }>(
  'agent/userLoans',
  async ({ ids }) => {
    const response = await ProjectLoansService.getDetailedLoansByUserId(ids).then(
      ({ data }) => data
    )
    return response?.data
  }
)

export const fetchAgentLoanAccept = createAsyncThunk<
  any,
  { loan_id: string; comment?: string; successAction?: () => void; errorAction?: () => void }
>('agent/LoanAccept', async (props) => {
  const { loan_id: id, comment = '', successAction, errorAction } = props

  const response = await ProjectAgentLoansService.loanAgentAccept({ id, comment })
    .then(({ data }) => {
      if (successAction) successAction()
      return {
        ...data,
        comment,
      }
    })
    .catch((e) => {
      if (errorAction) errorAction()
      throw new Error(e)
    })
  return response
})

export const getLoanComments = createAsyncThunk<any, { loanNumber: string }>(
  'agent/getComments',
  async (props) => {
    const { loanNumber } = props
    const response = await ProjectAgentLoansService.getLoanAgentComments(loanNumber).then(
      ({ data }) => data
    )
    return response
  }
)

export const createLoanComments = createAsyncThunk<
  any,
  {
    loanNumber: string
    comment: string
    successAction?: () => void
    errorAction?: () => void
  }
>('agent/createComment', async (props) => {
  const { loanNumber, comment, successAction, errorAction } = props
  const response = await ProjectAgentLoansService.createLoanAgentComments(loanNumber, comment)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})

export const updateLoanComments = createAsyncThunk<
  any,
  { commentId: string; comment: string; successAction?: () => void; errorAction?: () => void }
>('agent/updateComment', async (props) => {
  const { commentId, comment, successAction, errorAction } = props
  const response = await ProjectAgentLoansService.updateLoanComments(commentId, comment)
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})

export const getAgentUsers = createAsyncThunk<any, { id: string }>(
  'agent/getAgentUsers',
  async (props) => {
    const { id } = props
    const response = await ProjectAgentLoansService.getAgentUsers(id).then(({ data }) => data)
    return response
  }
)

export const refuseLoan = createAsyncThunk<
  any,
  {
    id: string
    comment: string
    byClient: boolean
    notice: boolean
    reject_code_id?: number
    ban_user?: boolean
    onSuccess?: () => void
    onError?: () => void
  }
>('agent/refuseLoan', async (props) => {
  const {
    id,
    comment,
    onSuccess,
    onError,
    byClient,
    notice,
    reject_code_id,
    ban_user = true,
  } = props

  const response = await ProjectAgentLoansService.refuseLoan({
    id,
    comment,
    byClient,
    notice,
    ban_user,
    reject_code_id,
  })
    .then(({ data }) => {
      if (onSuccess) onSuccess()
      return data
    })
    .catch(() => {
      if (onError) onError()
    })
  return response
})

export const changeStatus = createAsyncThunk<
  any,
  {
    loanId: string
    comment: string
    statusName: string
    successAction?: () => void
    errorAction?: () => void
  }
>('agent/changeStatus', async (props) => {
  const { comment, successAction, errorAction, statusName, loanId } = props
  const response = await ProjectAgentLoansService.changeStatus({
    comment,
    statusName,
    loanId,
  })
    .then(({ data }) => {
      successAction()
      return data
    })
    .catch(() => errorAction())
  return response
})
