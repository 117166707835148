import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

export class ProjectAuthService extends ServiceBase {
  protected static BASE_PATH = 'admins'

  public static authorization(data: FormData): AxiosPromise {
    return this.post(`${this.BASE_PATH}/token`, data)
  }

  public static getUserInfo(): AxiosPromise {
    return this.get(`${this.BASE_PATH}/info`)
  }
}
