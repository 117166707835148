import styled from 'styled-components/macro'
import { THEME } from '../../core/theme'
import { MIN_WIDTH } from '../../pages/UnderwriterList/Table/options'

export const StyledTableHeadWrapper = styled.div<{ template: string }>`
  padding: 0 35px;
  display: grid;
  /* column-gap: 8px; */
  grid-template-columns: ${(props) => props.template};
  min-width: ${MIN_WIDTH};
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px dotted ${THEME.COLORS.TABLE_BORDER};
  background-color: #f8cc4609;
  background: ${THEME.COLORS.SMOOTH};
  box-shadow: 0 1px 2px rgba(57, 63, 72, 0.1);
  z-index: 999;

  @media (max-width: 480px) {
    display: none;
    min-width: 100%;
  }
`

export const StyledTable = styled.div`
  padding: 15px 5px;
  border-right: 1px dotted ${THEME.COLORS.TABLE_BORDER};
  word-break: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
`

export const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: #4b506d;
  word-break: initial;
`
