/* eslint-disable indent */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */
import { Fragment, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { Button, Module, Select } from 'components'
import {
  normalizeValues,
  taskStatus as taskStatuses,
  collectorStatus as collectorStatuses,
} from 'helpers'
import { GlobalState } from 'index'
import { orderBy } from 'lodash'

import { ReactComponent as CloseSVG } from 'assets/close.svg'

import {
  StyledOverflowWrapper,
  StyledTaskButtonBlock,
  StyledTasksContent,
  StyledCommentsBlock,
  StyledCommentTitle,
  StyledTextArea,
  StyledButtons,
  StyledSelectBlock,
  StyledResetFilter,
  StyledCreateTaskBlock,
} from './Modules.styles'
import { addCollectorTasks, fetchCollectorTasks } from '../../../features/collector/extra'
import { addToast } from '../../../features/toast/toastSlice'
import { ToastTypes } from '../../../lib/undo/Undo'

const moduleId = 'tasks'

export const Tasks: any = ({ openedModules, onToggleModule, loan_id }): JSX.Element => {
  const dispatch = useDispatch()
  const [newComment, setNewComment] = useState<string>('')
  const [taskStatus, setTaskStatus] = useState<keyof typeof taskStatuses | string>('')
  const [collectorStatus, setCollectorStatus] = useState<keyof typeof collectorStatuses | string>(
    ''
  )
  const [showForm, setShowForm] = useState<boolean>(false)

  const isOpen = openedModules.includes(moduleId)

  const tasks = useSelector((store: GlobalState) => store.collector.tasks)
  const isFetchAddTask = useSelector((store: GlobalState) => store.collector.isFetchAddTask)

  const StatusesList = Object.keys(taskStatuses).map((value) => ({
    label: taskStatuses[value],
    value,
  }))

  const CollectorStatusesList = Object.keys(collectorStatuses).map((value) => ({
    label: collectorStatuses[value],
    value,
  }))

  const handleExpand = (): void => onToggleModule(moduleId)

  const resetState = (): void => {
    setNewComment('')
    setTaskStatus('')
    setCollectorStatus('')
  }

  const handleAddTask = (): void => {
    // required
    const data = {
      comment: newComment,
      task_status: taskStatus,
    }
    // optional
    if (collectorStatus) Object.assign(data, { reminded_at: collectorStatus })

    dispatch(
      addCollectorTasks({
        loanId: loan_id,
        body: data,
        onSuccess: () => {
          resetState()
          setShowForm(false)
          batch(() => {
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Здача создана',
                description: 'Здача успешно создана',
              })
            )
            dispatch(fetchCollectorTasks({ loanId: loan_id }))
          })
        },
        onError: () =>
          dispatch(
            addToast({
              type: ToastTypes.danger,
              title: 'Здача не создана',
              description: 'произошла ошибка',
            })
          ),
      })
    )
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            Задачи
            {!tasks || !tasks.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {!showForm && (
          <StyledTaskButtonBlock>
            <Button
              disabled={false}
              type='bigSuccess'
              pending={false}
              onClick={() => setShowForm(true)}
            >
              Создать задачу
            </Button>
          </StyledTaskButtonBlock>
        )}
        {showForm && (
          <>
            <StyledCommentsBlock>
              <StyledCommentTitle>Создание задачи</StyledCommentTitle>
              <StyledCreateTaskBlock>
                <StyledTextArea
                  value={newComment || ''}
                  maxLength={999}
                  placeholder='Комментарий *'
                  onChange={(e) => setNewComment(e?.target?.value)}
                />
                <StyledSelectBlock>
                  <Select
                    data={StatusesList}
                    placeholder='Ответ *'
                    value={StatusesList?.find((v) => v.value === taskStatus) || ''}
                    onChange={(v) => setTaskStatus(v.value)}
                    error={taskStatus.trim() === ''}
                  />
                  {taskStatus && (
                    <StyledResetFilter onClick={() => setTaskStatus('')}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
                <StyledSelectBlock>
                  <Select
                    data={CollectorStatusesList}
                    placeholder='Статус коллектора'
                    value={CollectorStatusesList?.find((v) => v.value === collectorStatus) || ''}
                    onChange={(v) => setCollectorStatus(v.value)}
                  />
                  {collectorStatus && (
                    <StyledResetFilter onClick={() => setCollectorStatus('')}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
              </StyledCreateTaskBlock>
              <StyledButtons>
                <Button
                  type='bigSuccess'
                  onClick={
                    newComment.trim() === '' || taskStatus === '' || isFetchAddTask
                      ? null
                      : handleAddTask
                  }
                  disabled={newComment.trim() === '' || taskStatus === '' || isFetchAddTask}
                  pending={isFetchAddTask}
                >
                  Создать
                </Button>
                <Button type='emptyBig' disabled={false} onClick={() => setShowForm(false)}>
                  Отмена
                </Button>
              </StyledButtons>
            </StyledCommentsBlock>
          </>
        )}

        <StyledOverflowWrapper>
          <StyledTasksContent>
            <TableHead />
          </StyledTasksContent>
          {tasks &&
            tasks.length > 0 &&
            orderBy(tasks, ['created_at'], ['desc']).map((item, index) => (
              <Fragment key={item?.created_at || index}>
                <StyledTasksContent>
                  <Table {...item} key={item?.created_at || index} />
                </StyledTasksContent>
              </Fragment>
            ))}
        </StyledOverflowWrapper>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата создания</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Статус</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Ответ</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Комментарий</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Создал</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const {
    created_at: createDate,
    username,
    comment,
    answer_type: answerType,
    task_status: status,
  } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(createDate, 'date')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(status, 'collector_status')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(answerType)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(comment)}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(username)}</Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
