/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ContractStatus } from 'features/contracts/types'
import { formatDate, normalizeValues } from 'helpers'
import { CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'

import {
  StyledMobileHeaderAll,
  StyledMobileDescription,
  StyledMobileItemWrapper,
  StyledMobileLoanNumbers,
  StyledMobileSection,
  StyledMobileTopAll,
  StyledMobileValue,
  StyledMobileCheckedHead,
  StyledCreateDate,
  StyledStatusWrapper,
  StyledStatusMobile,
  StyledMobileDividerSection,
  StyledHead,
} from './Table.styles'

export const AllView: any = ({ contract }): JSX.Element => {
  return (
    <StyledMobileItemWrapper>
      <StyledMobileHeaderAll>
        <StyledMobileTopAll>
          <StyledStatusWrapper>
            <StyledStatusMobile status={contract.loan_statuses_status_name} />
            {ContractStatus[contract.loan_statuses_status_name]}
          </StyledStatusWrapper>
        </StyledMobileTopAll>
      </StyledMobileHeaderAll>
      <br />

      <StyledMobileSection>
        <StyledMobileSection>
          <StyledMobileCheckedHead>
            <StyledHead>
              <div>
                <StyledMobileDescription>Номер заявки/Дата создания</StyledMobileDescription>
                <StyledMobileLoanNumbers>
                  {normalizeValues(contract.loans_loan_number)}
                </StyledMobileLoanNumbers>
              </div>
              <StyledCreateDate>{formatDate(contract?.loans_loan_date_create)}</StyledCreateDate>
            </StyledHead>
          </StyledMobileCheckedHead>
        </StyledMobileSection>

        <StyledMobileSection>
          <StyledMobileDescription>ФИО клиента</StyledMobileDescription>
          <StyledMobileValue>
            {contract?.passport_last_name} {contract?.passport_first_name}{' '}
            {contract?.passport_middle_name}
          </StyledMobileValue>
        </StyledMobileSection>

        <StyledMobileSection>
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Баланс ₽</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(contract.balance)}</StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Дней просрочки</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(contract.overdue_days)}</StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
          <div style={{ marginTop: '18px' }}>
            <StyledMobileDescription>Тип продукта</StyledMobileDescription>
            <StyledMobileValue>
              {CreditProductTranslate[CreditProductCodes[contract?.loans_product_id]]}
            </StyledMobileValue>
          </div>
        </StyledMobileSection>
        <StyledMobileSection>
          <StyledMobileDividerSection>
            <div>
              <StyledMobileDescription>Руководитель</StyledMobileDescription>
              <StyledMobileValue>
                {normalizeValues(contract.agent_manager_username)}
              </StyledMobileValue>
            </div>
            <div>
              <StyledMobileDescription>Агент</StyledMobileDescription>
              <StyledMobileValue>{normalizeValues(contract.agent_username)}</StyledMobileValue>
            </div>
          </StyledMobileDividerSection>
        </StyledMobileSection>
      </StyledMobileSection>
    </StyledMobileItemWrapper>
  )
}

export const ActiveView: any = ({ contract }): JSX.Element => <StyledMobileItemWrapper />

export const OverdueView: any = ({ contract }): JSX.Element => <StyledMobileItemWrapper />
