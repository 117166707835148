/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { rolesType } from 'constants/rtoles'
import { useEffect, useState, useContext } from 'react'
import { AgentLoanTypeValue } from 'features/agentLoans/types'
import { useHistory, useLocation } from 'react-router-dom'

import { NavMenuType } from 'App'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'index'
import { fetchAgentLoans } from 'features/agentLoans/extra'

import { resetAgentsLoans } from 'features/agentLoans/loanSlice'
import { setAgentsFilters } from 'features/agentFilters/filtersSlice'
import { Controls } from '../../components/Controls'
import { Filters } from './Filters'
import { Search } from '../../components'
import { StyledPageWrapper } from './AgentPage.styles'

import { AGENT_LOANS_ROUTE, UNDERWRITER_LOANS_ROUTE } from '../../constants'
import { Table } from './Table'
import { NavigationContext } from 'app/contexts'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const AgentList = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const q = useQuery()
  const location: any = useLocation()

  const [type, setType] = useState(null)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const {
    setNavigation,
    isAgentRole,
    isManager,
    isAgent,
    setNavMenuType,
    isRegionalManager,
    isHeadOfDirectionRole,
  } = useContext(NavigationContext)
  const { data: filters } = useSelector((store: GlobalState) => store.agentFilters)
  const loans = useSelector((store: GlobalState) => store.agentLoans)
  const { username = '' } = useSelector((store: GlobalState) => store.auth?.user || {})

  const [agentUsername, setAgentUsername] = useState(null)

  useEffect(() => {
    if (isAgentRole === false) {
      history.push(UNDERWRITER_LOANS_ROUTE)
      setNavMenuType(NavMenuType.underwriter)
    } else {
      setNavMenuType(NavMenuType.agent)
    }
  }, [isAgentRole])

  useEffect(() => {
    const queryType = q.get('type')
    setType(queryType ? AgentLoanTypeValue[queryType] : AgentLoanTypeValue.all_app)
  }, [])

  useEffect(() => {
    if (username && isManager !== null && isAgent !== null) {
      let checkAgentUsername = null

      if (!isManager && isAgent) checkAgentUsername = username
      else checkAgentUsername = false

      setAgentUsername(checkAgentUsername)
    }
  }, [username, isManager, isAgent])

  useEffect(() => {
    if (loans[type]?.data === null && !loans[type]?.pending && agentUsername !== null) {
      let currentFilters = filters
      if (
        agentUsername &&
        !currentFilters.agent_username &&
        !isRegionalManager &&
        !isHeadOfDirectionRole
      ) {
        currentFilters = { ...currentFilters, agent_username: agentUsername }
      }
      if (isRegionalManager) {
        currentFilters = { ...currentFilters, agent_manager_username: username }
      }
      dispatch(setAgentsFilters(currentFilters))
      dispatch(
        fetchAgentLoans({
          iteration: 1,
          type,
          filters: currentFilters,
        })
      )
    }
  }, [type, filters, loans, agentUsername])

  useEffect(() => {
    if (loans[type]?.data !== null) dispatch(resetAgentsLoans(null))
  }, [filters])

  const fetchMoreData = () => {
    dispatch(fetchAgentLoans({ iteration: loans[type]?.iteration + 1, type, filters }))
  }

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Агент`,
      backTo: false,
    })
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()
    if (type) {
      params.append('type', type)
      history.push({
        pathname: AGENT_LOANS_ROUTE,
        search: params.toString(),
        state: { type },
      })
    }
  }, [type, history])

  useEffect(() => {
    if (location) {
      const { type: stateType } = location?.state || {}
      if (stateType) setType(stateType)
    }
  }, [location])

  const toggleFilters = (): void => {
    setShowFilters(!showFilters)
    setShowSearch(false)
  }

  const toggleSearch = (): void => {
    setShowSearch(!showSearch)
    setShowFilters(false)
  }

  const checkHasMore = () => !loans[type]?.finish

  const filterProps = {
    toggleFilters,
    toggleSearch,
    setShowFilters,
    showFilters,
    showSearch,
    setShowSearch,
    type,
    setType,
    pageControlsStructure: rolesType.agent,
    actionType: rolesType.agent,
    pending: loans[type]?.pending,
  }

  const tableProps = {
    fetchMoreData,
    checkHasMore,
    loans,
    type,
  }

  return (
    <StyledPageWrapper>
      <Controls {...filterProps} />
      <Filters {...filterProps} type={type} />
      <Search {...filterProps} pending={false} />
      <Table {...tableProps} />
    </StyledPageWrapper>
  )
}
