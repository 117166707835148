export const THEME = {
  COLORS: {
    DARK_BLUE: '#08003A',
    BLUE: '#2333CB',
    YELLOW: '#F8CC46',
    YELLOW_HOVER: '#fad35e',
    WARNING_YELLOW: '#ffc107',
    WARNING: '#FF4646',
    RED: '#FF4646',
    SUCCESS: '#24C665',
    SUCCESS_LIGHT: '#2bd574',
    SNACK_SUCCESS: '#28a745',
    SNACK_ERROR: '#dc3545',
    GRAY: '#73717E',
    DARK_GRAY: '#3b414f',
    LIGHT_GRAY: '#4b5059',
    LIGHTLY_GRAY: '#CFCED8',
    BLACK: '#1F2532',
    WHITE: '#ffffff',
    SMOOTH: '#F5F6FA',
    TABLE_BORDER: '#e8e8e8',
    TABLE_HOVER: '#ebeeff',
    TABLE_INDURATE: '#fef5dab8',
    BORDER_LOAN: '#dfe0eb',
  },
}
