import { StyledWrapper } from './Badge.styled'

interface IBadgeProps {
  type: 'danger' | 'success'
  title: string
}

export const Badge: React.FC<IBadgeProps> = (props): JSX.Element => {
  const { type, title } = props

  return (
    <StyledWrapper type={type}>
      <p>{title}</p>
    </StyledWrapper>
  )
}
