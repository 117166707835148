/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchModalLoans } from 'features/modalLoans/extra'
import { useGetLoanPassportData } from 'features/loan/loanSelectors'
import { GlobalState } from 'index'

import { normalizeValues } from 'helpers'
import { EmptyValueReplacer, Module } from 'components'
import { ImageModal } from 'containers'
import { IPassportData } from 'features/loan/types'
import { IModuleProps } from './types'

import {
  StyledSubTitle,
  StyledValueWrapper,
  StyledModalButton,
  StyledBottomBlock,
} from './Modules.styles'

const moduleId = 'info'
export const Verification: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
  claimsResolve,
}): JSX.Element => {
  const dispatch = useDispatch()

  const passportData: IPassportData = useGetLoanPassportData()
  const { isFetching, intersection, ip } = useSelector(
    (store: GlobalState) => store.singleLoan.analyzeData
  )

  const [showModal, setShowModal] = useState({ show: false, title: '' })
  const isOpen = openedModules.includes(moduleId)

  const handleExpand = (): void => onToggleModule(moduleId)
  const handleCloseModal = (): void => setShowModal({ show: false, title: '' })
  const handleShowModal = (data, title, type) => (): void => {
    dispatch(fetchModalLoans({ ids: data, type }))
    setShowModal({ show: true, title })
  }

  return (
    <Module.Wrapper isOpen={isOpen} editable={false} isEdited={false} id={moduleId}>
      <ImageModal.ModalIntersections
        claimsResolve={claimsResolve}
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        title={showModal.title}
      />

      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Информация для проверки</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <Module.Content isFetching={isFetching}>
          {isFetching && <Module.LoaderBlock />}
          <Module.Column column={4} withoutBorder>
            <StyledSubTitle>СОВПАДЕНИЕ ДАННЫХ С ДРУГИМИ КЛИЕНТАМИ</StyledSubTitle>
          </Module.Column>
          <Module.Column column={2}>
            <Module.Title>Совпадения по номеру телефона</Module.Title>
            <StyledValueWrapper>
              <Module.Value>
                {(intersection?.phones && intersection?.phones.length) || <EmptyValueReplacer />}
              </Module.Value>
              {intersection?.phones && intersection?.phones.length ? (
                <StyledModalButton
                  onClick={handleShowModal(
                    intersection?.phones,
                    'Совпадения по номеру телефона',
                    'userIds'
                  )}
                >
                  Посмотреть
                </StyledModalButton>
              ) : null}
            </StyledValueWrapper>
          </Module.Column>
          <Module.Column column={2}>
            <Module.Title>Совпадения по адресу регистрации</Module.Title>
            <StyledValueWrapper>
              <Module.Value>
                {(intersection?.registration_addresses &&
                  intersection?.registration_addresses.length) || <EmptyValueReplacer />}
              </Module.Value>
              {intersection?.registration_addresses &&
              intersection?.registration_addresses.length ? (
                <StyledModalButton
                  onClick={handleShowModal(
                    intersection?.registration_addresses,
                    'Совпадения по адресу регистрации',
                    'userIds'
                  )}
                >
                  Посмотреть
                </StyledModalButton>
              ) : null}
            </StyledValueWrapper>
          </Module.Column>
          <Module.Column column={2}>
            <Module.Title>Совпадения по адресу проживания</Module.Title>
            <StyledValueWrapper>
              <Module.Value>
                {(intersection?.residence_addresses &&
                  intersection?.residence_addresses.length) || <EmptyValueReplacer />}
              </Module.Value>
              {intersection?.residence_addresses && intersection?.residence_addresses.length ? (
                <StyledModalButton
                  onClick={handleShowModal(
                    intersection?.residence_addresses,
                    'Совпадения по адресу проживания',
                    'userIds'
                  )}
                >
                  Посмотреть
                </StyledModalButton>
              ) : null}
            </StyledValueWrapper>
          </Module.Column>
          <Module.Column column={2}>
            <Module.Title>Совпадения по карте</Module.Title>
            <StyledValueWrapper>
              <Module.Value>
                {(intersection?.bank_cards && intersection?.bank_cards.length) || (
                  <EmptyValueReplacer />
                )}
              </Module.Value>
              {intersection?.bank_cards && intersection?.bank_cards.length ? (
                <StyledModalButton
                  onClick={handleShowModal(
                    intersection?.bank_cards,
                    'Совпадения по карте',
                    'userIds'
                  )}
                >
                  Посмотреть
                </StyledModalButton>
              ) : null}
            </StyledValueWrapper>
          </Module.Column>
          <Module.Column column={2}>
            <Module.Title>Совпадения по серии и номеру паспорта</Module.Title>
            <StyledValueWrapper>
              <Module.Value>
                {(intersection?.passports && intersection?.passports.length) || (
                  <EmptyValueReplacer />
                )}
              </Module.Value>
              {intersection?.passports && intersection?.passports.length ? (
                <StyledModalButton
                  onClick={handleShowModal(
                    intersection?.passports,
                    'Совпадения по серии и номеру паспорта',
                    'userIds'
                  )}
                >
                  Посмотреть
                </StyledModalButton>
              ) : null}
            </StyledValueWrapper>
          </Module.Column>
          <Module.Column column={2}>
            <Module.Title>Cookie совпадение с другими клиентами</Module.Title>
            <Module.Value>{normalizeValues(intersection.cookies)}</Module.Value>
          </Module.Column>
        </Module.Content>
        <StyledBottomBlock />
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
