export const rolesType = {
  underwriter: 'underwriter',
  agent: 'agent',
  agentContract: 'agentContract',
  operator: 'operator',
  collector: 'collector',
  regionalManager: 'regional_manager',
  headOfDirection: 'head_of_direction',
  areaDirector: 'area_director',
} as const
