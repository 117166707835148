/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

interface IFilterState {
  data: {
    [key: string]: string | any
    search?: string
  }
}

export const initialFiltersState: IFilterState = {
  data: {},
}

export const banListFiltersSlice = createSlice({
  name: 'banListFilters',
  initialState: initialFiltersState,
  reducers: {
    setBanListFilters: (state, { payload }) => {
      state.data = { ...payload }
    },
  },
})

export const { setBanListFilters } = banListFiltersSlice.actions

export default banListFiltersSlice.reducer
