import { v4 as uuid } from 'uuid'

export const STRUCTURE = {
  underwriter: {
    all: [
      { title: 'Номер', id: uuid() },
      { title: 'Трафик', id: uuid() },
      { title: 'Дата и время создания', id: uuid(), sort: 'date' },
      { title: 'Тип продукта', id: uuid(), sort: 'date' },
      { title: 'Кол-во закрытых займов', id: uuid() },
      { title: 'ФИО / Паспорт', id: uuid() },
      { title: 'Выданная сумма', id: uuid() },
      { title: 'Регион', id: uuid() },
      { title: 'Оператор', id: uuid() },
      { title: 'Статус', id: uuid() },
    ],
    unassigned: [
      { title: 'Номер', id: uuid() },
      { title: 'Трафик / Тип продукта', id: uuid() },
      { title: 'Дата и время создания', id: uuid(), sort: 'date' },
      { title: 'Кол-во закрытых займов', id: uuid() },
      { title: 'ФИО', id: uuid() },
      { title: 'Телефон', id: uuid() },
      { title: 'Запрашиваемая сумма, ₽', id: uuid() },
      { title: 'Регион', id: uuid() },
      { title: 'Оператор', id: uuid() },
    ],
    pre_approved: [
      { title: 'Номер', id: uuid() },
      { title: 'Трафик / Тип продукта', id: uuid() },
      { title: 'Дата статуса', id: uuid(), sort: 'date' },
      { title: 'Дата и время создания', id: uuid(), sort: 'date' },
      { title: 'Кол-во закрытых займов', id: uuid() },
      { title: 'ФИО', id: uuid() },
      { title: 'Телефон', id: uuid() },
      { title: 'Запрашиваемая сумма, ₽', id: uuid() },
      { title: 'Регион', id: uuid() },
      { title: 'Оператор', id: uuid() },
    ],
    processing: [
      { title: 'Номер', id: uuid() },
      { title: 'Трафик / Тип продукта', id: uuid() },
      { title: 'Дата и время создания', id: uuid(), sort: 'date' },
      { title: 'Кол-во закрытых займов', id: uuid() },
      { title: 'ФИО', id: uuid() },
      { title: 'Телефон', id: uuid() },
      { title: 'Запрашиваемая сумма, ₽', id: uuid() },
      { title: 'Регион', id: uuid() },
      { title: 'Оператор', id: uuid() },
    ],
  },
  operator: {
    all: [
      { title: 'Номер', id: uuid() },
      { title: 'Трафик', id: uuid() },
      { title: 'Дата и время создания', id: uuid(), sort: 'date' },
      { title: 'Тип продукта', id: uuid() },
      { title: 'Кол-во закрытых займов', id: uuid() },
      { title: 'ФИО', id: uuid() },
      { title: 'Паспорт', id: uuid() },
      { title: 'Телефон', id: uuid() },
      { title: 'Запрашиваемая сумма, ₽', id: uuid() },
      { title: 'Выданная сумма', id: uuid() },
      { title: 'Регион', id: uuid() },
      { title: 'Статус', id: uuid() },
    ],
  },
}
