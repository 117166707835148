/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { MouseEvent } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import StickyBox from 'react-sticky-box'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { EmptyValueReplacer, Loader, ScrollToTop, TableHead } from 'components'
import { calcTime, copyToClipboard } from 'helpers'
import { GlobalState } from '../../../index'
import { fetchAssignTheLoan, fetchUnAssignTheLoan } from 'features/collector/extra'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'
import { BODY_STRUCTURE, COLUMN_TEMPLATE, HEAD_STRUCTURE } from './tableStructure'
import { HiOutlineClipboardCopy } from 'react-icons/hi'

import { ReactComponent as AlertSVG } from 'assets/alert_rectangle.svg'

import {
  StaledContainer,
  StyledEmptyLabel,
  StyledInfiniteLoader,
  StyledTableWrapper,
} from '../CollectorList.styles'
import { StyledRowWrapper, StyledTitle, StyledValue, StyledInfo } from './Table.styles'
import { StyledTimeLimitation, StyledTimeLimitationTitle } from '../CollectorLimitation.styles'

export const Table = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  const { type, fetchMoreData, checkHasMore, loans } = props
  const user = useSelector((store: GlobalState) => store.auth.user)
  const history = useHistory()
  const { path } = useRouteMatch()

  const handleOpenLoan = (e, id: string) => {
    e.preventDefault()
    history.push(`${path}/${id}`)
  }

  const dataList = Object.values(loans[type]?.data || {})

  const LATE_HOURS = 22
  const EARLY_HOURS = 9

  const handleAssignActionsLoan = (
    loanId: string,
    type: string,
    username: any,
    collectorUserName: string
  ): void => {
    if (collectorUserName)
      dispatch(
        fetchUnAssignTheLoan({
          loanId,
          type,
          username,
          onSuccess: () =>
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Поздравляем',
                description: `Заявка успешно откреплена от коллектора ${username?.username}`,
              })
            ),
        })
      )
    else
      dispatch(
        fetchAssignTheLoan({
          loanId,
          type,
          username,
          onSuccess: () =>
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Поздравляем',
                description: 'Заявка успешно взята в работу',
              })
            ),
        })
      )
  }
  const copy = (e, value) => {
    e.stopPropagation()
    e.preventDefault()
    copyToClipboard(value)
      .then(() => {
        dispatch(
          addToast({
            type: ToastTypes.success,
            title: 'Успех',
            description: `Данные скопированны`,
          })
        )
      })
      .catch((err) => {
        console.log('Something went wrong', err)
      })
  }
  return (
    <div style={{ overflow: 'auto' }} id='scrollable'>
      <ScrollToTop scrollTo='#scrollable' />
      <StickyBox style={{ zIndex: 1 }}>
        <TableHead structure={HEAD_STRUCTURE} type={type} template={COLUMN_TEMPLATE[type]} />
      </StickyBox>
      <StyledTableWrapper>
        <>
          <InfiniteScroll
            dataLength={dataList?.length || 0}
            next={fetchMoreData}
            hasMore={checkHasMore()}
            loader={
              <StyledInfiniteLoader>
                <Loader />
              </StyledInfiniteLoader>
            }
            scrollableTarget='scrollable'
          >
            {loans && dataList.length ? (
              dataList?.map((loan: any) => {
                const currentUserHours = calcTime(loan?.users_time_zone?.split(':')[0]).split(
                  ':'
                )[0]

                const overTime = loan?.users_time_zone
                  ? currentUserHours >= LATE_HOURS || currentUserHours < EARLY_HOURS
                  : false

                return (
                  <StaledContainer key={`${loan.loans_loan_id}`} overTime={overTime}>
                    <>
                      <StyledRowWrapper
                        onClick={(e) => handleOpenLoan(e, loan.loans_loan_id)}
                        template={COLUMN_TEMPLATE[type]}
                        href={`${path}/${loan.loans_loan_id}`}
                      >
                        {BODY_STRUCTURE[type]?.map((column) => {
                          return (
                            <StyledValue
                              key={column.id}
                              onClick={(e: MouseEvent<HTMLElement>) => {
                                if (column.copy) {
                                  copy(e, loan?.[column?.name])
                                  return
                                }
                                e.stopPropagation()
                                column.action && !loan.pending
                                  ? handleAssignActionsLoan(
                                      loan.loans_loan_id,
                                      type,
                                      user,
                                      loan.collector_username
                                    )
                                  : handleOpenLoan(e, loan.loans_loan_id)
                              }}
                            >
                              <StyledTitle pending={loan.pending}>
                                {column.value
                                  ? column.value(loan, user)
                                  : loan[column.name] || <EmptyValueReplacer />}
                              </StyledTitle>
                              {column?.name === 'users_time_zone' && (
                                <StyledTimeLimitation>
                                  <AlertSVG />
                                  <StyledTimeLimitationTitle>
                                    Действует ограничение звонков из-за местного времени клиента с 9
                                    до 22
                                  </StyledTimeLimitationTitle>
                                </StyledTimeLimitation>
                              )}
                              {column.copy && (
                                <StyledInfo>
                                  <HiOutlineClipboardCopy />
                                </StyledInfo>
                              )}
                            </StyledValue>
                          )
                        })}
                      </StyledRowWrapper>
                    </>
                  </StaledContainer>
                )
              })
            ) : (
              <StyledEmptyLabel>
                {!loans[type]?.pending && <p>Ничего не найдено</p>}
              </StyledEmptyLabel>
            )}
          </InfiniteScroll>
        </>
      </StyledTableWrapper>
    </div>
  )
}
