import { clamp } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { RangeSlider } from './RangeSlider'
import {
  StyledRangeInputWrapper,
  StyledRangeSlider,
  StyledInput,
  StyledLabel,
} from './RangeInput.styles'

export const RangeInput: React.FC<any> = ({
  value = 0,
  onChange,
  label,
  placeholder,
  minValue = 0,
  maxValue = 1,
  postfix,
  step,
  disabled,
  ...props
}) => {
  const input = useRef(null)
  const [type, setType] = useState('text')
  const [postfixValue, setPostfixValue] = useState(value)
  const [error, setError] = useState(false)

  const checkError = (val) => {
    if (val > maxValue || val < minValue) {
      if (!error) {
        setError(true)
      }
    } else if (error) {
      setError(false)
    }
  }

  const handleFocus = (e) => {
    setType('number')
    setPostfixValue(value)
    setTimeout(() => e.target.select())
  }

  const handleChange = (v) => {
    if (disabled) return

    const val = v?.target?.value || v

    onChange(clamp(val, minValue, maxValue))
    setPostfixValue(Math.ceil(val))
    checkError(val)
  }

  const handleChangeSlider = (val) => {
    if (type === 'number') {
      input.current.blur()
    }
    handleChange(val)
  }

  const handleBlur = () => {
    setType('text')
    handleChange(value)
  }

  useEffect(() => {
    if (type === 'text') {
      setPostfixValue(`${value.toLocaleString()} ${postfix}`)
    }
  }, [value, type, postfix])

  return (
    <StyledRangeInputWrapper {...(props || {})}>
      <StyledInput
        disabled={disabled}
        ref={input}
        error={error}
        type={type}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={postfixValue}
        min={minValue}
        max={maxValue}
        label={label ? <StyledLabel>{label}</StyledLabel> : null}
        placeholder={placeholder || null}
      />
      <StyledRangeSlider>
        <RangeSlider
          minValue={minValue}
          maxValue={maxValue}
          value={clamp(value, minValue, maxValue)}
          onChange={handleChangeSlider}
          step={step}
        />
      </StyledRangeSlider>
    </StyledRangeInputWrapper>
  )
}
