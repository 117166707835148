/* eslint-disable arrow-body-style */
import { HiOutlineTrash, HiOutlineRefresh } from 'react-icons/hi'

import {
  StyledActionChange,
  StyledActionDelete,
  StyledActionProvider,
  StyledMobileDescription,
  StyledMobileItemWrapper,
  StyledMobileLoanNumbers,
  StyledMobileSection,
  StyledMobileValue,
} from './Table.styles'

export const ClientView: any = ({
  client,
  handleChangeAgentModal,
  handleDeleteModal,
}): JSX.Element => {
  return (
    <StyledMobileItemWrapper>
      <StyledMobileSection>
        <StyledMobileDescription>Номер телефона</StyledMobileDescription>
        <StyledMobileValue>{client?.phone}</StyledMobileValue>
      </StyledMobileSection>
      <StyledMobileSection>
        <StyledMobileDescription>Паспорт</StyledMobileDescription>
        <StyledMobileValue>{`${client?.serial} ${client?.number}`}</StyledMobileValue>
      </StyledMobileSection>
      <StyledMobileSection>
        <StyledMobileDescription>ФИО</StyledMobileDescription>
        <StyledMobileLoanNumbers>{`${client?.last_name} ${client?.first_name} ${client?.middle_name}`}</StyledMobileLoanNumbers>
      </StyledMobileSection>
      <StyledActionProvider>
        <StyledActionChange onClick={() => handleChangeAgentModal(client?.phone)}>
          <HiOutlineRefresh />
        </StyledActionChange>
        <StyledActionDelete onClick={() => handleDeleteModal(client?.phone)}>
          <HiOutlineTrash />
        </StyledActionDelete>
      </StyledActionProvider>
    </StyledMobileItemWrapper>
  )
}
