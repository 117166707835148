/* eslint-disable no-prototype-builtins */
/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState, Fragment, useContext } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { isEmpty } from 'lodash'
import { Formik, FieldArray } from 'formik'

import {
  createAdditionalContacts,
  deleteAdditionalContacts,
  fetchAdditionalContacts,
  updateAdditionalContacts,
} from 'features/loan/extra'
import { addToast } from 'features/toast/toastSlice'
import { GlobalState } from 'index'
import { IModuleProps } from './types'

import { ToastTypes } from 'lib/toast/Toast'
import { Button, ModalConfirm, Module, Select } from 'components'
import {
  InputField,
  normalizeValues,
  validationAdditionalContactsArray,
  validationAdditionalContact,
  phoneMask,
  familiarTypes,
  AccessWrapper,
} from 'helpers'

import { ReactComponent as EditButtonSVG } from 'assets/edit-button.svg'
import { ReactComponent as CloseSVG } from 'assets/close.svg'

import {
  StyledTextArea,
  StyledSelectBlock,
  StyledResetFilter,
  StyledHeadTitle,
  StyledEditButton,
} from './Modules.styles'
import { rolesType } from 'constants/rtoles'
import { PermissionContext } from 'app/contexts'

const moduleId = 'additionalContacts'
interface IAdditionalContactsProps extends IModuleProps {
  loan_id: string
}

export const AdditionalContacts: React.FC<IAdditionalContactsProps> = ({
  openedModules,
  onToggleModule,
  claimsResolve,
  userId,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { data, isFetching, isUpdating } = useSelector(
    (state: GlobalState) => state.singleLoan.additionalContacts
  )

  const isOpen = openedModules.includes(moduleId)
  const [editable, setEditable] = useState<string>(null)
  const [addNewContact, setAddNewContact] = useState<boolean>(false)
  const [additionalContactsData, setAdditionalContactsData] = useState({
    contacts: [],
  })

  const handleExpand = (): void => {
    onToggleModule(moduleId)
  }

  useEffect(() => {
    if (data && data.length > 0) setAdditionalContactsData({ contacts: data })
  }, [data])

  const submitForm = async (values) => {
    const { contacts } = values
    const editedItem = contacts.filter((x) => x.id === editable)
    const unmaskedPhone = `+${editedItem[0].number_phone.replace(/[^\d]/g, '')}`

    const body = {
      number_phone: unmaskedPhone,
      first_name: editedItem[0].first_name,
      last_name: editedItem[0].last_name,
      middle_name: editedItem[0].middle_name,
      familiar_type: editedItem[0].familiar_type,
    }

    if (editedItem[0]?.comment) Object.assign(body, { comment: editedItem[0]?.comment })

    dispatch(
      updateAdditionalContacts({
        id: editedItem[0].id,
        body,
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Данные не сохранены`,
            })
          )
        },
        onSuccess: () => {
          batch(() => {
            dispatch(fetchAdditionalContacts({ id: userId }))
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Контакт был успешно обновлен`,
              })
            )
          })
          setEditable(null)
        },
      })
    )
  }

  const setNewContact = (): void => {
    const newContactData = {
      id: null,
      number_phone: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      familiar_type: '',
      comment: null,
    }
    setAddNewContact(true)
  }

  return (
    <Formik
      initialValues={additionalContactsData}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationAdditionalContactsArray}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        const contactsProps = {
          claimsResolve,
          editable,
          setEditable,
          isUpdating,
          userId,
          setAddNewContact,
          ...formikProps,
        }

        const newContactProps = { isUpdating, userId, setAddNewContact }

        return (
          <Module.Wrapper
            isOpen={isOpen}
            editable={editable}
            isEdited={dirty && editable}
            id={moduleId}
          >
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <StyledHeadTitle>
                  Дополнительные контакты
                  {additionalContactsData.contacts.length === 0 ? (
                    <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
                  ) : null}{' '}
                </StyledHeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              {isFetching && <Module.LoaderBlock />}
              <Contacts {...contactsProps} />
              {addNewContact && <NewContact {...newContactProps} />}
              {!addNewContact && (
                <div style={{ marginLeft: '20px' }}>
                  <Button
                    disabled={false}
                    type='bigSuccess'
                    pending={false}
                    onClick={() => {
                      setNewContact()
                      setEditable(null)
                      resetForm()
                    }}
                  >
                    Добавить новый контакт
                  </Button>
                </div>
              )}
            </Module.ContentWrapper>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Contacts = (props) => {
  const {
    values,
    userId,
    setFieldValue,
    handleSubmit,
    claimsResolve,
    errors,
    dirty,
    resetForm,
    editable,
    setEditable,
    isUpdating,
    setAddNewContact,
  } = props
  const dispatch = useDispatch()
  const { permissions } = useContext(PermissionContext)
  const [showModal, setShowModal] = useState<any>({
    show: false,
    title: '',
    description: '',
    action: null,
    style: '',
  })

  const handleCloseMode = (): void =>
    setShowModal({ show: false, title: '', description: '', action: null, style: '' })

  const handleDeleteContact = (cardId: string, fio: string): void => {
    const action = (): void => {
      dispatch(
        deleteAdditionalContacts({
          familiarId: editable,

          onError: () => {
            dispatch(
              addToast({
                type: ToastTypes.warning,
                title: 'Ошибка',
                description: `Контакт не был удален`,
              })
            )
          },
          onSuccess: () => {
            batch(() => {
              dispatch(fetchAdditionalContacts({ id: userId }))
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Контакт был успешно удален`,
                })
              )
            })
            setEditable(null)
          },
        })
      )
    }
    setShowModal({
      show: true,
      title: `Удаление контакта`,
      description: `Удалить контакт ${fio} ?`,
      action,
      style: 'warning',
    })
  }

  const familiarTypesList = Object.keys(familiarTypes).map((value) => ({
    label: familiarTypes[value],
    value,
  }))

  return (
    <>
      <FieldArray
        name='contacts'
        render={
          (/* arrayHelpers */) => (
            <>
              {values.contacts.map((val, index) => (
                <Module.Content key={index}>
                  <>
                    <ModalConfirm
                      isOpen={showModal.show}
                      onRequestClose={handleCloseMode}
                      onClose={handleCloseMode}
                      title={showModal.title}
                      action={showModal.action}
                      description={showModal.description}
                      style={showModal.style}
                    />

                    {editable === val.id ? (
                      <>
                        <Module.Column column={1}>
                          <InputField
                            name={`contacts[${index}].last_name`}
                            onChange={setFieldValue}
                            placeholder='Фамилия'
                            value={val.last_name}
                            noMove
                            {...props}
                          />
                        </Module.Column>
                        <Module.Column column={1}>
                          <InputField
                            name={`contacts[${index}].first_name`}
                            onChange={setFieldValue}
                            placeholder='Имя'
                            value={val.first_name}
                            noMove
                            {...props}
                          />
                        </Module.Column>
                        <Module.Column column={1}>
                          <InputField
                            name={`contacts[${index}].middle_name`}
                            onChange={setFieldValue}
                            placeholder='Отчество'
                            value={val.middle_name}
                            noMove
                            {...props}
                          />
                        </Module.Column>
                        <Module.Column column={1} style={{ position: 'relative' }}>
                          {editable && (
                            <StyledEditButton editing>
                              <EditButtonSVG
                                onClick={() => {
                                  resetForm()
                                  setEditable(null)
                                }}
                              />
                            </StyledEditButton>
                          )}
                        </Module.Column>
                        <Module.Column column={1}>
                          <Module.Value>
                            <StyledSelectBlock>
                              <Select
                                data={familiarTypesList}
                                placeholder='Кем приходится'
                                value={
                                  familiarTypesList?.find((v) => v.value === val.familiar_type) ||
                                  ''
                                }
                                onChange={(v) =>
                                  setFieldValue(`contacts[${index}].familiar_type`, v.value)
                                }
                                error={!!errors?.contacts?.[index]?.hasOwnProperty('familiar_type')}
                              />
                              {val.familiar_type && (
                                <StyledResetFilter
                                  onClick={() =>
                                    setFieldValue(`contacts[${index}].familiar_type`, '')
                                  }
                                >
                                  <CloseSVG />
                                </StyledResetFilter>
                              )}
                            </StyledSelectBlock>
                          </Module.Value>
                        </Module.Column>
                        <Module.Column column={3}>{}</Module.Column>
                        <Module.Column column={1}>
                          <InputField
                            name={`contacts[${index}].number_phone`}
                            onChange={setFieldValue}
                            placeholder='Телефон'
                            value={val.number_phone}
                            mask={phoneMask}
                            noMove
                            {...props}
                          />
                        </Module.Column>
                        {val.comment !== null ? (
                          <Module.Column column={4}>
                            <p style={{ marginBottom: '30px' }}>Комментарий</p>
                            <StyledTextArea
                              value={val.comment}
                              placeholder='Комментарий'
                              onChange={(e) => {
                                setFieldValue(`contacts[${index}].comment`, e?.target?.value)
                              }}
                              style={{ width: 'calc(100% - 80px)' }}
                            />
                          </Module.Column>
                        ) : (
                          <Module.Column column={4}>
                            <p style={{ marginBottom: '30px' }}>
                              Комментарий {val.comment === null && 'отсутвует'}
                            </p>
                            <Button
                              disabled={false}
                              type='bigSuccess'
                              pending={false}
                              onClick={() => setFieldValue(`contacts[${index}].comment`, ' ')}
                            >
                              Добавить комментарий
                            </Button>
                          </Module.Column>
                        )}
                      </>
                    ) : (
                      <>
                        <Module.Column column={3}>
                          <Module.Title>ФИО</Module.Title>
                          <Module.Value>{`${val.last_name} ${val.first_name} ${val.middle_name}`}</Module.Value>
                        </Module.Column>
                        <Module.Column column={1} style={{ position: 'relative' }}>
                          <StyledEditButton>
                            <EditButtonSVG
                              onClick={() => {
                                resetForm()
                                setEditable(val.id)
                                setAddNewContact(false)
                              }}
                            />
                          </StyledEditButton>
                        </Module.Column>
                        <Module.Column column={4}>
                          <Module.Title>Кем приходится</Module.Title>
                          <Module.Value>
                            {normalizeValues(val.familiar_type, 'familiar_types')}
                          </Module.Value>
                        </Module.Column>
                        <Module.Column column={4}>
                          <Module.Title>Телефон </Module.Title>
                          <Module.Value styles='styledValuePhone'>
                            {normalizeValues(val.number_phone)}
                          </Module.Value>
                        </Module.Column>
                        <Module.Column column={4}>
                          <Module.Title>Комментарий </Module.Title>
                          <Module.Value>
                            {val.comment ? <span>{val.comment}</span> : 'отсутвует'}
                          </Module.Value>
                        </Module.Column>
                      </>
                    )}

                    <AccessWrapper
                      access={[rolesType.agent, rolesType.regionalManager]}
                      condition={permissions.includes(rolesType.regionalManager) || claimsResolve}
                    >
                      <div style={{ gridColumn: 'span 4' }}>
                        <div style={{ display: 'flex' }}>
                          {editable && editable === val.id && (
                            <Button
                              pending={isUpdating}
                              onClick={() =>
                                handleDeleteContact(
                                  'null',
                                  `${val?.last_name} ${val?.first_name} ${val?.middle_name}`
                                )
                              }
                              type='bigDanger'
                            >
                              Удалить контакт
                            </Button>
                          )}
                          {editable === val.id && (
                            <Button
                              pending={isUpdating}
                              style={{ marginLeft: '20px' }}
                              onClick={
                                editable
                                  ? () => {
                                      setEditable(null)
                                      resetForm()
                                    }
                                  : () => {}
                              }
                              type='standardBig'
                            >
                              Отмена
                            </Button>
                          )}
                          {editable === val.id && (
                            <Button
                              disabled={!dirty || !isEmpty(errors)}
                              style={{ marginLeft: '20px' }}
                              pending={isUpdating}
                              onClick={() => (dirty && isEmpty(errors) ? handleSubmit() : null)}
                              type='bigSuccess'
                            >
                              Сохранить
                            </Button>
                          )}
                        </div>
                      </div>
                    </AccessWrapper>
                    {/* {claimsResolve && (
                 
                    )} */}
                    <Module.Column column={4}>
                      <hr style={{ opacity: '0.2' }} />
                    </Module.Column>
                  </>
                </Module.Content>
              ))}
            </>
          )
        }
      />
    </>
  )
}

const NewContact = (props) => {
  const { setAddNewContact, userId, isUpdating } = props
  const dispatch = useDispatch()

  const [newContactsData, setNewContactsData] = useState({
    id: null,
    number_phone: null,
    first_name: null,
    last_name: null,
    middle_name: null,
    familiar_type: null,
    comment: null,
  })

  const familiarTypesList = Object.keys(familiarTypes).map((value) => ({
    label: familiarTypes[value],
    value,
  }))

  const submitForm = async (values) => {
    const unmaskedPhone = `+${values.number_phone.replace(/[^\d]/g, '')}`

    const body = {
      number_phone: unmaskedPhone,
      first_name: values.first_name,
      last_name: values.last_name,
      middle_name: values.middle_name,
      familiar_type: values.familiar_type,
    }

    if (values?.comment) Object.assign(body, { comment: values?.comment })

    dispatch(
      createAdditionalContacts({
        userId,
        body,
        onError: () => {
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Контакт не сохранен`,
            })
          )
        },
        onSuccess: () => {
          batch(() => {
            dispatch(fetchAdditionalContacts({ id: userId }))
            dispatch(
              addToast({
                type: ToastTypes.success,
                title: 'Успех',
                description: `Контакт был успешно добавлен`,
              })
            )
          })
          setAddNewContact(false)
        },
      })
    )
  }
  return (
    <Formik
      initialValues={newContactsData}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationAdditionalContact}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit, setFieldValue, values } = formikProps

        return (
          <Module.Content>
            <div style={{ gridColumn: 'span 4', marginTop: '20px', fontWeight: 'bold' }}>
              <p>Добавление нового контакта</p>
            </div>
            <Module.Column column={1}>
              <InputField
                name='last_name'
                onChange={setFieldValue}
                placeholder='Фамилия'
                value={values.last_name}
                noMove
                {...formikProps}
              />
            </Module.Column>
            <Module.Column column={1}>
              <InputField
                name='first_name'
                onChange={setFieldValue}
                placeholder='Имя'
                value={values.first_name}
                noMove
                {...formikProps}
              />
            </Module.Column>
            <Module.Column column={1}>
              <InputField
                name='middle_name'
                onChange={setFieldValue}
                placeholder='Отчество'
                value={values.middle_name}
                noMove
                {...formikProps}
              />
            </Module.Column>
            <Module.Column column={1}>
              <Module.Value>
                <StyledSelectBlock>
                  <Select
                    data={familiarTypesList}
                    placeholder='Кем приходится'
                    value={familiarTypesList?.find((v) => v.value === values.familiar_type) || ''}
                    onChange={(v) => setFieldValue('familiar_type', v.value)}
                    error={!!errors?.familiar_type}
                  />
                  {values.familiar_type && (
                    <StyledResetFilter onClick={() => setFieldValue('familiar_type', '')}>
                      <CloseSVG />
                    </StyledResetFilter>
                  )}
                </StyledSelectBlock>
              </Module.Value>
            </Module.Column>
            <Module.Column column={1}>
              <InputField
                name='number_phone'
                onChange={setFieldValue}
                placeholder='Телефон'
                value={values.number_phone}
                mask={phoneMask}
                noMove
                {...formikProps}
              />
            </Module.Column>
            {values.comment !== null ? (
              <Module.Column column={4}>
                <p style={{ marginBottom: '30px' }}>Комментарий</p>
                <StyledTextArea
                  value={values.comment}
                  placeholder='Комментарий'
                  onChange={(e) => {
                    setFieldValue('comment', e?.target?.value)
                  }}
                  style={{ width: 'calc(100% - 80px)' }}
                />
              </Module.Column>
            ) : (
              <Module.Column column={4}>
                <p style={{ marginBottom: '30px' }}>
                  Комментарий {values.comment === null && 'отсутвует'}
                </p>
                <Button
                  disabled={false}
                  type='bigSuccess'
                  onClick={() => setFieldValue('comment', ' ')}
                >
                  Добавить комментарий
                </Button>
              </Module.Column>
            )}
            <div style={{ gridColumn: 'span 4' }}>
              <div style={{ display: 'flex' }}>
                <Button
                  pending={isUpdating}
                  style={{ marginLeft: '20px' }}
                  onClick={() => {
                    resetForm()
                    setAddNewContact(false)
                  }}
                  type='standardBig'
                >
                  Отмена
                </Button>
                <Button
                  pending={isUpdating}
                  disabled={!dirty || !isEmpty(errors)}
                  style={{ marginLeft: '20px' }}
                  onClick={() => (dirty && isEmpty(errors) ? handleSubmit() : null)}
                  type='bigSuccess'
                >
                  Сохранить
                </Button>
              </div>
            </div>
          </Module.Content>
        )
      }}
    </Formik>
  )
}
