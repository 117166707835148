/* eslint-disable indent */
import { THEME } from 'core/theme'
import styled, { css } from 'styled-components/macro'

export const StyledImageWrapper = styled.div<any>`
  width: fit-content;
  margin-right: 10px;
  position: relative;
  input {
    display: none;
  }
`

export const StyledPlus = styled.div<{ title: any }>`
  display: flex;
  position: relative;
  align-items: center;
  > div {
    width: 80px;
    height: 80px;
    border: 1px solid #dfe0eb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #dfe0eb;

    ${({ title }) =>
      title &&
      css`
        border: none;
      `};
  }
`

export const StyledUploadImageLoaderWrapper = styled.div`
  position: absolute;
  top: calc(50% - 25px);
`

export const StyledImage = styled.label<any>`
  width: 190px;
  height: 145px;
  border: 1px solid ${THEME.COLORS.BORDER_LOAN};
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  & img {
    object-fit: contain;
  }
  @media (max-width: 480px) {
    width: 250px;
  }
`

export const StyledTitle = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #73717e;
  top: 90px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
`
