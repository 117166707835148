/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components'
import { CreditProductCodes, CreditProductTranslate } from 'constants/creditProduct'
import { AgentLoanType } from 'features/agentLoans/types'
import { loanStatuses } from 'helpers'
import { v4 as uuid } from 'uuid'

export const BODY_STRUCTURE: any = {
  [AgentLoanType.allApp]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true, value: () => '' },
    { id: uuid(), name: 'residence_address' },
    { id: uuid(), name: 'users_login', copy: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'loans_loan_ask_sum' },
    // { id: uuid(), name: 'loans_loan_permitted_sum' },
    // { id: uuid(), name: '' },
    // { message: true, id: uuid(), name: 'message' },
  ],

  [AgentLoanType.requiredDocs]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true, value: () => '' },
    { id: uuid(), name: 'users_login', copy: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'loans_loan_ask_sum' },
    { id: uuid(), value: () => null, transfer: true },
  ],
  [AgentLoanType.checking]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true, value: () => '' },
    { id: uuid(), name: 'users_login', copy: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'loans_loan_ask_sum' },
    // { message: true, id: uuid(), name: 'message' },
  ],
  [AgentLoanType.atWork]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true, value: () => '' },
    { id: uuid(), name: 'users_login', copy: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'loans_loan_ask_sum' },
    // { message: true, id: uuid(), name: 'message' },
  ],
  [AgentLoanType.checked]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true, value: () => '' },
    { id: uuid(), name: 'users_login', copy: true },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'loans_loan_ask_sum' },
    // { id: uuid(), name: 'loans_loan_permitted_sum' },
    {
      value: (data) => data.admin_username,
      id: uuid(),
      name: 'admin_username',
    },
  ],
  [AgentLoanType.pre_approved]: [
    { id: uuid(), name: 'loans_loan_number', createDate: true, copy: true },
    { id: uuid(), name: 'status', status: true, value: () => '' },
    { id: uuid(), name: 'users_login' },
    {
      value: (data) =>
        `${data?.passport_last_name} ${data?.passport_first_name} ${data?.passport_middle_name}`,
      id: uuid(),
      name: 'fio',
    },
    {
      id: uuid(),
      name: 'loans_product_id',
      product: true,
      value: (data) => `${CreditProductTranslate[CreditProductCodes[data?.loans_product_id]]}`,
    },
    { id: uuid(), name: 'agent_manager_username' },
    { id: uuid(), name: 'agent_username' },
    { id: uuid(), name: 'loans_loan_ask_sum' },
  ],
}

export const HEAD_STRUCTURE = {
  [AgentLoanType.allApp]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'Регион', id: uuid() },
    { title: 'Номер телефона', id: uuid() },
    { title: 'ФИО клиента', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
    // { title: 'Одобренная сумма', id: uuid() },
    // { title: 'Размер нед. платежа', id: uuid() },
    // { title: 'Комментарий', id: uuid() },
  ],
  [AgentLoanType.requiredDocs]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'Номер телефона', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
    { title: 'Действия', id: uuid() },
    // { title: 'Размер платежа', id: uuid() },
  ],
  [AgentLoanType.checking]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'Номер телефона', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
    // { title: 'Комментарий', id: uuid() },
  ],
  [AgentLoanType.atWork]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'Номер телефона', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
    // { title: 'Комментарий', id: uuid() },
  ],
  [AgentLoanType.checked]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'Номер телефона', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
    // { title: 'Одобренная сумма', id: uuid() },
    // { title: 'Размер платежа', id: uuid() },
    { title: 'Андеррайтер', id: uuid() },
  ],
  [AgentLoanType.pre_approved]: [
    { title: 'Номер заявки/Дата создания', id: uuid() },
    { title: 'Статус', id: uuid() },
    { title: 'Номер телефона', id: uuid() },
    { title: 'ФИО', id: uuid() },
    { title: 'Тип продукта', id: uuid() },
    { title: 'Руководитель', id: uuid() },
    { title: 'Агент', id: uuid() },
    { title: 'Сумма займа', id: uuid() },
  ],
}

export const MIN_WIDTH = '1500px'

export const COLUMN_TEMPLATE = {
  [AgentLoanType.allApp]: `
    minmax(150px, 150px) minmax(200px, 250px) minmax(200px, 250px) minmax(150px, 200px)
    minmax(350px, 450px) minmax(150px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(250px, 300px)
    `,
  [AgentLoanType.requiredDocs]: `
    minmax(150px, 150px) minmax(200px, 250px) minmax(150px, 300px) minmax(350px, 450px) minmax(150px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 300px) minmax(100px, 300px) 
    `,
  [AgentLoanType.checking]: `
  minmax(150px, 150px) minmax(200px, 250px) minmax(150px, 300px) minmax(350px, 450px) minmax(150px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 200px) 
    `,
  [AgentLoanType.atWork]: `
  minmax(150px, 150px) minmax(200px, 250px) minmax(150px, 300px) minmax(350px, 450px) minmax(150px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 200px) 
    `,
  [AgentLoanType.checked]: `
     minmax(150px, 150px) minmax(200px, 250px) minmax(150px, 200px) minmax(350px, 450px) minmax(150px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 200px)
     minmax(250px, 200px) minmax(250px, 200px)
    `,
  [AgentLoanType.pre_approved]: `
     minmax(150px, 150px) minmax(200px, 250px) minmax(150px, 200px) minmax(350px, 450px) minmax(150px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 200px)
  
    `,
}
