import styled, { css } from 'styled-components/macro'

export const StyledDadataWrapper = styled.div`
  width: 100%;
  position: relative;
  input {
    padding-right: 0;
    &::-webkit-calendar-picker-indicator {
      color: transparent;
    }
  }
  datalist {
    position: absolute;
    max-height: 20em;
    border: 0 none;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 111;
  }

  datalist option {
    font-size: 0.8em;
    padding: 0.3em 1em;
    background-color: #ccc;
    cursor: pointer;
  }

  /* option active styles */
  datalist option:hover,
  datalist option:focus {
    color: #fff;
    background-color: #036;
    outline: 0 none;
  }
`

// =========

export const StyledDadataContainer = styled.div`
  position: relative;
`
export const StyledDadataInput = styled.input`
  display: block;
  box-sizing: border-box;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  outline: none;
`
export const StyledDadataSuggestions = styled.ul`
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
  top: 100%;
  border-radius: 4px;
  overflow: hidden;
  left: 0;
  right: 0;
  text-align: left;
`

export const StyledButton = styled.button<any>`
  width: 100%;
  background-color: #fff;
  padding: 10px 10px 5px 10px;
  border: 0;
  cursor: pointer;
  border-bottom: 1px solid #cfced8;
  text-align: left;
  p {
    font-size: 14px;
  }
  :hover {
    background-color: rgba(0, 124, 214, 0.1);
  }

  ${({ current }) =>
    current &&
    css`
      background-color: rgba(0, 124, 214, 0.15);
    `};
`

export const StyledDadataSuggestionNote = styled.div`
  font-size: 14px;
  color: #828282;
  padding: 10px 10px 5px 10px;
`
