/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect, useContext, Fragment } from 'react'
import { GlobalState } from 'index'
import { groupBy, isEqual, sortBy, orderBy } from 'lodash'
import moment from 'moment'
import { useDispatch, batch, useSelector } from 'react-redux'
import { FaRegFileAlt, FaRegTrashAlt, FaPen, FaPlus, FaRegSave, FaTimes } from 'react-icons/fa'
import { NavigationContext } from 'app/contexts'

import {
  createDocument,
  deleteDocumentsList,
  fetchDocumentsList,
  updateDocument,
} from 'features/settings/extra'
import { documentsGroup, documentSubGroup } from 'helpers'
import { NavMenuType } from 'App'
import { Button, DatePicker, Loader, ModalConfirm, Select } from 'components'
import { ReactComponent as CloseSVG } from 'assets/close.svg'

import {
  StyledLoaderPage,
  StyledWrapper,
  StyledTaskButtonBlock,
  StyledHead,
  StyledBody,
  StyledGroup,
  StyledGroupTitle,
  StyledSubGroupTitle,
  StyledSubGroup,
  StyledSubGroupItem,
  StyledSubGroupHead,
  StyledIcon,
  StyledTextArea,
  StyledButtons,
  StyledSelectBlock,
  StyledContentBlock,
  StyledContentWrapper,
  StyledHeadDocument,
  StyledContentGrid,
  StyledContentGridItem,
  StyledPlaceHolder,
  StyledResetFilter,
  StyledTitleDate,
} from './Settings.styles'

import { ToastTypes } from 'lib/toast/Toast'
import { addToast } from 'features/toast/toastSlice'

interface IModal {
  show: boolean
  title: string
  action?: any
  style: string
  description: string
}

const formatDate = (date, format) => (date ? moment(new Date(date)).format(format) : date)

export const Settings: React.FC = (): JSX.Element => {
  const { data, isFetching } = useSelector((store: GlobalState) => store.settings)
  const dispatch = useDispatch()
  const [documents, setDocuments] = useState({})
  const { setNavigation, setNavMenuType } = useContext(NavigationContext)
  const [initialDocumentContent, setInitialDocumentContent] = useState({})
  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    title: '',
    action: null,
    description: '',
    style: '',
  })
  const emptyDocumentContentState = {
    show: false,
    title: '',
    group: '',
    subGroup: '',
    url: '',
    date: '',
    id: null,
    isHighlighted: '',
    head: '',
  }
  const [documentContent, setDocumentContent] = useState(emptyDocumentContentState)

  useEffect(() => {
    dispatch(fetchDocumentsList())
    setNavMenuType(NavMenuType.settings)
  }, [])

  useEffect(() => {
    if (data.length) {
      const normalizeData = groupBy(
        data.map((obj) => {
          if (!obj?.sub_type) {
            return {
              ...obj,
              sub_type: 'aCommon',
            }
          }
          return obj
        }),
        'type'
      )

      let convertData = {}
      Object.keys(normalizeData).forEach((key) => {
        convertData = {
          ...convertData,
          [key]: groupBy(normalizeData[key], 'sub_type'),
        }
      })
      setDocuments(convertData)
    }
  }, [data])

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Документы сайт`,
      backTo: null,
    })
  }, [])

  const handleAddDocument = (): void => {
    const state = {
      show: true,
      title: '',
      group: '',
      subGroup: '',
      url: '',
      date: '',
      id: null,
      isHighlighted: '',
      head: 'Добавить новый документ',
    }

    setDocumentContent(state)
    setInitialDocumentContent(state)
  }

  const handleCloseDocument = (): void => handleReset()

  const handleReset = (): void => {
    setDocumentContent(emptyDocumentContentState)
    setInitialDocumentContent({})
  }

  const handleEditDocument = (doc) => {
    const state = {
      show: true,
      title: doc.title,
      group: doc.type,
      url: doc.url,
      subGroup: doc?.sub_type === 'aCommon' ? '' : doc?.sub_type,
      isHighlighted: doc.is_highlighted ? 'bold' : 'standard',
      date: new Date(formatDate(doc.date, 'YYYY-MM-DD')),
      head: `Редактирование документа :   ${doc.title} `,
      id: doc.id,
    }

    setDocumentContent(state)
    setInitialDocumentContent(state)
  }

  const suggestionListGroups = Object.keys(documentsGroup).map((value) => ({
    label: documentsGroup[value],
    value,
  }))

  const suggestionListSubGroups = Object.keys(documentSubGroup)
    .filter((g) => g !== 'aCommon')
    .map((value) => ({
      label: documentSubGroup[value],
      value,
    }))

  const fontStyle = {
    standard: 'Обычный',
    bold: 'Толстый',
  }

  const suggestionFontStyle = Object.keys(fontStyle).map((value) => ({
    label: fontStyle[value],
    value,
  }))

  const allowHandler = (): boolean => {
    return (
      !isEqual(initialDocumentContent, documentContent) &&
      !!documentContent.title &&
      !!documentContent.group &&
      !!documentContent.url &&
      !!documentContent.date
    )
  }

  const handleCloseModal = (): void =>
    setShowModal({ show: false, title: '', action: null, style: '', description: '' })

  const handleSubmit = (): void => {
    const data = {
      title: documentContent.title,
      date: `${formatDate(documentContent.date, 'YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`,
      url: documentContent.url,
      is_highlighted: documentContent.isHighlighted === 'bold',
      type: documentContent.group,
    }
    if (documentContent.subGroup) Object.assign(data, { sub_type: documentContent.subGroup })
    if (documentContent.id) Object.assign(data, { id: documentContent.id })

    if (documentContent.id)
      dispatch(
        updateDocument({
          data,
          documentId: documentContent.id,
          successAction: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Документ успешно сохранен`,
                })
              )
              dispatch(fetchDocumentsList())
            })
            handleReset()
          },
          errorAction: () => {
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: `Во время выполнения запроса произошла ошибка, документ не сохранен`,
              })
            )
          },
        })
      )
    else
      dispatch(
        createDocument({
          data,
          successAction: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Документ успешно сохранен`,
                })
              )
              handleReset()
              dispatch(fetchDocumentsList())
            })
          },
          errorAction: () => {
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: `Во время выполнения запроса произошла ошибка, документ не сохранен`,
              })
            )
          },
        })
      )
  }

  const handleDeleteDocument = (title: string, id: string): void => {
    const action = (): void => {
      dispatch(
        deleteDocumentsList({
          documentId: id,
          successAction: () => {
            batch(() => {
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: `Документ успешно удален`,
                })
              )
              dispatch(fetchDocumentsList())
            })
            handleReset()
          },
          errorAction: () => {
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: `Во время выполнения запроса произошла ошибка, документ не удален`,
              })
            )
          },
        })
      )
    }
    setShowModal({
      show: true,
      title: 'Удалить документ ?',
      action,
      style: 'success',
      description: title,
    })
  }

  return (
    <>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        description={showModal.description}
        onClose={handleCloseModal}
        title={showModal.title}
        action={showModal.action}
        style={showModal.style}
      />
      {isFetching ? (
        <StyledLoaderPage>
          <Loader />
        </StyledLoaderPage>
      ) : (
        <>
          <StyledWrapper>
            <StyledHead>
              <StyledTaskButtonBlock>
                <Button
                  disabled={false}
                  type='smallSuccess'
                  pending={false}
                  onClick={handleAddDocument}
                >
                  <FaPlus />
                  Добавить документ
                </Button>
              </StyledTaskButtonBlock>

              <StyledContentBlock showFilters={documentContent.show}>
                <StyledContentWrapper>
                  <StyledHeadDocument>{documentContent.head}</StyledHeadDocument>
                  <StyledContentGrid>
                    <StyledContentGridItem>
                      <StyledPlaceHolder>Название</StyledPlaceHolder>
                      <StyledTextArea
                        value={documentContent.title || ''}
                        maxLength={999}
                        placeholder='Название'
                        onChange={(e) =>
                          setDocumentContent({
                            ...documentContent,
                            title: e?.target?.value,
                          })
                        }
                      />

                      <StyledPlaceHolder>Группа</StyledPlaceHolder>
                      <StyledSelectBlock>
                        <Select
                          data={suggestionListGroups}
                          placeholder='Группа'
                          value={
                            suggestionListGroups?.find((v) => v.value === documentContent.group) ||
                            ''
                          }
                          onChange={(v) =>
                            setDocumentContent({
                              ...documentContent,
                              group: v.value,
                            })
                          }
                          error={false}
                        />
                        {documentContent.group && (
                          <StyledResetFilter
                            onClick={() =>
                              setDocumentContent({
                                ...documentContent,
                                group: '',
                              })
                            }
                          >
                            <CloseSVG />
                          </StyledResetFilter>
                        )}
                      </StyledSelectBlock>
                    </StyledContentGridItem>
                    <StyledContentGridItem>
                      <StyledPlaceHolder>URL</StyledPlaceHolder>
                      <StyledTextArea
                        value={documentContent.url || ''}
                        maxLength={999}
                        placeholder='URL'
                        onChange={(e) =>
                          setDocumentContent({
                            ...documentContent,
                            url: e?.target?.value,
                          })
                        }
                      />
                      <StyledPlaceHolder>Подгруппа</StyledPlaceHolder>
                      <StyledSelectBlock>
                        <Select
                          data={suggestionListSubGroups}
                          placeholder='Подгруппа'
                          value={
                            suggestionListSubGroups?.find(
                              (v) => v.value === documentContent.subGroup
                            ) || ''
                          }
                          onChange={(v) =>
                            setDocumentContent({
                              ...documentContent,
                              subGroup: v.value,
                            })
                          }
                          error={false}
                        />
                        {documentContent.subGroup && (
                          <StyledResetFilter
                            onClick={() =>
                              setDocumentContent({
                                ...documentContent,
                                subGroup: '',
                              })
                            }
                          >
                            <CloseSVG />
                          </StyledResetFilter>
                        )}
                      </StyledSelectBlock>
                    </StyledContentGridItem>
                    <StyledContentGridItem>
                      <StyledPlaceHolder>Стиль текста</StyledPlaceHolder>
                      <StyledSelectBlock>
                        <Select
                          data={suggestionFontStyle}
                          placeholder='Стиль текста'
                          value={
                            suggestionFontStyle?.find(
                              (v) => v.value === documentContent.isHighlighted
                            ) || ''
                          }
                          onChange={(v) =>
                            setDocumentContent({
                              ...documentContent,
                              isHighlighted: v.value,
                            })
                          }
                          error={false}
                        />
                      </StyledSelectBlock>
                      <div style={{ marginTop: '60px' }} />
                      <StyledPlaceHolder style={{ marginTop: '60px!important' }}>
                        Дата
                      </StyledPlaceHolder>
                      <DatePicker
                        value={documentContent.date}
                        format='dd.MM.yyyy'
                        selected={documentContent.date}
                        onChange={(v) =>
                          setDocumentContent({
                            ...documentContent,
                            date: v,
                          })
                        }
                        error={false}
                        errorText={null}
                      />
                    </StyledContentGridItem>
                  </StyledContentGrid>

                  <StyledButtons>
                    <Button
                      type='smallSuccess'
                      onClick={allowHandler() ? handleSubmit : null}
                      disabled={!allowHandler()}
                      style={{ marginRight: '20px' }}
                    >
                      <FaRegSave />
                      Сохранить документ
                    </Button>
                    <Button type='emptySmall' disabled={false} onClick={handleCloseDocument}>
                      Отмена
                    </Button>
                  </StyledButtons>
                </StyledContentWrapper>
              </StyledContentBlock>
            </StyledHead>
            <StyledBody>
              <StyledGroup>
                {Object.keys(documentsGroup).map((key) => {
                  return (
                    <Fragment key={documentsGroup[key]}>
                      <StyledGroupTitle>{documentsGroup[key]}</StyledGroupTitle>
                      <StyledSubGroup>
                        {documents[key] &&
                          sortBy(Object.keys(documents[key])).map((subKey, i) => {
                            return (
                              <StyledSubGroup key={i}>
                                <StyledSubGroupHead>
                                  {documentSubGroup[subKey] && 'подгуппа : '}
                                  {documentSubGroup[subKey]}
                                </StyledSubGroupHead>

                                {sortBy(documents[key][subKey], 'date').map((v, subIndex) => {
                                  const edited = v.id === documentContent.id
                                  return (
                                    <StyledSubGroupItem key={subIndex}>
                                      <FaRegFileAlt style={{ marginRight: '5px' }} />
                                      <StyledSubGroupTitle
                                        isHighlighted={v.is_highlighted}
                                        isEdited={edited}
                                      >
                                        <a
                                          href={v.url}
                                          key={v.title}
                                          target='_blank'
                                          rel='noreferrer'
                                        >
                                          {v.title}{' '}
                                        </a>
                                      </StyledSubGroupTitle>
                                      <StyledTitleDate>
                                        <span style={{ fontSize: '14px' }}>
                                          {formatDate(v.date, 'YYYY-MM-DD')}
                                        </span>
                                      </StyledTitleDate>
                                      {edited ? (
                                        <StyledIcon edited={edited} onClick={handleReset}>
                                          <FaTimes />
                                        </StyledIcon>
                                      ) : (
                                        <StyledIcon onClick={() => handleEditDocument(v)}>
                                          <FaPen style={{ marginRight: '15px' }} />
                                        </StyledIcon>
                                      )}
                                      <StyledIcon>
                                        <FaRegTrashAlt
                                          onClick={() => handleDeleteDocument(v.title, v.id)}
                                        />
                                      </StyledIcon>
                                    </StyledSubGroupItem>
                                  )
                                })}
                              </StyledSubGroup>
                            )
                          })}
                      </StyledSubGroup>
                    </Fragment>
                  )
                })}
              </StyledGroup>
            </StyledBody>
          </StyledWrapper>
        </>
      )}
    </>
  )
}
