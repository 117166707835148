import styled, { css } from 'styled-components/macro'

import { StyledRowWrapper, StyledTitle } from './Table/Table.styles'
import { StyledTimeLimitation } from './CollectorLimitation.styles'

export const StyledPageWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  overflow: hidden;
`

export const StyledTableWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 208px);

  .infinite-scroll-component {
    overflow: inherit !important;
  }
`

export const StaledContainer = styled.div<{ overTime: boolean }>`
  &:nth-child(even) {
    ${StyledRowWrapper} {
      background: #ebeeff3c;
    }
  }

  ${({ overTime }) =>
    overTime &&
    css`
      border: 1px solid #ff000029;
      background: #ff7c2a1a;

      ${StyledTimeLimitation} {
        display: block;
      }
      ${StyledTitle} {
        opacity: 0.5;
      }
    `}
`

export const StyledLoaderPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 1111111;
  pointer-events: none;
`

export const StyledInfiniteLoader = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledEmptyLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`
