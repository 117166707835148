/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable guard-for-in */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Modal from 'react-modal'

import { useWindowSize } from 'helpers'
import { ReactComponent as CloseModalSVG } from 'assets/close-modal.svg'

import { MODAL_STYLE_CONFIRM, MODAL_MOBILE_STYLE_CONFIRM } from 'core/theme'
import { Button } from 'components'
import 'core/theme/imageZoom.css'

import {
  StyledModalContent,
  StyleCloseModal,
  StyledModalTitle,
  StyledButtonsBlock,
  StyledDescription,
} from './Modal.styles'

export const ModalConfirm = ({
  isOpen,
  onRequestClose,
  onClose,
  title = '',
  description = '',
  action = null,
  style = 'warning',
  buttons = true,
  content = null,
}): JSX.Element => {
  const { width } = useWindowSize()

  const getStyle = () => (width >= 480 ? MODAL_STYLE_CONFIRM : MODAL_MOBILE_STYLE_CONFIRM)

  const type = {
    danger: 'bigDanger',
    success: 'bigSuccess',
    warning: 'standardBig',
  }

  const handleAccept = (): void => {
    action && action()
    onClose()
  }
  const handleRefuse = (): void => {
    onClose()
  }
  return (
    <Modal
      isOpen={isOpen}
      style={getStyle()}
      onRequestClose={onRequestClose}
      contentLabel='onRequestClose Example'
    >
      <StyledModalContent>
        <StyleCloseModal onClick={onClose}>
          <CloseModalSVG />
        </StyleCloseModal>
        {title && <StyledModalTitle>{title}</StyledModalTitle>}
        {description && <StyledDescription>{description}</StyledDescription>}
        {content && content()}
        {buttons && (
          <StyledButtonsBlock>
            <Button type={type[style]} onClick={handleAccept}>
              Подтвердить
            </Button>
            <Button type='emptyBig' onClick={handleRefuse}>
              Отменить
            </Button>
          </StyledButtonsBlock>
        )}
      </StyledModalContent>
    </Modal>
  )
}
