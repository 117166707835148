/* eslint-disable @typescript-eslint/no-unused-vars */

import { StyledWrapper } from './Profile.styles'
import { TabsType } from '../types'

export const Profile = (props): JSX.Element => {
  const { toggleOpen, setActiveTab } = props

  return <StyledWrapper>Profile</StyledWrapper>
}
