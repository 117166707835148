import { StyledLoaderRoller, StyledLoaderWrapper, StyledLoaderMessage } from './Loader.styles'
import { ILoaderProps } from './types'

export const Loader = ({ withText, text }: ILoaderProps): JSX.Element => (
  <>
    <StyledLoaderWrapper>
      <StyledLoaderRoller>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </StyledLoaderRoller>
      {withText && <StyledLoaderMessage>{text}</StyledLoaderMessage>}
    </StyledLoaderWrapper>
  </>
)
