import styled, { css } from 'styled-components/macro'
import { THEME } from 'core/theme'
import { MIN_WIDTH, COLUMN_TEMPLATE } from './options'

export const StyledImageDeleteBlock = styled.div`
  margin-top: 35px;

  @media (max-width: 480px) {
    margin-top: 0;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const StyledImageInfoBlock = styled.div`
  width: 500px;
  height: 100%;
  z-index: 1;
  left: 0;
  border-radius: 5px;
  background: ${THEME.COLORS.WHITE};
  margin-right: 20px;
  padding: 10px;
  overflow-y: auto;

  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledModalContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const StyledInfoContentWrapper = styled.div<{ isOpen: boolean }>`
  margin-bottom: 15px;
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${StyledInfoContent} {
        max-height: 2000px;
        margin-top: 5px;
        opacity: 1;
      }
      ${StyledInfoTitle} {
        color: ${THEME.COLORS.BLUE};
      }
    `}
`
export const StyledInfoContent = styled.div<any>`
  margin-left: 10px;
  margin-top: 0;
  opacity: 0;
  max-height: 0px;
  overflow: hidden;

  ${({ edit }) =>
    edit &&
    css`
      margin-left: 0;
    `}
`

export const StyledInfoDescTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #828282;
`

export const StyledInfoDescContent = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.DARK_BLUE};
  margin-bottom: 5px;
`

export const StyledZoomBlock = styled.div<{ rotateDegree: number }>`
  /* cursor: pointer; */
  background-color: ${THEME.COLORS.WHITE};
  overflow: hidden;
  width: 100%;
  height: 100%;

  .lb-title {
    color: #828282;
  }
`

export const StyledImageDate = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin-top: -30px;
  margin-left: 20px;
`

export const StyledImageData = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 480px) {
    height: 90%;
  }
`

export const StyleCloseModal = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: ${THEME.COLORS.WHITE};
  border-radius: 50%;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.35);
  padding: 10px;
  display: flex;
  cursor: pointer;

  @media (max-width: 480px) {
    top: -10px;
    right: -10px;
    z-index: 99;
    padding: 12px;
    background-color: ${THEME.COLORS.WARNING};
    & svg path {
      fill: ${THEME.COLORS.WHITE};
    }
  }
`

export const StyledInfoTitle = styled.p`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 10px;
  color: ${THEME.COLORS.DARK_BLUE};
  cursor: pointer;

  &:hover {
    color: ${THEME.COLORS.BLUE};
  }
`

export const StyledCardCountTitle = styled.p`
  font-weight: bold;
  color: ${THEME.COLORS.SUCCESS};
  border-top: 1px solid ${THEME.COLORS.SUCCESS};
  padding: 10px 0;
`

export const StyledRowWrapper = styled.div<{ indicate: boolean }>`
  padding: 0 35px;
  display: grid;
  grid-template-columns: ${COLUMN_TEMPLATE};
  column-gap: 6px;
  min-width: ${MIN_WIDTH};
  border-bottom: 1px solid ${THEME.COLORS.TABLE_BORDER};
  border-right: 1px solid ${THEME.COLORS.TABLE_BORDER};
  border-left: 1px solid ${THEME.COLORS.TABLE_BORDER};
  cursor: pointer;
  &:hover {
    background: ${THEME.COLORS.TABLE_HOVER}!important;
  }

  ${({ indicate }) =>
    indicate &&
    css`
      background: ${THEME.COLORS.TABLE_INDURATE}!important;
    `}
`

export const StyledValue = styled.div`
  padding: 25px 5px;
  word-break: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const StyledTitle = styled.p<{ select?: boolean }>`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ select }) =>
    select &&
    css`
      cursor: pointer;

      &: hover {
        color: ${THEME.COLORS.BLUE};
      }
    `}

  & svg {
    margin-left: 5px;
  }
`

export const StyledActionTable = styled.div`
  cursor: pointer;
`

export const StyledLoaderPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1111111;
  pointer-events: none;
`

export const StyledTableHeadWrapper = styled.div`
  padding: 0 35px;
  display: grid;
  column-gap: 8px;
  grid-template-columns: ${COLUMN_TEMPLATE};
  min-width: ${MIN_WIDTH};
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px dotted ${THEME.COLORS.TABLE_BORDER};
  border-right: 1px solid ${THEME.COLORS.TABLE_BORDER};
  border-left: 1px solid ${THEME.COLORS.TABLE_BORDER};
  background-color: #f8cc4609;
  background: ${THEME.COLORS.SMOOTH};
  box-shadow: 0 1px 2px rgba(57, 63, 72, 0.1);
`

export const StyledTable = styled.div`
  padding: 15px 5px;
  border-right: 1px dotted ${THEME.COLORS.TABLE_BORDER};
  word-break: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &:last-child {
    border-right: 1px dotted transparent;
  }
`

export const StyledHeadTitle = styled.p`
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: #4b506d;
  word-break: initial;
`
export const StyledTableWrapper = styled.div`
  width: 100%;
  height: calc(100% - 115px);
  height: calc(100vh - 213px);
  overflow: auto;
  /* padding-bottom: 100px; */
  /* position: relative; */
  .infinite-scroll-component {
    overflow: inherit !important;
  }
`

export const StyledTableModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
`
export const StyledModalTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${THEME.COLORS.GRAY};
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
`
export const StyledRemoveImage = styled.div`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #de0000;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }

  svg {
    margin: 0 5px;
  }
`
export const StyledChangeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0;
  font-weight: 700;
`
export const StyledSave = styled.div`
  color: ${THEME.COLORS.SUCCESS};
  cursor: pointer;
`
export const StyledCancel = styled.div`
  color: #de0000;
  cursor: pointer;
`
export const StyledButtonBlockModal = styled.div`
  margin: 0;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
`
export const StyledHr = styled.div`
  height: 1px;
  border-bottom: 1px solid #dfdede;
`

export const StyledPassportVerificationBlock = styled.div`
  margin-top: 10px;
  padding: 5px;
`

export const StyledPassportVerificationTitle = styled.div``
